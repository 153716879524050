exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-scripts-components-StudySemaphore-__studySemaphore___container {\n  max-height: 60vh;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  width: 100%;\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___search {\n  padding-bottom: 4rem;\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___row {\n  margin-bottom: 2rem;\n  display: flex;\n  padding: 0 2rem;\n  justify-content: space-between;\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___sliderContainer {\n  margin-bottom: 1rem;\n  padding: 0 2rem;\n  display: flex;\n  flex: 1 1;\n  margin-right: 4rem;\n  flex-direction: column;\n  max-width: 25vw\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___sliderContainer span {\n    font-size: 2rem;\n    color: #a5a5a5;\n    margin-bottom: 4px;\n  }\n\n.src-scripts-components-StudySemaphore-__studySemaphore___popupContainer {\n  max-height: 50vh;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___popupTitle {\n  font-size: 3rem;\n  color: var(--grayColor);\n  font-weight: 500;\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___popupHeader {\n  padding: 1rem 4rem !important;\n}\n\n.src-scripts-components-StudySemaphore-__studySemaphore___popupFooter {\n  padding: 2rem 4rem !important;\n}\n\n@media screen and (max-width: 768px) {\n  \n\n  .src-scripts-components-StudySemaphore-__studySemaphore___sliderContainer {\n    max-width: 45vw;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .src-scripts-components-StudySemaphore-__studySemaphore___sliderContainer {\n    max-width: 100%;\n    margin-right: 0;\n    padding: 0;\n  }\n  \n  .src-scripts-components-StudySemaphore-__studySemaphore___row {\n    flex-direction: column;\n  }\n}\n\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-StudySemaphore-__studySemaphore___container",
	"search": "src-scripts-components-StudySemaphore-__studySemaphore___search",
	"row": "src-scripts-components-StudySemaphore-__studySemaphore___row",
	"sliderContainer": "src-scripts-components-StudySemaphore-__studySemaphore___sliderContainer",
	"popupContainer": "src-scripts-components-StudySemaphore-__studySemaphore___popupContainer",
	"popupTitle": "src-scripts-components-StudySemaphore-__studySemaphore___popupTitle",
	"popupHeader": "src-scripts-components-StudySemaphore-__studySemaphore___popupHeader",
	"popupFooter": "src-scripts-components-StudySemaphore-__studySemaphore___popupFooter"
};