// @flow
import React from 'react';
import { Field } from 'redux-form';
import i18n from 'i18n-js';

import type { FOBI_ELEMENT_TYPE } from './types';
import * as types from './types';

import {
  RenderInput,
  RenderTextarea,
  RenderDatePicker,
  RenderSelect,
  RenderSwitch,
  RenderRadioOptions,
} from '../FieldRenderer';


type FobiElementPropTypes = {
  element: FOBI_ELEMENT_TYPE,
  formName: string,
  getElementName: Function,
  showPreview?: boolean,
  baseColor?: string,
  elementIndex: Number,
};

const FobiReduxFormField = ({
  formName,
  elementIndex,
  element,
  getElementName,
  showPreview = false,
  baseColor = '',
}: FobiElementPropTypes) => {

  const { id, type } = element;
  const name = `${id}-${type}`;
  delete element.name;
  
  // const plugin_data = {
  //   ...element.plugin_data,
  // };

  // // Remove unused plugin attributes
  // delete plugin_data.readonly_value;
  // delete plugin_data.autocomplete_value;
  // delete plugin_data.multiple_value;
  // delete plugin_data.autofocus_value;
  // delete plugin_data.disabled_value;

  switch (element.type) {
    case types.BOOLEAN_ELEMENT:
      return (
        <Field
          component={RenderSwitch}
          {...element}
          name={name}
          showPreview={false}
          baseColor={baseColor}
        />
      );
    case types.DATE_ELEMENT:
      return (
        <Field
          component={RenderDatePicker}
          {...element}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.EMAIL_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...element}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.DECIMAL_ELEMENT:
    case types.FLOAT_ELEMENT:
    case types.NUMERIC_OPEN_ELEMENT:
      // At this point, elementIndex is the only different element among the duplicate (intentional) question fields 
      const repetitions = element["num_repetitions"];
      const minDecimalPlaces = element["min_decimal_places"];
      const maxDecimalPlaces = element["max_decimal_places"];
      const hasIntegerConstraint = minDecimalPlaces !== undefined && maxDecimalPlaces !== undefined 
        && minDecimalPlaces === 0 && maxDecimalPlaces === 0;
      let elementId = id;
      let elementName = name;
      if (repetitions > 1) {
        elementId = `${id}_duplicated_${elementIndex}`;
        elementName = `${name}_duplicated_${elementIndex}`;
      }

      return (
        <Field
          component={RenderInput}
          {...element}
          id={elementId}
          name={elementName}
          type="number"
          showPreview={showPreview}
          baseColor={baseColor}
          parse={hasIntegerConstraint ? (val) => parseInt(val, 10) : undefined}
        />
      );
    case types.NULL_BOOLEAN_ELEMENT:
      return (
        <Field
          component={RenderSwitch}
          {...element}
          name={name}
          handleNull
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.SELECT_ELEMENT:
      return (
        <Field
          component={RenderSelect}
          {...element}
          name={name}
          id={`${formName}_${element.name}`}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.TEXT_ELEMENT:
      return (
        <Field
          component={RenderInput}
          {...element}
          name={name}
          type="text"
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.TEXTAREA_ELEMENT:
      return (
        <Field
          component={RenderTextarea}
          {...element}
          name={name}
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
    case types.RADIO_ELEMENT:
      return (
        <Field
          component={RenderRadioOptions}
          name={name}
          selectMany={element.select_many}
          showPreview={showPreview}
          baseColor={baseColor}
          showDetail
          {...element}
        />
      )
    default:
      return (
        <Field
          component={RenderInput}
          {...element}
          name={name}
          type="text"
          showPreview={showPreview}
          baseColor={baseColor}
        />
      );
  }
};

export default FobiReduxFormField;
