// @flow
import type { FOBI_FORM_TYPE } from './types';
import * as types from './types';
import * as validators from './elementValidators';
import _ from "lodash";



export const generateValidator = ({ form_elements }, type, formValues) => (values: Object) => {
  const errors = {};
  const tempDuplicatedKeys = [];

  form_elements.forEach((formElement, idx) => {
    const { id, type, required } = formElement;
    const key = `${id}-${type}`;
    let value = values[key];

    if (formValues && formElement["num_repetitions"] && formElement["num_repetitions"] > 1) {
      const duplicatedKeys = Object.keys(formValues).filter(value => value.indexOf(key) !== -1 && !tempDuplicatedKeys.includes(value));
      const duplicated = duplicatedKeys[0];
      value = values[duplicated];
      tempDuplicatedKeys.push(duplicated);
    }

    let lError;
    switch (formElement.type) {
      case types.BOOLEAN_ELEMENT: {
        lError = validators.validateBooleanElement(value, formElement);
        break;
      }
      case types.DATE_ELEMENT: {
        lError = validators.validateDateElement(value, formElement);
        break;
      }
      case types.EMAIL_ELEMENT: {
        lError = validators.validateEmailElement(value, formElement);
        break;
      }
      // Validate numeric open (or not that open: integer or decimal/float values)
      case types.NUMERIC_OPEN_ELEMENT: {
        lError = validators.validateNumericOpenElement(value, formElement, form_elements, formValues);
        break;
      }
      case types.NULL_BOOLEAN_ELEMENT: {
        lError = validators.validateNullBooleanElement(value, formElement);
        break;
      }
      case types.SELECT_ELEMENT: {
        lError = validators.validateSelectElement(value, formElement);
        break;
      }
      case types.TEXT_ELEMENT: {
        lError = validators.validateTextElement(value, formElement);
        break;
      }
      case types.TEXTAREA_ELEMENT: {
        lError = validators.validateTextareaElement(value, formElement);
        break;
      }
      case types.RADIO_ELEMENT: {
        lError = validators.validateRadioOptionsElement(value, formElement);
        break;
      }
      default: {
        lError = validators.validateTextElement(value, formElement);
        break;
      }
    }

    if (lError !== undefined) {
      // Case 1: Validate form element with repetitions when has been answered
      if (formValues && formElement["num_repetitions"] && formElement["num_repetitions"] > 1) {
        const duplicatedKeys = Object.keys(formValues).filter(value => value.indexOf(key) !== -1);
        duplicatedKeys.forEach( duplicated => {
          errors[duplicated] = lError;
        })
      }

      // Case 2: Validate form element with repetitions if has no answer, but its required...
      if (formElement["num_repetitions"] && formElement["num_repetitions"] > 1) {
        errors[`${key}_duplicated_${idx}`] = lError;
      } else {
        errors[key] = lError;
      }
    }
  });

  // New requirement: anthropometry studies needs a phase...
  // if (!values.phase && type !== ANTHROPOMETRY_TYPE) ...
  if (!values.phase) {
    errors['phase'] = 'Requerido';
  }

  return errors;
};

export const extractInitialValues = ({ form_elements }: FOBI_FORM_TYPE): Object => {
  const initialValues = {};

  form_elements.forEach(({ plugin_data: { name, initial } }) => {
    if (name != null) {
      initialValues[name] = initial;
    }
  });

  return initialValues;
};

export const getQuestionsVisibilities = ( formElements: Array<FOBI_ELEMENT_TYPE>, formValues: Object,  allInstrumentQuestions: Object): FormChildElementPropTypes => {
  // NOTE: We'll assume that parent and childs exists on the same context (sub-form)
  // Initialize every question with visibility = true
  const visibleFormElements = Object.assign({})
  formElements.forEach(elem => {
    visibleFormElements[elem.id] = true;
  })
  
  // Keys to replace
  const parentRelationGroup = "parent_ordinals";
  const oppositeParentRelationGroup = "opposite_parent_ordinals";
  // Keys to keep & track on visibility validation
  const parentRelation = "parent_ordinal";
  const isOppositeParent = "is_opposite_parent_ordinal";

  // Group questions based on its 'parent_ordinals'/'opposite_parent_ordinals' value
  const segmentedParentRelation = _(formElements).filter(
    elem => (_.has(elem, parentRelationGroup) && elem[parentRelationGroup] != null)
      || (_.has(elem, oppositeParentRelationGroup) && elem[oppositeParentRelationGroup] != null)
  ).map( elem => {
    return elem[oppositeParentRelationGroup]
    ? elem[oppositeParentRelationGroup].map(parentOrdinal => {
      return {
        ..._.omit(elem, oppositeParentRelationGroup), 
        [parentRelation]: parentOrdinal,
        // NOTE: we still group by parents, but we need to track if visibility is
        //        activated on opposite value!
        [isOppositeParent]: true,
      }
    })
    : elem[parentRelationGroup].map(parentOrdinal => {
      return {
        ..._.omit(elem, parentRelationGroup),
        [parentRelation]: parentOrdinal
      }
    })
  }).flatten();
  const childQuestions = segmentedParentRelation.groupBy(parentRelation).value();
  
  // OK, lets check parents for every child queried...
  Object.keys(childQuestions).forEach(parentId => {
    // STEP 1. Validate within same Question Set
    const parentIdx = formElements.findIndex(elem => elem.ordinal === parseInt(parentId));
    let parentWithinQuestionSet = parentIdx !== -1;
    let externalParent = null;
    
    // STEP 2. Try to check among others QuestionSets
    if (!parentWithinQuestionSet) {
      const possibleTypes = [
        types.RADIO_ELEMENT, types.BOOLEAN_ELEMENT, 
        types.DATE_ELEMENT, types.NUMERIC_OPEN_ELEMENT,
      ]

      for (let j = 0; j < possibleTypes.length; j++) {
        const parentQuestion = allInstrumentQuestions[parentId]
        const type = possibleTypes[j];
        const parentTestName = `${parentQuestion.id}-${type}`;
        
        const isParentAnswered = _.has(formValues, parentTestName);
        if (isParentAnswered) {
          externalParent = parentQuestion;
          break;
        }
      }
    }
    
    // STEP 3. If parent idx is still missing, return and omit
    if (parentIdx === -1 && !externalParent) return;

    // Parent can be retrieve within the same Question Set
    //    or from the external parent found before...

    const parent = parentWithinQuestionSet 
      ? formElements[parentIdx]
      : externalParent;
    const parentName = `${parent.id}-${parent.type}`;

    const isParentAnswered = _.has(formValues, parentName);
    if (!isParentAnswered) {
      childQuestions[parentId].forEach(child => {
        visibleFormElements[child.id] = false;
      })
      return;
    }

    // For each child of parent question, validate if can be shown
    childQuestions[parentId].forEach(child => {
      const parentAnswer = formValues[parentName];
      // Validate each child according to parent question type
      switch (parent.type) {
        case types.RADIO_ELEMENT:
          // Check if choice (single) or at least one of the choices (multiple) selected has the field: show_child_question
          const { choices = [] } = parentAnswer;

          let visibility = false;
          // For each choice, we'll validate that selected choice(s) makes visible its child questions
          choices.forEach(formChoice => {
            const parentChoiceIdx = parent.choices.findIndex(choice => choice.id === formChoice.id);
            if (parentChoiceIdx === -1) return;

            const choiceSelected = parent.choices[parentChoiceIdx];
            if (_.has(choiceSelected, 'show_child_question') 
                && choiceSelected['show_child_question']
                && (visibleFormElements[parent.id] || externalParent)
            ) {
              visibility = true;
            } 
          })

          visibleFormElements[child.id] = visibility;
          break;
        case types.BOOLEAN_ELEMENT:
          // Check question field: show_child_question_value
          let booleanVisibility = false
          
          if (_.has(parent, 'show_child_question_value')) {
            // New Requirement: 'show_child_question_on_opposite_value'
            booleanVisibility = (visibleFormElements[parent.id] || externalParent) 
              && (
                _.has(parent, 'show_child_question_on_opposite_value') && 
                _.has(child, isOppositeParent)
                ? !parent['show_child_question_value'] === parentAnswer
                : parent['show_child_question_value'] === parentAnswer
              );
          }
          visibleFormElements[child.id] = booleanVisibility
          break;
        case types.DATE_ELEMENT:
        case types.NUMERIC_OPEN_ELEMENT:
          visibleFormElements[child.id] = parentAnswer && parentAnswer.length && (visibleFormElements[parent.id] > 0 || externalParent) ? true : false;
          break;
        default:
          visibleFormElements[child.id] = (visibleFormElements[parent.id] || externalParent) && true;
      }
    });
  })

  return visibleFormElements;
}
