import React, { useState } from "react";
import Button from '../Button';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/lib/md';

import StudySubmissionsQueue from '../StudySubmissionsQueue';
import styles from './studyConnectionFooter.css';
import { connect } from "react-redux";
import * as selectors from '../../reducers';


type StudyConnectionFooterPropTypes = {
  online: Boolean,
};

const StudyConnectionFooter = ({ 
  online,
}: StudyConnectionFooterPropTypes) => {
  const [isQueueOpen, setIsQueueOpen] = useState(false);
  const toggleQueueOpen = () => setIsQueueOpen(!isQueueOpen);

  return (
    !online && <div className={` 
      ${styles.queueContainer}
      ${online ? styles.hide : ''}
      ${isQueueOpen ? styles.queueExpanded : ''}
    `}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>
          {'Aplicación en modo "Desconectado"'}
        </span>
        <span className={`
          ${styles.titleInfo}
          ${styles.loading}
        `}>
          {'Reconectando'}
        </span>
        <Button
          className={styles.expandButton}
          Icon={isQueueOpen ? MdKeyboardArrowDown : MdKeyboardArrowUp}
          tint="white"
          isRound
          onClick={() => toggleQueueOpen()}
        />
      </div>
      <div className={`
        ${styles.queueList}
        ${!isQueueOpen ? styles.hide : ''}
      `}>
        {
          isQueueOpen && 
          <div className={styles.submissionsContainer}>
            <StudySubmissionsQueue/>
          </div>
        }
      </div>
    </div>
  )
};

export default connect(
  state => ({
    online: selectors.hasInternetConnection(state),
  })
)(StudyConnectionFooter);
