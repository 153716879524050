//@flow

export const transformOmnisearchResults = (response) => (
  response.map((result) => {
    const newResult = {
      ...result,
      value: `${result.type}${result.id}`,
      textIndex: result.text,
      object_id: result.id,
      result_type: result.type
    };

    if (result.type === 'kid') {
      return {
        ...newResult,
        label: result.full_name,
        group_type: 'user',
        target_role: 'user',
        base_model_id: newResult.id,
      }
    }

    return {
      ...newResult,
      label: result.first_name,
      group_type: 'user',
      target_role: 'user',
      base_model_id: newResult.id,
    };

  })
);
