// @flow
import React from 'react';

import Button from '../Button';

import styles from './switch.css';


type SwitchPropTypes = {
  trueTitle?: string,
  falseTitle?: string,
  activeTint?: string,
  inactiveTint?: string,
  handleNull?: boolean,
  onChange?: Function,
  onBlur?: Function,
  value?: ?boolean,
  isSingleButton?: boolean,
  isDisabled?: boolean,
  clearMargins?: boolean,
};

class Switch extends React.Component<SwitchPropTypes> {
  constructor(props: SwitchPropTypes) {
    super(props);

    const { onChange, value, handleNull } = props;

    if ([true, false].includes(value)) {
      return onChange && onChange(value);
    }

    if (!handleNull) {
      return onChange && onChange(false);
    }

    return onChange && onChange(null);
  }

  render() {
    const {
      onChange,
      onBlur,
      value,
      trueTitle = 'Si',
      falseTitle = 'No',
      activeTint = 'main',
      inactiveTint = 'mediumGray',
      handleNull = false,
      isSingleButton = false,
      isDisabled = false,
      clearMargins = false,
    } = this.props;

    let rvalue;
    if (!isSingleButton && handleNull) {
      rvalue = value === '' ? null : value;
    } else {
      rvalue = value === '' ? false : value;
    }

    if (isSingleButton) {
      return (
        <Button
          type="button"
          tint={rvalue ? activeTint : inactiveTint}
          title={rvalue ? trueTitle : falseTitle}
          onClick={() => onChange && onChange(!rvalue)}
          // eslint-disable-next-line react/destructuring-assignment
          onBlur={() => onBlur && onBlur(this.props.value)}
          disabled={isDisabled}
          clearMargins={clearMargins}
          isFlex
        />
      );
    }

    return (
      <div className={styles.switch}>
        <Button
          type="button"
          tint={rvalue ? activeTint : inactiveTint}
          title={trueTitle}
          // eslint-disable-next-line react/destructuring-assignment
          onBlur={() => onBlur && onBlur(this.props.value)}
          onClick={
            () => {
              if (handleNull) {
                if (rvalue) {
                  return onChange && onChange(null);
                }
              }
              return value !== true && onChange && onChange(true);
            }
          }
          disabled={isDisabled}
          isLeft
          clearMargins={clearMargins}
          className={isDisabled ? styles.disabled : ''}
        />
        <Button
          type="button"
          tint={rvalue === false ? activeTint : inactiveTint}
          title={falseTitle}
          // eslint-disable-next-line react/destructuring-assignment
          onBlur={() => onBlur && onBlur(this.props.value)}
          onClick={
            () => {
              if (handleNull) {
                if (rvalue === false) {
                  return onChange && onChange(null);
                }
              }
              return value !== false && onChange && onChange(false);
            }
          }
          disabled={isDisabled}
          isRight
          isFlex
          clearMargins={clearMargins}
          className={isDisabled ? styles.disabled : ''}
        />
      </div>
    );
  }
}


export default Switch;


export const NullSwitch = (props: SwitchPropTypes) => <Switch {...props} handleNull />;
