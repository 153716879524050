//@flow
import React, { useState, useEffect } from 'react';
import { Grid } from 'react-virtualized';
import groupBy from 'lodash/groupBy';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import isEmptyObject from 'is-empty-object';
import { sumArrays } from '../../utils';

import useWindowSize from '../../hooks/useWindowSize';
import GenericStackedChart from '../GenericStackedChart';
import { listToMatrix } from '../../utils';
/* import styles from './studyResults.css'; */

// CSS Breakpoint(s)
const TABLET_BREAKPOINT = 768;

type EntrepreneurChartViewPropTypes = {
  results: Array<Object>,
  phases: Array<number>,
  name: string,
  participant: string,
};

const EntrepreneurChartView = ({
  results = [],
  phases,
  name,
  participant,
}: EntrepreneurChartViewPropTypes) => {

  const [pointsResult, setPointsResult] = useState([]);
  const matrix = listToMatrix(pointsResult, 2);
  const { height = [], width } = useWindowSize();

  
  useEffect(() => {
    const resultsWithPoints = results.filter(result => result.indicator !== null && !isEmptyObject(result.pointsResults));
    const entrepreneurTypes = uniqWith(resultsWithPoints.map(result => Object.keys(result.pointsResults)), isEqual)[0] || [];
    
    let entrepreneurResults = {};
    entrepreneurTypes.forEach(entrepreneurType => {
      const resultsByIndicatorCategory = groupBy(resultsWithPoints, 'indicator');
      const categories = Object.keys(resultsByIndicatorCategory);
      // Initialize empty data for each category, with its values for each phase
      categories.forEach(category => {
        entrepreneurResults = {
          ...entrepreneurResults,
          [entrepreneurType]: {
            ...entrepreneurResults[entrepreneurType],
            [category]: [...phases.map(() => 0)],
          }
        }
      })
      
      categories.forEach(category => {
        const categoryPoints = resultsByIndicatorCategory[category].reduce((acc, curr) => {
          const total = [...sumArrays(acc, curr.pointsResults[entrepreneurType] || [])];
          return total;
        }, entrepreneurResults[entrepreneurType][category])
        entrepreneurResults = {
          ...entrepreneurResults,
          [entrepreneurType]: {
            ...entrepreneurResults[entrepreneurType],
            [category]: categoryPoints,
          }
        }
      })
    })

    // Actually, we only have one entrepreneur type on results. 
    const finalResults = Object.keys(entrepreneurResults).map((key, idx) => ({
      id: idx,
      name: key,
      pointsResults: entrepreneurResults[key]
    }))
    setPointsResult(finalResults)
  }, [results, phases])


  const renderRow = ({ columnIndex, key, rowIndex, style }) => {
    return (
      matrix[rowIndex][columnIndex] 
        ? (
          <div
            style={style}
            key={key}
          >
            <GenericStackedChart
              key={matrix[rowIndex][columnIndex].id}
              name={name}
              phases={phases}
              results={matrix[rowIndex][columnIndex].pointsResults}
              participant={participant}
            />
          </div>
        ) : null
    )
  };

  return (
    <Grid
      height={height - 450}
      rowCount={matrix.length}
      columnCount={ width > TABLET_BREAKPOINT ? 2 : 1}
      columnWidth={width > TABLET_BREAKPOINT ? ((width - 400) * .49) : ((width - 30) * .85) }
      rowHeight={width > TABLET_BREAKPOINT ? 500 : (height * 0.5)}
      width={width > TABLET_BREAKPOINT ? width - 400 : ((width - 30) * .85)}
      cellRenderer={renderRow}
    />
  )
};

export default EntrepreneurChartView;
