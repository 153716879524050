// @flow
import React from 'react';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import OrganizationGraphicsContent from '../OrganizationGraphicsPopupContent';

import styles from './orgGraphicsPopup.css';


const OrganizationGraphicsPopup = () => (
  <Popup
    id="organizationGraphics"
    theme="main"
    transitionMs={600}
  >
    <PopupContainer isFullscreen>
      <div className={styles.closeButton}>
        <PopupCloseButton
          Icon={MdClose}
          // size='large'
          // tint='gray'
          // isTransparent={true}
          tint="mediumGray"
          isRound={true}
        />
      </div>
      <OrganizationGraphicsContent />
    </PopupContainer>
  </Popup>
);

export default OrganizationGraphicsPopup;
