//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  INSTRUMENT_SUBMISSIONS_ACTION_TYPE,
  INSTRUMENT_SUBMISSION_TYPE,
} from '../types/instrumentSubmissions';
import * as common from './common';
import * as types from '../types/instrumentSubmissions';


export type InstrumentsubmissionsState = {
  byId: { [ID_TYPE]: INSTRUMENTSUBMISSION_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: number,
  counter: number,
  fetching: Array<ID_TYPE>,
  filters: Object,
  nextPage: ?number,
  isSubmitting: boolean,
  formError: ERROR_TYPE,
  errorValues: Array<ID_TYPE>,
};

const byId = common.byId({
  added: [
    types.INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED,
    types.INSTRUMENT_SUBMISSION_FETCH_COMPLETED,
  ],
  updated: [
    types.INSTRUMENT_SUBMISSIONS_UPDATE_STARTED,
  ],
  confirmed: [types.INSTRUMENT_SUBMISSIONS_ADD_COMPLETED],
  fetched: [types.INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED],
  removed: [types.INSTRUMENT_SUBMISSIONS_REMOVED],
});

const order = common.order({
  added: [
    types.INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED,
  ],
  confirmed: [types.INSTRUMENT_SUBMISSIONS_ADD_COMPLETED],
  fetched: [types.INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED],
  cleared: [types.INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED],
  removed: [types.INSTRUMENT_SUBMISSIONS_REMOVED],
  preferPrepend: true,
});

const isFetching = common.isFetching({
  started: [types.INSTRUMENT_SUBMISSIONS_FETCH_STARTED],
  succeed: [types.INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED],
  failed: [types.INSTRUMENT_SUBMISSIONS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.INSTRUMENT_SUBMISSIONS_FETCH_STARTED,
    types.INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED
  ],
  populate: [types.INSTRUMENT_SUBMISSIONS_FETCH_FAILED],
});

const selected = common.mux({
  selected: [types.INSTRUMENT_SUBMISSION_SELECTED],
  allDeselected: [types.UNSELECT_ALL_INSTRUMENT_SUBMISSIONS],
  default: -1,
});

const formError = common.error({
  clear: [
    types.INSTRUMENT_SUBMISSIONS_UPDATE_STARTED,
    types.INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED,
    types.INSTRUMENT_SUBMISSIONS_ADD_STARTED,
    types.INSTRUMENT_SUBMISSIONS_ADD_COMPLETED,
    types.INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED,
    types.INSTRUMENT_SUBMISSION_FETCH_STARTED,
  ],
  populate: [
    types.INSTRUMENT_SUBMISSIONS_UPDATE_FAILED,
    types.INSTRUMENT_SUBMISSIONS_ADD_FAILED,
  ],
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const filters = (
  state: Object = {},
  action: INSTRUMENTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }
    default: {
      return state;
    }
  }
};

const errorValues = common.order({
  cleared: [
    types.INSTRUMENT_SUBMISSIONS_ADD_STARTED,
    types.INSTRUMENT_SUBMISSIONS_UPDATE_STARTED,
    types.INSTRUMENT_SUBMISSIONS_ADD_COMPLETED,
    types.INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED,
    types.CLEAR_REQUIRED_VALUES_ERROR,
  ],
  replaced: [types.REQUIRED_VALUES_ERROR],
})

const nextPage = common.nextPage({
  fetched: [types.INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED],
  failed: [types.INSTRUMENT_SUBMISSIONS_FETCH_FAILED],
  reset: [types.INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED],
});

const isSubmitting = common.isFetching({
  started: [
    types.INSTRUMENT_SUBMISSIONS_ADD_STARTED,
    types.INSTRUMENT_SUBMISSIONS_UPDATE_STARTED,
  ],
  succeed: [
    types.INSTRUMENT_SUBMISSIONS_ADD_COMPLETED,
    types.INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED,
  ],
  failed: [
    types.INSTRUMENT_SUBMISSIONS_ADD_FAILED,
    types.INSTRUMENT_SUBMISSIONS_UPDATE_FAILED,
  ]
});

const fetching = common.fetching({
  started: [types.INSTRUMENT_SUBMISSION_FETCH_STARTED],
  succeed: [types.INSTRUMENT_SUBMISSION_FETCH_COMPLETED],
  failed: [types.INSTRUMENT_SUBMISSION_FETCH_FAILED]
});

const instrumentSubmissions = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
  counter,
  filters,
  fetching,
  nextPage,
  isSubmitting,
  formError,
  errorValues,
});


export default instrumentSubmissions;


// Selectors
export const getInstrumentSubmission = (state: InstrumentsubmissionsState, id: ID_TYPE): ?INSTRUMENTSUBMISSION_TYPE => state.byId[id];
export const getInstrumentSubmissions = (state: InstrumentsubmissionsState): Array<?INSTRUMENTSUBMISSION_TYPE> => state.order.map(i => getInstrumentSubmission(state, i));
export const isInstrumentSubmissionFetching = (state: InstrumentsubmissionsState, id: ID_TYPE): boolean => state.fetching.includes(id);
export const getInstrumentsubmissionsError = (state: InstrumentsubmissionsState): ERROR_TYPE => state.error;
export const getSelectedInstrumentsubmission = (state: InstrumentsubmissionsState): ?INSTRUMENTSUBMISSION_TYPE => getInstrumentSubmission(state, state.selected);
export const getInstrumentsubmissionCounter = (state: InstrumentsubmissionsState): number => state.counter;

export const isFetchingInstrumentSubmissions = (state: InstrumentsubmissionsState): boolean => state.isFetching;

export const getAllInstrumentsSubmissions = (state: InstrumentsubmissionsState): Array<INSTRUMENTSUBMISSION_TYPE> => state.order.map(id => state.byId[id]);
export const getAllInstrumentsSubmissionsIds = (state: InstrumentsubmissionsState): Array<ID_TYPE> => state.order;

export const getInstrumentSubmissionsFilter = (state: InstrumentsubmissionsState, filter: string): string => state.filters[filter];
export const getInstrumentSubmissionsFilters = (state: InstrumentsubmissionsState): Object => state.filters;

export const getInstrumentSubmissionsNextPage = (state: InstrumentsubmissionsState): ?number => state.nextPage;
export const hasMoreInstrumentSubmissions = (state: InstrumentsubmissionsState): boolean => {
  return (state.nextPage != null) && !state.isFetching && state.nextPage !== -1;
};
export const isSubmittingInstrument = (state: InstrumentsubmissionsState): boolean => state.isSubmitting;
export const getSubmissionError = (state: InstrumentsubmissionsState): ERROR_TYPE => state.formError;
export const getSubmissionErrorValues = (state: InstrumentsubmissionsState): Array<number> => state.errorValues;
