// @flow
import React, { Fragment } from 'react';
import orderBy from 'lodash/orderBy';

import { FullLoader } from '../Loader';
import AntropometryChart from '../AntropometryChart';
import styles from './orgGraphics.css';


type OrganizationGraphicsType = {
  loading: boolean,
  data: Array<Object>,
  name: string,
  olderChildrenFilters: boolean,
};


const OrganizationGraphics = ({
  loading = true,
  data = [],
  name,
  olderChildrenFilters = false,
}: OrganizationGraphicsType) => {

  const gChart = 'boys';
  const kids = 'niños';

  const resultsAge = orderBy(data, ['age'], ['asc']);
  const resultsByHeight = orderBy(data, ['height'], ['asc']);
  const resultsByLength = orderBy(data, ['length'], ['asc']);

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <FullLoader
          tint="mediumGray"
          title="Cargando graficas"
        />
      </div>
    )
  } else {
    return (
      <Fragment>  
        <div className={styles.row}>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="age"
              fromUnits="cm"
              to={`${ olderChildrenFilters ? "height" : "length" }`}
              toUnits="Kg"
              title={`${ olderChildrenFilters ? 'Talla' : 'Longitud' } para la edad ${olderChildrenFilters ? `(${kids})` : `(${kids} menores de 2 años)`}`}
              measurements={resultsAge}
              entityName={name}
              isOrganizationFiltered
              olderChildrenFilters={olderChildrenFilters}
            />
          </div>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="age"
              fromUnits="cm"
              to="weight"
              toUnits="Kg"
              title={`Peso para la edad (${kids})`}
              measurements={data}
              entityName={name}
              isOrganizationFiltered
              olderChildrenFilters={olderChildrenFilters}
            />
          </div>
        </div>
        {
          !olderChildrenFilters && (
            <div className={styles.row}>
              <div className={`${styles.chart}`}>
                <AntropometryChart
                  gender={gChart}
                  from="age"
                  fromUnits="cm"
                  to="height"
                  toUnits="cm"
                  title={`Talla para la edad (${kids} mayores de 2 años)`}
                  measurements={resultsAge}
                  entityName={name}
                  isOrganizationFiltered
                  olderChildrenFilters={olderChildrenFilters}
                />
              </div>
              <div className={`${styles.chart}`}>
              <AntropometryChart
                  gender={gChart}
                  from="height"
                  fromUnits="cm"
                  to="weight"
                  toUnits="Kg"
                  title={`Peso para la talla (${kids} mayores de 2 años)`}
                  measurements={resultsByHeight}
                  entityName={name}
                  isOrganizationFiltered
                  olderChildrenFilters={olderChildrenFilters}
                />
              </div>
            </div>
          )
        }
        {
          !olderChildrenFilters && (
            <div className={styles.row}>
              <div className={`${styles.chart}`}>
                <AntropometryChart
                  gender={gChart}
                  from="length"
                  fromUnits="cm"
                  to="weight"
                  toUnits="Kg"
                  title={`Peso para la longitud (${kids} menores de 2 años)`}
                  measurements={resultsByLength}
                  entityName={name}
                  isOrganizationFiltered
                  olderChildrenFilters={olderChildrenFilters}
                />
              </div>

              <div className={styles.filled} />
            </div>
          )
        }
      </Fragment>
    )
  }

};


export default OrganizationGraphics;
