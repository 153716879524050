// @flow
import type { ComponentType } from 'react';
import React from 'react';

import styles from './omnisearchResultDummy.css';


type OmnisearchResultDummyPropTypes = {
  title: string,
  subtitle?: React<Node>,
  pictureURL?: string,
  Icon?: ComponentType<*>,
  isSelected?: boolean,
  size?: string,
  onClick?: Function
};

const OmnisearchResultDummy = ({
  title = '',
  subtitle,
  pictureURL,
  Icon,
  isSelected,
  size = 'medium',
  onClick,
}: OmnisearchResultDummyPropTypes) => (
  <div
    className={
      `
        ${styles.result}
        ${styles[size]}
        ${isSelected ? styles.selected : ''}
      `
    }
    onClick={onClick}
    onKeyPress={onClick}
    role="presentation"
  >
    {
      typeof Icon !== 'undefined' && (
        <div className={styles.iconContainer}>
          <Icon />
        </div>
      )
    }
    { typeof pictureURL !== 'undefined' && <img src={pictureURL} alt="" /> }
    <div className={styles.data}>
      <div className={styles.title}>
        { title }
      </div>
      {
        typeof subtitle !== 'undefined' && (
          <div className={styles.subtitle}>
            { subtitle }
          </div>
        )
      }
    </div>
  </div>
);


export default OmnisearchResultDummy;
