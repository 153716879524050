// @flow
import type { ComponentType } from 'react';
import React from 'react';

import styles from './navbarButton.css';


type NavbarButtonPropTypes = {
  Icon?: ComponentType<*>,
  badgeCount?: number,
  isOpaque?: boolean,
  hasSubmenu?: boolean,
  className?: string,
};

const NavbarButton = ({
  Icon,
  badgeCount = 0,
  isOpaque = false,
  hasSubmenu = false,
  className = '',
  ...props
}: NavbarButtonPropTypes) => (
  <a
    className={
      `
        ${styles.navbarButton}
        ${isOpaque ? styles.opaque : ''}
        ${hasSubmenu ? styles.withSubmenu : ''}
        ${className}

      `
    }
    {...props}
  >
    { Icon && <Icon size="34" /> }
    {
      badgeCount > 0 && (
        <span className={styles.badge}>
          { badgeCount }
        </span>
      )
    }
  </a>
);


export default NavbarButton;
