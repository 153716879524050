// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';


export type STUDY_RESULTS_TYPE = {
  id: ID_TYPE,
  label: String,
  result: String,
};

export type FETCH_STUDY_RESULTS_STARTED_TYPE = {
  type: 'FETCH_STUDY_RESULTS_STARTED',
  payload: {
    id: ID_TYPE,
  }
};
export const FETCH_STUDY_RESULTS_STARTED = 'FETCH_STUDY_RESULTS_STARTED';

export type FETCH_STUDY_RESULTS_COMPLETED_TYPE = {
  type: 'FETCH_STUDY_RESULTS_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: STUDY_RESULTS_TYPE},
    result: Array<ID_TYPE>,
  }
};
export const FETCH_STUDY_RESULTS_COMPLETED = 'FETCH_STUDY_RESULTS_COMPLETED';

export type FETCH_STUDY_RESULTS_FAILED_TYPE = {
  type: 'FETCH_STUDY_RESULTS_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_STUDY_RESULTS_FAILED = 'FETCH_STUDY_RESULTS_FAILED';

export type UPDATE_STUDY_RESULTS_FILTERS_TYPE = {
  type: 'UPDATE_STUDY_RESULTS_FILTERS',
  payload: {
    key: string,
    value: any,
  }
};
export const UPDATE_STUDY_RESULTS_FILTERS = 'UPDATE_STUDY_RESULTS_FILTERS';

export type CLEAR_STUDY_RESULTS_FILTERS_TYPE = {
  type: 'CLEAR_STUDY_RESULTS_FILTERS',
};
export const CLEAR_STUDY_RESULTS_FILTERS = 'CLEAR_STUDY_RESULTS_FILTERS';

export type INSTRUMENT_RESULTS_STARTED_TYPE = {
  type: 'INSTRUMENT_RESULTS_STARTED',
  payload: ID_TYPE,
};
export const INSTRUMENT_RESULTS_STARTED = 'INSTRUMENT_RESULTS_STARTED';

export type INSTRUMENT_RESULTS_COMPLETED_TYPE = {
  type: 'INSTRUMENT_RESULTS_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: STUDY_RESULTS_TYPE},
    result: Array<ID_TYPE>,
  },
};
export const INSTRUMENT_RESULTS_COMPLETED = 'INSTRUMENT_RESULTS_COMPLETED';

export type INSTRUMENT_RESULTS_FAILED_TYPE = {
  type: 'INSTRUMENT_RESULTS_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_RESULTS_FAILED = 'INSTRUMENT_RESULTS_FAILED';


export type STUDY_RESULTS_ACTION_TYPE =
  | FETCH_STUDY_RESULTS_STARTED_TYPE
  | FETCH_STUDY_RESULTS_COMPLETED_TYPE
  | FETCH_STUDY_RESULTS_FAILED_TYPE
  | CLEAR_STUDY_RESULTS_FILTERS_TYPE
  | UPDATE_STUDY_RESULTS_FILTERS_TYPE
  | INSTRUMENT_RESULTS_STARTED_TYPE
  | INSTRUMENT_RESULTS_COMPLETED_TYPE
  | INSTRUMENT_RESULTS_FAILED_TYPE;
