// @flow
import React from 'react';

import BackendSidebarLink from '../BackendSidebarLink';
import { connect } from 'react-redux';
import * as selectors from '../../../reducers';
import { EXIT_PROFILE_AVOIDABLE_AXIS } from '../../../settings';

import styles from '../backendSidebar.css';

type SidebarPropType = {
  isExitProfileAvoidable: boolean
};

const items = [
  {
    to: '/results/semaphore',
    title: "Semáforo",
    getIsActive: (match, { pathname }) => match || pathname === `/results/` || pathname === `/results`,
  },
  {
    to: '/results/intrument-results',
    title: "Resultados",
  },
  {
    to: '/results/indicators',
    title: "Indicadores",
  },
  {
    to: '/results/exit-profiles',
    title: "Indice de Perfil de Salida",
  }
];

const SidebarResults = ({
  isExitProfileAvoidable = false,
}: SidebarPropType) => (
  <div className={styles.sidebar}>
    {
      // Match Exit Profile Index option...
      items.map((item, i) => (
        i === 3 ? (
          !isExitProfileAvoidable && 
            <BackendSidebarLink
              key={i}
              Icon={null}
              to={item.to}
              title={item.title}
              isActive={item.getIsActive}
            />
        ) : (
          <BackendSidebarLink
            key={i}
            Icon={null}
            to={item.to}
            title={item.title}
            isActive={item.getIsActive}
          />
        )
      ))
    }
  </div>

);

export default connect(
  state => {
    const { is_scorable, name } = selectors.getSelectedInstrument(state) || {};

    const isExitProfileAvoidableAxis = 
      name && EXIT_PROFILE_AVOIDABLE_AXIS.some(axis => name.toLowerCase().includes(axis)) && is_scorable

    return {
      isExitProfileAvoidable: isExitProfileAvoidableAxis,
    }
  }
)(SidebarResults);
