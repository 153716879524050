
//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import * as types from '../types/connection';
import * as common from './common';
import { CONNECTION_STATUS_ACTION_TYPE } from '../types/connection';

export type ConnectionState = {
  connection: boolean,
};

// Connection reducer was provided by Redux-Offline, I needed it on my own reducer...
const recentStatus = (
  state: boolean = null,
  action: CONNECTION_STATUS_ACTION_TYPE,
): boolean => {
  switch (action.type) {
    case types.STATUS_CHANGED: {
      const { payload: online } = action;
      return online;
    }
    case types.PREVENTIVE_OFFLINE_MODE_ON:
    case types.PREVENTIVE_OFFLINE_MODE_OFF: {
      return true
    }
    case types.CONNECTION_STATUS_CLEARED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const offlineMode = common.toggle({
  turnedOn: [types.PREVENTIVE_OFFLINE_MODE_ON],
  turnedOff: [types.PREVENTIVE_OFFLINE_MODE_OFF],
  default: false,
});

const connection = combineReducers({
  recentStatus,
  offlineMode
});


export default connection;

// Selectors
export const isRecentStatus = (state: ConnectionState): boolean => state.recentStatus;
export const isOfflineMode = (state: ConnectionState): boolean => state.offlineMode;
