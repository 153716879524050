// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, formValueSelector, change } from 'redux-form';
import moment from 'moment';
import {
  RenderInput,
  RenderSelect,
  RenderDatePicker,
} from '../FieldRenderer';
import validate from './validate';
import { triggerSubmit } from './submit';
import { UPDATE_ACTION_FORM } from '../../settings';

import { GENDERS_VALUES } from '../../api/mock';
import styles from './addKidForm.css';
import { generateChoices } from '../../utils';
import * as selectors from '../../reducers';

type AddKidFormPropTypes = {
  children: Array<Object>,
  organizations: Array<Object>,
  hasParticipant: boolean,
  kids: Array<Object>,
  onKidSelected: Function,
  actionForm: string,
};


const AddKidForm = ({
  children = [],
  organizations = [],
  hasParticipant,
  kids = [],
  onKidSelected,
  kid,
  mother,
  actionForm,
  locations,
  sublocations,
  onSelectLocation,
  onSelectSublocation,
}: AddKidFormPropTypes) => (
  <Form className={styles.form}>
    <div className={styles.separator} />
    <div className={styles.section}>
      <div className={styles.select}>
        {
          actionForm !== UPDATE_ACTION_FORM && (
            <Fragment>
              <h2>{"Seleccione un niño "}</h2>
              <Field
                name="kid_select"
                component={RenderSelect}
                options={kids}
                onChange={el => el != -1 && onKidSelected(el)}
                isDisabled={mother == null}
                required={false}
              />
            </Fragment>
          )
        }
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Field
            name='full_name'
            label="Nombre completo:"
            component={RenderInput}
            placeholder="Escriba el nombre acá.."
            disabled={hasParticipant}
          />
        </div>
        <div className={styles.col}>
          <Field
            name='birthdate'
            label="Fecha de nacimiento"
            component={RenderDatePicker}
            disabled={hasParticipant}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Field
            name="gender"
            label="Género"
            component={RenderSelect}
            options={GENDERS_VALUES}
            isDisabled={hasParticipant}
          />
        </div>
        <div className={styles.col}>
          <Field
            name='code'
            label="Código"
            component={RenderInput}
            placeholder="Escriba el código acá.."
            disabled={hasParticipant}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Field
            name="location"
            label="Departamento"
            component={RenderSelect}
            options={locations}
            onChange={({ id }) => onSelectLocation(id)}
            isDisabled={hasParticipant}
          />
        </div>
        <div className={styles.col}>
          <Field
            name="sublocation"
            label="Municipio"
            component={RenderSelect}
            options={sublocations}
            onChange={({ id }) => onSelectSublocation(id)}
            isDisabled={hasParticipant}
          />
        </div>
        <div className={styles.col}>
          <Field
            name='organization'
            label="Comunidad"
            component={RenderSelect}
            options={organizations}
            isDisabled={hasParticipant}
          />
        </div>
      </div>

    </div>
  </Form>
);

const AddKidFormConnected = connect(
  state => {
    const formSelector = formValueSelector('addKidForm');

    const kid = formSelector(state, 'kid');
    const mother = formSelector(state, 'mother');
    const possible_kids = formSelector(state, 'possible_kids') || [];

    const selectedStudy = selectors.getSelectedStudy(state) || {};
    const {
      locations = [],
      sublocations = [],
      organizations = [],
    } = selectedStudy.organizations || {};

    const location = formSelector(state, 'location');
    const sublocation = formSelector(state, 'sublocation');

    let sublocationsToRender = [];
    let organizationsToRender = [];

    if (location != null) {
      sublocationsToRender = sublocations.filter(el => el.location == location);
    }
    if (sublocation != null) {
      organizationsToRender = organizations.filter(el => el.sublocation_id == sublocation);
    }

    // Null options
    sublocationsToRender.push({ id: -1, name: 'Select...' });
    organizationsToRender.push({ id: -1, name: 'Select...' });

    // Set kid options
    if (kid != null) {
      let organization = formSelector(state, 'organization');

      organizationsToRender = organizations.filter(el => el.organization == organization);
      organization = organizations[0] || {};

      sublocationsToRender = sublocations.filter(el => el.id == organization.sublocation_id);
    }

    const kids = possible_kids.map(el => ({
      value: el,
      label: el.full_name,
    }));

    if (!kids.length) {
      kids.push({ value: -1, label: 'Select ...' });
    }
    return ({
      kids,
      mother,
      kid,
      hasParticipant: kid != null || mother == null,
      locations: generateChoices(locations, el => el.name),
      sublocations: generateChoices(sublocations, el => el.name),
      organizations: generateChoices(organizations, el => el.name, el => el.organization),
      studyOrganizations: selectedStudy.organizations || {},
    })
  },
  dispatch => ({
    onKidSelected(kid, studyOrganizations) {
      const {
        organizations = [],
        sublocations = [],
        locations = [],
      } = studyOrganizations;
      
      const organization = organizations.find(el => el.organization == kid.organization);
      if (organization) {
        const sublocation = sublocations.find(el => el.id == organization.sublocation_id);
        const location = locations.find(el => el.id == organization.location_id);
        dispatch(change('addKidForm', 'location', location.id));
        dispatch(change('addKidForm', 'sublocation', sublocation.id));
        dispatch(change('addKidForm', 'organization', kid.organization));
      }
      
      dispatch(change('addKidForm', 'kid', kid.id));
      dispatch(change('addKidForm', 'full_name', kid.full_name));
      dispatch(change('addKidForm', 'birthdate', moment(kid.birthdate).toDate()));
      dispatch(change('addKidForm', 'gender', kid.gender));
      dispatch(change('addKidForm', 'code', kid.code));

      // Set values on instrument submission
      dispatch(change('addInstrumentSubmission', 'kid', kid.id));
      dispatch(change('addInstrumentSubmission', 'participant', kid.mother));
    },
    onSelectLocation() {
      dispatch(change('addKidForm', 'sublocation', -1));
      dispatch(change('addKidForm', 'community', -1));
    },
    onSelectSublocation() {
      dispatch(change('addKidForm', 'community', -1));
    },
  }),
  (stateProps, { onKidSelected, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onKidSelected(kid) {
      onKidSelected(kid, stateProps.studyOrganizations);
    }
  })
)(AddKidForm);

export default reduxForm({
  form: 'addKidForm',
  onSubmit: triggerSubmit,
  validate,
})(AddKidFormConnected);
