// @flow
import React from 'react';
import { connect } from 'react-redux';

import * as closeableActions from '../../actions/closeable';
import Button from '../Button';


const OpenButton = connect(
  undefined,
  (dispatch, { id, onClick = () => {} }) => ({
    onClick() {
      dispatch(closeableActions.open(id));
      onClick();
    },
  }),
)(
  ({ onClick, ...props }) => <Button onClick={onClick} {...props} />,
);

export default OpenButton;
