// @flow
import React, { Fragment } from 'react';
import ReactApexChart from 'react-apexcharts';


type IndicatorChartPropTypes = {
  percentage: Number,
  name: String,
  color?: String, 
};


const IndicatorChart = ({
  percentage,
  name,
  color = '#f89151',
}) => (
  <div>
    <ReactApexChart
      type="radialBar"
      series={[percentage]}
      options={{
        chart: {
          type: 'radialBar',
          sparkline: {
            enabled: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -70,
            endAngle: 70,
            track: {
              background: "#e7e7e7",
              strokeWidth: '100%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 1,
                left: 0,
                color: '#999',
                opacity: 0.5,
                blur: 2
              },
            },
            dataLabels: {
              name: {
                show: false,
                offsetY: 20,
              },
              value: {
                offsetY: -5,
                fontSize: '20px',
                fontWeight: 'bold',
                color: "#838383",
              }
            },
            hollow: {
              size: '35%',
            },
          }
        },
        grid: {
          padding: {
            top: -20
          }
        },
        fill: {
          colors: [color, color],
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.1,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: [''],
      }}
    />
    <h2
      style={{
        textAlign: 'center',
        fontSize: '1.6rem',
        fontWeight: 600,
        color: '#838383',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}>{name}</h2>
  </div>
)


export default IndicatorChart;
