// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type INSTRUMENT_TYPE = {
  id: ID_TYPE,
  question_sets: Array<ID_TYPE>,
};

export type INSTRUMENTS_FETCH_STARTED_TYPE = {
  type: 'INSTRUMENTS_FETCH_STARTED',
  payload: {},
};
export const INSTRUMENTS_FETCH_STARTED = 'INSTRUMENTS_FETCH_STARTED';

export type INSTRUMENTS_FETCH_COMPLETED_TYPE = {
  type: 'INSTRUMENTS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: INSTRUMENT_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const INSTRUMENTS_FETCH_COMPLETED = 'INSTRUMENTS_FETCH_COMPLETED';

export type INSTRUMENTS_FETCH_FAILED_TYPE = {
  type: 'INSTRUMENTS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENTS_FETCH_FAILED = 'INSTRUMENTS_FETCH_FAILED';

export type INSTRUMENT_FETCH_STARTED_TYPE = {
  type: 'INSTRUMENT_FETCH_STARTED',
  payload: number,
};
export const INSTRUMENT_FETCH_STARTED ='INSTRUMENT_FETCH_STARTED';

export type INSTRUMENT_FETCH_COMPLETED_TYPE = {
  type: 'INSTRUMENT_FETCH_COMPLETED',
  payload: INSTRUMENT_TYPE,
};
export const INSTRUMENT_FETCH_COMPLETED = 'INSTRUMENT_FETCH_COMPLETED';

export type INSTRUMENT_FETCH_FAILED_TYPE = {
  type: 'INSTRUMENT_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_FETCH_FAILED = 'INSTRUMENT_FETCH_FAILED';

export type INSTRUMENT_QUESTIONS_FETCH_STARTED_TYPE = {
  type: 'INSTRUMENT_QUESTIONS_FETCH_STARTED',
  payload: ID_TYPE,
};
export const INSTRUMENT_QUESTIONS_FETCH_STARTED = 'INSTRUMENT_QUESTIONS_FETCH_STARTED';

export type INSTRUMENT_QUESTIONS_FETCH_COMPLETED_TYPE = {
  types: 'INSTRUMENT_QUESTIONS_FETCH_COMPLETED',
  payload: {
    id: ID_TYPE,
    questions_sets: Array<ID_TYPE>,
  },
};
export const INSTRUMENT_QUESTIONS_FETCH_COMPLETED = 'INSTRUMENT_QUESTIONS_FETCH_COMPLETED';

export type INSTRUMENT_QUESTIONS_FETCH_FAILED_TYPE = {
  type: 'INSTRUMENT_QUESTIONS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_QUESTIONS_FETCH_FAILED = 'INSTRUMENT_QUESTIONS_FETCH_FAILED';

export type INSTRUMENTS_FILTERS_UPDATED_TYPE = {
  type: 'INSTRUMENTS_FILTERS_UPDATED',
  payload: {
    key: string,
    value: any,
  }
};
export const INSTRUMENTS_FILTERS_UPDATED = 'INSTRUMENTS_FILTERS_UPDATED';

export type INSTRUMENTS_FILTERS_CLEARED_TYPE = {
  type: 'INSTRUMENTS_FILTERS_CLEARED',
};
export const INSTRUMENTS_FILTERS_CLEARED = 'INSTRUMENTS_FILTERS_CLEARED';

export type INSTRUMENT_SELECTED_TYPE = {
  type: 'INSTRUMENT_SELECTED',
  payload: ID_TYPE,
};
export const INSTRUMENT_SELECTED = 'INSTRUMENT_SELECTED';

export type INSTRUMENT_UNSELECTED_TYPE = {
  type: 'INSTRUMENT_UNSELECTED',
};
export const INSTRUMENT_UNSELECTED = 'INSTRUMENT_UNSELECTED';

export type INSTRUMENTS_ACTION_TYPE =
  | INSTRUMENTS_FETCH_STARTED_TYPE
  | INSTRUMENTS_FETCH_COMPLETED_TYPE
  | INSTRUMENTS_FETCH_FAILED_TYPE
  | INSTRUMENT_FETCH_STARTED_TYPE
  | INSTRUMENT_FETCH_COMPLETED_TYPE
  | INSTRUMENT_FETCH_FAILED_TYPE
  | INSTRUMENT_QUESTIONS_FETCH_COMPLETED_TYPE
  | INSTRUMENT_QUESTIONS_FETCH_FAILED_TYPE
  | INSTRUMENTS_FILTERS_UPDATED_TYPE
  | INSTRUMENTS_FILTERS_CLEARED_TYPE
  | INSTRUMENT_SELECTED_TYPE
  | INSTRUMENT_UNSELECTED_TYPE;
