// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  QUESTION_TYPE,
  QUESTIONS_FETCH_STARTED_TYPE,
  QUESTIONS_FETCH_COMPLETED_TYPE,
  QUESTIONS_FETCH_FAILED_TYPE,
} from '../types/questions';
import * as types from '../types/questions';
import { error } from './common';


export const startFetchQuestions = (): QUESTIONS_FETCH_STARTED_TYPE => ({
  type: types.QUESTIONS_FETCH_STARTED,
  payload: {},
});

export const completeFetchQuestions = (
  entities: {[ID_TYPE]: QUESTION_TYPE},
  order: Array<ID_TYPE>,
): QUESTIONS_FETCH_COMPLETED_TYPE => ({
  type: types.QUESTIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchQuestions = error(types.QUESTIONS_FETCH_FAILED);


