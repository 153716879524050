// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
  all
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import {
  RESULTS_EXIT_PROFILE_FETCH_STARTED,
  STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
  INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
} from '../types/resultsExitProfile';

import { Studies, Instruments } from '../api'
import { arrayOfResultExitProfile } from '../api/schemas/resultsExitProfile';

import * as selectors from '../reducers';
import * as actions from '../actions/resultsExitProfile';
import * as studyActions from '../actions/studies';


function* fetchResultsExitProfile(action) {
  try {
    const token = yield select(selectors.getToken);
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchResultsExitProfile({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
};

function* fetchStudyResultsExitProfile(action) {
  try {
    const studyId = action.payload;
    const token = yield select(selectors.getToken);
    let query = yield select(selectors.getStudiesExitProfileResultsFilters, studyId);
    
    yield put(actions.clearExitProfileExtraInfo());
    const { response, timeout } = yield race({
      response: call(
        [Studies.custom, 'resultsExitProfile'],
        {
          token,
          id: studyId,
          filters: { filters: JSON.stringify(query) },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    const { data, extra_data } = response;
    yield all(Object.keys(extra_data).map(key => {
      return put(actions.updateExitProfileExtraInfo(key, extra_data[key]));
    }));

    const dataUpdated = data.map(el => ({ 
      ...el,
      value: (100 * el.value).toFixed(2),
    }));

    const {
      entities: { resultExitProfile },
      result,
    } = normalize(dataUpdated, arrayOfResultExitProfile);
    
    yield put(actions.completeStudyResultsExitProfile(resultExitProfile, result));
    yield put(studyActions.updateStudyResultsExitProfileOrder(studyId, result));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failStudyResultsExitProfile({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchInstrumentResultsExitProfile(action) {
  try {
    const id = action.payload;
    const token = yield select(selectors.getToken);
    let query = yield select(selectors.getInstrumentsFilters);
    query = {
      ...query,
      phase: undefined,
    };

    yield put(actions.clearExitProfileExtraInfo());
    const { response, timeout } = yield race({
      response: call(
        [Instruments.custom, 'resultsExitProfile'],
        {
          token,
          id,
          filters: { filters: JSON.stringify(query) },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    const { data, extra_data } = response;
    yield all(Object.keys(extra_data).map(key => {
      return put(actions.updateExitProfileExtraInfo(key, extra_data[key]));
    }));

    const dataUpdated = data.map(el => ({ 
      ...el,
      value: (100 * el.value).toFixed(2),
    }))

    const {
      entities: { resultExitProfile },
      result,
    } = normalize(dataUpdated, arrayOfResultExitProfile);

    yield put(actions.completeFetchInstrumentResultsExitProfile(resultExitProfile, result));
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchInstrumentResultsExitProfile({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchResultsExitProfile(): Iterator<any> {
  yield takeEvery(
    RESULTS_EXIT_PROFILE_FETCH_STARTED,
    fetchResultsExitProfile,
  )
};

export function* watchFetchStudyResultsExitProfile(): Iterator<any> {
  yield takeEvery(
    STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    fetchStudyResultsExitProfile,
  )
};

export function* watchFetchInstrumentResultsExitProfile(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    fetchInstrumentResultsExitProfile,
  )
};
