exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "@-webkit-keyframes src-scripts-components-MenuFixed-__menuFixed___fadeInUp {\n  from {\n    opacity: 0;\n    -webkit-transform: translateY(-15%);\n            transform: translateY(-15%)\n  }\n  to {\n    opacity: 1;\n    -webkit-transform: translateY(0);\n            transform: translateY(0)\n  }\n}\n\n\n@keyframes src-scripts-components-MenuFixed-__menuFixed___fadeInUp {\n  from {\n    opacity: 0;\n    -webkit-transform: translateY(-15%);\n            transform: translateY(-15%)\n  }\n  to {\n    opacity: 1;\n    -webkit-transform: translateY(0);\n            transform: translateY(0)\n  }\n}\n\n.src-scripts-components-MenuFixed-__menuFixed___fullContainer {\n  background: var(--mainColor);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  flex: 1 1;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  flex-direction: column;\n  z-index: 999;\n  visibility: hidden;\n  opacity: 0;\n  -webkit-transition: visibility .4s cubic-bezier(.19,1,.22,1), opacity .4s cubic-bezier(.19,1,.22,1);\n  transition: visibility .4s cubic-bezier(.19,1,.22,1), opacity .4s cubic-bezier(.19,1,.22,1);\n}\n\n.src-scripts-components-MenuFixed-__menuFixed___show {\n  opacity: 1 !important;\n  visibility: visible !important\n}\n\n.src-scripts-components-MenuFixed-__menuFixed___show .src-scripts-components-MenuFixed-__menuFixed___menuItem {\n    -webkit-animation: src-scripts-components-MenuFixed-__menuFixed___fadeInUp 1.6s cubic-bezier(.19,1,.22,1) both;\n            animation: src-scripts-components-MenuFixed-__menuFixed___fadeInUp 1.6s cubic-bezier(.19,1,.22,1) both;\n  }\n\n.src-scripts-components-MenuFixed-__menuFixed___closeButton {\n  background: rgba(0, 0, 0, 0.2);\n  position: absolute;\n  right: 4rem;\n  top: 4rem;\n}\n", ""]);
// Exports
exports.locals = {
	"fullContainer": "src-scripts-components-MenuFixed-__menuFixed___fullContainer",
	"show": "src-scripts-components-MenuFixed-__menuFixed___show",
	"menuItem": "src-scripts-components-MenuFixed-__menuFixed___menuItem",
	"fadeInUp": "src-scripts-components-MenuFixed-__menuFixed___fadeInUp",
	"closeButton": "src-scripts-components-MenuFixed-__menuFixed___closeButton"
};