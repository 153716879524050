// @flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { LOCATION_TYPE } from '../types/locations';
import * as common from './common';
import * as types from '../types/locations';


export type LocationsState = {
  byId: { [ID_TYPE]: LOCATION_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: ID_TYPE,
};

const byId = common.byId({
  fetched: [types.LOCATIONS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.LOCATIONS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.LOCATIONS_FETCH_STARTED],
  succeed: [types.LOCATIONS_FETCH_COMPLETED],
  failed: [types.LOCATIONS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.LOCATIONS_FETCH_STARTED,
    types.LOCATIONS_FETCH_COMPLETED,
  ],
  populate: [types.LOCATIONS_FETCH_FAILED],
});

const selected = common.mux({
  selected: [types.LOCATION_SELECTED],
  allDeselected: [],
  default: -1,
});

const locations = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
});

export default locations;

export const getLocation = (state: LocationsState, id: ID_TYPE): LOCATION_TYPE => state.byId[id];
export const getLocations = (state: LocationsState): Array<LOCATION_TYPE> => state.order.map(id => getLocation(state, id));
export const isFetchingLocations = (state: LocationsState): boolean => state.isFetching;
export const getLocationsError = (state: LocationsState): ERROR_TYPE => state.error;
export const getSelectedLocation = (state: LocationsState): LOCATION_TYPE => state.byId[state.selected];
