// @flow
import type {
  MENU_FIXED_SHOWED_TYPE,
  MENU_FIXED_HIDDEN_TYPE,
} from '../types/menuFixed';

import * as types from '../types/menuFixed';


export const showMenuFixed = () => ({
  type: types.MENU_FIXED_SHOWED
});

export const hiddenMenuFixed = () => ({
  type: types.MENU_FIXED_HIDDEN,
});
