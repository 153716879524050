import React from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Button from '../Button';
import Popup from '../Popup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContainer from '../PopupContainer';
import PopupContent from '../PopupContent';
import PopupHeader from '../PopupHeader';
import PopupFooter from '../PopupFooter';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';

import styles from './deleteParticipantPopup.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/participants';
import * as closableActions from '../../actions/closeable';

export const DELETE_PARTICIPANT_POPUP = 'DeleteParticipantPopup';

type DeleteParticipantPopupPropTypes = {
  deleteParticipant: Function,
  onCancel: Function,
};

const DeleteParticipantPopup = ({
  deleteParticipant,
  onCancel,
}: DeleteParticipantPopupPropTypes) => {
  return (
    <Popup 
      id={DELETE_PARTICIPANT_POPUP}
      theme='main'
      transitionMs={600}
      onAfterClose={()=> onCancel()}
    >
      <PopupContainer size='medium' isRound={true}>
        <PopupContent>
          <PopupHeader isBorderless={true} clearPadding isAlert>
            <PopupHeaderRightGroup>
              <PopupCloseButton
                tint='gray'
                Icon={MdClose}
                clearMargins
                isTransparent
                isOutlined
                closePopup={() => onCancel()}
              />
            </PopupHeaderRightGroup>
          </PopupHeader>

          <div className={styles.center}>
            <h1 className={styles.mainMessage}>
              Estás por{' '}
              <em className={styles.dangerMessage}>eliminar permanentemente</em>{' '}
              un participante, y todos sus registros y documentos en este y otros estudios.
            </h1>
          </div>
          <div className={styles.center}>
            <h2
              className={`${styles.secondaryMessage} ${styles.dangerMessage}`}
            >
              ESTA ACCIÓN ES IRREVERSIBLE.
            </h2>
          </div>

          <PopupFooter align='center' className={styles.footer}>
            <Button
              isRound
              type='button'
              title='Cancelar'
              tint='gray'
              onClick={() => onCancel()}
            />
            <Button
              isRound
              type='button'
              title='Eliminar Permanentemente'
              tint='error'
              onClick={() => deleteParticipant()}
            />
          </PopupFooter>
        </PopupContent>
      </PopupContainer>
    </Popup>
  );
};

export default connect(
  (state) => {
    const { id } = selectors.getSelectedStudyParticipant(state) || {};
    return{
      participantId: id,
    };
  },
  (dispatch) => ({
    deleteParticipant(id) {
      dispatch(actions.removeStudyParticipant(id));
      dispatch(closableActions.close(DELETE_PARTICIPANT_POPUP));
    },
    onCancel() {
      dispatch(closableActions.close(DELETE_PARTICIPANT_POPUP));
      dispatch(actions.unselectParticipant());
    },
  }),
  (stateProps, { deleteParticipant, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    deleteParticipant() {
      deleteParticipant(stateProps.participantId);
    },
  })
)(DeleteParticipantPopup);
