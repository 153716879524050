exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-views-Results-__results___viewPort { \n  background-color: #efefef;\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.src-scripts-components-views-Results-__results___aside {\n  flex: 1 1;\n}\n\n.src-scripts-components-views-Results-__results___content {\n  /* display: flex; */\n  flex: 8 1;\n  margin-left: 4rem;\n  overflow: auto;\n  width: 100%;\n}\n\n.src-scripts-components-views-Results-__results___card {\n  box-sizing: border-box;\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n}\n\n.src-scripts-components-views-Results-__results___row {\n  display: flex;\n  flex-direction: row;\n  flex: 1 1;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-views-Results-__results___content {\n    margin-left: 0;\n    flex: 1 1;\n  }\n\n  .src-scripts-components-views-Results-__results___viewPort { \n    overflow-y: scroll;\n  }\n\n  .src-scripts-components-views-Results-__results___row {\n    flex-direction: column;\n  }\n\n  .src-scripts-components-views-Results-__results___aside {\n    flex: unset;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"viewPort": "src-scripts-components-views-Results-__results___viewPort",
	"aside": "src-scripts-components-views-Results-__results___aside",
	"content": "src-scripts-components-views-Results-__results___content",
	"card": "src-scripts-components-views-Results-__results___card",
	"row": "src-scripts-components-views-Results-__results___row"
};