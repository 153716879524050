exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../node_modules/postcss-loader/src/index.js??postcss!./variables/font.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../node_modules/postcss-loader/src/index.js??postcss!./variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../node_modules/postcss-loader/src/index.js??postcss!./loadingContent.css");
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:300,700,800,900|Open+Sans:300,400,600,700);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
exports.push([module.id, "html {\n  font-size: 8px;\n  font-weight: 100;\n  overflow: hidden;\n}\n\nbody {\n  font-family: var(--mainFont);\n  margin: 0;\n}\n\nh1, h2, h3 {\n  font-weight: 100;\n  color: var(--grayColor);\n}\n\nh1 {\n  font-size: 3rem;\n}\n\na {\n  -webkit-transition: 0.25s all ease-in-out;\n  transition: 0.25s all ease-in-out\n}\n\na:focus {\n    outline: none;\n    /* transform: scale(1.05); */\n  }\n", ""]);
