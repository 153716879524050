// @flow
import React from 'react';

import IndicatorChart from '../IndicatorChart';
import styles from './studyIndicatorResults.css';


const ChartView = ({
  results,
}) => (
  <div className={styles.container}>
    {
      results.map(el => (
        <div className={styles.charElement} key={el.id}>
          <IndicatorChart
            {...el}
          />
        </div>
      ))
    }
    <div className={styles.footer}></div>
  </div>
);

export default ChartView;
