// @flow
import React from 'react';
import ReactApexChart from 'react-apexcharts';


type GenericResultsChartPropTypes = {
  results: {[string]: Array<number>},
  phases: Array<number>,
  name: string
};

const GenericResultsChart = ({
  results,
  phases,
  name,
}: GenericResultsChartPropTypes) => (
  <ReactApexChart
    options={{
      chart: {
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        curve: 'straight',
        width: [1.5, ...Object.keys(results).map(el => 1.5)],
      },
      title: {
        text: `${name.length > 64 ? name.substr(0, 64) + '...' : name}`,
        align: 'left'
      },
      markers: {
        size: [0],
      },
      xaxis: {
        categories: phases.map(el => el),
        type: 'category',
        tickAmount: 'dataPoints',
        labels: {
          rotateAlways: true,
        }
        // tickAmount: phases.lenght,
        // step: 1,
        // min: 1,
        // max: phases.lenght,
      }
    }}
    series={
      [
        ...Object.keys(results).map(el => ({
          name: el.length > 10 ? `${el.slice(0, 10)}...` : el,
          data: results[el],
        }))
      ]
    }
  />
)

export default GenericResultsChart;
