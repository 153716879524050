// @flow
import React, { Node } from 'react';
import styles from './headerRightGroup.css';

type HeaderRightGroupPropTypes = {
  children: Node,
};

const HeaderRightGroup = ({
  children,
}: HeaderRightGroupPropTypes) => (
  <div className={styles.rightGroup}>
    {children}
  </div>
);

export default HeaderRightGroup; 
