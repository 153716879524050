// @flow
import { pluginComponent as plugin } from 'redux-plugin';

import { register } from '../../actions/closeable';
import Popup from './popup';

export { withClosePopup } from './providers';


const PLUGIN_NAME = 'closeable';

const ReduxPopup = plugin({
  defaultStateKey: PLUGIN_NAME,
  registerPluginElement: register,
})(Popup);

export default ReduxPopup;
