import React, { useState, useEffect } from 'react';

import styles from './alert.css';

const Alert = ({
    message = '',
    color = 'white',
    duration = 5000,
    closeable = true,
    onClose,
}) => {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
          setIsOpen(false);
          onClose !== null && onClose();
        }, duration);
        return () => clearTimeout(timer);
      }, []);

    return (
        <div 
            className={`
                ${styles.alert}
                ${!isOpen ? styles.hide : ''}
            `}
            style={{ backgroundColor: color }}    
        >
            {closeable && 
                <span 
                    className={styles.closeBtn}
                    onClick={() => setIsOpen(false)}
                >
                    &times;
                </span>
            }
            { message }
        </div>
    )
};

export default Alert;
