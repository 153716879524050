// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type ANTHROPOMETRY_RESULT_TYPE = {
  id: ID_TYPE,
  height_cm: number, // -> cm
  weight_lbs: number, // -> kg
  age_years: number,
  age_months: number,
};

export type ANTHROPOMETRY_RESULTS_FETCH_STARTED_TYPE = {
  type: 'ANTHROPOMETRY_RESULTS_FETCH_STARTED',
  payload: {
    study: ID_TYPE,
    kid: ID_TYPE,
  },
};
export const ANTHROPOMETRY_RESULTS_FETCH_STARTED = 'ANTHROPOMETRY_RESULTS_FETCH_STARTED';

export type ANTHROPOMETRY_RESULTS_FETCH_COMPLETED_TYPE = {
  type: 'ANTHROPOMETRY_RESULTS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: ANTHROPOMETRY_RESULT_TYPE},
    order: Array<ID_TYPE>,
  }
};
export const ANTHROPOMETRY_RESULTS_FETCH_COMPLETED = 'ANTHROPOMETRY_RESULTS_FETCH_COMPLETED';

export type ANTHROPOMETRY_RESULTS_FETCH_FAILED_TYPE = {
  type: 'ANTHROPOMETRY_RESULTS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const ANTHROPOMETRY_RESULTS_FETCH_FAILED = 'ANTHROPOMETRY_RESULTS_FETCH_FAILED';


export type ANTHROPOMETRY_RESULTS_ACTION_TYPE =
  | ANTHROPOMETRY_RESULTS_FETCH_STARTED_TYPE
  | ANTHROPOMETRY_RESULTS_FETCH_COMPLETED_TYPE
  | ANTHROPOMETRY_RESULTS_FETCH_FAILED_TYPE;
