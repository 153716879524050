//@flow
import React from 'react';
import { MdAdd, MdEdit } from 'react-icons/lib/md';

import DataTable from '../DataTable';
import TextColumn from '../DataTable/Columns/Text';
import ButtonColumn from '../DataTable/Columns/Button';
import TagColumn from '../DataTable/Columns/Tag';
import HighlightColumn from '../DataTable/Columns/Highlight';


type StudiestablePropTypes = {
  data: Array<Object>,
  onStudyClick: Function,
  isAdmin: boolean,
  onEditClicked: Function,
  onCreateClick: Function,
};


const StudiesTable = ({
  data,
  onStudyClick,
  isAdmin,
  onEditClicked,
  onCreateClick,
}: StudiestablePropTypes) => {
  return (
    <DataTable
      data={data}
      className="white"
      columns={
        [
          HighlightColumn({
            Header: '',
            accessor: 'name',
            editable: false,
            minWidth: 160,
            getOnClick: (params: { id: number }) => () => {
              onStudyClick(params.id);
            },
          }),
          TagColumn({
            Header: '',
            accessor: 'type',
            editable: false,
            minWidth: 150,
            tint: 'gray'
          }),
          TextColumn({
            Header: '',
            accessor: 'nameInstrument',
            editable: false,
          }),
          TextColumn({
            Header: '',
            accessor: 'startDate',
            editable: false,
          }),
          TextColumn({
            Header: '',
            accessor: 'phases',
            editable: false,
            // maxWidth: 120,
          }),
          TextColumn({
            Header: '',
            accessor: 'submissions',
            editable: false,
            // maxWidth: 120,
          }),
          ButtonColumn({
            Header: '',
            accessor: 'openCodePopup',
            buttonProps: {
              Icon: MdAdd,
              title: 'Ingreso',
            size: 'small',
              tint: 'main',
              isRound: true,
            },
            getOnClick: (params: { id: number }) => () => {
              onCreateClick(params.id);
            },
            minWidth: 150,
          }),
          ButtonColumn({
            Header: '',
            accessor: 'openCodePopup',
            buttonProps: {
              Icon: MdEdit,
              size: 'small',
              tint: 'gray',
              isRound: true,
            },
            getOnClick: (params: { id: number }) => () => {
              onEditClicked(params.id);
            },
            maxWidth: 50,
            show: true,
          })
        ]
      }
      defaultPageSize={99999}
      minRows={1}
      showPagination={false}
    />
  );
}

export default StudiesTable;
