// @flow
import { schema } from 'normalizr';

// import { study } from './studies';
// import { instrument } from './instruments';
// import { user } from './users';
// import { phase } from './phases';
import { answer } from './answers';
import { question } from './questions';


export const instrumentSubmission = new schema.Entity('instrumentSubmissions', {
  questions: [question],
  answers: [answer],
});
export const arrayOfInstrumentSubmissions = new schema.Array(instrumentSubmission);
