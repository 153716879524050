exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-ParticipantTypeahead-__participantTypeahead___searchContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center\n}\n\n.src-scripts-components-ParticipantTypeahead-__participantTypeahead___searchContainer h2 {\n    color: var(--almostGrayColor);\n    font-size: 1.6rem;\n    font-weight: 800;\n  }\n\n.src-scripts-components-ParticipantTypeahead-__participantTypeahead___search {\n  width: 50%;\n}\n\n@media(max-width: 768px) {\n  .src-scripts-components-ParticipantTypeahead-__participantTypeahead___search {\n    width: 80%;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"searchContainer": "src-scripts-components-ParticipantTypeahead-__participantTypeahead___searchContainer",
	"search": "src-scripts-components-ParticipantTypeahead-__participantTypeahead___search"
};