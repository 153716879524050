// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ResultsList from './results';
import IndicatorChart from '../IndicatorChart';
import styles from './studyResults.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/studyResults';


type StudyResultsPropTypes = {
  studyId: ID_TYPE,
  loading: Boolean,
};

const StudyResults = ({
  studyId,
  loading,
}: StudyResultsPropTypes) => (
  <div className={styles.container}>
    {
      !loading && (
        <ResultsList studyId={studyId}/>
      )
    }
  </div>
);

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);
    const study = selectors.getStudy(state, studyId);
    const isFetchingStudy = selectors.isFetchingStudy(state, studyId);

    let loading = true;
    
    if (study != null && !isFetchingStudy) {
      loading = false;
    };

    return ({
      studyId,
      loading,
    });
  },
)(StudyResults);
