// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import includes from 'lodash/includes';

import PopupContent from '../PopupContent';
import PopupFooter from '../PopupFooter';
import Button from '../Button';
import { MultipleFiltersList } from '../MultipleFilters';
import { FILTER_STUDY_KEY } from '../../settings';
import { INSTRUMENT_FILTERS_ID } from '../PopupInstrumentFilters';
import styles from './popupStudyFilters.css';

import * as selectors from '../../reducers';
import * as closeableActions from '../../actions/closeable';
import * as instrumentsActions from '../../actions/instruments';
import * as resultsStoplightActions from '../../actions/results';
import * as resultsIndicatorsActions from '../../actions/indicatorResults';
import * as resultsActions from '../../actions/studyResults';
import * as resultsExitProfile from '../../actions/resultsExitProfile';


type FiltersProptypes = {
  filters: Array<Object>,
  instrument: number,
  location: Object,
  onSubmit: Function,
  filtersValues: Object,
  instrumentFilters: Array<Object>,
};

const Filters = ({
  instrument,
  filters,
  location,
  onSubmit,
  filtersValues,
  instrumentFilters = [],
}: FiltersProptypes) => {
  const [ values, setValues ] = useState(filtersValues);

  return (
    <>
      <PopupContent className={styles.container}>
        <MultipleFiltersList
          filters={[ ...filters, ...instrumentFilters ]}
          values={values}
          onChange={(key, val) => setValues({
            ...values,
            [key]: val,
          })}
        />
      </PopupContent>
      <PopupFooter className={styles.footer}>
        <Button
          tint='gray'
          size='small'
          title="aplicar"
          onClick={() => onSubmit(values, location, instrument)}
        />
      </PopupFooter>
    </>
  )
};

export default connect( 
  state => {
    const studyId = selectors.getInstrumentsFilter(state, FILTER_STUDY_KEY);
    const instrument = selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state);
    const filtersValues = selectors.getInstrumentsFilters(state);

    const { filters = [] } = selectors.getStudy(state, studyId) || {};
    const newFilters = filters.filter(el => !el.default);

    return ({
      instrument: instrument.id,
      instrumentFilters: instrument.filters,
      filters: newFilters,
      location: selectors.getRouteLocation(state),
      filtersValues,
    });
  },
  dispatch => ({
    onSubmit(filters, location, instrument) {
      const path = location.pathname;
      
      Object.keys(filters).map(key => {
        dispatch(instrumentsActions.updateInstrumentsFilters(key, filters[key]));
      });

      if (includes(path, 'semaphore')) {
        dispatch(resultsStoplightActions.startFetchStudyResults('semaphore'));
      } else if (includes(path, 'intrument-results')) {
        dispatch(resultsActions.startFetchInstrumentResults(instrument));
      } else if (includes(path, 'indicators')) {
        dispatch(resultsIndicatorsActions.startFetchInstrumentIndicatorResults(instrument));
      } else if (includes(path, 'exit-profiles')) {
        dispatch(resultsExitProfile.startFetchInstrumentResultsExitProfile(instrument));
      }
      
      dispatch(closeableActions.close(INSTRUMENT_FILTERS_ID));
    },
  }),
)(Filters);
