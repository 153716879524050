// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import {
  FETCH_STUDY_TYPES_STARTED,
} from '../types/studyTypes';
import { Studies } from '../api';
import { arrayOfStudyTypes } from '../api/schemas/studyTypes';
import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import * as selectors from '../reducers';
import * as actions from '../actions/studyTypes';


function* fetchStudyTypes(action) {
  try {
    const token = yield select(selectors.getToken);

    const { response, timeout } = yield race({
      response: call(
        [Studies.custom, 'types'],
        {
          token,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetch study types saga');
    }

    const {
      entities: { studyType },
      result,
    } = normalize(response.results, arrayOfStudyTypes);

    yield put(actions.completeFetchStudyTypes(studyType, result));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchStudyTypes({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}

export function* watchFetchStudyTypes(): Iterator<any> {
  yield takeEvery(
    FETCH_STUDY_TYPES_STARTED,
    fetchStudyTypes,
  )
}
