// @flow
import React, { Component, Fragment } from 'react';
import debounce from 'lodash/debounce';

import Input from './input';
import InputText from '../Input';
import styles from './radioOptions.css';


type SelectManyPropTypes = {
  name: String,
  value: Object,
  choices: Array<Object>,
  onChange: Function,
};

class SelectMany extends Component<SelectManyPropTypes> {
  constructor(props: SelectManyPropTypes) {
    super(props);
    this._debouncedMethod = debounce(this._onInputChange, 0);
  }

  _onInputChange(id, text) {
    const { value, onChange } = this.props;
    const { choices = [] } = value;

    let repeted = [];
    let nValues = [];
    for (const c of choices) {
      if (c.id == id) {
        repeted.push(c);
      } else {
        nValues.push(c);
      }
    }

    const nVal = { ...repeted[0], text };
    return onChange({ choices: [ ...nValues, nVal ] });
  }

  _onChange(id) {
    const { value, onChange } = this.props;
    const { choices = [] } = value;

    let repeted = [];
    let nValues = [];

    for (const c of choices) {
      if (c.id == id) {
        repeted.push(c);
      } else {
        nValues.push(c);
      }
    }

    if (!repeted.length) {
      nValues = [ ...nValues, { id } ];
    }

    return onChange({
      choices: nValues,
    })
  }

  render() {
    const {
      name,
      value,
      choices,
    } = this.props;
    const vChoices = value.choices || [];
    let oChoices = {};
    let vIds = [];

    vChoices.map(el => {
      vIds.push(el.id);
      oChoices = {
        ...oChoices,
        [el.id]: { ...el }
      }
    });

    return (
      <Fragment>
        {
          choices.map((el, i) => {
            return (
              <div className={styles.option} key={el.id}>
                <Input
                  name={name}
                  value={el.value}
                  type="checkbox"
                  label={`${i + 1}. ${el.name}`}
                  isSelected={vIds.includes(el.value)}
                  onChange={el => this._onChange(parseInt(el))}
                />
                {
                  el.explanation_needed && vIds.includes(el.value) && (
                    <div className={styles.detailContainer}>
                      <div className={styles.indicator}/>
                      <div className={styles.detailText}>
                        {"ESPECIFIQUE: "}
                      </div>
                      <InputText
                        placeholder="Escribe acá"
                        value={oChoices[el.value] ? oChoices[el.value].text : null}
                        onChange={(e) => {
                          const text = e.target.value;
                          this._debouncedMethod(el.id, text);
                        }}
                      />
                    </div>
                  )
                }
              </div>
            )
          })
        }
      </Fragment>
    )
  }
}

export default SelectMany;
