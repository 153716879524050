//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { ORGANIZATION_TYPE } from '../types/organizations';
import * as common from './common';
import * as types from '../types/organizations';

export type OrganizationsState = {
  byId: { [ID_TYPE]: ORGANIZATION_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: ID_TYPE,
  counter: number,
  filters: Object,
  nextPage: Number,
};

// All Organizations
const byId = common.byId({
  fetched: [types.ORGANIZATIONS_FETCH_COMPLETED],
  defaultAttributes: {},
});

const order = common.order({
  fetched: [types.ORGANIZATIONS_FETCH_COMPLETED],
  cleared: [types.ORGANIZATIONS_FILTERS_UPDATED],
  preferPrepend: true,
});

// Paginated Organizations
const paginatedById = common.byId({
  fetched: [types.PAGINATED_ORGANIZATIONS_FETCH_COMPLETED],
  defaultAttributes: {},
});

const paginatedOrder = common.order({
  fetched: [types.PAGINATED_ORGANIZATIONS_FETCH_COMPLETED],
  cleared: [types.ORGANIZATIONS_FILTERS_UPDATED],
  preferPrepend: true,
});

const isFetching = common.isFetching({
  started: [
    types.ORGANIZATIONS_FETCH_STARTED,
    types.PAGINATED_ORGANIZATIONS_FETCH_STARTED,
  ],
  succeed: [
    types.ORGANIZATIONS_FETCH_COMPLETED,
    types.PAGINATED_ORGANIZATIONS_FETCH_COMPLETED,
  ],
  failed: [
    types.ORGANIZATIONS_FETCH_FAILED,
    types.PAGINATED_ORGANIZATIONS_FETCH_FAILED,
  ],
});

const error = common.error({
  clear: [
    types.ORGANIZATIONS_FETCH_STARTED,
    types.ORGANIZATIONS_FETCH_COMPLETED,
    types.PAGINATED_ORGANIZATIONS_FETCH_STARTED,
    types.PAGINATED_ORGANIZATIONS_FETCH_COMPLETED,
  ],
  populate: [
    types.ORGANIZATIONS_FETCH_FAILED,
    types.PAGINATED_ORGANIZATIONS_FETCH_FAILED,
  ],
});

const selected = common.mux({
  selected: [types.ORGANIZATION_SELECTED],
  allDeselected: [types.ALL_ORGANIZATIONS_UNSELECTED],
  default: -1,
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const filters = (
  state: Object = {},
  action: STUDY_PARTICIPANTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.ORGANIZATIONS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      }
    }
    default: {
      return state;
    }
  }
};

const nextPage = common.nextPage({
  fetched: [types.PAGINATED_ORGANIZATIONS_FETCH_COMPLETED],
  failed: [types.PAGINATED_ORGANIZATIONS_FETCH_FAILED],
  reset: [types.ORGANIZATIONS_FILTERS_UPDATED],
});

const organizations = combineReducers({
  byId,
  order,
  paginatedById,
  paginatedOrder,
  isFetching,
  error,
  selected,
  counter,
  filters,
  nextPage,
});


export default organizations;


// Selectors
export const getOrganization = (state: OrganizationsState, id: ID_TYPE): ?ORGANIZATION_TYPE => state.byId[id];
export const getOrganizations = (state: OrganizationsState): Array<?ORGANIZATION_TYPE> => state.order.map(i => getOrganization(state, i));

// Paginated
export const getPaginatedOrganization = (state: OrganizationsState, id: ID_TYPE): ?ORGANIZATION_TYPE => state.paginatedById[id];
export const getPaginatedOrganizations = (state: OrganizationsState): Array<?ORGANIZATION_TYPE> => state.paginatedOrder.map(i => getPaginatedOrganization(state, i));

export const getOrganizationsFilter = (state: OrganizationsState, filter: string): string => state.filters[filter];
export const getOrganizationsFilters = (state: OrganizationsState): Object => state.filters;
export const isFetchingOrganizations = (state: OrganizationsState): boolean => state.isFetching;
export const getOrganizationsNextPage = (state: OrganizationsState): ?number => state.nextPage;
export const hasMoreOrganizations = (state: OrganizationsState): boolean => {
  return (state.nextPage != null) && !state.isFetching && state.nextPage !== -1;
};
export const getOrganizationsError = (state: OrganizationsState): ERROR_TYPE => state.error;

export const getSelectedOrganization = (state: OrganizationsState): ?ORGANIZATION_TYPE => 
  getOrganization(state, state.selected) !== null && getOrganization(state, state.selected) !== undefined && getOrganization(state, state.selected) !== {} 
  ? getOrganization(state, state.selected) 
  : getPaginatedOrganization(state, state.selected);
export const getOrganizationCounter = (state: OrganizationsState): number => state.counter;
