// @flow
import React from 'react';
import { MdGraphicEq } from 'react-icons/lib/md';

import DataTable from '../DataTable';
import TextColumn from '../DataTable/Columns/Text';
import ButtonColumn from '../DataTable/Columns/Button';


type KidsTablePropTypes = {
  data: Array<Object>,
  openKidGraphics: Function,
};


const KidsTable = ({
  data,
  openKidGraphics
}: KidsTablePropTypes) => (
  <DataTable
    data={data}
    columns={[
      TextColumn({
        Header: 'ID',
        accessor: 'id',
        editable: false,
        maxWidth: 50,
      }),
      TextColumn({
        Header: 'Nombre',
        accessor: 'full_name',
        editable: false,
      }),
      TextColumn({
        Header: 'Código',
        accessor: 'code',
        editable: false,
      }),
      TextColumn({
        Header: 'Género',
        accessor: 'gender',
        editable: false,
      }),
      TextColumn({
        Header: 'Fecha de nacimiento',
        accessor: 'birthdate',
        editable: false,
      }),
      ButtonColumn({
        Header: '',
        accessor: 'files',
        buttonProps: {
          Icon: MdGraphicEq,
          size: 'small',
          tint: 'primary',
          isRound: true,
          title: 'Grafica',
        },
        getOnClick: (params: { id: number }) => () => {
          openKidGraphics(params.id);
        },
        minWidth: 150,
      }),
    ]}
    minRows={0}
    defaultPageSize={99999}
    showPagination={false}
  />
);

export default KidsTable;
