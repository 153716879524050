// @flow
import React from 'react';

import type { ID_TYPE } from '../../types/common';
import Input from '../Input';
import Textarea from '../Textarea';
import Select from '../Select';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import Switch from '../Switch';
import MultiSelect from '../MultiSelect';
import InputError from '../InputError';
import InputPreview from '../InputPreview';
import Typeahead from '../Typeahead/async';
import TaggedInput from '../TaggedInput';
import { generateRenderer } from './services';
import ToggleSwitch from '../ToggleSwitch';
import RadioOptions from '../RadioOptions';
import AddOrganizationsTable from '../AddOrganizationsTable';


type DatePickerPreviewPropTypes = {
  value: Date | string
};

type DateRangePickerPreviewPropTypes = {
  value: Date | string
};

type SelectPreviewPropTypes = {
  options: Array<{ value: ID_TYPE, label: string }>,
  value: Array<ID_TYPE>,
};

type SwitchPreviewPropTypes = {
  value: boolean,
};

type MultiSelectPreviewPropTypes = {
  value: Array<ID_TYPE>,
};

type RadioPreviewPropTypes = {
  value: ID_TYPE,
};

export const RenderInput = generateRenderer({
  FieldComponent: Input,
  ErrorComponent: InputError,
  PreviewComponent: InputPreview,
});

export const RenderTextarea = generateRenderer({
  FieldComponent: Textarea,
  ErrorComponent: InputError,
  PreviewComponent: InputPreview,
});

export const RenderDatePicker = generateRenderer({
  FieldComponent: props => <DatePicker {...props} hideClearIcon />,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: DatePickerPreviewPropTypes) => {
    let strDate = 'Sin especificar';
    if (typeof value === 'string') {
      const dValue = new Date(value);
      // TODO: wtf isNaN != Number.isNaN
      if (!isNaN(dValue)) { // eslint-disable-line no-restricted-globals
        strDate = dValue.toLocaleDateString();
      }
    } else if (value && value.constructor === Date) {
      strDate = value.toLocaleDateString();
    }

    return <InputPreview value={strDate} />;
  },
});

export const RenderSelect = generateRenderer({
  FieldComponent: Select,
  ErrorComponent: InputError,
  PreviewComponent: InputPreview,
  overrideOnBlur: true,
});

export const RenderTimePicker = generateRenderer({
  FieldComponent: TimePicker,
  ErrorComponent: InputError,
  PreviewComponent: ({ options, value }: SelectPreviewPropTypes) => {
    const matched = options.filter(option => option.value === value);
    return (
      <InputPreview
        value={
          matched.length > 0
            ? matched[0].label
            : 'Sin especificar'
        }
      />
    );
  },
  overrideOnBlur: true,
});

export const RenderSwitch = generateRenderer({
  FieldComponent: Switch,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: SwitchPreviewPropTypes) => {
    let strValue = '';
    switch (value) {
      case true: strValue = 'Si'; break;
      case false: strValue = 'No'; break;
      default: strValue = 'Sin especificar';
    }

    return <InputPreview value={strValue} />;
  },
});

export const RenderToggleSwitch = generateRenderer({
  FieldComponent: ToggleSwitch,
  ErrorComponent: InputError,
});

export const RenderMultiSelect = generateRenderer({
  FieldComponent: MultiSelect,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: MultiSelectPreviewPropTypes) => (
    <InputPreview value={value.join(', ')} />
  ),
});

export const RenderTypeahead = generateRenderer({
  FieldComponent: Typeahead,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
});

export const RenderTaggedInput = generateRenderer({
  FieldComponent: TaggedInput,
  ErrorComponent: InputError,
  // PreviewComponent: ({ value }: { value: Object }) => (
  //   <pre>
  //     { JSON.stringify(value) }
  //   </pre>
  // ),
});

// TODO: check maybe a FieldRenderer switch

export const RenderRadioOptions = generateRenderer({
  FieldComponent: RadioOptions,
  ErrorComponent: InputError,
});

export const RenderAddOrganizationsTable = generateRenderer({
  FieldComponent: AddOrganizationsTable,
  ErrorComponent: InputError,
  PreviewComponent: ({ value }: { value: Object }) => (
    <pre>
      { JSON.stringify(value) }
    </pre>
  ),
});
