// @flow
import React, { Node } from 'react';
import styles from './headerLeftGroup.css';

type HeaderLeftGroupPropTypes = {
  children: Node,
};


const HeaderLeftGroup = ({
  children,
}: HeaderLeftGroupPropTypes) => (
  <div className={styles.leftGroup}>
    {children}
  </div>
);

export default HeaderLeftGroup;
