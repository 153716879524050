// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  STOPLIGHT_RESULT_TYPE,
  STOPLIGHT_RESULTS_FETCH_STARTED_TYPE,
  STOPLIGHT_RESULTS_FETCH_COMPLETED_TYPE,
  STOPLIGHT_RESULTS_FETCH_FAILED_TYPE,
} from '../types/stoplightResults';
import * as types from '../types/stoplightResults';
import { error } from './common';
import { UPDATE_STUDY_RESULTS_FILTERS } from '../types/studyResults';


export const startFetchStoplightResults = (
  studyId: number,
  phase: number,
): STOPLIGHT_RESULTS_FETCH_STARTED_TYPE => ({
  type: types.STOPLIGHT_RESULTS_FETCH_STARTED,
  payload: {
    studyId,
    phase,
  },
});

export const completeFetchStoplightResults = (
  entities: {[ID_TYPE]: STOPLIGHT_RESULT_TYPE},
  order: Array<ID_TYPE>,
): STOPLIGHT_RESULTS_FETCH_COMPLETED_TYPE => ({
  type: types.STOPLIGHT_RESULTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchStoplightResults = error(types.STOPLIGHT_RESULTS_FETCH_FAILED);
