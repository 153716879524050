exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-MultipleFilters-__multipleFilters___container {\n  /* border: 1px solid; */\n  display: flex;\n  flex: 1 1;\n  flex-wrap: wrap;\n  margin-bottom: 2rem;\n  padding: 0 2rem;\n  /* justify-content: center; */\n}\n\n.src-scripts-components-MultipleFilters-__multipleFilters___filter {\n  padding: 1rem 0.8rem;\n  box-sizing: border-box;\n  width: 25rem;\n  /* border: 1px solid red; */\n}\n\n.src-scripts-components-MultipleFilters-__multipleFilters___filterTwo {\n  padding: 1.2rem;\n  box-sizing: border-box;\n  width: 30rem;\n}\n\n.src-scripts-components-MultipleFilters-__multipleFilters___header {\n  color: var(--grayColor);\n  outline: none;\n  cursor: pointer;\n  font-size: 1.8rem;\n  font-weight: 500;\n  padding-bottom: 1rem;\n}\n\n.src-scripts-components-MultipleFilters-__multipleFilters___clearOutline {\n  outline: none !important;\n  width: 100%;\n}\n\n.src-scripts-components-MultipleFilters-__multipleFilters___name {\n  font-weight: 500;\n  font-size: 1.5rem;\n  color: var(--grayColor);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-MultipleFilters-__multipleFilters___container",
	"filter": "src-scripts-components-MultipleFilters-__multipleFilters___filter",
	"filterTwo": "src-scripts-components-MultipleFilters-__multipleFilters___filterTwo",
	"header": "src-scripts-components-MultipleFilters-__multipleFilters___header",
	"clearOutline": "src-scripts-components-MultipleFilters-__multipleFilters___clearOutline",
	"name": "src-scripts-components-MultipleFilters-__multipleFilters___name"
};