// @flow

const validate = (values) => {
  const errors = {};

  if (!values.full_name) { errors.full_name = 'Requerido'; }
  if (!values.code) { errors.code = 'Requerido'; }
  if (!values.gender) { errors.gender = 'Requerido'; }
  if (!values.birthdate) { errors.birthdate = 'Requerido'; }
  if (!values.organization) { errors.organization = 'Requerido'; }

  return errors;
}

export default validate;
