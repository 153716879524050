// @flow
import { v4 as uuidv4 } from 'uuid';
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  INDICATOR_RESULT_TYPE,
  INDICATOR_RESULTS_FETCH_STARTED_TYPE,
  INDICATOR_RESULTS_FETCH_COMPLETED_TYPE,
  INDICATOR_RESULTS_FILTERS_UPDATED_TYPE,
  INDICATOR_RESULTS_FILTERS_CLEARED_TYPE,
  INSTRUMENT_INDICATOR_RESULTS_STARTED_TYPE,
  INSTRUMENT_INDICATOR_RESULTS_COMPLETED_TYPE,
} from '../types/indicatorResults';
import * as types from '../types/indicatorResults';
import { error } from './common';


export const startFetchIndicatorResults = (
  filters,
): INDICATOR_RESULTS_FETCH_STARTED_TYPE => ({
  type: types.INDICATOR_RESULTS_FETCH_STARTED,
  payload: filters,
});

export const completeFetchIndicatorResults = (
  entities: {[ID_TYPE]: INDICATOR_RESULT_TYPE},
  order: Array<ID_TYPE>,
): INDICATOR_RESULTS_FETCH_COMPLETED_TYPE => ({
  type: types.INDICATOR_RESULTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchIndicatorResults = error(types.INDICATOR_RESULTS_FETCH_FAILED);

export const updateIndicatorsFilters = (
  key: string,
  value: any,
): INDICATOR_RESULTS_FILTERS_UPDATED_TYPE => ({
  type: types.INDICATOR_RESULTS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const clearIndicatorsFilters = (): INDICATOR_RESULTS_FILTERS_CLEARED_TYPE => ({
  type: types.INDICATOR_RESULTS_FILTERS_CLEARED,
})

export const startFetchStudyIndicatorResults = (
  studyId: ID_TYPE,
  phase: Number,
) => ({
  type: types.STUDY_INDICATOR_RESULTS_STARTED,
  payload: {
    studyId,
    phase,
    requestTimestamp: Date.now(),
  }
});

export const completeFetchStudyIndicatorResults = (
  entities: {[ID_TYPE]: INDICATOR_RESULT_TYPE},
  order: Array<ID_TYPE>,
) => ({
  type: types.STUDY_INDICATOR_RESULTS_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchStudyInsdicatorResults = error(types.STUDY_INDICATOR_RESULTS_FAILED);

export const startFetchInstrumentIndicatorResults = (
  id: ID_TYPE,
  phase: Number,
): INSTRUMENT_INDICATOR_RESULTS_STARTED_TYPE => ({
  type: types.INSTRUMENT_INDICATOR_RESULTS_STARTED,
  payload: id,
});

export const completeFetchInstrumentIndicatorResults = (
  entities: {[ID_TYPE]: INDICATOR_RESULT_TYPE},
  order: Array<ID_TYPE>,
): INSTRUMENT_INDICATOR_RESULTS_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_INDICATOR_RESULTS_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchInstrumentIndicatorResults = error(types.INSTRUMENT_INDICATOR_RESULTS_FAILED);
