exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-Dim-__dim___dim {\n  align-items: center;\n  background: rgba(0, 0, 0, 0.2);\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 100;\n}\n\n/* TRANSITION GROUPS: */\n.src-scripts-components-Dim-__dim___enter, .src-scripts-components-Dim-__dim___exitActive {\n  opacity: 0;\n}\n\n.src-scripts-components-Dim-__dim___enterActive, .src-scripts-components-Dim-__dim___enterDone {\n  opacity: 1.0;\n}\n\n/* THEMES: */\n.src-scripts-components-Dim-__dim___transparent {\n  background: rgba(0, 0, 0, 0) !important;\n}\n\n.src-scripts-components-Dim-__dim___main {\n  background: rgba(0, 0, 0, 0.65);\n}\n\n.src-scripts-components-Dim-__dim___secondary {\n  background: var(--overlayDarkGradient);\n}\n\n.src-scripts-components-Dim-__dim___white {\n  background: white;\n}\n", ""]);
// Exports
exports.locals = {
	"dim": "src-scripts-components-Dim-__dim___dim",
	"enter": "src-scripts-components-Dim-__dim___enter",
	"exitActive": "src-scripts-components-Dim-__dim___exitActive",
	"enterActive": "src-scripts-components-Dim-__dim___enterActive",
	"enterDone": "src-scripts-components-Dim-__dim___enterDone",
	"transparent": "src-scripts-components-Dim-__dim___transparent",
	"main": "src-scripts-components-Dim-__dim___main",
	"secondary": "src-scripts-components-Dim-__dim___secondary",
	"white": "src-scripts-components-Dim-__dim___white"
};