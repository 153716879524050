// @flow
import React from 'react';

import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import { CHART_VIEW_TYPE, TABLE_VIEW_TYPE } from '../../settings';

type ToggleViewPropTypes = {
  onChange: Function,
  value: string,
};

const ToggleView = ({
  onChange,
  value,
}) => (
  <ButtonGroup
    horizontalAlign={'right'}
  >
    <Button
      title="Grafica"
      isLeft
      size='small'
      onClick={() => onChange(CHART_VIEW_TYPE)}
      tint={value === CHART_VIEW_TYPE ? 'main' : 'mediumGray'}
    />
    <Button
      title="Tabla"
      isRight
      size='small'
      onClick={() => onChange(TABLE_VIEW_TYPE)}
      tint={value === TABLE_VIEW_TYPE ? 'main' : 'mediumGray'}
    />
  </ButtonGroup>
);


export default ToggleView;
