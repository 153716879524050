// @flow
import React from 'react';

import image from './logo.png';
import styles from './navbarLogo.css';


const NavbarLogo = () => (
  <div className={styles.logo}>
    <img 
      src={image}
      className={styles.image}
      alt="Nutrición Fundación JBG" />
  </div>
);

export default NavbarLogo;
