// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { MdSearch, MdAdd } from 'react-icons/lib/md';
import { ID_TYPE } from '../../types/common';

import Header from '../ViewHeader';
import HeaderLeftGroup from '../ViewHeaderLeftGroup';
import HeaderRightGroup from '../ViewHeaderRightGroup';
import Select from '../Select';
import Input from '../Input';
import Button from '../Button';
import styles from './studiesHeader.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/studies';
import * as closableActions from '../../actions/closeable';
import { ADD_STUDY_POPUP_ID } from '../AddStudyPopup';


type StudiesHeaderPropTypes = {
  instruments: Array<Object>,
  updateFilters: Function,
  fetchStudies: Function,
  instrument: ID_TYPE,
  text: string,
  openAddStudyPopup: Function,
};


class StudiesHeader extends Component<StudiesHeaderPropTypes> {
  constructor(props: StudiesHeaderPropTypes) {
    super(props);

    this._debouncedMethod = debounce(this._onInputChange, 300);
  }

  _onInputChange(text) {
    const {
      fetchStudies,
    } = this.props;

    fetchStudies();
  }

  _onSelectChange(value) {
    const {
      updateFilters,
      fetchStudies,
    } = this.props;

    updateFilters('instrument', value);
    fetchStudies();
  }

  render() {
    const { 
      instruments = [],
      updateFilters,
      instrument = '',
      text = '',
      openAddStudyPopup,
     } = this.props;

    return (
      <Header>
        <HeaderLeftGroup>
          <div className={styles.selectContainer}>
            <Select
              options={instruments}
              onChange={this._onSelectChange.bind(this)}
              value={instrument}
              isRound
            />
          </div>
        </HeaderLeftGroup>
        <HeaderRightGroup>
          <Input
            isRound
            clearMargins
            Icon={MdSearch}
            placeholder="Realiza tu búsqueda aquí"
            onChange={(e) => {
              const text = e.target.value;
              updateFilters('text', text);
              this._debouncedMethod();
            }}
            value={text}
            className={styles.approveInput}
          />
          <Button
            isRound
            clearMargins
            tint="main"
            title="Crear estudio"
            Icon={MdAdd}
            onClick={() => openAddStudyPopup()}
          />
        </HeaderRightGroup>
      </Header>
    )
  }
};


export default connect(
  state => {
    const instruments = selectors.getInstruments(state).map(el => ({
      value: el.id,
      label: el.name,
    }));

    instruments.push({
      value: undefined,
      label: 'Todos'
    });

    return ({
      instruments,
      instrument: selectors.getStudiesFilter(state, 'instrument'),
      text: selectors.getStudiesFilter(state, 'text'),
    });
  },
  dispatch => ({
    updateFilters(key, value) {
      dispatch(actions.updateStudiesFilters(key, value));
    },
    fetchStudies() {
      dispatch(actions.startFetchStudies());
    },
    openAddStudyPopup() {
      dispatch(closableActions.open(ADD_STUDY_POPUP_ID));
    }
  })
)(StudiesHeader);
