// @flow
import * as lodash from 'lodash';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import zStandards from './whoZStandards';


type MEASUREMENT = {
  age: number,
  weight: number,
  height: number,
};

type AntropometryChartPropTypes = {
  gender: 'male' | 'female',
  from: 'age' | 'length' | 'height',
  fromUnits: string,
  to: 'length' | 'height' | ' weight',
  toUnits: string,
  measurements: Array<MEASUREMENT>,
  title: string,
  entityName: string,
  isOrganizationFiltered: boolean,
  olderChildrenFilters: boolean,
};

// TODO: define zStandards for a generic gender (we are representing everything as )
const AntropometryChart = ({
  gender,
  from,
  fromUnits,
  to,
  toUnits,
  measurements,
  title,
  entityName,
  isOrganizationFiltered,
  olderChildrenFilters,
}: AntropometryChartPropTypes) => {
  if ((gender in zStandards && from in zStandards[gender] && to in zStandards[gender][from]) || isOrganizationFiltered) {
    const { x, ys } = zStandards[gender][from][to];
    const sds = lodash.zip.apply(lodash, ys);
    const measurementsData = []

    if (isOrganizationFiltered) {
      const filteredMeasurements = measurements
        .map(({ phase, age, weight, height }) => (
          !olderChildrenFilters ? (
            {
              age,
              length: age <= 24 ? height : undefined,
              height: age >= 24 ? height : undefined,
              phase,
              weight,
            }
          ) : (
            {
              age,
              height,
              phase,
              weight,
            }
          )
        )).filter(measurement => measurement[from] != null && measurement[to] != null)
      
      // Group data by phase
      const phasesMeasurements = lodash.groupBy(filteredMeasurements , 'phase')
      const phasesKeys = lodash.keys(phasesMeasurements)
      
      // Transform each phase data as unique avg value
      const phasesValues = phasesKeys.map(phase => {
        const phaseMeasurements = phasesMeasurements[phase];

        const fromAvg = lodash.meanBy(phaseMeasurements, from)
        const toAvg = lodash.meanBy(phaseMeasurements, to)

        return {
          name: `${entityName} - Fase ${phase}`,
          data: [[fromAvg, toAvg]]
        }
      })

      measurementsData.push(...phasesValues)

    } else {
      const trMeasurements = measurements
        .map(({ age, weight, height }) => {
          return {
            age,
            weight,
            length: age <= 24 ? height : undefined,
            height: age >= 24 ? height : undefined,
          }
        })
        .filter(measurement => measurement[from] != null && measurement[to] != null)
        .map(measurement => [measurement[from], measurement[to]]);

      measurementsData.push({
        name: entityName,
        data: trMeasurements,
      })
    }

    return (
      <ReactApexChart
        options={{
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          stroke: {
            curve: 'straight',
            width: [...measurementsData.map(()=> 4), ...sds.map(() => 1)],
            // dashArray: [0, ...sds.map(() => 2)],
          },
          title: {
            text: title,
            align: 'left'
          },
          markers: {
            size: [...measurementsData.map(()=> 6), ...sds.map(() => 0)],
          },
          xaxis: {
            type: 'numeric',
          }
        }}
        series={
          [
            ...measurementsData,
            ...sds.map(
              (sd, index) => ({
                name: `SD${index - parseInt(Math.floor(sds.length / 2))}`,
                data: lodash.zip(x, sd),
              })
            ),
          ]
        }
      />
    );
  }

  return null;
};


export default AntropometryChart;
