// @flow
import React from 'react';

import styles from './dataTable.css';

type AggregatedPropTypes = {
  subRows?: Array<Object>,
  column: {
    id: string,
  },
  className: string,
};

const Aggregated = ({
  subRows = [],
  column,
  className = '',
  ...rest
}: AggregatedPropTypes) => {
  const previewValues = subRows.filter(d => typeof d[column.id] !== 'undefined').map((row, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={i}>
      {row[column.id]}
      {i < subRows.length - 1 ? ', ' : ''}
    </span>
  ));

  return (
    <span className={`${styles.aggregated} ${className}`} {...rest}>
      {previewValues}
    </span>
  );
};

export default Aggregated;
