// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  INSTRUMENT_TYPE,
  INSTRUMENTS_FETCH_STARTED_TYPE,
  INSTRUMENTS_FETCH_COMPLETED_TYPE,
  INSTRUMENTS_FETCH_FAILED_TYPE,
  INSTRUMENT_FETCH_STARTED_TYPE,
  INSTRUMENT_FETCH_COMPLETED_TYPE,
  INSTRUMENT_QUESTIONS_FETCH_STARTED_TYPE,
  INSTRUMENT_QUESTIONS_FETCH_COMPLETED_TYPE,
  INSTRUMENTS_FILTERS_UPDATED_TYPE,
  INSTRUMENTS_FILTERS_CLEARED_TYPE,
  INSTRUMENT_SELECTED_TYPE,
  INSTRUMENT_UNSELECTED_TYPE,
} from '../types/instruments';
import * as types from '../types/instruments';
import { error } from './common';


export const startFetchInstruments = (): INSTRUMENTS_FETCH_STARTED_TYPE => ({
  type: types.INSTRUMENTS_FETCH_STARTED,
  payload: {},
});

export const completeFetchInstruments = (
  entities: {[ID_TYPE]: INSTRUMENT_TYPE},
  order: Array<ID_TYPE>,
): INSTRUMENTS_FETCH_COMPLETED_TYPE => ({
  type: types.INSTRUMENTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchInstruments = error(types.INSTRUMENTS_FETCH_FAILED);

export const startFetchInstrument = (id: ID_TYPE): INSTRUMENT_FETCH_STARTED_TYPE => ({
  type: types.INSTRUMENT_FETCH_STARTED,
  payload: id,
});

export const completeFetchInstrument = (instrument: INSTRUMENT_TYPE): INSTRUMENT_FETCH_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_FETCH_COMPLETED,
  payload: instrument,
});

export const failFetchInstrument = error(types.INSTRUMENT_FETCH_FAILED);

export const startFetchInstrumentQuestions = (id: ID_TYPE): INSTRUMENT_QUESTIONS_FETCH_STARTED_TYPE => ({
  type: types.INSTRUMENT_QUESTIONS_FETCH_STARTED,
  payload: id,
});

export const completeFetchInstrumentQuestions = (id: ID_TYPE, questionsSets: Array<ID_TYPE>) => ({
  type: types.INSTRUMENT_QUESTIONS_FETCH_COMPLETED,
  payload: {
    id,
    questions_sets: questionsSets,
  }
});

export const failFetchInstrumentQuestions = error(types.INSTRUMENT_QUESTIONS_FETCH_FAILED);

export const updateInstrumentsFilters = (key: string, value: any): INSTRUMENTS_FILTERS_UPDATED_TYPE => ({
  type: types.INSTRUMENTS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const clearInstrumentsFilters = (): INSTRUMENTS_FILTERS_CLEARED_TYPE => ({
  type: types.INSTRUMENTS_FILTERS_CLEARED,
});

export const selectInstrument = (id: ID_TYPE): INSTRUMENT_SELECTED_TYPE => ({
  type: types.INSTRUMENT_SELECTED,
  payload: id,
});

export const unselectInstrument = (): INSTRUMENT_UNSELECTED_TYPE => ({
  type: types.INSTRUMENT_UNSELECTED,
});
