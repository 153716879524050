// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './label.css';


type LabelPropTypes = {
  children?: Node,
  className?: string
};

const Label = ({
  children,
  className,
}: LabelPropTypes) => (
  <div className={
    `
      ${styles.label}
      ${className || ''}
    `
  }
  dangerouslySetInnerHTML={{ __html: children }}
  />
);

export default Label;
