// @flow
import { schema } from 'normalizr';

import { questionsSet } from './questionsSets';


export const instrument = new schema.Entity('instruments', {
  questionsSets: [questionsSet],
});
export const arrayOfInstruments = new schema.Array(instrument);
