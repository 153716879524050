exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___elementWraper {\n  border-bottom: 1px solid var(--grayColor);\n  padding: 4rem;\n}\n\n.src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___title {\n  font-size: 2rem;\n  font-weight: 700;\n  color: var(--almostGrayColor);\n}\n\n.src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___setContainer {\n  padding: 2rem 0;\n}\n\n@media(max-width: 768px) {\n  .src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___elementWraper {\n    padding: 2rem;\n  }\n}", ""]);
// Exports
exports.locals = {
	"elementWraper": "src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___elementWraper",
	"title": "src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___title",
	"setContainer": "src-scripts-components-QuestionsSetFormRenderer-__questionSetFormRenderer___setContainer"
};