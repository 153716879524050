// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';

import PopupStudyFilters from '../PopupStudyFilters';
import PopupContent from '../PopupContent';
import PopupFooter from '../PopupFooter';
import Button from '../Button';
import { MultipleFiltersList } from '../MultipleFilters';
import styles from './studyResults.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/studyResults';
import * as closeableActions from '../../actions/closeable';


type PopupFiltersStudyResultsPropTypes = {
  filters: Array<Object>,
  onSubmit: Function,
  fValues: Object,
  study: number,
};

export const POPUP_FILTERS_RESULTS_ID = 'PopupFiltersResults';

const PopupFiltersStudyResults = ({
  study,
  filters,
  fValues,
  onSubmit,
}: PopupFiltersStudyResultsPropTypes) => {
  const [ values, setValues ] = useState(fValues);

  return (
    <PopupStudyFilters id={POPUP_FILTERS_RESULTS_ID}>
      <PopupContent className={styles.popupContainer}>
        <MultipleFiltersList
          filters={filters}
          values={values}
          onChange={(key, val) => setValues({
            ...values,
            [key]: val,
          })}
        />
      </PopupContent>
      <PopupFooter className={styles.popupFooter}>
        <Button
          tint='gray'
          size='small'
          title="aplicar"
          onClick={() => onSubmit(values, study)}
        />
      </PopupFooter>
    </PopupStudyFilters>
  )
};


export default connect(
  state => {
    const study = selectors.getSelectedStudy(state);
    return ({
      study: study ? study.id : -1,
      filters: study ? study.filters : [],
      fValues: selectors.getStudyResultsFilters(state),
    })
  },
  dispatch => ({
    onSubmit(filters, study) {
      Object.keys(filters).map(key => {
        dispatch(actions.updateStudyResultsFilters(key, filters[key]));
      });
      dispatch(actions.startFetchStudyResults(study));
      dispatch(closeableActions.close(POPUP_FILTERS_RESULTS_ID));
    }
  })
)(PopupFiltersStudyResults);
