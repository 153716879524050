// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdClose, MdSearch } from 'react-icons/lib/md';

import * as fileActions from '../../actions/files';
import * as closeableActions from '../../actions/closeable';
import * as selectors from '../../reducers';
import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupHeader from '../PopupHeader';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import Button from '../Button';

import styles from './fileUploader.css';
import Dropzone from './dropzone';
import Input from '../Input';

type StudiesFormPopupType = {
  id: ID_TYPE,
  participantId: ID_TYPE,
  isFetchingFiles: boolean,
  isUploadingFile: boolean,
  isOpen: boolean,
  onCancel: Function,
  onConfirm: Function,
  uploadFile: Function,
  loadFiles: Function,
  deleteFile: Function,
  files: any,
};

export const FILE_UPLOADER = 'FileUploader';

const FileUploader = ({
  onCancel,
  onConfirm,
  uploadFile,
  deleteFile,
  files,
  participantId,
  isFetchingFiles,
  isUploadingFile,
}: StudiesFormPopupType) => {
  return (
    <Popup id={FILE_UPLOADER}>
      <PopupContainer
        elevation={8}
        isRound
        size="small"
      >
        <Fragment>
          <PopupHeader clearPadding isAlert>
            <PopupHeaderLeftGroup>
              <p className={styles.popUpTitle}>
                Archivos
              </p>
            </PopupHeaderLeftGroup>
            <PopupHeaderRightGroup>
              <PopupCloseButton
                tint="mediumGray"
                Icon={MdClose}
                clearMargins
                isTransparent
                isOutlined
              />
            </PopupHeaderRightGroup>
          </PopupHeader>
          <PopupContent className={styles.popUpContainer}>
            <Dropzone
              uploadFile={uploadFile}
              files={files}
              isLoading={isFetchingFiles}
              isUploading={isUploadingFile}
              deleteFile={(uuid) => deleteFile(uuid, participantId)}
            />
            <div
              className={styles.buttonsContainer}
            >
              <Button
                type="button"
                title="Aceptar"
                tint="primary"
                onClick={() => onConfirm()}
              />
              <Button
                type="button"
                title="Cancelar"
                tint="secondary"
                onClick={() => onCancel()}
              />
            </div>
          </PopupContent>
        </Fragment>
      </PopupContainer>
    </Popup>
  );
}

export default connect(
  (state, ownProps) => {
    const files = state.files.byUserId[ownProps.participantId];

    return {
      files: files ? Object.values(files) : [],
      isFetchingFiles: state.files.isFetching,
      isUploadingFile: state.files.isUploading,
    };
  },
  (dispatch, ownProps) => ({
    onCancel() {
      dispatch(closeableActions.close(FILE_UPLOADER));
    },
    onConfirm() {
      dispatch(closeableActions.close(FILE_UPLOADER));
    },
    uploadFile(file: any, fileName: string, fileExtension: string, uuid: string) {
      dispatch(fileActions.startFileUpload(file, fileName, fileExtension, uuid, ownProps.participantId));
    },
    deleteFile(uuid: string, participantId: ID_TYPE) {
      dispatch(fileActions.startFileDelete(uuid, participantId));
    },
  })
)(FileUploader);