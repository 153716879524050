// @flow
import React from 'react';
import { MdPlace } from 'react-icons/lib/md';

import OmnisearchResultDummy from '../OmnisearchResultDummy';


type OrganizationResultPropTypes = {
  name?: string,
  short_name?: string,
  address?: string,
  isSelected?: boolean,
  size?: string,
  onClick?: Function
};

class OrganizationResult extends React.Component<OrganizationResultPropTypes> {
  render() {
    const {
      name = '',
      short_name = '',
      isSelected = false,
      size = 'medium',
      onClick,
    } = this.props;
    return (
      <OmnisearchResultDummy
        title={name}
        subtitle={``}
        Icon={MdPlace}
        isSelected={isSelected}
        size={size}
        onClick={onClick}
      />
    )
  }
};

export default OrganizationResult;
