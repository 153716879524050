// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './buttongroup.css';

// import Icon from 'react-icon-base';


// <Input RightIcon={ MdDashboard } /> 

type ButtonGroupPropTypes = {
  children?: Node,
  className?: string, 
  isFullWidth?: boolean,
  isFlex?: boolean,
  hasMultipleItems?: boolean,
  horizontalAlign?: string,
  isWraperItems?: boolean
};

const ButtonGroup = ({
  children,
  className,
  isFlex = false,
  isFullWidth = false,
  horizontalAlign = 'left',
  isWraperItems = false,
  hasMultipleItems = false,
}: ButtonGroupPropTypes) => (
  <div
    className={
      `
        ${styles.buttonGroup}
        ${isFullWidth ? styles.fullWidthButtons : ''}
        ${isFlex ? styles.isFlex : ''}
        ${typeof className !== 'undefined' ? className : ''}
        ${typeof styles[horizontalAlign] !== 'undefined' ? styles[horizontalAlign] : ''}
        ${isWraperItems ? styles.wrapItems : ''}
        ${hasMultipleItems ? styles.stackItems : ''}
      `
    }
  >
    {children}
  </div>);

export default ButtonGroup;
