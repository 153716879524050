import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { MdClose } from 'react-icons/lib/md';

import Button from '../Button';
import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import AddParticipantForm from '../AddParticipantForm/addParticipantPopup';

import styles from './participantPopup.css';
import * as selectors from '../../reducers';
import * as closableActions from '../../actions/closeable';

export const PARTICIPANT_POPUP = 'AddParticipantPopup';

const ParticipantPopup = ({ onLoad, clearForm, isSubmitting, submitForm }) => {
  useEffect(() => {
    onLoad();
    return () => clearForm();
  });

  return (
    <Popup
      id={PARTICIPANT_POPUP}
      theme='main'
      transitionMs={600}
      dontCloseOnEscape
    >
      <PopupContainer isFullscreen>
        <div className={styles.closeButton}>
          <Button
            tint='primary'
            isLoading={isSubmitting}
            title='Guardar'
            isRound
            onClick={submitForm}
          />
          <PopupCloseButton Icon={MdClose} tint='mediumGray' isRound />
        </div>

        <div className={styles.formContainer}>
          <AddParticipantForm />
        </div>
      </PopupContainer>
    </Popup>
  );
};

ParticipantPopup.propTypes = {
  onLoad: PropTypes.func,
  clearForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitForm: PropTypes.func,
};

// export default ParticipantPopup;
export default connect(
  (state, ownProps) => ({
    instrument: ownProps.id,
    isSubmitting: selectors.isAddingUser(state),
    isOnline: selectors.hasInternetConnection(state),
  }),
  (dispatch) => ({
    onLoad(isOnline) {
      if (!isOnline) return;
    },
    clearForm() {
      dispatch(closableActions.close(PARTICIPANT_POPUP));
    },
    submitForm() {
      dispatch(submit('addParticipantForm'));
    },
  }),
  (stateProps, { onLoad, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onLoad() {
      onLoad(stateProps.isOnline);
    },
  })
)(ParticipantPopup);
