// @flow
import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import {
  MdDirections,
  MdEvent,
  MdGroup,
  MdAssignment,
  MdDirectionsRun,
  MdDirectionsBus,
} from 'react-icons/lib/md';

import { ANTHROPOMETRY_TYPE, EXIT_PROFILE_AVOIDABLE_AXIS } from '../../settings';
import BackendSidebarLink from './BackendSidebarLink';
import styles from './backendSidebar.css';

import * as selectors from '../../reducers';


type SidebarPropType = {
  studyId: number,
  studyType: string,
  isExitProfileAvoidable: boolean,
};

const Sidebar = ({
  studyId,
  studyType = ANTHROPOMETRY_TYPE,
  isExitProfileAvoidable = false,
}: SidebarPropType) => (
  <div className={styles.sidebar}>
    <BackendSidebarLink
      Icon={null}
      to={`/studies/${studyId}/submissions`}
      title="Ingresos"
      isActive={(match, { pathname }) => match || pathname === `/studies/${studyId}`}
    />
    <BackendSidebarLink
      Icon={MdDirections}
      to={`/studies/${studyId}/participants`}
      title="Participantes"
    />
    {
      studyType !== ANTHROPOMETRY_TYPE && (
        <BackendSidebarLink
          Icon={MdGroup}
          to={`/studies/${studyId}/semaphore`}
          title="Semáforo"
        />
      )
    }
    {
      studyType === ANTHROPOMETRY_TYPE ? (
        <BackendSidebarLink
          Icon={MdDirectionsBus}
          to={`/studies/${studyId}/results-anthropometry`}
          title="Resultados"
        />
      ) : (
        <BackendSidebarLink
          Icon={MdDirectionsBus}
          to={`/studies/${studyId}/results`}
          title="Resultados"
        />
      )
    }
    {
      studyType !== ANTHROPOMETRY_TYPE && (
        <BackendSidebarLink
          Icon={MdDirectionsRun}
          to={`/studies/${studyId}/indicators`}
          title="Indicadores"
        />
      )
    }
    {
      studyType !== ANTHROPOMETRY_TYPE && !isExitProfileAvoidable && (
        <BackendSidebarLink
          Icon={MdDirectionsRun}
          to={`/studies/${studyId}/exit-profiles`}
          title="Indice de Perfil de Salida"
        />
      )
    }
    {
      studyType === ANTHROPOMETRY_TYPE && (
        <BackendSidebarLink
          Icon={MdDirectionsBus}
          to={`/studies/${studyId}/organizations`}
          title="Comunidades"
        />
      )      
    }
  </div>

);

export default connect(
  state => {
    const { instrument = {} } = selectors.getSelectedStudy(state) || {};
    const { type_key, is_scorable, name } = instrument;

    const isExitProfileAvoidableAxis = 
      name && EXIT_PROFILE_AVOIDABLE_AXIS.some(axis => name.toLowerCase().includes(axis))

    return {
      studyType: type_key,
      isExitProfileAvoidable: isExitProfileAvoidableAxis,
    }
  }
)(Sidebar);
