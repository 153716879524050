// @flow
import React from 'react';
import { connect } from 'react-redux';
import PopupContainer from '../PopupContainer';
import { FullLoader } from '../Loader';

import styles from './offlineMode.css';
import * as selectors from '../../reducers';


type OfflineModePopupContentType = {
  loading: boolean,
};

const OfflineModePopupContent = ({
  loading,
}: OfflineModePopupContentType) => {
  return (
    <PopupContainer isFullscreen>
      <div className={
        `${styles.loader} ${!loading ? styles.hidden : ''}`
      }>
        <FullLoader size="large" title='Preparando datos para el modo "Desconectado"...'/>
      </div>
      <div className={styles.content}>
        {/* We dont want to display any message here... */}
      </div>
    </PopupContainer>
  );
}

export default connect(
  state => ({
    loading: selectors.isFetchingStudyParticipants(state),
  }),
)(OfflineModePopupContent);
