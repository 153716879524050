// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './dataTable.css';

type TrPropTypes = {
  children?: Node,
  className: string,
};

const Tr = ({ children, className = '', ...rest }: TrPropTypes) => (
  <tr className={`${styles.tr} ${className}`} role="row" {...rest}>
    { children }
  </tr>
);

export default Tr;
