// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import range from 'lodash/range';
import includes from 'lodash/includes';

import Slider from '../SlideFilter';
import styles from './resultsPhasesFilter.css';
import { FILTER_STUDY_KEY } from '../../settings';

import * as selectors from '../../reducers';
import * as actions from '../../actions/instruments';
import * as semaphoreResultsActions from '../../actions/results';
import * as resultsStoplightActions from '../../actions/stoplightResults';
import * as resultsIndicatorsActions from '../../actions/indicatorResults';
import * as resultsActions from '../../actions/studyResults';
import * as resultsExitProfile from '../../actions/resultsExitProfile';
import MultipleSelect from '../MultipleSelect';


type ResultsPhasesFilterPropTypes = {
  phases: Array<number>,
  isFetching: boolean,
  onChange: Function,
  location: Object,
  instrument: Object,
  value: number[],
};

const ResultsPhasesFilter = ({
  phases,
  isFetching,
  onChange,
  location,
  instrument,
  value = [],
}: ResultsPhasesFilterPropTypes) => {
  const isPathResults = includes(location.pathname, 'intrument-results');
  const isPathIndicators = includes(location.pathname, 'exit-profiles');
  const [phasesFilter, setPhasesFilter] = useState([]);

  useEffect(() => {
    onChange(phasesFilter.map(x => x.id), location, instrument);
  }, [phasesFilter, location, instrument])

  if (isFetching && !isPathResults && !isPathIndicators) {
    return null;
  }

  if (!phases.length || isPathResults || isPathIndicators) {
    return null
  }

  return (
    <div className={styles.container}>
      {/* <Slider
        phases={phases}
        onChange={(el) => onChange(el, location, instrument)}
        tint='light'
        value={value}
      /> */}
      <span>Selecciona las fases que deseas filtrar:</span>
      <MultipleSelect
        options={(phases || []).map(x => ({ value: x, label: x.name }))}
        value={phasesFilter}
        onChange={vals => setPhasesFilter(vals)}
      />
    </div>
  )
};

export default connect(
  state => {
    const studyId = selectors.getInstrumentsFilter(state, FILTER_STUDY_KEY);
    const study = selectors.getStudy(state, studyId);
    const isFetchingStudy = selectors.isFetchingStudy(state, studyId);
    const isFetchingInstruments = selectors.isFetchingInstruments(state);
    const instrument = selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state);
    const { phase } = selectors.getInstrumentsFilters(state);

    return ({
      isFetching: isFetchingInstruments || isFetchingStudy,
      phases: study ? study.phases : [],
      location: selectors.getRouteLocation(state),
      instrument,
      value: phase,
    });
  },
  dispatch => ({
    onChange(value, location, instrument) {
      /* const phases = range(1, (value + 1)); */
      const path = location.pathname;

      dispatch(actions.updateInstrumentsFilters('phase', value));
      if (includes(path, 'semaphore')) {
        dispatch(semaphoreResultsActions.startFetchStudyResults('semaphore'));
      } else if (includes(path, 'indicators') && instrument && instrument.id) {
        dispatch(resultsIndicatorsActions.startFetchInstrumentIndicatorResults(instrument.id))
      }
    }
  })
)(ResultsPhasesFilter);
