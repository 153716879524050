// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import StudyParticipantsList from '../StudyParticipantsList';
import SearchBar from './searchBar';
import ParticipantBar from './participantBar';

import * as actions from '../../actions/participants';
import * as selectors from '../../reducers';
import styles from './studyParticipants.css';


type StudyParticipantsPropTypes = {
  studyId: number,
  hasScorableInstrument: boolean,
  onLoad: Function,
  clearFilters: Function
};


const StudyParticipants = ({
  studyId,
  hasScorableInstrument,
  onLoad,
  clearFilters
}: StudyParticipantsPropTypes) => {
  useEffect(() => {
    onLoad();
    return () => clearFilters()
  });

  return (
    <Fragment>
      <div className={styles.bar}>
        <SearchBar />
        <ParticipantBar />
      </div>

      <div className={styles.container}>
        <StudyParticipantsList 
          studyId={studyId}
          hasScorableInstrument={hasScorableInstrument}  
        />
      </div>
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);
    const currentStudy = selectors.getStudy(state, studyId) || {};
    const hasScorableInstrument = currentStudy.instrument && currentStudy.instrument.is_scorable;

    return ({
      studyId,
      hasScorableInstrument
    });
  },
  dispatch => ({
    onLoad(studyId, hasScorableInstrument) {
      dispatch(actions.updateStudyParticipantsFilters('only-with-submissions', false));
      dispatch(actions.updateStudyParticipantsFilters('study', studyId));
      hasScorableInstrument && dispatch(actions.updateStudyParticipantsFilters('scorable', true));
      dispatch(actions.startFetchStudyParticipants());
    },
    clearFilters() {
      dispatch(actions.updateStudyParticipantsFilters('only-with-submissions', ''));
      dispatch(actions.updateStudyParticipantsFilters('text', ''));
      dispatch(actions.updateStudyParticipantsFilters('scorable', ''));
    }
  }),
  (stateProps, { onLoad, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onLoad() {
      onLoad(stateProps.studyId, stateProps.hasScorableInstrument);
    }
  })
)(StudyParticipants);
