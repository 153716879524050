// @flow
import keycode from 'keycode';
import type { ComponentType, Node } from 'react';
import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import styles from './popup.css';
import Dim from '../Dim';
import { ClosePopupProvider } from './providers';
import * as closeableActions from '../../actions/closeable';


type PopupPropTypes = {
  isOpen?: boolean,
  onClose: Function,
  onAfterClose: Function,
  children?: Node,
  transitionMs?: number,
  transitionType?: string,
  closeOnClickOutside?: boolean,
  dontCloseOnEscape?: boolean,
  theme?: string,
  useDim?: boolean,
  includeWindowPadding?: boolean
};

type PopupStateTypes = {
  CloseProvider: ComponentType<*>,
  CloseConsumer: ComponentType<*>,
};

const updateBodyScrollClass = (isOpen: boolean) => {
  const body = document.getElementsByTagName('body')[0];

  if (isOpen) {
    body.classList.add(styles.hideScroll);
  } else {
    body.classList.remove(styles.hideScroll);
  }
};

class Popup extends React.Component<PopupPropTypes, PopupStateTypes> {
  constructor(props: PopupPropTypes) {
    super(props);

    updateBodyScrollClass(props.isOpen || false);
    if (props.isOpen) {
      this._handleEscape = this._handleEscapeUtility.bind(this);
      document.addEventListener(
        'keydown',
        this._handleEscape,
      );
    }
  }

  componentWillReceiveProps(nextProps: PopupPropTypes) {
    const { isOpen } = this.props;
    if (isOpen && !nextProps.isOpen) {
      updateBodyScrollClass(false);
      document.removeEventListener(
        'keydown',
        this._handleEscape,
      );
    } else if (!isOpen && nextProps.isOpen) {
      updateBodyScrollClass(true);
      this._handleEscape = this._handleEscapeUtility.bind(this);
      document.addEventListener(
        'keydown',
        this._handleEscape,
      );
    }
  }

  _handleEscape: KeyboardEvent => mixed;

  _handleEscapeUtility(event: KeyboardEvent) {
    const { dontCloseOnEscape = false, onClose, onAfterClose } = this.props;
    if (!dontCloseOnEscape && typeof onClose !== 'undefined') {
      switch (event.keyCode) {
        case keycode('escape'): {
          event.preventDefault();
          onClose();
          if(onAfterClose !== undefined) {
            onAfterClose();
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  render() {
    const {
      isOpen = false,
      onClose,
      onAfterClose,
      children,
      transitionMs = 300,
      transitionType = 'default',
      closeOnClickOutside = true,
      theme = 'default',
      useDim = true,
      includeWindowPadding = false,
    } = this.props;

    let onCloseAction = onClose;
    if(onAfterClose !== undefined) {
      onCloseAction = () => {
        onClose();
        onAfterClose();
      }
    }

    return (
      <Fragment>
        <Dim
          isShowing={isOpen}
          onClick={closeOnClickOutside ? onCloseAction : undefined}
          theme={useDim ? theme : 'transparent'}
          transitionMs={transitionMs}
        />
        <CSSTransition
          in={isOpen}
          timeout={transitionMs}
          classNames={styles}
          unmountOnExit
        >
          <div
            className={
              `
                ${styles.windowBoundaries}
                ${styles[`${transitionType}Transition`]}
              `
            }
            style={{
              padding: includeWindowPadding ? '2rem' : 0,
            }}
          >
            <div
              className={styles.container}
              style={{
                transition: `${transitionMs}ms`,
              }}
              onClick={e => e.stopPropagation()}
              onKeyPress={e => e.stopPropagation()}
              role="presentation"
            >
              <ClosePopupProvider closePopup={onCloseAction}>
                { children }
              </ClosePopupProvider>
            </div>
          </div>
        </CSSTransition>
      </Fragment>
    );
  }
}

export default connect(
  undefined,
  (dispatch, { id }) => ({
    onClose() {
      dispatch(closeableActions.close(id));
    },
  }),
)(Popup);
