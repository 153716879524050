// @flow
import React from 'react';

import Button from '../Button'; 
import Select from '../Select';
import ButtonGroup from '../ButtonGroup';
import DatePicker from '../DatePicker';
import MultipleSelect from '../MultipleSelect';

import * as types from '../FobiRenderer/types';


type MultipleFiltersPropTypes = {
  filters: Array<Object>,
};

type FilterComponentPropTypes = {
  type: string,
  element: Object,
  onChange: Function,
};


const Field = ({
  type,
  element,
  onChange,
  value,
}: FilterComponentPropTypes) => {
  switch (type) {
    case types.SELECT_ELEMENT: {
      let values = element.values.map(el => ({
        value: el.id,
        label: el.name,
      }));

      return (
        <Select
          value={value}
          options={values}
          size="small"
          onChange={(el) => onChange(element.type, el)}
        />
      )
    }
    case types.DATE_ELEMENT: {
      return (
        <DatePicker
          value={value}
          onChange={(date) => onChange(element.type, date)}
          size="small"
          hideClearIcon
          clearMargins
        />
      )
    }
    case types.MULTIPLE_SELECT: {
      let values = element.values.map(el => ({
        value: el.id,
        label: el.name,
      }));
      return (
        <MultipleSelect
          options={values}
          value={value}
          onChange={vals => onChange(element.type, vals)}
        />
      )
    }
    default: {
      const values = element.values.map(el => ({
        value: el.id,
        label: el.name,
      }));

      return (
        <Select
          value={value}
          options={values}
          size="small"
          placeholder={element.name}
          onChange={(el) => onChange(element.type, el)}
        />
      )
    }
  }
};

export default Field;
