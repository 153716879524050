// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdExitToApp, MdSignalWifiOff, MdSignalWifi4Bar } from 'react-icons/lib/md';
import { actions as jwtActions } from 'nozzmo-redux-jwt';

import PopMenu from '../../PopMenu';
import NavbarButton from '../NavbarButton';
import NavbarUser from '../NavbarUser';
import { SELECT_ORGANIZATIONS_POPUP_ID } from '../../OfflineModeOrganizationsPopup';

import styles from './navbarSettings.css';
import * as selectors from '../../../reducers';
import * as connectionActions from '../../../actions/connection';
import * as closeableActions from '../../../actions/closeable';

type NavbarSettingsPropTypes = {
  onLogout: Function,
  offlineMode: boolean,
  onToggleOfflineMode: Function,
};

const NavbarSettings = ({ 
  onLogout, 
  offlineMode, 
  onToggleOfflineMode
}: NavbarSettingsPropTypes) => {
  return (
    <PopMenu
      id="navbarSettings"
      vertical="below"
      horizontal="right"
      tipPosition="right"
      isRound
      options={[
        {
          Icon: MdExitToApp,
          title: 'Logout',
          onClick: onLogout,
        },
        {
          Icon: offlineMode ? MdSignalWifiOff : MdSignalWifi4Bar,
          title: `${offlineMode ? 'Desactivar' : 'Activar'} modo "Desconectado"`,
          onClick: onToggleOfflineMode,
          tint: offlineMode ? 'active' : '',
        },
      ]}
    >
      <div className={styles.userSettings}>
        <NavbarUser />
        <NavbarButton className={styles.indicator} isOpaque hasSubmenu />
      </div>
    </PopMenu>
  )
};


export default connect(
  state => ({ 
    _persist: selectors.getPersist(state),
    offlineMode: selectors.isOfflineMode(state),
    study: selectors.getSelectedStudy(state),
  }),
  dispatch => ({
    onLogout(_persist) {
      dispatch({
        ...jwtActions.logout(),
        state: { _persist },
      });
    },
    onToggleOfflineMode(active, study) {
      if (!study) {
        dispatch(closeableActions.open('offlineModeWarningPopup'));
        return;
      }

      if (active) {
        dispatch(connectionActions.setOfflineModeOff());
      } else {
        dispatch(closeableActions.open(SELECT_ORGANIZATIONS_POPUP_ID));
      }
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
    onLogout: () => {
      dispatchProps.onLogout(stateProps._persist);
    },
    onToggleOfflineMode: () => {
      dispatchProps.onToggleOfflineMode(stateProps.offlineMode, stateProps.study)
    }
  }),
)(NavbarSettings);
