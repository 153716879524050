// @flow

export type STATUS_CHANGED_TYPE = {
  type: 'Offline/STATUS_CHANGED'
};
export const STATUS_CHANGED = 'Offline/STATUS_CHANGED';

export type CONNECTION_STATUS_CLEARED_TYPE = {
  type: 'CONNECTION_STATUS_CLEARED'
};
export const CONNECTION_STATUS_CLEARED = 'CONNECTION_STATUS_CLEARED';


export type PREVENTIVE_OFFLINE_MODE_ON_TYPE = {
  type: 'PREVENTIVE_OFFLINE_MODE_ON'
};
export const PREVENTIVE_OFFLINE_MODE_ON = 'PREVENTIVE_OFFLINE_MODE_ON';

export type PREVENTIVE_OFFLINE_MODE_OFF_TYPE = {
  type: 'PREVENTIVE_OFFLINE_MODE_OFF'
};
export const PREVENTIVE_OFFLINE_MODE_OFF = 'PREVENTIVE_OFFLINE_MODE_OFF';

export type CONNECTION_STATUS_ACTION_TYPE = 
  | CONNECTION_STATUS_CLEARED_TYPE
  | PREVENTIVE_OFFLINE_MODE_ON_TYPE
  | PREVENTIVE_OFFLINE_MODE_OFF_TYPE
  | STATUS_CHANGED_TYPE;
