// @flow
import React from 'react';
import {
  MdAddCircle,
} from 'react-icons/lib/md';

import { FullLoader } from '../../Loader';
import FullPlaceholder from '../../ListPlaceholder';
import styles from './fileList.css';

import FileItem from './fileItem';
import Button from '../../Button';


type StudiesListPropTypes = {
  files?: Array<any>,
  isFetching: boolean,
  onDelete: Function,
  error: ERROR_TYPE,
  hasMore: boolean,
  fetchFiles: Function,
};


const StudiesList = ({
  files = [],
  isFetching = false,
  onDelete,
  error,
}: StudiesListPropTypes) => {
  let toRender = files.map(file => (
    <FileItem
      key={file.UUID}
      fileName={file.name}
      containerStyle={styles.fileContainer}
      onDownload={() => {
        window.open(file.url);
      }}
      onDelete={() => onDelete(file.UUID)}
    />
    )
  );

  toRender = [
    <UploadButton />,
    ...toRender
  ];

  return (
    <div className={styles.listContainer}>
      {
        files.length > 0 && (
          <div className={styles.fileListContainer}>
            {
              toRender
            }
          </div>
        )
      }
      {
        isFetching && (
          <FullLoader
            tint="mediumGray"
            title="Cargando archivos"
          />
        )
      }
      {
        files.length === 0 
        && !isFetching 
        && (<FullPlaceholder customMessage=", has click aqui o arrastra archivos a esta área." />)
      }
    </div>
  )
};

const UploadButton = () => (
  <div
    className={styles.uploadButton}
  >
    <Button
      isRound
      tint="primary"
      size="large"
      isTransparent
      Icon={MdAddCircle}
    />
  </div>
)

export default StudiesList;
