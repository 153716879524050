import React from 'react';
import {
  MdDelete,
  MdFileDownload
} from 'react-icons/lib/md';
import styles from './fileList.css';
import Button from '../../Button';

type FileItemPropType = {
  fileName: string,
  onClick: Function,
  onDelete: Function,
  onDownload: Function,
  containerStyle: any,
}

const FileItem = ({
  fileName,
  onClick,
  onDelete,
  onDownload,
  containerStyle,
}: FileItemPropType) => (
  <div 
    className={`
      ${containerStyle}
    `}
  >
    <div className={styles.filePreview}>
      <p className={styles.fileName}>{ fileName }</p>
    </div>
    <div className={styles.bottomItems}>
      <Button
        isRound
        tint="primary"
        size="small"
        Icon={MdFileDownload}
        onClick={(e) => {
          e.stopPropagation();
          onDownload();
        }}
      />
      <Button
        isRound
        tint="error"
        size="small"
        Icon={MdDelete}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    </div>
  </div>
);

export default FileItem;