import React from 'react';
import { Grid } from 'react-virtualized';

import GenericAnswersChart from '../GenericAnswersChart';

// import 'react-virtualized/styles.css';
import useWindowSize from '../../hooks/useWindowSize';

// CSS Breakpoint(s)
const TABLET_BREAKPOINT = 768;

const StopLightVirtualList = ({ ignoreStoplightColors, spotlightResults }) => {
  const { height, width } = useWindowSize();

  const renderChart = ({ columnIndex, key, rowIndex, style }) => {
    return (spotlightResults[rowIndex][columnIndex]
      ? (
        <div
          key={key}
          style={{
            ...style,
            padding: '2rem',
            boxSizing: 'border-box',
          }}
        >
          <GenericAnswersChart
            question={spotlightResults[rowIndex][columnIndex].name}
            answers={spotlightResults[rowIndex][columnIndex].results}
            colors={spotlightResults[rowIndex][columnIndex].colors}
            ignoreStoplightColors={ignoreStoplightColors}
          />
        </div>
      ) : null
    );
  }

  return (
    <Grid
      height={height - 300}
      rowCount={spotlightResults.length}
      columnCount={ width > TABLET_BREAKPOINT ? 2 : 1}
      columnWidth={width > TABLET_BREAKPOINT ? ((width - 320) * .49) : ((width - 30) * .96) }
      rowHeight={width > TABLET_BREAKPOINT ? (height - 320) : (height * 0.5)}
      width={width > TABLET_BREAKPOINT ? width - 300 : ((width - 30) * .96)}
      cellRenderer={renderChart}
    />
  );
}

export default StopLightVirtualList;