// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Waypoint from 'react-waypoint';

import SubmissionsTable from '../SubmissionsTable';
import FullPlaceholder from '../ListPlaceholder';

import styles from './studySubmissionsQueue.css'
import * as selectors from '../../reducers';
import * as actions from '../../actions/instrumentSubmissions';


type StudySubmissionsQueuePropTypes = {
  submissions: Array<Object>,
  fetchInstrumentSubmissions: Function,
};


const StudySubmissionsQueue = ({
  submissions,
  fetchInstrumentSubmissions,
}: StudySubmissionsQueuePropTypes) => (
  <div className={styles.submissionsList}>
    <span className={styles.description}>
      {"Ingresos en cola esperando conexión a internet para guardarse:"}
    </span>
    {
      submissions.length > 0 && (
        <Fragment>
          <SubmissionsTable
            data={submissions}
            showActions={false}
            isOfflineQueue
          />
          {/* <Waypoint
            onEnter={() => fetchInstrumentSubmissions()}
            viewportBottom={10}
          /> */}
        </Fragment>
      )
    }
    {
      submissions.length === 0 && (
        <FullPlaceholder />
      )
    }
  </div>
);


export default connect(
  state => {
    const data = selectors.getOfflineStudySubmissions(state);
    const submissions = data.map((el = {}) => ({
      ...el,
      date: moment(el.creation_date).format('L'),
      time: moment(el.creation_date).format('LT'),
      user: el.user_name || '',
    }));

    return ({
      submissions,
    });
  },
  dispatch => ({
    fetchInstrumentSubmissions() {
      dispatch(actions.startFetchInstrumentSubmissions());
    },
  })
)(StudySubmissionsQueue);

