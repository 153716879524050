// @flow
import type { Element } from 'react';
import debounce from 'lodash/debounce';
import React from 'react';
import ReactAutocomplete from 'react-autocomplete';
import { MdInbox } from 'react-icons/lib/md';
import i18n from 'i18n-js';

import type { ID_TYPE } from '../../types/common';
import Input from '../Input';
import Loader from '../Loader';

import styles from './typeahead.css';
// import { NoResults } from '../CommonTexts';


export type OPTION_TYPE = {
  value: ID_TYPE,
  label: string,
  textIndex: string,
};

type TypeaheadPropTypes = {
  className?: string,
  items: Array<OPTION_TYPE>,
  renderItem?: (Object, boolean) => Element<any>,
  value: ?OPTION_TYPE,
  renderSelectedValue?: (Object, Function) => Element<any>,
  inputProps?: Object,
  onChange?: Function,
  onBlur?: Function,
  isLoading?: boolean,
  renderLoading?: () => Element<any>,
  renderEmpty?: () => Element<any>,
  onInputChange?: Function,
  inputDebounce?: number,
  isLocallyContained?: boolean,
  shouldItemRender?: (OPTION_TYPE, string) => boolean,
  clearAfterSelected?: boolean,
  renderSelectedItemValue?: (Object) => string,
};

type TypeaheadStateTypes = {
  inputValue: string,
  selectedItem: ?OPTION_TYPE,
  onInputChange: Function
};

const defaultRenderLoading = () => (
  <div className={styles.loadingElement}>
    <Loader tint="mediumGray" isInline />
  </div>
);

const defaultRenderEmpty = () => (
  <div className={styles.emptyResults}>
    <div className={styles.emptyIconContainer}>
      <MdInbox />
    </div>
    <div className={styles.emptyMessage}>
      { 'No hay resultados' }
    </div>
  </div>
);

const defaultItemRenderer = (item, highlighted) => (
  <div
    key={item.value}
    className={
      `
        ${styles.element}
        ${highlighted ? styles.selected : ''}
      `
    }
  >
    { item.label }
  </div>
);

class Typeahead extends React.Component<TypeaheadPropTypes, TypeaheadStateTypes> {
  constructor(props: TypeaheadPropTypes) {
    super(props);

    const {
      value,
      onInputChange = _ => _,
      inputDebounce = 0,
    } = this.props;
    this.state = {
      inputValue: '',
      selectedItem: value,
      onInputChange: debounce(onInputChange, inputDebounce),
    };
  }

  componentWillReceiveProps(nextProps: TypeaheadPropTypes) {
    const { onInputChange, inputDebounce } = this.props;
    if (onInputChange !== nextProps.onInputChange) {
      this.setState({
        onInputChange: debounce(nextProps.onInputChange, inputDebounce),
      });
    }
  }

  getInput() {
    return this._input.getInput();
  }

  getAutocomplete() {
    return this._autocomplete;
  }

  _getItemByValue(value: ID_TYPE) {
    const { items } = this.props;
    const filtered = items.filter(item => item.value === value);
    return filtered.length ? filtered[0] : null;
  }

  _input: Input;

  _autocomplete: ReactAutocomplete;

  render() {
    const {
      className = '',
      items,
      renderSelectedValue,
      inputProps = {},
      renderItem = defaultItemRenderer,
      onChange,
      isLoading = false,
      renderLoading = defaultRenderLoading,
      renderEmpty = defaultRenderEmpty,
      isLocallyContained = false,
      shouldItemRender = (item, iValue) => (
        item
        && typeof item.textIndex === 'string'
        && item.indexOf(iValue.toLowerCase()) > -1
      ),
      clearAfterSelected = false,
      renderSelectedItemValue = null,
    } = this.props;

    const {
      inputValue,
      onInputChange,
    } = this.state;

    const newItems = items.filter(item => item.object_id != null || item.result_type != null);

    return (
      <ReactAutocomplete
        items={newItems}
        autoHighlight={false}
        shouldItemRender={shouldItemRender}
        wrapperProps={{ className: `${styles.typeahead} ${className}` }}
        renderMenu={
          function renderMenu(itms = [], _value, style) {
            if (isLoading) {
              return (
                <div
                  className={
                    `
                      ${styles.menu}
                      ${isLocallyContained ? styles.locallyContained : ''}
                    `
                  }
                  style={style}
                >
                  {renderLoading()}
                  <div style={{ display: 'none' }}>
                    {itms}
                  </div>
                </div>
              );
            }

            if (itms.length === 0) {
              return (
                <div
                  className={
                    `
                      ${styles.menu}
                      ${isLocallyContained ? styles.locallyContained : ''}
                    `
                  }
                  style={style}
                >
                  {renderEmpty()}
                  <div style={{ display: 'none' }}>
                    {itms}
                  </div>
                </div>
              );
            }
            return (
              <div
                className={
                  `
                    ${styles.menu}
                    ${isLocallyContained ? styles.locallyContained : ''}
                  `
                }
                children={itms}
                style={style}
              >

              </div>
            );
          }
        }
        getItemValue={item => item.value}
        renderItem={renderItem}
        value={inputValue}
        onChange={e => this.setState({ inputValue: e.target.value })}
        onSelect={
          (cValue, selectedItem) => {
            const newValue = renderSelectedItemValue != null ? renderSelectedItemValue(selectedItem) : cValue;
            this.setState({
              inputValue: clearAfterSelected ? '' : newValue,
              selectedItem,
            });

            if (onChange) {
              onChange(selectedItem);
            }
          }
        }
        renderInput={
          (lProps) => {
            const { selectedItem } = this.state;
            if (selectedItem && renderSelectedValue != null) {
              return renderSelectedValue(
                selectedItem,
                () => {
                  this.setState(
                    {
                      inputValue: '',
                      selectedItem: null,
                    },
                    () => {
                      if (this._input != null) {
                        this._input.focus();
                        this._input.select();
                      }
                    },
                  );

                  if (onChange) {
                    onChange(null);
                  }
                },
              );
            }

            return (
              <Input
                {...inputProps}
                {...lProps}
                clearMargins
                inputRef={lProps.ref}
                onBlur={() => {
                  const { value, onBlur } = this.props;
                  lProps.onBlur();
                  if (onBlur) {
                    onBlur(value);
                  }
                }}
                ref={(node) => { this._input = node; }}
                onChange={
                  (e) => {
                    lProps.onChange(e);
                    if (this._input) {
                      const rInput = this._input.getInput();
                      if (rInput) {
                        onInputChange(rInput.value);
                      }
                    }
                  }
                }
              />
            );
          }
        }
        wrapperStyle={{}}
        ref={(node) => { this._autocomplete = node; }}
      />
    );
  }
}


export default Typeahead;
