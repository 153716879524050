// @flow
import React from 'react';

import styles from './exitProfileTable.css';


type ExitProfileResultsTablePropTypes = {
  results: {[string]: Array<number>},
  name: ?string,
};

const ExitProfileResultsTable = ({
  results,
}: ExitProfileResultsTablePropTypes) => (
  <div className={styles.container}>
    <table className={styles.table}>
      <tbody>
        {
          Array.isArray(results) && results.length && results.map((el, idx) => (
            <tr key={idx}>
              <th className={styles.name}>{el && el.label ? el.label : ''}</th>
              <th>{`${el && el.value} %`}</th>
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
)

export default ExitProfileResultsTable;
