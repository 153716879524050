// @flow
import type { Node } from 'react';
import React from 'react';
import { MdEmail } from 'react-icons/lib/md';

import styles from './user.css';


type UserPropTypes = {
  pictureURL?: string,
  firstName: string,
  lastName?: string,
  userRole?: string,
  size?: string,
  className?: string,
  theme?: string,
  href?: string,
  isInvited?: boolean,
  useAsTitle?: boolean,
};

const User = ({
  pictureURL,
  firstName = '',
  lastName = '',
  userRole,
  size = 'medium',
  className,
  theme,
  href,
  isInvited = false,
  useAsTitle = false,
}: UserPropTypes) => {
  const Container = href
    ? ({ children, ...props }: {children: Node}) => (
      <a {...props} href={href}>
        { children }
      </a>
    ) : props => <div {...props} />;
  return (
    <Container className={
      `
        ${styles.user}
        ${typeof className === 'string' ? className : ''}
        ${styles[size]}
        ${typeof userRole !== 'string' ? styles.noUserRole : ''}
        ${typeof styles[theme] !== 'undefined' ? styles[theme] : ''}
        ${isInvited ? styles.invited : ''}
        ${useAsTitle ? styles.title : ''}
      `
    }
    >
      {
        isInvited && (
          <div className={styles.pictureContainer}>
            <MdEmail />
          </div>
        )
      }
      {
        !isInvited && typeof pictureURL !== 'undefined' && pictureURL != null && (
          <div className={styles.pictureContainer}>
            <img src={pictureURL} alt="" />
          </div>
        )
      }
      {
        pictureURL === null && (
          <div className={styles.pictureContainer}>
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTez7mpG_Lfb01PnEMjHa4gYPOx7iNTab4KKVnVCdNSGpa1O2RJ" alt="" />
          </div>
        )
      }
      <div className={styles.userData}>
        <div className={styles.name}>
          { `${firstName} ${lastName}` }
        </div>
        {
        typeof userRole !== 'undefined'
          && (
            <div className={styles.userRole}>
              { userRole }
            </div>
          )
      }
      </div>
    </Container>
  );
};

export default User;
