// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';

import type { ERROR_TYPE } from '../../types/common';
import StudyResultsParticipantsTable from '../StudyResultsParticipantsTable';
import FullPlaceholder from '../ListPlaceholder';
import { FullLoader } from '../Loader';

import styles from './studyParticipantsList.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/participants';
import * as closeableActions from '../../actions/closeable';
import StudyParticipantGraphicsPopup, { 
  POPUP_PARTICIPANT_RESULTS_ID,
} from '../StudyParticipantGraphicsPopup';


type StudyParticipantsListPropTypes = {
  participants: Array<Object>,
  isFetching: boolean,
  error: ERROR_TYPE,
  hasMore: boolean,
  hasScorableInstrument: boolean,
  fetchInstrumentParticipants: Function,
  openParticipantGraphics: Function,
};


const StudyParticipantsList = ({
  participants,
  isFetching,
  hasMore,
  hasScorableInstrument,
  fetchInstrumentParticipants,
  openParticipantGraphics,
}: StudyParticipantsListPropTypes) => {

  return (
    <div className={styles.approve}>
      {
        participants.length > 0 && (
          <Fragment>
            <StudyResultsParticipantsTable
              data={participants}
              openPointsGraphics={(id) => {
                openParticipantGraphics(id);
              }}
              hasScorableInstrument={hasScorableInstrument}
            />
            <Waypoint
              onEnter={() => hasMore && fetchInstrumentParticipants()}
              viewportBottom={10}
            />
          </Fragment>
        )
      }
      {
        isFetching && (
          <FullLoader
            tint="mediumGray"
            title="Cargando ingresos"
          />
        )
      }
      {
        participants.length === 0 && !isFetching && (
          <FullPlaceholder />
        )
      }
      <StudyParticipantGraphicsPopup />
    </div>
  );
}


export default connect(
  (state, ownProps) => {
    const { studyId } = ownProps;
    const data = selectors.getStudyParticipants(state, studyId);
    const { id } = selectors.getSelectedStudy(state) || {};
    const currentStudy = selectors.getStudy(state, id) || {};

    const participants = data.map((el = {}) => ({
      ...el,
      // allow to modify data.
    }));

    return ({
      participants,
      isFetching: selectors.isFetchingStudyParticipants(state),
      hasMore: selectors.hasMoreStudyParticipants(state),
      hasScorableInstrument: currentStudy.instrument && currentStudy.instrument.is_scorable,
    });
  },
  dispatch => ({
    fetchInstrumentParticipants() {
      dispatch(actions.startFetchStudyParticipants());
    },
    openParticipantGraphics(id) {
      dispatch(actions.selectParticipant(id));
      dispatch(closeableActions.open(POPUP_PARTICIPANT_RESULTS_ID));
    }
  })
)(StudyParticipantsList);

