exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-SlideFilter-__sliderFilter___container {\n  display: flex;\n  flex: 1 1;\n  margin-bottom: 4rem;\n}\n\n.src-scripts-components-SlideFilter-__sliderFilter___mark {\n  /* position: relative; */\n  padding: 1.4rem 0 0 0;\n  font-size: 1.4rem;\n  font-weight: 600;\n  /* margin-left: 2rem; */\n  z-index: -2;\n}\n\n.src-scripts-components-SlideFilter-__sliderFilter___first {\n  /* margin-left: 3rem; */\n}\n.src-scripts-components-SlideFilter-__sliderFilter___last {\n  margin-left: -2rem !important;\n  /* margin-right: 2rem; */\n}\n\n.src-scripts-components-SlideFilter-__sliderFilter___vl {\n  /* position: absolute; */\n  border-left: 1.3px solid;\n  border-color: var(--grayColor);\n  height: 1.4rem;\n  width: 1rem;\n  /* top: -0.4rem; */\n  /* left: 0.4rem; */\n  margin: 0 auto;\n  margin-top: -1.4rem;\n}\n\n/* \n.rc-slider-mark-text {\n  position: absolute;\n  display: inline-block;\n  vertical-align: middle;\n  text-align: center;\n  cursor: pointer;\n  color: #999;\n  border: 1px solid green !important;\n  width: 21px;\n  white-space: nowrap;\n  overflow: hidden;\n  display: flex;\n  flex: 1;\n  text-overflow: ellipsis;\n}\n\n.rc-slider-mark {\n  position: absolute;\n  top: 18px;\n  left: 0;\n  width: 100%;\n  font-size: 12px;\n  display: flex;\n}\n.sliderRc {\n} */\n\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-SlideFilter-__sliderFilter___container",
	"mark": "src-scripts-components-SlideFilter-__sliderFilter___mark",
	"first": "src-scripts-components-SlideFilter-__sliderFilter___first",
	"last": "src-scripts-components-SlideFilter-__sliderFilter___last",
	"vl": "src-scripts-components-SlideFilter-__sliderFilter___vl"
};