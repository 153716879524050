// @flow
// import flattenDeep from 'lodash/flattenDeep';
import {
  takeEvery,
  put,
  select,
  call,
} from 'redux-saga/effects';
import { types as jwtTypes } from 'nozzmo-redux-jwt';
import { reset } from 'redux-form';
import { push } from 'react-router-redux';
// import { purgeStoredState } from 'redux-persist';
// import { normalize } from 'normalizr';
// import persistorConfiguration from '../store/persistorConfiguration';
import * as selectors from '../reducers';
import { Users } from '../api';
import * as loginActions from '../actions/login';


function* redirectAfterLogin(action) {
  // Clear form
  const token = yield select(selectors.getToken);
  const user = yield select(selectors.getUser);

  // Redirect
  yield put(reset('loginForm'));
  yield put(push('/studies'));

  try {
    var response = yield call(
      [Users.custom, 'profile'],
      {
        token,
        id: user.user_id,
      }
    );

    yield put(loginActions.populateCurrentUser(response));
  } catch (error) {
    console.log(error, "login error");
    // Handle error
  }

}

function* cleanAfterLogout() {
  // purgeStoredState(persistorConfiguration);
  // yield put(push('/'));
}

export function* watchRedirectAfterLogin(): Iterator<any> {
  yield takeEvery(jwtTypes.LOGIN_SUCCEED, redirectAfterLogin);
}

export function* watchLogout(): Iterator<any> {
  yield takeEvery(jwtTypes.LOGOUT, cleanAfterLogout);
}
