// @flow
import { combineReducers } from 'redux';

import { ID_TYPE, ERROR_TYPE } from '../types/common';
import { STUDY_RESULTS_TYPE } from '../types/studyResults';
import * as common from './common';
import * as types from '../types/studyResults';


export type StudyResultsState = {
  byId: {[ID_TYPE]: STUDY_RESULTS_TYPE},
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  filters: Object,
};

const byId = common.byId({
  fetched: [
    types.FETCH_STUDY_RESULTS_COMPLETED,
    types.INSTRUMENT_RESULTS_COMPLETED,
  ],
  cleared: [
    types.FETCH_STUDY_RESULTS_STARTED,
    types.INSTRUMENT_RESULTS_STARTED,
  ]
});

const order = common.order({
  replaced: [
    types.FETCH_STUDY_RESULTS_COMPLETED,
    types.INSTRUMENT_RESULTS_COMPLETED,
  ],
});

const isFetching = common.isFetching({
  started: [
    types.FETCH_STUDY_RESULTS_STARTED,
    types.INSTRUMENT_RESULTS_STARTED,
  ],
  succeed: [
    types.FETCH_STUDY_RESULTS_COMPLETED,
    types.INSTRUMENT_RESULTS_COMPLETED,
  ],
  failed: [
    types.FETCH_STUDY_RESULTS_FAILED,
    types.INSTRUMENT_RESULTS_FAILED,
  ],
});

const error = common.error({
  clear: [
    types.FETCH_STUDY_RESULTS_STARTED,
    types.FETCH_STUDY_RESULTS_COMPLETED,
    types.INSTRUMENT_RESULTS_STARTED,
    types.INSTRUMENT_RESULTS_COMPLETED,
  ],
  populate: [
    types.FETCH_STUDY_RESULTS_FAILED,
    types.INSTRUMENT_RESULTS_FAILED,
  ],
});

const filters = (
  state: Object = {},
  action: INDICATOR_RESULTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.UPDATE_STUDY_RESULTS_FILTERS: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }
    case types.CLEAR_STUDY_RESULTS_FILTERS: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const studyResults = combineReducers({
  byId,
  order,
  isFetching,
  error,
  filters
});

export default studyResults;

export const getStudyResult = (state: StudyResultsState, id: ID_TYPE): STUDY_RESULTS_TYPE => state.byId[id];
export const getStudyResults = (state: StudyResultsState): Array<STUDY_RESULTS_TYPE> => state.order.map(id => getStudyResult(state, id));
export const getStudyResultsError = (state: StudyResultsState): ERROR_TYPE => state.error;
export const isFetchingStudyResults = (state: StudyResultsState): boolean => state.isFetching;
export const getStudyResultsFilters = (state: StudyResultsState): Object => state.filters;
export const getStudyResultsFilter = (state: StudyResultsState, key: string): any => state.filters[key];
