export const INDICATOR_COLORS = [
  '#f89151',
  '#96b77e',
  '#4fd8d8',
  '#fad125',
  '#4fd8d8',
  '#f276a6',
];

export const COLORS_BASE = {
  // 'pink': '#e91e63',
  // 'purple': '#9c27b0',
  // 'deeppurple': '#673ab7',
  // 'indigo': '#3f51b5',
  'yellow': '#ffeb3b',
  'blue': '#2196f3',
  'lime': '#cddc39',
  'orange': '#ff9800',
  'red': '#f44336',
  'lightblue': '#03a9f4',
  'cyan': '#00bcd4',
  'teal': '#009688',
  'green': '#4caf50',
  'lightgreen': '#8bc34a',
  'amber': '#ffc107',
  'deeporange': '#ff5722',
  'brown': '#795548',
  'grey': '#9e9e9e',
  'bluegrey': '#607d8b'
};

export const COLORS_BASE_VALUES = Object.values(COLORS_BASE)
