// @flow
import type { ComponentType, Node } from 'react';
import React from 'react';

import styles from './tag.css';


export type TagPropTypes = {
  size?: string,
  className?: string,
  isRound?: boolean,
  isTransparent?: boolean,
  title?: string,
  pictureUrl?: string,
  tint?: string,
  Icon?: ComponentType<*>,
  iconPosition?: string,
  children?: Node,
  isLeft?: boolean,
  isMiddle?: boolean,
  isFullWidth?: boolean,
  isRight?: boolean,
  isOutlined?: boolean,
  elevation?: number,
  isLoading?: boolean,
  disabled?: boolean,
  isSquare?: boolean,
  isBottom?: boolean,
  iconAction?: Function,
  isTop?: boolean,
  clearMargins?: boolean,
  clearBottomMargin?: boolean,
  isInline?: boolean,
  // onClick?: Function
};

const Tag = ({
  size = 'medium',
  className = '',
  isRound = false,
  isTransparent = false,
  title,
  tint = 'main',
  Icon,
  iconPosition = 'right',
  isLeft = false,
  iconAction = () => {},
  isSquare = false,
  isMiddle = false,
  isRight = false,
  isOutlined = false,
  isBottom = false,
  pictureUrl = '',
  isTop = false,
  elevation = 1,
  isFullWidth = false,
  clearMargins = false,
  clearBottomMargin = false,
  isInline = false,
  disabled = false,
  // onClick = () => {},
  ...props
}: TagPropTypes) => (
  <div
    {...props}
    // onClick={disabled ? undefined : onClick}
    className={
      `
        ${styles.tag}
        ${styles[size]}
        ${styles[tint]}
        ${className}
        ${isRound ? styles.roundButton : ''}
        ${isOutlined ? styles.outline : ''}
        ${isFullWidth ? styles.fullwidth : ''}
        ${isTransparent ? styles.transparent : ''}
        ${isLeft ? styles.buttonLeft : ''}
        ${isBottom ? styles.buttonBottom : ''}
        ${isTop ? styles.buttonTop : ''}
        ${isSquare ? styles.squareBorderRadius : ''}
        ${isRight ? styles.buttonRight : ''}
        ${isMiddle ? styles.buttonMiddle : ''}
        ${styles[`elevation${elevation}`]}
        ${typeof title !== 'string' ? styles.noTextButton : ''}
        ${clearMargins ? styles.noMargins : ''}
        ${clearBottomMargin ? styles.noBottomMargins : ''}
        ${isInline ? styles.inline : ''}
        ${disabled ? styles.disabled : ''}
      `
    }
    // role="presentation"
  >
    <div className={
        `
          ${styles.content}
        `
      }
    >
      {
        pictureUrl !== ''
          ? (
            <div className={styles.pictureContainer}>
              <img className={styles.tagPicture} src={pictureUrl} alt="" />
            </div>
          ) : null
      }
      { title }
      {
        typeof Icon !== 'undefined'
          && (
            <Icon
              onClick={iconAction}
              className={
                `
                  ${styles[`${iconPosition}IconPosition`]}
                `
              }
            />
          )
        }
    </div>
  </div>
);

export default Tag;
