// @flow
import { schema } from 'normalizr';

// import { instrument } from './instruments';
import { user } from './users';
import { organization } from './organizations';
import { phase } from './phases'


export const study = new schema.Entity('studies', {
  allowed_users: [user],
  organizations: [organization],
});

export const arrayOfStudies = new schema.Array(study);
