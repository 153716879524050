// @flow
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import uuid from 'uuid';
import range from 'lodash/range';
import flatten from 'lodash/flatten';

import {
  FETCH_STUDY_RESULTS_STARTED,
  INSTRUMENT_RESULTS_STARTED,
} from '../types/studyResults';
import { FETCH_STUDY_PARTICIPANT_RESULTS_STARTED } from '../types/participants';
import { Studies, Instruments } from '../api';
import { arrayOfStudyResults, studyResult } from '../api/schemas/studyResults';
import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import * as selectors from '../reducers';
import * as actions from '../actions/studyResults';
import * as studyActions from '../actions/studies';
import * as participantActions from '../actions/participants';
import * as studyResultsActions from '../actions/studyResults';


function* fetchStudyResults(action) {
  try {
    const { id, filters } = action.payload;
    const token = yield select(selectors.getToken);
    const { phases = [] } = yield select(selectors.getStudy, id);
    const finalFilters = filters ? filters : yield select(selectors.getStudyResultsFilters, id);

    delete finalFilters['phase'];

    const summaryResults = {};

    for (const phase of phases) {
      let query = { ...finalFilters, phase: phase.id };

      const { responseResults = [], timeout } = yield race({
        responseResults: call(
          [Studies.custom, 'results'],
          {
            token,
            id,
            filters: {filters: JSON.stringify(query)},
          },
        ),
        timeout: call(delay, REQUEST_TIMEOUT),
      });

      if (timeout) {
        throwTimeout('fetchStudyResults');
      }

      responseResults.map(result => {
        let charResults = {};
        charResults = (summaryResults[result.id] || {}).charResults || {};
        Object.keys(result.results).map(res => {
          const old = charResults[res] || [];
          charResults[res] = [ ...old, result.results[res] ];
        });

        let pointsResults = {};
        pointsResults = (summaryResults[result.id] || {}).pointsResults || {};
        Object.keys(result.points || {}).forEach(field => {
          const old = pointsResults[field] || [];
          pointsResults[field] = [ ...old, result.points && result.points[field] ? result.points[field] : 0 ];
        });

        const nResult = { ...summaryResults[result.id] };

        summaryResults[result.id] = {
          ...result,
          charResults,
          pointsResults,
          resultsByPhases: result.type === 'numeric_open' ? {
            ...nResult.resultsByPhases,
            [phase.id]: { ...result.results }
          } : null,
        };
      });
    }

    const order = [];
    Object.keys(summaryResults).map(key => {
      // set order
      order.push(key);
      const oResult = summaryResults[key];

      if (oResult.type === 'numeric_open') {
        // For MAX and AVG cases, don't make extra range calculations...
        if ((oResult.display_type !== undefined && oResult.display_type === 'none')) {
          const oResultsByPhases = oResult.resultsByPhases;
          let listKeys = Object.keys(oResultsByPhases).map(key =>
            Object.keys(oResultsByPhases[key]).map(sKey => parseInt(sKey))
          );
  
          listKeys = flatten(listKeys);
  
          const node = (Math.max(...listKeys)) / oResult.num_columns;
          const max = (oResult.num_columns + 1) * node;
          const listNodes = range(node, max, node);
  
          let newCharValues = {};
          
          listNodes.map(n => {
            const castN = n.toString().length > 3 ? n.toFixed(2) : n;
            newCharValues[castN] = phases.map(phase => {
              const nVals = Object.keys(oResultsByPhases[phase.id]).filter(key => key >= (n - node) && key <= n);
              let sum = 0;
              nVals.map(x => { sum += oResultsByPhases[phase.id][x] });
              return sum;
            })
          });
          summaryResults[key].charResults = { ...newCharValues };
        }
      }
    });

    // Re using code haha, no problem because filters were passed different on each call
    if (action.type === FETCH_STUDY_PARTICIPANT_RESULTS_STARTED) {
      yield put(participantActions.completeFetchStudyParticipantResults(summaryResults, order));
    } else {
      yield put(actions.completeFetchStudyResults(summaryResults, order));
    }
    yield put(studyActions.updateStudyResults(id, order));
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;

    // Re using code again lol
    if (action.type === FETCH_STUDY_PARTICIPANT_RESULTS_STARTED) {
      yield put(participantActions.failFetchStudyParticipantResults({
        statusCode,
        message,
        data: isPlain ? 'Error en el servidor' : data,
        retryAction: action,
      }));
    } else {
      yield put(actions.failFetchStudyResults({
        statusCode,
        message,
        data: isPlain ? 'Error en el servidor' : data,
        retryAction: action,
      }));
    }
    
  }
}

function* fetchInstrumentResults(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;
  
    let filters = yield select(selectors.getInstrumentsFilters);

    const { studyResponse, timeout } = yield race({
      studyResponse: call(
        [Studies, 'detail'],
        {
          token,
          id: filters.study,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchStudyInstrumentResults');
    }

    const { phases = [] } = studyResponse;
    // delete filters['phase'];
    const summaryResults = {};

    for (const phase of phases) {
      let query = { ...filters, phase: phase.id };

      const { responseResults = [], timeout } = yield race({
        responseResults: call(
          [Instruments.custom, 'results'],
          {
            token,
            id,
            filters: {filters: JSON.stringify(query)},
          },
        ),
        timeout: call(delay, REQUEST_TIMEOUT),
      });

      if (timeout) {
        throwTimeout('fetchInstrumentResults');
      }

      responseResults.map(result => {
        let charResults = {};
        charResults = (summaryResults[result.ordinal] || {}).charResults || {};
        Object.keys(result.results).map(res => {
          const old = charResults[res] || [];
          charResults[res] = [ ...old, result.results[res] ];
        });

        const nResult = { ...summaryResults[result.ordinal] };

        summaryResults[result.ordinal] = {
          ...result,
          charResults,
          resultsByPhases: result.type === 'numeric_open' ? {
            ...nResult.resultsByPhases,
            [phase.id]: { ...result.results }
          } : null
        };
      });
    }

    const order = [];
    Object.keys(summaryResults).map(key => {
      // set order
      order.push(key);
      const oResult = summaryResults[key];

      if (oResult.type === 'numeric_open') {
        const oResultsByPhases = oResult.resultsByPhases;
        let listKeys = Object.keys(oResultsByPhases).map(key =>
          Object.keys(oResultsByPhases[key]).map(sKey => parseInt(sKey))
        );

        listKeys = flatten(listKeys);

        const node = (Math.max(...listKeys)) / oResult.num_columns;
        const max = (oResult.num_columns + 1) * node;
        const listNodes = range(node, max, node);

        let newCharValues = {};
        
        listNodes.map(n => {
          const castN = n.toString().length > 3 ? n.toFixed(2) : n;
          newCharValues[castN] = phases.map(phase => {
            const nVals = Object.keys(oResultsByPhases[phase.id]).filter(key => key >= (n - node) && key <= n);
            let sum = 0;
            nVals.map(x => { sum += oResultsByPhases[phase.id][x] });
            return sum;
          })
        });
        summaryResults[key].charResults = { ...newCharValues };
      }
    });

    yield put(studyResultsActions.completeFetchInstrumentsResults(summaryResults, order));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(studyResultsActions.failFetchInstrumentResults({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchStudyResults(): Iterator<any> {
  yield takeLatest(
    FETCH_STUDY_RESULTS_STARTED,
    fetchStudyResults,
  );
}

export function* watchFetchStudyParticipantResults(): Iterator<any> {
  yield takeLatest(
    FETCH_STUDY_PARTICIPANT_RESULTS_STARTED,
    fetchStudyResults,
  );
}

export function* watchFetchInstrumentResults(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_RESULTS_STARTED,
    fetchInstrumentResults,
  );
}
