// @flow
import React, { Node } from 'react';
import styles from './viewContent.css';

type ViewContetntPropTypes = {
  children?: Node,
  overflow?: string,
};

const ViewContent = ({
  children,
  overflow = 'none',
}: ViewContetntPropTypes) => (
  <div className={
    `
      ${styles.container}
      ${overflow === 'scroll' ? styles.scroll : ''}
    `
  }>
    {children}
  </div>
);

export default ViewContent;
