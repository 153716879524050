// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';
import { submit } from 'redux-form';

import Button from '../Button';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import InstrumentSubmissionForm from '../InstrumentSubmissionForm';

import styles from './addStudySubmissionPopup.css';
import * as actions from '../../actions/instruments';
import * as selectors from '../../reducers';
import * as locationsActions from '../../actions/locations';
import * as sublocationsActions from '../../actions/sublocations';
import * as organizationsActions from '../../actions/organizations';
import * as instrumentSubmissionActions from '../../actions/instrumentSubmissions';
import * as userActions from '../../actions/users';


type AddStudySubmissionContentPropTypes = {
  instrument: number,
  isSubmitting: boolean,
  onLoad: Function,
  clearForm: Function,
  submitForm: Function,
};


const AddStudySubmissionContent = ({
  instrument,
  isSubmitting,
  onLoad,
  clearForm,
  submitForm,
}: AddStudySubmissionContentPropTypes) => {
  useEffect(() => {
    onLoad();
    return () => clearForm();
  }, []);

  return(
    <PopupContainer isFullscreen>
      <div className={styles.closeButton}>
        <Button
          tint="primary"
          className={styles.approveButton}
          isLoading={isSubmitting}
          title="Guardar"
          isRound
          onClick={() => submitForm()}
        />
        <PopupCloseButton
          Icon={MdClose}
          tint="mediumGray"
          isRound={true}
        />  
      </div>
      <div className={styles.content}>
        <InstrumentSubmissionForm id={instrument} globalFormName="addInstrumentSubmission" />
      </div>
    </PopupContainer>
  );
};

export default connect(
  (state, ownProps) => ({
    instrument: ownProps.id,
    isSubmitting: selectors.isSubmittingInstrument(state)
    || selectors.isAddingKid(state)
    || selectors.isAddingUser(state),
    isOnline: selectors.hasInternetConnection(state),
  }),
  (dispatch, ownProps) => ({
    onLoad(isOnline) {
      if (!isOnline) return;
      const id = ownProps.id;
      dispatch(actions.startFetchInstrumentQuestions(id));

      // Fetch organzations data
      dispatch(locationsActions.startFetchLocations());
      dispatch(sublocationsActions.startFetchSublocations());
      dispatch(organizationsActions.startFetchOrganizations());

    },
    clearForm() {
      dispatch(instrumentSubmissionActions.clearErrorRequiredValues());
      dispatch(userActions.clearUserError());
    },
    submitForm() {
      dispatch(submit('addInstrumentSubmission'));
    }
  }),
  (stateProps, { onLoad, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onLoad() {
      onLoad(stateProps.isOnline);
    }
  })
)(AddStudySubmissionContent);
