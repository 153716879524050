// @flow
import type { ID_TYPE } from '../types/common';

import {
  RESULTS_TYPE,
  FETCH_RESULTS_STARTED_TYPE,
  FETCH_RESULTS_COMPLETED_TYPE,
} from '../types/results';
import * as types from '../types/results';
import { error } from './common';


export const startFetchStudyResults = (
  typeOfResults: string,
): FETCH_RESULTS_STARTED_TYPE => ({
  type: types.FETCH_RESULTS_STARTED,
  payload: {
    typeOfResults
  },
});

export const completeFetchStudyResults = (
  results: {[ID_TYPE]: RESULTS_TYPE},
  id: ID_TYPE,
): FETCH_RESULTS_COMPLETED_TYPE => ({
  type: types.FETCH_RESULTS_COMPLETED,
  payload: {
    results,
    id,
  }
});

export const failFetchStudyResults = error(types.FETCH_RESULTS_FAILED);
