// @flow
import type { ID_TYPE } from '../types/common';

import type {
  SUBLOCATION_TYPE,
  SUBLOCATIONS_FETCH_STARTED_TYPE,
  SUBLOCATIONS_FETCH_COMPLETED_TYPE,
  SUBLOCATION_SELECTED_TYPE
} from '../types/sublocations';

import * as types from '../types/sublocations';
import { error } from './common';


export const startFetchSublocations = (): SUBLOCATIONS_FETCH_STARTED_TYPE => ({
  type: types.SUBLOCATIONS_FETCH_STARTED
});

export const completeFetchSublocations = (
  entities: {[ID_TYPE]: SUBLOCATION_TYPE},
  order: Array<ID_TYPE>,
): SUBLOCATIONS_FETCH_COMPLETED_TYPE => ({
  type: types.SUBLOCATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchSublocations = error(types.SUBLOCATIONS_FETCH_FAILED);

export const selectSublocation = (id: ID_TYPE): SUBLOCATION_SELECTED_TYPE => ({
  type: types.SUBLOCATION_SELECTED,
  payload: id,
});
