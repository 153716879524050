// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type STUDY_PARTICIPANT_TYPE = {
  id: ID_TYPE,
  type: string,
  first_name: string,
  last_name: string,
  group: string,
  kid_full_name: string,
  cui: ID_TYPE,
  age: string,
  years_living_in_community: string,
  civil_state: string,
  ethnicity: string,
  grades: string,
  alive_kids: number,
  smallest_kid_age: string,
  is_husband_present: boolean,
  is_husband_acknowledged: boolean,
  woman_state: string,
  can_read: boolean,
  organization: ID_TYPE,
  studies: ID_TYPE[],
  files: any[],
};

export type STUDY_PARTICIPANT_SELECTED_TYPE = {
  type: 'STUDY_PARTICIPANT_SELECTED',
  payload: ID_TYPE,
};
export const STUDY_PARTICIPANT_SELECTED = 'STUDY_PARTICIPANT_SELECTED';

export type STUDY_PARTICIPANT_UNSELECTED_TYPE = {
  type: 'STUDY_PARTICIPANT_UNSELECTED',
  payload: ID_TYPE,
};
export const STUDY_PARTICIPANT_UNSELECTED = 'STUDY_PARTICIPANT_UNSELECTED';


export type STUDY_PARTICIPANTS_FETCH_STARTED_TYPE = {
  type: 'STUDY_PARTICIPANTS_FETCH_STARTED',
  payload: {
    page: number,
  },
};
export const STUDY_PARTICIPANTS_FETCH_STARTED = 'STUDY_PARTICIPANTS_FETCH_STARTED';

export type STUDY_PARTICIPANTS_FETCH_COMPLETED_TYPE = {
  type: 'STUDY_PARTICIPANTS_FETCH_COMPLETED',
  payload: {
    entities: {[number]: STUDY_PARTICIPANTS_TYPE},
    order: Array<number>,
    nextPage: ?number,
  },
};
export const STUDY_PARTICIPANTS_FETCH_COMPLETED = 'STUDY_PARTICIPANTS_FETCH_COMPLETED';

export type STUDY_PARTICIPANTS_FETCH_FAILED_TYPE = {
  type: 'STUDY_PARTICIPANTS_FETCH_FAILED',
  payload: {},
};
export const STUDY_PARTICIPANTS_FETCH_FAILED = 'STUDY_PARTICIPANTS_FETCH_FAILED';



export type STUDY_PARTICIPANTS_ADD_STARTED_TYPE = {
  type: 'STUDY_PARTICIPANTS_ADD_STARTED',
  payload: {
    values: Object
  },
};
export const STUDY_PARTICIPANTS_ADD_STARTED = 'STUDY_PARTICIPANTS_ADD_STARTED';

export type STUDY_PARTICIPANTS_ADD_COMPLETED_TYPE = {
  type: 'STUDY_PARTICIPANTS_ADD_COMPLETED',
  payload: {},
};
export const STUDY_PARTICIPANTS_ADD_COMPLETED = 'STUDY_PARTICIPANTS_ADD_COMPLETED';



export type STUDY_PARTICIPANTS_ADD_FAILED_TYPE = {
  type: 'STUDY_PARTICIPANTS_ADD_FAILED',
  payload: {},
};
export const STUDY_PARTICIPANTS_ADD_FAILED = 'STUDY_PARTICIPANTS_ADD_FAILED';

export type STUDY_PARTICIPANTS_UPDATE_STARTED_TYPE = {
  type: 'STUDY_PARTICIPANTS_UPDATE_STARTED',
  payload: {},
};
export const STUDY_PARTICIPANTS_UPDATE_STARTED = 'STUDY_PARTICIPANTS_UPDATE_STARTED';

export type STUDY_PARTICIPANTS_UPDATE_COMPLETED_TYPE = {
  type: 'STUDY_PARTICIPANTS_UPDATE_COMPLETED',
  payload: {},
};
export const STUDY_PARTICIPANTS_UPDATE_COMPLETED = 'STUDY_PARTICIPANTS_UPDATE_COMPLETED';

export type STUDY_PARTICIPANTS_UPDATE_FAILED_TYPE = {
  type: 'STUDY_PARTICIPANTS_UPDATE_FAILED',
  payload: {},
};
export const STUDY_PARTICIPANTS_UPDATE_FAILED = 'STUDY_PARTICIPANTS_UPDATE_FAILED';

// Delete participant types
export type STUDY_PARTICIPANTS_REMOVED_TYPE = {
  type: 'STUDY_PARTICIPANT_REMOVED',
  payload: ID_TYPE,
};
export const STUDY_PARTICIPANTS_REMOVED = 'STUDY_PARTICIPANTS_REMOVED';

export type STUDY_PARTICIPANTS_REMOVE_FAILED_TYPE = {
  type: 'STUDY_PARTICIPANT_REMOVE_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_PARTICIPANTS_REMOVE_FAILED = 'STUDY_PARTICIPANTS_REMOVE_FAILED';

export type STUDY_PARTICIPANTS_FILTERS_UPDATED_TYPE = {
  type: 'STUDY_PARTICIPANTS_FILTERS_UPDATED',
  payload: {
    key: string,
    value: any,
  }
};
export const STUDY_PARTICIPANTS_FILTERS_UPDATED = 'STUDY_PARTICIPANTS_FILTERS_UPDATED';

export type STUDY_PARTICIPANT_FETCH_STARTED_TYPE = {
  type: 'STUDY_PARTICIPANT_FETCH_STARTED',
  payload: ID_TYPE,
};
export const STUDY_PARTICIPANT_FETCH_STARTED = 'STUDY_PARTICIPANT_FETCH_STARTED';

export type STUDY_PARTICIPANT_FETCH_COMPLETED_TYPE = {
  type: 'STUDY_PARTICIPANT_FETCH_COMPLETED',
  payload: PARTICIPANT_TYPE,
};
export const STUDY_PARTICIPANT_FETCH_COMPLETED = 'STUDY_PARTICIPANT_FETCH_COMPLETED';

export type STUDY_PARTICIPANT_FETCH_FAILED_TYPE = {
  type: 'STUDY_PARTICIPANT_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_PARTICIPANT_FETCH_FAILED = 'STUDY_PARTICIPANT_FETCH_FAILED';


// Study Results by Participant
export type STUDY_PARTICIPANT_RESULTS_TYPE = {
  id: ID_TYPE,
  label: String,
  result: String,
};

export type FETCH_STUDY_PARTICIPANT_RESULTS_STARTED_TYPE = {
  type: 'FETCH_STUDY_PARTICIPANT_RESULTS_STARTED',
  payload: {
    id: ID_TYPE,
  }
};
export const FETCH_STUDY_PARTICIPANT_RESULTS_STARTED = 'FETCH_STUDY_PARTICIPANT_RESULTS_STARTED';

export type FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED_TYPE = {
  type: 'FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: STUDY_PARTICIPANT_RESULTS_TYPE},
    result: Array<ID_TYPE>,
  }
};
export const FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED = 'FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED';

export type FETCH_STUDY_PARTICIPANT_RESULTS_FAILED_TYPE = {
  type: 'FETCH_STUDY_PARTICIPANT_RESULTS_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_STUDY_PARTICIPANT_RESULTS_FAILED = 'FETCH_STUDY_PARTICIPANT_RESULTS_FAILED';


// Offline Mode Support
export type OFFLINE_PARTICIPANTS_FETCH_STARTED_TYPE = {
  type: 'OFFLINE_PARTICIPANTS_FETCH_STARTED',
  payload: {
    page: number,
    organizations: Array<number>,
  },
};
export const OFFLINE_PARTICIPANTS_FETCH_STARTED = 'OFFLINE_PARTICIPANTS_FETCH_STARTED';

export type OFFLINE_PARTICIPANTS_FETCH_COMPLETED_TYPE = {
  type: 'OFFLINE_PARTICIPANTS_FETCH_COMPLETED',
  payload: {
    entities: {[number]: STUDY_PARTICIPANTS_TYPE},
    order: Array<number>,
    nextPage: ?number,
  },
};
export const OFFLINE_PARTICIPANTS_FETCH_COMPLETED = 'OFFLINE_PARTICIPANTS_FETCH_COMPLETED';

export type OFFLINE_PARTICIPANTS_FETCH_FAILED_TYPE = {
  type: 'OFFLINE_PARTICIPANTS_FETCH_FAILED',
  payload: {},
};
export const OFFLINE_PARTICIPANTS_FETCH_FAILED = 'OFFLINE_PARTICIPANTS_FETCH_FAILED';



export type STUDY_PARTICIPANTS_ACTION_TYPE =
  | STUDY_PARTICIPANT_SELECTED_TYPE
  | STUDY_PARTICIPANT_UNSELECTED_TYPE
  | STUDY_PARTICIPANTS_FETCH_STARTED_TYPE
  | STUDY_PARTICIPANTS_FETCH_COMPLETED_TYPE
  | STUDY_PARTICIPANTS_FETCH_FAILED_TYPE
  | STUDY_PARTICIPANTS_ADD_STARTED_TYPE
  | STUDY_PARTICIPANTS_ADD_COMPLETED_TYPE
  | STUDY_PARTICIPANTS_ADD_FAILED_TYPE
  | STUDY_PARTICIPANTS_UPDATE_STARTED_TYPE
  | STUDY_PARTICIPANTS_UPDATE_COMPLETED_TYPE
  | STUDY_PARTICIPANTS_UPDATE_FAILED_TYPE
  | STUDY_PARTICIPANTS_REMOVED_TYPE
  | STUDY_PARTICIPANTS_REMOVE_FAILED_TYPE
  | STUDY_PARTICIPANTS_FILTERS_UPDATED_TYPE
  | STUDY_PARTICIPANT_FETCH_STARTED_TYPE
  | STUDY_PARTICIPANT_FETCH_COMPLETED_TYPE
  | STUDY_PARTICIPANT_FETCH_FAILED_TYPE
  | FETCH_STUDY_PARTICIPANT_RESULTS_STARTED_TYPE
  | FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED_TYPE
  | FETCH_STUDY_PARTICIPANT_RESULTS_FAILED_TYPE
  | OFFLINE_PARTICIPANTS_FETCH_STARTED_TYPE
  | OFFLINE_PARTICIPANTS_FETCH_COMPLETED_TYPE
  | OFFLINE_PARTICIPANTS_FETCH_FAILED_TYPE;
