// @flow
import React from 'react';
import type { ComponentType } from 'react';

import type { TextPropTypes } from '../Cells/Text';
import TextCell from '../Cells/Text';


type TextPropsTypes = {
  accessor: string | Function,
  Header: string | ComponentType<*> | React$Node,
  editable?: boolean,
  onChange?: (Object, string | number) => mixed,
  inputType?: string,
  isHighlight?: boolean,
  padded?: boolean,
};

const Text = ({
  accessor,
  Header,
  editable = false,
  onChange,
  inputType = 'text',
  isHighlight = false,
  padded = false,
  ...props
}: TextPropsTypes) => ({
  Header,
  accessor,
  Cell: ({ value, ...cProps }: TextPropTypes) => (
    typeof value === 'object' ? (
      <TextCell
        {...value}
        editable={editable}
        onChange={onChange}
        inputType={inputType}
        isHighlight={isHighlight}
        padded={padded}
        {...cProps}
      />
    ) : (
      <TextCell
        value={value}
        editable={editable}
        onChange={onChange}
        inputType={inputType}
        isHighlight={isHighlight}
        padded={padded}
        {...cProps}
      />
    )
  ),
  ...props,
});


export default Text;
