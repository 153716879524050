// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type SUBLOCATION_TYPE = {
  id: ID_TYPE,
  name: String,
  loaction: ID_TYPE,
};

export type SUBLOCATIONS_FETCH_STARTED_TYPE = {
  type: 'SUBLOCATIONS_FETCH_STARTED',
};
export const SUBLOCATIONS_FETCH_STARTED = 'SUBLOCATIONS_FETCH_STARTED';

export type SUBLOCATIONS_FETCH_COMPLETED_TYPE = {
  type: 'SUBLOCATIONS_FETCH_COMPLETED',
  payload: {
    order: Array<number>,
    entities: {[number]: SUBLOCATION_TYPE}
  },
};
export const SUBLOCATIONS_FETCH_COMPLETED = 'SUBLOCATIONS_FETCH_COMPLETED';

export type SUBLOCATIONS_FETCH_FAILED_TYPE = {
  type: 'SUBLOCATIONS_FETCH_FAILED',
  payload: ERROR_TYPE
};
export const SUBLOCATIONS_FETCH_FAILED = 'SUBLOCATIONS_FETCH_FAILED';

export type SUBLOCATION_SELECTED_TYPE = {
  type: 'SUBLOCATION_SELECTED',
  payload: ID_TYPE
};
export const SUBLOCATION_SELECTED = 'SUBLOCATION_SELECTED';

export type SUBLOCATION_ACTION_TYPE =
  | SUBLOCATIONS_FETCH_STARTED_TYPE
  | SUBLOCATIONS_FETCH_COMPLETED_TYPE
  | SUBLOCATIONS_FETCH_FAILED_TYPE
  | SUBLOCATION_SELECTED_TYPE;
