//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  INSTRUMENT_TYPE,
  INSTRUMENTS_ACTION_TYPE,
} from '../types/instruments';
import * as common from './common';
import * as types from '../types/instruments';
import {
  FETCH_RESULTS_STARTED,
  FETCH_RESULTS_COMPLETED,
  FETCH_RESULTS_FAILED,
} from '../types/results';

export type InstrumentsState = {
  byId: { [ID_TYPE]: INSTRUMENT_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: ID_TYPE,
  counter: number,
  semaphoreResults: { [ID_TYPE]: Object },
  fetchingQuestions: Array<ID_TYPE>,
  filters: Object,
  isFetchingResults: boolean,
};

const byId = common.byId({
  updated: [types.INSTRUMENT_QUESTIONS_FETCH_COMPLETED],
  fetched: [types.INSTRUMENTS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.INSTRUMENTS_FETCH_COMPLETED],
  replaced: [],
});

const isFetching = common.isFetching({
  started: [types.INSTRUMENTS_FETCH_STARTED],
  succeed: [types.INSTRUMENTS_FETCH_COMPLETED],
  failed: [types.INSTRUMENTS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.INSTRUMENTS_FETCH_STARTED,
    types.INSTRUMENTS_FETCH_COMPLETED,
  ],
  populate: [
    types.INSTRUMENTS_FETCH_FAILED
  ],
});

const selected = common.mux({
  selected: [types.INSTRUMENT_SELECTED],
  allDeselected: [types.INSTRUMENT_UNSELECTED],
  default: -1,
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const semaphoreResults = common.keyExtractorById({
  extractionKey: 'results',
  set: [FETCH_RESULTS_COMPLETED],
});

const isFetchingResults = common.isFetching({
  started: [FETCH_RESULTS_STARTED],
  succeed: [FETCH_RESULTS_COMPLETED],
  failed: [FETCH_RESULTS_FAILED],
});

const fetchingQuestions = common.fetching({
  started: [types.INSTRUMENT_QUESTIONS_FETCH_STARTED],
  succeed: [types.INSTRUMENT_QUESTIONS_FETCH_COMPLETED],
  failed: [types.INSTRUMENT_QUESTIONS_FETCH_FAILED],
});

const filters = (
  state: Object = {},
  action: INSTRUMENTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.INSTRUMENTS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    };
    case types.INSTRUMENTS_FILTERS_CLEARED: {
      return {};
    };
    default: {
      return state;
    }
  }
};

const instruments = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
  semaphoreResults,
  counter,
  fetchingQuestions,
  filters,
  isFetchingResults
});


export default instruments;


// Selectors
export const getInstrument = (state: InstrumentsState, id: ID_TYPE): ?INSTRUMENT_TYPE => state.byId[id];
export const getInstruments = (state: InstrumentsState): Array<?INSTRUMENT_TYPE> => state.order.map(i => getInstrument(state, i));
export const isFetchingInstruments = (state: InstrumentsState): boolean => state.isFetching;
export const getInstrumentError = (state: InstrumentsState, id: ID_TYPE): ERROR_TYPE => state.errors[id];
export const getSelectedInstrument = (state: InstrumentsState): ?INSTRUMENT_TYPE => getInstrument(state, state.selected);
export const getInstrumentCounter = (state: InstrumentsState): number => state.counter;
export const areInstrumentQuestionsFetching = (state: InstrumentsState, id: ID_TYPE): boolean => state.fetchingQuestions.includes(id);
export const getInstrumentsFilters = (state: InstrumentsState): Object => state.filters;
export const getInstrumentsFilter = (state: InstrumentsState, key: string): any => state.filters[key];
export const getDefaultInstrument = (state: InstrumentsState): ?INSTRUMENT_TYPE => state.byId[state.order[0]];
export const getSemaphoreResultsByInstrumentId = (state: InstrumentsState, id: ID_TYPE): Object => state.semaphoreResults[id];
export const isFetchingInstrumentResults = (state: InstrumentsState): boolean => state.isFetchingResults;
