import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  STUDY_PARTICIPANTS_TYPE,
  STUDY_PARTICIPANTS_FETCH_STARTED_TYPE,
  STUDY_PARTICIPANTS_FETCH_COMPLETED_TYPE,
  STUDY_PARTICIPANTS_FETCH_FAILED_TYPE,
  STUDY_PARTICIPANTS_ADD_STARTED_TYPE,
  STUDY_PARTICIPANTS_ADD_COMPLETED_TYPE,
  STUDY_PARTICIPANTS_ADD_FAILED_TYPE,
  STUDY_PARTICIPANTS_UPDATE_STARTED_TYPE,
  STUDY_PARTICIPANTS_UPDATE_COMPLETED_TYPE,
  STUDY_PARTICIPANTS_UPDATE_FAILED_TYPE,
  STUDY_PARTICIPANTS_REMOVED_TYPE,
  STUDY_PARTICIPANTS_REMOVE_FAILED_TYPE,
  STUDY_PARTICIPANTS_FILTERS_UPDATED_TYPE,
  STUDY_PARTICIPANT_FETCH_STARTED_TYPE,
  STUDY_PARTICIPANT_FETCH_COMPLETED_TYPE,
  STUDY_PARTICIPANT_FETCH_FAILED_TYPE,
  OFFLINE_PARTICIPANTS_FETCH_STARTED_TYPE,
  OFFLINE_PARTICIPANTS_FETCH_COMPLETED_TYPE,
  OFFLINE_PARTICIPANTS_FETCH_FAILED_TYPE,
  STUDY_PARTICIPANT_SELECTED_TYPE,
  STUDY_PARTICIPANT_UNSELECTED_TYPE,
  STUDY_PARTICIPANT_RESULTS_TYPE,
  FETCH_STUDY_PARTICIPANT_RESULTS_STARTED_TYPE,
  FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED_TYPE,
} from '../types/participants';

import { error } from './common';
import * as types from '../types/participants';

export const selectParticipant = (id: ID_TYPE): STUDY_PARTICIPANT_SELECTED_TYPE => ({
  type: types.STUDY_PARTICIPANT_SELECTED,
  payload: id,
});

export const unselectParticipant = (): STUDY_PARTICIPANT_UNSELECTED_TYPE => ({
  type: types.STUDY_PARTICIPANT_UNSELECTED,
});

export const startFetchStudyParticipants = (
): STUDY_PARTICIPANTS_FETCH_STARTED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_FETCH_STARTED,
  payload: {},
});

export const completeFetchStudyParticipants = (
  entities: {[number]: STUDY_PARTICIPANTS_TYPE},
  order: Array<number>,
  nextPage,
): STUDY_PARTICIPANTS_FETCH_COMPLETED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    nextPage
  },
});

export const failFetchStudyParticipants = error(types.STUDY_PARTICIPANTS_FETCH_FAILED);

export const startAddStudyParticipants = (
  values: Object,
): STUDY_PARTICIPANTS_ADD_STARTED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_ADD_STARTED,
  payload: values,
});

export const completeAddStudyParticipants = (
  participants: Object,
): STUDY_PARTICIPANTS_ADD_COMPLETED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_ADD_COMPLETED,
  payload: {
    ...participants
  },
});

export const failAddStudyParticipants = error(types.STUDY_PARTICIPANTS_ADD_FAILED);


export const startUpdateStudyParticipants = (): STUDY_PARTICIPANTS_UPDATE_STARTED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_UPDATE_STARTED,
  payload: {},
});

export const completeUpdateStudyParticipants = (): STUDY_PARTICIPANTS_UPDATE_COMPLETED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_UPDATE_COMPLETED,
  payload: {},
});

export const failUpdateStudyParticipants = (): STUDY_PARTICIPANTS_UPDATE_FAILED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_UPDATE_FAILED,
  payload: {},
});

export const removeStudyParticipant = (id: ID_TYPE): STUDY_PARTICIPANTS_REMOVED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_REMOVED,
  payload: id,
});

export const failRemoveStudyParticipant = error(types.STUDY_PARTICIPANTS_REMOVE_FAILED);


export const updateStudyParticipantsFilters = (
  key: string,
  value: any,
): STUDY_PARTICIPANTS_FILTERS_UPDATED_TYPE => ({
  type: types.STUDY_PARTICIPANTS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  },
});

export const startFetchStudyParticipant = (
  id: ID_TYPE,
): STUDY_PARTICIPANT_FETCH_STARTED_TYPE => ({
  type: types.STUDY_PARTICIPANT_FETCH_STARTED,
  payload: id
});

export const completeFetchStudyParticipant = (participant: STUDY_PARTICIPANT_TYPE) => ({
  type: types.STUDY_PARTICIPANT_FETCH_COMPLETED,
  payload: participant,
});

export const failFetchParticipant = error(types.STUDY_PARTICIPANT_FETCH_FAILED);



// Study Participant Results
export const startFetchStudyParticipantResults = (id: ID_TYPE, filters: Object): FETCH_STUDY_PARTICIPANT_RESULTS_STARTED_TYPE => ({
  type: types.FETCH_STUDY_PARTICIPANT_RESULTS_STARTED,
  payload: {
    id,
    filters, // Representation: { participantId: XXXXX }
  },
});

export const completeFetchStudyParticipantResults = (
  entities: {[ID_TYPE]: STUDY_PARTICIPANT_RESULTS_TYPE},
  order: Array<ID_TYPE>,
): FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED_TYPE => ({
  type: types.FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchStudyParticipantResults = error(types.FETCH_STUDY_PARTICIPANT_RESULTS_FAILED);


// Offline Mode Support
export const startFetchOfflineParticipants = (
  filters
): OFFLINE_PARTICIPANTS_FETCH_STARTED_TYPE => ({
  type: types.OFFLINE_PARTICIPANTS_FETCH_STARTED,
  payload: { filters },
});

export const completeFetchOfflineParticipants = (
  entities: {[number]: STUDY_PARTICIPANTS_TYPE},
  order: Array<number>,
): OFFLINE_PARTICIPANTS_FETCH_COMPLETED_TYPE => ({
  type: types.OFFLINE_PARTICIPANTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchOfflineParticipants = error(types.OFFLINE_PARTICIPANTS_FETCH_FAILED);