// @flow
import type { ComponentType, Node } from 'react';
import React, { Fragment, useEffect } from 'react';
import _ from "lodash";

import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form';

import type { FOBI_FORM_TYPE } from './types';
import FobiReduxFormField from './FobiReduxFormField';
import { getQuestionsVisibilities } from './services';
import * as selectors from '../../reducers';
// import styles from './fobiFormRenderer.css';


type ElementWrapperPropTypes = {
  key: string,
  plugin_uid: string,
  children?: Node
};

type FobiReduxFormPropTypes = {
  globalFormName: string,
  formDescription: FOBI_FORM_TYPE,
  ElementWrapper?: ComponentType<ElementWrapperPropTypes>,
  getElementName?: string => string,
  showPreviews?: boolean,
  baseColor?: boolean,
  formValues: Object,
  clearHiddenField: Function,
};

const defaultElementNamer = (name: string): string => name;

const FobiReduxFormRenderer = ({
  globalFormName,
  formDescription: { name, form_elements },
  ElementWrapper,
  getElementName = defaultElementNamer,
  showPreviews = false,
  baseColor = '',
  formValues,
  visibleFormFields,
  clearHiddenField,
}: FobiReduxFormPropTypes) => {

  // Clear values of hidden fields
  useEffect(() => {
    const hiddenFieldIds = _.map(visibleFormFields, (value, key) => ({ value, key })).filter( obj => obj.value === false )
    _.map(formValues, (value, key) => ({ value, key })).forEach(({ key, value }) => {
      const splited = key.split('-');
      const id = splited[0];
  
      if (_.some(hiddenFieldIds, { value: false, key: id })) {
        console.log('Deleting ', key)
        clearHiddenField(key);
      } 
    })
  }, []) 

  return (
  <Fragment>
    {
      form_elements.map(
        (element, i) => visibleFormFields[element.id] && (typeof ElementWrapper !== 'undefined'
          ? (
            <ElementWrapper key={`${element.id}_${i}`}>
              <FobiReduxFormField
                key={`${element.id}_${i}`}
                formName={name}
                elementIndex={i}
                element={element}
                getElementName={getElementName}
                showPreview={showPreviews}
                baseColor={baseColor}
              />
            </ElementWrapper>
          ) : (
            <FobiReduxFormField
              key={element.plugin_data.name}
              formName={name}
              elementIndex={i}
              element={element}
              getElementName={getElementName}
              showPreview={showPreviews}
              baseColor={baseColor}
            />
          )
        ),
      )
    }
  </Fragment>
)};

export default connect(
  (state, { globalFormName, formDescription: { form_elements } }) => {
    const formValues = getFormValues(globalFormName)(state);
    const { instrument } = selectors.getSelectedStudy(state);
    const { id } = instrument;
    const questionSets = selectors.getInstrumentQuestionsSets(state, id);
    // Collect all instrument question IDs, in one array
    const questionsIds = questionSets.map(item => item.questions).reduce((acc, val) => acc.concat(val))
    let questions = {}
    questionsIds.forEach(id => {
      const question = selectors.getQuestion(state, id) || {}
      questions = {...questions, [question.ordinal]: question}
    })
    
    return {
      formValues: formValues,
      visibleFormFields: getQuestionsVisibilities(form_elements, formValues, questions)
    }
  },
  (dispatch, { globalFormName }) => ({
    clearHiddenField: field => dispatch(change(globalFormName, field, ''))
  })
)(FobiReduxFormRenderer);
