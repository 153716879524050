// @flow
import type {
  STATUS_CHANGED_TYPE
} from '../types/connection';

import * as types from '../types/connection';


export const clearConnectionStatus = () => ({
  type: types.CONNECTION_STATUS_CLEARED,
});

export const setOfflineModeOn = () => ({
  type: types.PREVENTIVE_OFFLINE_MODE_ON,
});

export const setOfflineModeOff = () => ({
  type: types.PREVENTIVE_OFFLINE_MODE_OFF,
});