// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Field,
  reduxForm,
  reset,
  initialize,
  formValueSelector,
  change,
} from 'redux-form';
import sortBy from 'lodash/sortBy';

import { ID_TYPE } from '../../types/common';
import { QUESTIONS_SET_TYPE } from '../../types/questionsSets';
import {
  RenderSelect,
  RenderRadioOptions,
} from '../FieldRenderer';
import Button from '../Button';
import { FullLoader } from '../Loader';
import AddParticipanForm from '../AddParticipantForm';
import AddKidForm from '../AddKidForm';
import QuestionsSetFormRenderer from '../QuestionsSetFormRenderer';
import ParticipantTypeahead from '../ParticipantTypeahead';

import styles from './instrumentSubmissionForm.css';
import {
  ANTHROPOMETRY_TYPE,
  UPDATE_ACTION_FORM,
} from '../../settings';
import { addSubmission, updateSubmission } from './submit';

import * as selectors from '../../reducers';
import * as actions from '../../actions/instrumentSubmissions';
import * as closeableActions from '../../actions/closeable';


type InstrumentSubmissionFormPropTypes = {
  instrument: Object,
  areFetchingQuestions: boolean,
  instrumentQuestionsSets: Array<QUESTIONS_SET_TYPE>,
  phases: Array<Object>,
  isLoading: boolean,
  cancelPressed: Function,
  errorApi: ERROR_TYPE,
  fieldsRequireds: Array<number>,
};


const InstrumentSubmissionForm = ({
  instrument = {},
  areFetchingQuestions,
  instrumentQuestionsSets,
  phases = [],
  handleSubmit,
  isLoading,
  clearForm,
  cancelPressed,
  onSubmit,
  actionForm,
  errorApi = {},
  error,
  fieldsRequireds = [],
}: InstrumentSubmissionFormPropTypes) => {

  useEffect(() => {}, [instrumentQuestionsSets])

  return (
    <div className={styles.formContainer}>
      {
        actionForm !== UPDATE_ACTION_FORM && (
          <ParticipantTypeahead
            instrumentType={instrument.type}
          />
        )
      }
      {
        instrument.type === ANTHROPOMETRY_TYPE ? <AddKidForm actionForm={actionForm} /> : <AddParticipanForm />
      }
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {
          !areFetchingQuestions && (
            <div className={styles.formHeader}>
              <div className={styles.title}>
                {`Instrumento: ${instrument.name}`}
              </div>
              {
                /* New requirement: anthropometry studies needs a phase...  */
                /* instrument.type !== ANTHROPOMETRY_TYPE && ( */
                <div className={styles.phase}>
                  <div className={styles.phaseLabel}>
                    {"Medición: "}
                  </div>
                  <Field
                    component={RenderSelect}
                    name={'phase'}
                    options={phases}
                    size="small"
                    isRequired={true}
                    isRound
                  />
                </div>
              }
            </div>
          )
        }
        {
          !areFetchingQuestions && instrumentQuestionsSets.length > 0
            && instrumentQuestionsSets.map(({ id, name }) => (
              <QuestionsSetFormRenderer
                key={id}
                id={id}
                label={name}
              />
            )
          )
        }
        {
          (!areFetchingQuestions && !isLoading) && Object.keys(errorApi).length > 0
          && (
            <div className={styles.errorMessage}>
              {"Ha ocurrido un error intente de nuevo"}
            </div>
          )
        }
        {
          (!areFetchingQuestions && !isLoading) && fieldsRequireds.length > 0
            && (
              <div className={styles.requiredValues}>
                {`*Debe de completar las siguientes preguntas: ${[...new Set(fieldsRequireds)].map(el => ` ${el}`)}`}
              </div>
            )
          
        }
        {
          !areFetchingQuestions && (
            <div className={styles.formFooter}>
              <Button
                type="reset"
                tint="mediumGray"
                title="cancelar"
                onClick={() => cancelPressed()}
                isRound
              />
              <Button
                type="submit"
                tint="primary"
                className={styles.approveButton}
                isLoading={isLoading}
                title={"realizar ingreso"}
                isRound
              />
            </div>
          )
        }
        {
          areFetchingQuestions && (
            <FullLoader
              title="Trayendo preguntas..."
              tint='gray'
            />
          )
        }
      </Form>
    </div>
  )
};


const InstrumentSubmissionFormCreator = connect(
  (state, { id, action }) => {
    const formSelector = formValueSelector('addKidForm');
    const { phases = [] } = selectors.getSelectedStudy(state) || {};
    const errorValues = selectors.getSubmissionErrorValues(state) || [];
    /* const fieldsRequireds = errorValues.map(id => {
      if (id !== 'phase') {
        const { ordinal } = selectors.getQuestion(state, id) || {};
        return ordinal; 
      } else return "Fase";
    }); */
    const fieldsRequireds = []
    const vPhases = phases.map(el => ({
      value: el.id,
      label: el.name,
      ordinal: el.ordinal,
      ordinal_key: el.ordinal_key,
    }));

    return ({
      instrument: selectors.getInstrument(state, id),
      instrumentQuestionsSets: selectors.getInstrumentQuestionsSets(state, id),
      areFetchingQuestions: selectors.areInstrumentQuestionsFetching(state, id),
      phases: sortBy(vPhases, ['ordinal']),
      isLoading: selectors.isSubmittingInstrument(state)
        || selectors.isAddingKid(state)
        || selectors.isAddingUser(state),
      actionForm: action,
      errorApi: selectors.getSubmissionError(state),
      fieldsRequireds,
    })
  },
  (dispatch, { action }) => ({
    cancelPressed() {
      if (action === UPDATE_ACTION_FORM) {
        dispatch(closeableActions.close('updateStudySubmission'));
      } else {
        dispatch(closeableActions.close('addStudySubmission'));
      }
    },
    onSubmit(values) {
      if (action === UPDATE_ACTION_FORM) {
        updateSubmission(values, dispatch);
      } else {
        addSubmission(values, dispatch);
      }
    },
  })
)(InstrumentSubmissionForm)


const InstrumentSubmissionReduxForm = reduxForm({
  form: 'addInstrumentSubmission',
  validate: (values, { validator }) => validator(values),
  onSubmitFail: (error, dispatch) => {
    const questions = Object.keys(error).map(key => {
      const splited = key.split('-');
      return splited[0];
    });

    dispatch(actions.errorRequieredValues(questions));
  },
})(InstrumentSubmissionFormCreator);

export default InstrumentSubmissionReduxForm;
