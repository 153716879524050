// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  USER_TYPE,
  USERS_FETCH_STARTED_TYPE,
  USERS_FETCH_COMPLETED_TYPE,
  USERS_FETCH_FAILED_TYPE,
  USERS_ADD_STARTED_TYPE,
  USERS_ADD_COMPLETED_TYPE,
  USERS_ADD_FAILED_TYPE,
  USERS_UPDATE_STARTED_TYPE,
  USERS_UPDATE_COMPLETED_TYPE,
  USERS_UPDATE_FAILED_TYPE,
  USERS_REMOVED_TYPE,
  USERS_REMOVE_STARTED_TYPE,
  USERS_REMOVE_COMPLETED_TYPE,
  USERS_REMOVE_FAILED_TYPE,
  USER_SELECTED_TYPE,
  USER_ERROR_CLEANED_TYPE,
} from '../types/users';
import * as types from '../types/users';
import { error } from './common';


export const startFetchUsers = (): USERS_FETCH_STARTED_TYPE => ({
  type: types.USERS_FETCH_STARTED,
  payload: {},
});

export const completeFetchUsers = (
  entities: {[ID_TYPE]: USER_TYPE},
  order: Array<ID_TYPE>,
): USERS_FETCH_COMPLETED_TYPE => ({
  type: types.USERS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchUsers = error(types.USERS_FETCH_FAILED);

export const startAddUsers = (user: USER_TYPE): USERS_ADD_STARTED_TYPE => ({
  type: types.USERS_ADD_STARTED,
  payload: user,
});

export const completeAddUsers = (user: USER_TYPE): USERS_ADD_COMPLETED_TYPE => ({
  type: types.USERS_ADD_COMPLETED,
  payload: user,
});

export const failAddUsers = error(types.USERS_ADD_FAILED);

export const startUpdateUsers = (user: USER_TYPE): USERS_UPDATE_STARTED_TYPE => ({
  type: types.USERS_UPDATE_STARTED,
  payload: user,
});

export const completeUpdateUsers = (user: USER_TYPE): USERS_UPDATE_COMPLETED_TYPE => ({
  type: types.USERS_UPDATE_COMPLETED,
  payload: user,
});

export const failUpdateUsers = (): USERS_UPDATE_FAILED_TYPE => ({
  type: types.USERS_UPDATE_FAILED,
  payload: {},
});

export const removeUsers = (): USERS_REMOVED_TYPE => ({
  type: types.USERS_REMOVED,
  payload: {},
});

export const startRemoveUsers = (): USERS_REMOVE_STARTED_TYPE => ({
  type: types.USERS_REMOVE_STARTED,
  payload: {},
});

export const completeRemoveUsers = (): USERS_REMOVE_COMPLETED_TYPE => ({
  type: types.USERS_REMOVE_COMPLETED,
  payload: {},
});

export const failRemoveUsers = (): USERS_REMOVE_FAILED_TYPE => ({
  type: types.USERS_REMOVE_FAILED,
  payload: {},
});

export const selectUser = (id: ID_TYPE): USER_SELECTED_TYPE => ({
  type: types.USER_SELECTED,
  payload: id,
});

export const clearUserError = (): USER_ERROR_CLEANED_TYPE => ({
  type: types.USER_ERROR_CLEANED
});
