// @flow
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { MdAdd, MdEdit } from 'react-icons/lib/md';

import Button from '../Button';

import styles from './studyHeader.css';
import * as closableActions from '../../actions/closeable';
import * as selectors from '../../reducers';


type StudyHeaderPropTypes = {
  name: string,
  study_type: Object,
  instrument: Object,
  start_date: Date,
  phases: number,
  submissions_count: number,
  openPopupAddSubmission: Function,
};

const DetailElement = ({
  title,
  value
}) => (
  <div className={styles.detailElement}>
    <p>
      <b>{`${title}: `}</b>{value}
    </p>
  </div>
);


const StudyHeader = ({
  name,
  study_type = {},
  instrument = {},
  start_date,
  phases = [],
  submissions_count = 0,
  openPopupAddSubmission,
}: StudyHeaderPropTypes) => (
  <div className={styles.container}>
    <div className={styles.name}>
      {name}
    </div>
    <div className={styles.detail}>
      <div className={styles.type}>
        {study_type.name}
      </div>
      <DetailElement
        title="Instrumento"
        value={instrument.name}
      />
      <DetailElement
        title="Fecha de inicio"
        value={moment(start_date).format('L')}
      />
      <DetailElement
        title="Mediciones"
        value={phases.length}
      />
      <DetailElement
        title="Ingresos"
        value={submissions_count}
      />
      <Button
        title="Ingreso"
        Icon={MdAdd}
        isRound
        size="small"
        clearMargins
        onClick={() => openPopupAddSubmission()}
      />
    </div>
    <div className={styles.editButton}>
      {/* <Button
        Icon={MdEdit}
        tint="gray"
        isRound
      /> */}

    </div>
  </div>
);

export default connect(
  (state, { id }) => ({
    // isFetching: selectors.isFetchingStudy(state, id),
    ...selectors.getStudy(state, id)
  }),
  dispatch => ({
    openPopupAddSubmission() {
      dispatch(closableActions.open('addStudySubmission'));
    }
  })
)(StudyHeader);
