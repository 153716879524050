//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { QUESTION_TYPE } from '../types/questions';
import * as common from './common';
import * as types from '../types/questions';

export type QuestionsState = {
  byId: { [ID_TYPE]: QUESTION_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  errors: { [ID_TYPE]: ERROR_TYPE },
  selected: number,
  counter: number,
};

const byId = common.byId({
  fetched: [types.QUESTIONS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.QUESTIONS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.QUESTIONS_FETCH_STARTED],
  succeed: [types.QUESTIONS_FETCH_COMPLETED],
  failed: [types.QUESTIONS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.QUESTIONS_FETCH_STARTED,
    types.QUESTIONS_FETCH_COMPLETED,
  ],
  populate: [
    types.QUESTIONS_FETCH_FAILED,
  ],
});

const selected = common.mux({
  selected: [],
  allDeselected: [],
  default: -1,
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const questions = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
  counter,
});


export default questions;


// Selectors
export const getQuestion = (state: QuestionsState, id: ID_TYPE): ?QUESTION_TYPE => state.byId[id];
export const getQuestions = (state: QuestionsState): Array<?QUESTION_TYPE> => state.order.map(i => getQuestion(state, i));
export const isFetchingQuestions = (state: QuestionsState): boolean => state.isFetching;
export const getQuestionsError = (state: QuestionsState): ERROR_TYPE => state.error;
export const getSelectedQuestion = (state: QuestionsState): ?QUESTION_TYPE => getQuestion(state, state.selected);
export const getQuestionCounter = (state: QuestionsState): number => state.counter;
