// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Popup from '../Popup';
import UpdateStudySubmissionContent from '../UpdateStudySubmissionContent';

import * as selectors from '../../reducers';


type UpdateStudySubmissionPopupPropTypes = {
  instrument: Object,
  submission: Object,
};
 

const UpdateStudySubmissionPopup = ({
  instrument = {},
  submission = {},
}: UpdateStudySubmissionPopupPropTypes) => (
  <Popup
    id="updateStudySubmission"
    theme="main"
    transitionMs={600}
    dontCloseOnEscape
  >
    <UpdateStudySubmissionContent
      instrument={instrument.id}
      submission={submission.id}
    />
  </Popup>
);


export default connect(
  state => {
    const study = selectors.getSelectedStudy(state) || {};
    const submission = selectors.getSelectedInstrumentsubmission(state);
    const { instrument } = study;
    return ({
      instrument: instrument,
      submission: submission,
    })
  },
)(UpdateStudySubmissionPopup);
