exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../assets/styles/variables/font.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "\n.src-scripts-components-views-Login-__login___login {\n  display: flex;\n  height: 100vh;\n  flex-direction: column;\n}\n\n.src-scripts-components-views-Login-__login___loginForm {\n  align-items: flex-end;\n  background-color: var(--mainColor);\n  display: flex;\n  height: 66vh;\n}\n\n.src-scripts-components-views-Login-__login___content {\n  display: flex;\n  padding-top: 6rem;\n  align-items: center;\n  flex-direction: column;\n}\n\n.src-scripts-components-views-Login-__login___logo {\n  height: auto;\n  width: 30rem;\n}\n\n.src-scripts-components-views-Login-__login___text {\n  color: var(--mainColor);\n  font-size: 2rem;\n  font-weight: 500;\n  text-decoration: underline;\n  margin-bottom: 4rem;\n  margin-top: 2rem;\n}\n\n@media(max-width: 576px) {\n  .src-scripts-components-views-Login-__login___logo {\n    height: 14rem;\n    width: 32rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"login": "src-scripts-components-views-Login-__login___login",
	"loginForm": "src-scripts-components-views-Login-__login___loginForm",
	"content": "src-scripts-components-views-Login-__login___content",
	"logo": "src-scripts-components-views-Login-__login___logo",
	"text": "src-scripts-components-views-Login-__login___text"
};