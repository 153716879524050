// @flow
import React from 'react';
import type { ComponentType } from 'react';
import Cell from '../Cells/Highlight';


type HighlightPropsTypes = {
  accessor: string | Function,
  Header?: string | ComponentType<*>,
  getOnClick?: Function,
};

type CellPropTypes = {
  value: string,
  original: Object,
};


const Highlight = ({
  accessor,
  Header,
  getOnClick = () => {},
  ...props
}: HighlightPropsTypes) => ({
  Header,
  accessor,
  Cell: ({ value, original }: CellPropTypes) =>
    <Cell value={value} onClick={getOnClick(original)} />,
  ...props,
});

export default Highlight;
