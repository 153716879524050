// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Navbar from '../../Navbar';
import ViewContent from '../../ViewContent';
// Offline Mode Support
import OfflineModePopup from '../../OfflineModePopup';
import OfflineModeWarningPopup from '../../OfflineModeWarningPopup';

import StudiesList from '../../StudiesList';
import MenuFixed from '../../MenuFixed';
import Alert from '../../Alert';
import StudyConnectionFooter from '../../StudyConnectionFooter';

import * as connectionActions from './../../../actions/connection';
import * as selectors from './../../../reducers';
import * as instrumentActions from '../../../actions/instruments';

import styles from './studies.css';


type StudiesViewPropTypes = {
  online: Boolean,
  isRecentStatus: Boolean,
  onLoad: Function,
  clearRecentStatus: Function,
};

const Studies = ({
  online,
  onLoad,
  isRecentStatus,
  clearRecentStatus,
}: StudiesViewPropTypes) => {
  useEffect(() => {
    onLoad();
  });

  return (
    <div className={styles.viewport}>
      <Navbar />
      {
        online &&
        isRecentStatus !== null &&
        <Alert 
          message={'Aplicación conectada'}
          color={"#5ac75a"}
          duration={5000}
          closeable
          onClose={() => clearRecentStatus()}
        />
      }
      <MenuFixed />
      <ViewContent>
        <StudiesList />
      </ViewContent>
      <StudyConnectionFooter />
      <OfflineModePopup />
      <OfflineModeWarningPopup />
    </div>
  );
};

export default connect(
  state => ({
    online: selectors.hasInternetConnection(state),
    isRecentStatus: selectors.isConnectionRecentStatus(state),
  }),
  dispatch => ({
    onLoad() {
      dispatch(instrumentActions.startFetchInstruments());
    },
    clearRecentStatus() {
      dispatch(connectionActions.clearConnectionStatus())
    }
  })
)(Studies);
