// @flow
import type { ID_TYPE } from '../types/common';

import type {
  STUDY_TYPE,
  STUDIES_FETCH_STARTED_TYPE,
  STUDIES_FETCH_COMPLETED_TYPE,
  STUDY_ADD_STARTED_TYPE,
  STUDY_ADD_COMPLETED_TYPE,
  STUDY_FETCH_STARTED_TYPE,
  STUDY_FETCH_COMPLETED_TYPE,
  STUDY_SELECTED_TYPE,
  STUDY_SELECTED_CLEARED_TYPE,
  STUDY_UPDATED_STARTED_TYPE,
  STUDY_UPDATED_COMPLETED_TYPE,
  STUDY_ORGANIZATIONS_UPDATE_STARTED_TYPE,
  STUDY_ORGANIZATIONS_UPDATE_COMPLETED_TYPE,
  STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED_TYPE,
  STUDIES_INDICATORS_FILTERS_CLEARED_TYPE,
  STUDIES_INDICATORS_FILTERS_UPDATED_TYPE,
  STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED_TYPE,
  STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED_TYPE,
  STUDY_RESULTS_EXIT_PROFILE_UPDATED_TYPE,
  STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED_TYPE,
  STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED_TYPE,
} from '../types/studies';
import * as types from '../types/studies';

import { error } from './common';


export const startFetchStudies = (
  page,
  filters,
): STUDIES_FETCH_STARTED_TYPE => ({
  type: types.STUDIES_FETCH_STARTED,
  payload: {
    page,
    filters,
  },
});

export const completeFetchStudies = (
  entities: {[ID_TYPE]: STUDY_TYPE},
  order: Array<ID_TYPE>,
  nextPage: number,
): STUDIES_FETCH_COMPLETED_TYPE => ({
  type: types.STUDIES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    nextPage,
  },
});

export const failFetchStudies = error(types.STUDIES_FETCH_FAILED);

export const startAddStudy = (
  study: STUDY_TYPE,
): STUDY_ADD_STARTED_TYPE => ({
  type: types.STUDY_ADD_STARTED,
  payload: study,
});

export const completeAddStudy = (
    study: STUDY_TYPE,
): STUDY_ADD_COMPLETED_TYPE => ({
  type: types.STUDY_ADD_COMPLETED,
  payload: study,
});

export const failAddStudy = error(types.STUDY_ADD_FAILED);

export const updateStudiesFilters = (key: string, value: any) => ({
  type: types.STUDIES_FILTERS_UPDATED,
  payload: {
    key,
    value,
  },
});

export const startFetchStudy = (id: number): STUDY_FETCH_STARTED_TYPE => ({
  type: types.STUDY_FETCH_STARTED,
  payload: id,
});

export const completeFetchStudy = (study: STUDY_TYPE) => ({
  type: types.STUDY_FETCH_COMPLETED,
  payload: study,
});

export const failFetchStudy = error(types.STUDY_FETCH_FAILED);

export const selectStudy = (id: number): STUDY_SELECTED_TYPE => ({
  type: types.STUDY_SELECTED,
  payload: id,
});

export const clearStudySelected = (): STUDY_SELECTED_CLEARED_TYPE => ({
  type: types.STUDY_SELECTED_CLEARED,
});

export const startUpdateStudy = (study: STUDY_TYPE): STUDY_UPDATED_STARTED_TYPE => ({
  type: types.STUDY_UPDATED_STARTED,
  payload: study,
});

export const completeUpdateStudy = (study: STUDY_TYPE): STUDY_UPDATED_COMPLETED_TYPE => ({
  type: types.STUDY_UPDATED_COMPLETED,
  payload: study,
});

export const updateStudySpotlightResults = (studyId: number, order: [number]) => ({
  type: types.STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED,
  payload: {
    id: studyId,
    order,
  },
});

export const updateStudyResults = (studyId: ID_TYPE, order: Array<ID_TYPE>) => ({
  type: types.STUDY_RESULTS_UPDATED,
  payload: {
    id: studyId,
    order,
  }
});

export const updateStudyIndicatorResults = (studyId: Number, order: Array<ID_TYPE>) => ({
  type: types.STUDY_INDICATOR_RESULTS_UPDATED,
  payload: {
    id: studyId,
    order,
  }
});

export const failUpdateStudy = error(types.STUDY_UPDATED_FAILED);

export const startUpdateStudyOrganizations = (
  study: ID_TYPE,
  organizations: Array<any>,
): STUDY_ORGANIZATIONS_UPDATE_STARTED_TYPE => ({
  type: types.STUDY_ORGANIZATIONS_UPDATE_STARTED,
  payload: {
    study,
    organizations
  },
});

export const completeUpdateStudyOrganizations = (): STUDY_ORGANIZATIONS_UPDATE_COMPLETED_TYPE => ({
  type: types.STUDY_ORGANIZATIONS_UPDATE_COMPLETED,
});

export const failUpdateStudyOrgnizations = error(types.STUDY_ORGANIZATIONS_UPDATE_FAILED);

export const updateStudiesIndicatorsFilters = (
  key: String,
  value: any,
): STUDIES_INDICATORS_FILTERS_UPDATED_TYPE => ({
  type: types.STUDIES_INDICATORS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const clearStudiesIndicatorsFilters = (
):STUDIES_INDICATORS_FILTERS_CLEARED_TYPE => ({
  type: types.STUDIES_INDICATORS_FILTERS_CLEARED,
});

export const updateStudyStoplightResultsFilters = (
  key: string,
  value: any,
): STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED_TYPE => ({
  type: types.STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const clearStudyStoplightResultsFilters = (
): STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED_TYPE => ({
  type: types.STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED,
});

export const updateStudyResultsExitProfileOrder = (
  id: ID_TYPE,
  order: Array<ID_TYPE>,
): STUDY_RESULTS_EXIT_PROFILE_UPDATED_TYPE => ({
  type: types.STUDY_RESULTS_EXIT_PROFILE_UPDATED,
  payload: {
    id,
    order,
  }
});

export const updateStudiesResultsExitProfileFilters = (
  key: string,
  value: any
): STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED_TYPE => ({
  type: types.STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const clearStudiesResultsExitProfileFilters = (
): STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED_TYPE => ({
  type: types.STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED,
})
