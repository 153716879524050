exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___container {\n  max-height: 50vh;\n  overflow: scroll;\n}\n\n.src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___title {\n  font-size: 3rem;\n  color: var(--grayColor);\n  font-weight: 500;\n}\n\n.src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___header {\n  padding: 1rem 4rem !important;\n}\n\n.src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___footer {\n  padding: 2rem 4rem !important;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___container",
	"title": "src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___title",
	"header": "src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___header",
	"footer": "src-scripts-components-PopupInstrumentFilters-__popupStudyFilters___footer"
};