// @flow
import React, { useState } from 'react';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupContent from '../PopupContent';
import PopupHeader from '../PopupHeader';
import PopupCloseButton from '../PopupCloseButton';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import CheckboxList from '../CheckboxList';
import Button from '../Button';

import * as selectors from '../../reducers';
import * as connectionActions from '../../actions/connection';
import * as participantActions from '../../actions/participants';
import * as closeableActions from '../../actions/closeable';
import * as closableActions from '../../actions/closeable';
import styles from './organizationsPopup.css';


type OfflineModeOrganizationsPopupPropTypes = {
  organizations: Array<any>,
  onSelectItems: Function,
  onCancel: Function,
};

export const SELECT_ORGANIZATIONS_POPUP_ID = "organizationsSelectPopup";

const OfflineModeOrganizationsPopup = ({
  organizations,
  onSelectItems,
  onCancel
}: OfflineModeOrganizationsPopupPropTypes) => {
  const [hasSelectedAtLeastOne, setHasSelectedAtLeastOne] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const onChange = (selectedItems) => {
    setSelectedItems(selectedItems);
    setHasSelectedAtLeastOne(selectedItems && selectedItems.length);
  };

  return (
    <Popup
      id={SELECT_ORGANIZATIONS_POPUP_ID}
      theme="main"
      transitionMs={600}
    >
      <PopupContainer
        elevation={8}
        isRound
        size="small"
      >
        <PopupContent>
          <PopupHeader clearPadding isAlert>
            <PopupHeaderLeftGroup>
              <h1 className={styles.popupTitle}>
                Selecciona las comunidades a visitar
              </h1>
            </PopupHeaderLeftGroup>
            <PopupHeaderRightGroup>
              <PopupCloseButton
                tint="mediumGray"
                Icon={MdClose}
                clearMargins
                isTransparent
                isOutlined
              />
            </PopupHeaderRightGroup>
          </PopupHeader>
          <CheckboxList
            className={styles.organizationsList}
            items={organizations}
            selectedItems={selectedItems}
            onSelectItems={onChange}
          />
          <div className={styles.footer}>
            <Button
              type="button"
              title="Cancelar"
              tint="gray"
              onClick={() => onCancel()}
            />
            <Button
              disabled={!hasSelectedAtLeastOne}
              type="button"
              title="Continuar"
              tint="secondary"
              className={styles.approveButton}
              onClick={() => hasSelectedAtLeastOne && onSelectItems(selectedItems)}
            />
          </div>
        </PopupContent>
      </PopupContainer>
    </Popup>
  );
};

export default connect(
  state => {
    const { study_organizations = [] } = selectors.getSelectedStudy(state) || {};
    return ({
      organizations: sortBy(study_organizations, 'sublocation').map(
        ({ organization_name, organization_id, sample, sublocation, location }) => ({ 
          id: organization_id,
          label: organization_name,
          subLabel: `${sublocation} - ${location} (Muestra: ${sample})`,
        })
      )
    })
  },
  dispatch => ({
    onSelectItems(items) {
      dispatch(connectionActions.setOfflineModeOn());
      dispatch(closeableActions.close(SELECT_ORGANIZATIONS_POPUP_ID));
      dispatch(closeableActions.open('initOfflineMode'));
      dispatch(participantActions.startFetchOfflineParticipants({
        organizations: items,
      }));
    },
    onCancel() {
      dispatch(closableActions.close(SELECT_ORGANIZATIONS_POPUP_ID));
    }
  })
)(OfflineModeOrganizationsPopup);
