// @flow
import React from 'react';
import ReactSelect from 'react-select';

import type { ID_TYPE } from '../../types/common';
// import Input from '../Input';

import styles from './select.css';


type OPTION_TYPE = {
  value: ID_TYPE
};

type SelectPropTypes = {
  size?: string,
  isRound?: boolean,
  value?: ID_TYPE,
  options: Array<OPTION_TYPE>,
  onChange?: OPTION_TYPE => void,
  isLeft?: boolean,
  isRight?: boolean,
  isMiddle?: boolean,
  className?: string,
};

type SelectStateTypes = {
  optionValue?: OPTION_TYPE
};

const getOptionValue = (value?: ID_TYPE, options: Array<OPTION_TYPE>) => {
  const result = options.filter(option => option.value === value);
  return result.length > 0 ? result[0] : null;
};

class Select extends React.Component<SelectPropTypes, SelectStateTypes> {
  constructor(props: SelectPropTypes) {
    super(props);

    this.state = {
      optionValue: getOptionValue(props.value, props.options),
    };
  }

  componentWillReceiveProps(nextProps: SelectPropTypes) {
    const { value } = this.props;

    if (value !== nextProps.value) {
      this.setState({
        optionValue: getOptionValue(nextProps.value, nextProps.options),
      });
    }
  }

  render() {
    const {
      size = 'medium',
      isRound = false,
      onChange,
      isLeft = false,
      isRight = false,
      isMiddle = false,
      className,
      ...props
    } = this.props;

    const { optionValue } = this.state;

    return (
      <ReactSelect
        {...props}
        className={
          `
            ${styles.select}
            ${{...styles}}
            ${styles[size] ? styles[size] : ''}
            ${isRound ? styles.roundSelect : ''}
            ${isLeft ? styles.selectLeft : ''}
            ${isRight ? styles.selectRight : ''}
            ${isMiddle ? styles.selectMiddle : ''}
            ${className ? styles[className] : ''}
          `
        }
        classNamePrefix="react-select"
        value={optionValue}
        onChange={
          option => onChange && onChange(option.value)
        }
        onBlur={event => event.preventDefault()}
        // blurInputOnSelect={false}
      />
    );
  }
}


export default Select;
