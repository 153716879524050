//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { RESULT_EXIT_PROFILE_TYPE, RESULTS_EXIT_PROFILE_ACTION_TYPE } from '../types/resultsExitProfile';
import * as common from './common';
import * as types from '../types/resultsExitProfile';


export type ResultsExitProfileState = {
  byId: { [ID_TYPE]: RESULT_EXIT_PROFILE_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  filters: Object,
};

const byId = common.byId({
  cleared: [
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
  ],
  fetched: [
    types.RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  ],
  defaultAttributes: {},
});

const order = common.order({
  cleared: [
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
  ],
  fetched: [
    types.RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  ],
});

const isFetching = common.isFetching({
  started: [
    types.RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
  ],
  succeed: [
    types.RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  ],
  failed: [
    types.RESULTS_EXIT_PROFILE_FETCH_FAILED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED,
  ],
});

const error = common.error({
  clear: [
    types.RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  ],
  populate: [
    types.RESULTS_EXIT_PROFILE_FETCH_FAILED,
    types.RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
    types.STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED,
    types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED,
  ],
});

// Timestamp & extra info
const extraInfo = (
  state: Object = {},
  action: RESULTS_EXIT_PROFILE_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    };
    case types.RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED: {
      return {};
    };
    default: {
      return state;
    }
  }
};

const resultsExitProfile = combineReducers({
  byId,
  order,
  isFetching,
  error,
  extraInfo,
});


export default resultsExitProfile;

export const getResultExitProfile = (state: ResultsExitProfileState, id: ID_TYPE): RESULT_EXIT_PROFILE_TYPE => state.byId[id];
export const getResultsExitProfile = (state: ResultsExitProfileState): Array<RESULT_EXIT_PROFILE_TYPE> => state.order.map(id => getResultExitProfile(state, id));
export const isFetchingResultsExitProfile = (state: ResultsExitProfileState): boolean => state.isFetching;
export const getResultsExitProfileError = (state: ResultsExitProfileState): ERROR_TYPE => state.error;
export const getResultExitProfileExtraInfo = (state: InstrumentsState): Object => state.extraInfo;
export const getResultExitProfileExtraInfoKeyValue = (state: InstrumentsState, key: string): any => state.extraInfo[key];