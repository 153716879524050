// @flow
import React, { Fragment } from 'react';

import type { TagPropTypes } from '../Tag';
import Tag from '../Tag';


export type TagsPropTypes = {
  tags: Array<Object>,
  iconAction: Function,
  tagProps?: TagPropTypes,
  size?: string,
};

const Tags = ({
  tags = [],
  tagProps = {},
  iconAction,
  size = 'medium',
}: TagsPropTypes) => (
  <Fragment>
    {
      tags.map(tag => (
        <Tag
          {...tagProps}
          key={tag.value}
          iconAction={() => iconAction(tag)}
          title={tag.label}
          size={size}
        />
      ))
    }
  </Fragment>
);

export default Tags;
