// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';
import { arrayOfStoplightResults } from '../api/schemas/stoplightResults';

import {
  INSTRUMENTS_FETCH_STARTED,
  INSTRUMENT_QUESTIONS_FETCH_STARTED,
} from '../types/instruments';
import {
  FETCH_RESULTS_STARTED,
} from '../types/results';
import { Instruments } from '../api';
import { arrayOfInstruments } from '../api/schemas/instruments';
import { arrayOfQuestionsSets, questionsSet } from '../api/schemas/questionsSets';

import * as selectors from '../reducers';
import * as actions from '../actions/instruments';
import * as resultActions from '../actions/results';
import * as questionsSetsActions from '../actions/questionsSets';
import * as questionsActions from '../actions/questions';


function* fetchInstruments(action) {
  try {
    const token = yield select(selectors.getToken);

    const { response, timeout } = yield race({
      response: call(
        [Instruments, 'list'],
        {
          token,
          filters: {
            page: 1,
            page_size: 15,
          }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const responseModified = response.map(el => {
      const genericFilters = [];
      const filters = [];

      el.filters.map(filter => {
        if (
            filter.default
            && filter.type !== 'location'
            && filter.type !== 'sublocation'
            && genericFilters.length < 7
          ) {
          genericFilters.push(filter);
        } else {
          filters.push(filter);
        }
      });

      return ({
        ...el,
        filters,
        genericFilters,
      })
    });

    const {
      entities: { instruments },
      result,
    } = normalize(responseModified, arrayOfInstruments);

    yield put(actions.completeFetchInstruments(instruments, result, null));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchInstruments({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}

function* fetchInstrumentQuestions(action) {
  try {
    
    const token = yield select(selectors.getToken);
    const id = action.payload;

    const { questionsResponse, timeout } = yield race({
      questionsResponse: call(
        [Instruments.custom, 'questions'],
        {
          token,
          id,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const {
      entities: {
        questionsSets,
        questions,
        // choices,
      },
      result,
    } = normalize(questionsResponse.question_sets, arrayOfQuestionsSets);

    // yield put(choicesActions.completeFetchChoices(choices));
    yield put(questionsActions.completeFetchQuestions(questions));
    yield put(questionsSetsActions.completeFetchQuestionsSets(questionsSets, result));
    yield put(actions.completeFetchInstrumentQuestions(id, result));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;

    const id = action.payload;
    
    yield put(actions.failFetchInstrumentQuestions({
      object_id: id,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
};

function* fetchResults({ payload }) {
  const token = yield select(selectors.getToken);
  let instrument = yield select(selectors.getSelectedInstrument);
  instrument = !!instrument ? instrument : yield select(selectors.getDefaultInstrument);

  const filters = yield select(selectors.getInstrumentsFilters);

  const { typeOfResults } = payload;
  let apiRoute = 'semaphore';
  
  if (!instrument) return;

  if (typeOfResults === 'semaphore') {
    apiRoute = 'semaphore';
  }

  try {
    const { response, timeout } = yield race({
      response: call(
        [Instruments.custom, apiRoute],
        {
          token,
          id: instrument.id,
          filters: { filters: JSON.stringify(filters) },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const transformedResult = response.map(el => {
      el.id = el.pk;
      el.results = []; 
      el.labels.map((label, i) => {
        el.results.push({
          name: label,
          data: !!el.values[i] ? el.values[i].toFixed(2) : 0,
        });
      });
      delete el.labels;
      delete el.values;
      return el;
    });

    const {
      entities: { stoplightResult },
      result,
    } = normalize(transformedResult, arrayOfStoplightResults);

    yield put(resultActions.completeFetchStudyResults(!!stoplightResult ? stoplightResult : {}, instrument.id));
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(resultActions.failFetchStudyResults({
      message,
      data: isPlain ? 'Error en el servidor' : data,
    }))
  }
}

export function* watchFetchResults(): Iterator<any> {
  yield takeEvery(
    FETCH_RESULTS_STARTED,
    fetchResults
  );
}

export function* watchFetchInstruments(): Iterator<any> {
  yield takeEvery(
    INSTRUMENTS_FETCH_STARTED,
    fetchInstruments
  );
};

export function* watchFetchInstrumentQuestions(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_QUESTIONS_FETCH_STARTED,
    fetchInstrumentQuestions
  );
};
