exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/shadows.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ":root {\n  --navbarHeight: 70px;\n}\n\n.src-scripts-components-Navbar-__navbar___navbar {\n  background: var(--mainColor);\n  box-shadow: var(--boxShadow2);\n  display: flex;\n  height: 70px;\n  height: var(--navbarHeight);\n  padding: 0 6rem;\n  z-index: 1;\n}\n\n.src-scripts-components-Navbar-__navbar___leftGroup, .src-scripts-components-Navbar-__navbar___rightGroup, .src-scripts-components-Navbar-__navbar___centerGroup {\n  align-items: center;\n  display: flex;\n  height: 100%;\n}\n\n.src-scripts-components-Navbar-__navbar___centerGroup {\n  flex: 1 1;\n  padding: 0 4rem;\n}\n\n.src-scripts-components-Navbar-__navbar___navbarMenuFixed {\n  display: none;\n  visibility: hidden;\n}\n\n.src-scripts-components-Navbar-__navbar___logoMovil {\n  display: none;\n  visibility: hidden;\n}\n\n@media(max-width: 768px) {\n  .src-scripts-components-Navbar-__navbar___navbar {\n    padding: 0 4rem;\n  }\n\n  .src-scripts-components-Navbar-__navbar___rightGroup, .src-scripts-components-Navbar-__navbar___leftGroup, .src-scripts-components-Navbar-__navbar___centerGroup {\n    display: none;\n  }\n\n  .src-scripts-components-Navbar-__navbar___navbarMenuFixed {\n    visibility: visible;\n    display: contents;\n  }\n\n  .src-scripts-components-Navbar-__navbar___logoMovil {\n    visibility: visible;\n    align-items: center;\n    display: flex !important;\n    justify-content: flex-start;\n    flex: 1 1;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"navbar": "src-scripts-components-Navbar-__navbar___navbar",
	"leftGroup": "src-scripts-components-Navbar-__navbar___leftGroup",
	"rightGroup": "src-scripts-components-Navbar-__navbar___rightGroup",
	"centerGroup": "src-scripts-components-Navbar-__navbar___centerGroup",
	"navbarMenuFixed": "src-scripts-components-Navbar-__navbar___navbarMenuFixed",
	"logoMovil": "src-scripts-components-Navbar-__navbar___logoMovil"
};