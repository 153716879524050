// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupFooter.css';


type PopupFooterPropTypes = {
  children?: Node,
  size?: string,
  align?: string,
  clearPaddings?: boolean,
  className?: String,
};

const PopupFooter = ({
  children,
  size = 'medium',
  align = 'right',
  clearPaddings = false,
  className = ''
}: PopupFooterPropTypes) => (
  <div className={
    `
      ${styles.popupFooter}
      ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
      ${typeof styles[align] !== 'undefined' ? styles[align] : ''}
      ${clearPaddings ? styles.clearPaddings : ''}
      ${className}
    `
  }
  >
    { children }
  </div>
);

export default PopupFooter;
