// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { ERROR_TYPE } from '../../types/common';
import { 
  TABLE_VIEW_TYPE,
  RESULTS_EXIT_PROFILE_IS_READY_KEY,
  RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY,
} from '../../settings';
import FullPlaceholder from '../ListPlaceholder';
import ExitProfileChart from '../ExitProfileChart';
import ExitProfileTable from '../ExitProfileTable';
import { FullLoader } from '../Loader';
import styles from './studyResultsExitProfile.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/resultsExitProfile';


type ResultPropsTypes = {
  isFetching: boolean,
  error: ERROR_TYPE,
  results: Array<Object>,
  isReady: boolean,
  lastUpdated: string,
  viewType: string,
};

const Results = ({
  results,
  isFetching,
  isReady,
  lastUpdated,
  error,
  viewType,
}: ResultPropsTypes) => (
  <Fragment>
    <div className={`${styles.row} ${styles.detail}`}>
      <p>
        <b>{'Última actualización: '}</b>
        {moment(lastUpdated).format('DD/MM/YY h:mm A').toString()}
      </p>
    </div>
    {
      (!isFetching && results.length) 
        && isReady && (
        <>
          {
            viewType === TABLE_VIEW_TYPE ? (
              <ExitProfileTable results={results}/>
            ) : (
              <div className={styles.contentChart}>
                <div className={styles.chartView}>
                  <ExitProfileChart results={results}/>
                </div>
              </div>
            )
          }
        </>
      )
    }
    {
      isFetching && (
        <div className={styles.fullView}>
          <FullLoader
            tint="mediumGray"
            title="Cargando resultados"
          />
        </div>
      )
    }
    {
      !isFetching && !isReady && (
        <FullPlaceholder
          replaceMessage={'Estamos calculando los resultados. Vuelve en unos minutos.'}
        />
      )
    }
    {
      !results.length && !isFetching && isReady && (
        <FullPlaceholder />
      )
    }
  </Fragment>
)

export default connect(
  (state, { study }) => {
    const resultsOrder = selectors.getStudyExitProfileResultsOrder(state, study) || [];
    const results = resultsOrder.map(id => selectors.getResultExitProfile(state, id));

    return ({
      results,
      isReady: selectors.getResultExitProfileExtraInfoKeyValue(state, RESULTS_EXIT_PROFILE_IS_READY_KEY),
      lastUpdated: selectors.getResultExitProfileExtraInfoKeyValue(state, RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY),
      isFetching: selectors.isFetchingResultsExitProfile(state),
      error: selectors.getResultsExitProfileError(state),
    })
  }
)(Results);
