exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/font.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, "\n.src-scripts-components-StudyHeader-__studyHeader___container {\n  align-items: center;\n  background-color: rgb(116 114 114);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  /* height: 40vh; */\n  padding: 2rem 0;\n  position: relative;\n}\n\n.src-scripts-components-StudyHeader-__studyHeader___name {\n  color: white;\n  font-size: 3rem;\n  font-weight: 600;\n  font-family: var(--primaryFont);\n  text-align: center;\n  text-decoration: underline;\n  padding: 0.5rem 0;\n}\n\n.src-scripts-components-StudyHeader-__studyHeader___detail {\n  display: flex;\n  flex-direction: row;\n  padding: 1rem 0;\n}\n\n.src-scripts-components-StudyHeader-__studyHeader___type {\n  color: #FFF;\n  display: flex;\n  align-items: center;\n  border-radius: var(--roundBorderRadius);\n  font-family: var(--secondaryFont);\n  font-size: 1.2rem;\n  font-weight: 700;\n  padding: 1rem 2rem;\n  /* height: 2rem; */\n  text-transform: uppercase;\n  background-color: var(--secondaryColor);\n  align-self: center;\n}\n\n.src-scripts-components-StudyHeader-__studyHeader___detailElement {\n  display: flex;\n  align-items: center;\n  font-size: 1.5rem;\n  color: white;\n  padding: 0 1.5rem;\n}\n\n.src-scripts-components-StudyHeader-__studyHeader___editButton {\n  position: absolute;\n  top: 5rem;\n  right: 8rem;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-StudyHeader-__studyHeader___detail {\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n  }\n}", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-StudyHeader-__studyHeader___container",
	"name": "src-scripts-components-StudyHeader-__studyHeader___name",
	"detail": "src-scripts-components-StudyHeader-__studyHeader___detail",
	"type": "src-scripts-components-StudyHeader-__studyHeader___type",
	"detailElement": "src-scripts-components-StudyHeader-__studyHeader___detailElement",
	"editButton": "src-scripts-components-StudyHeader-__studyHeader___editButton"
};