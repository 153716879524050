// @flow

const validate = (values) => {
  const errors = {};

  if (!values.first_name) { errors.first_name = 'Requerido'; }
  if (!values.last_name) { errors.last_name = 'Requerido'; }
  if (!values.location) { errors.location = 'Requerido'; }
  if (!values.sublocation) { errors.sublocation = 'Requerido'; }
  if (!values.community) { errors.community = 'Requerido'; }
  if (!values.group) { errors.group = 'Requerido'; }
  // CUI not required now, it easily can be null :) 
  // if (!values.cui) { errors.cui = 'Requerido'; }
  if (!values.age) { errors.age = 'Requerido'; }
  if (!values.years_living_in_community) { errors.years_living_in_community = 'Requerido'; }
  if (!values.ethnicity) { errors.ethnicity = 'Requerido'; }
  // if (!values.can_read) { errors.can_read = 'Requerido'; }
  if (values.alive_kids == null) {
    errors.alive_kids = 'Requerido';
  }

  if (!values.smallest_kid_age) { errors.smallest_kid_age = 'Requerido'; }
  if (!values.woman_state) { errors.woman_state = 'Requerido'; }
  // if (!values.is_husband_present) { errors.is_husband_present = 'Requerido'; }
  // if (!values.is_husband_acknowledged) { errors.is_husband_acknowledged = 'Requerido'; }

  return errors;
}

export default validate;