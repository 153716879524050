// @flow
import React, { Fragment } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';
import moment from 'moment';

import type { ERROR_TYPE } from '../../types/common';
import StudiesTable from '../StudiesTable';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import StudiesHeader from '../StudiesHeader';
import styles from './studiesList.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/studies';
import * as closeableActions from '../../actions/closeable';
import AddStudyPopup from '../AddStudyPopup';
import UpdateStudyPopup, { UPDATE_STUDY_POPUP } from '../UpdateStudyPopup';
import AddStudySubmissionPopup from '../AddStudySubmissionPopup';


type StudiesListPropTypes = {
  studies: Array<Object>,
  isFetching: boolean,
  error: ERROR_TYPE,
  hasMore: boolean,
  nextpage: number,
  isAdmin: Boolean,
  fetchStudies: Function,
  onStudyClick: Function,
  onEditClicked: Function,
  onCreateClick: Function,
};


class StudiesList extends React.Component<StudiesListPropTypes> {
  componentDidMount() {
    this.props.fetchStudies();
  }

  _handleLoadMore() {
    this.props.fetchStudies();
  }

  render() {
    const {
      studies=[],
      isFetching,
      // error,
      hasMore,
      onStudyClick,
      isAdmin,
      onEditClicked,
      onCreateClick,
    } = this.props;

    return (
      <div className={styles.listContainer}>
        <StudiesHeader />
        <div>
          {
            studies.length > 0 && (
              <Fragment>
                <StudiesTable
                  data={studies}
                  onStudyClick={onStudyClick}
                  isAdmin={isAdmin}
                  onEditClicked={onEditClicked}
                  onCreateClick={onCreateClick}
                />
                <Waypoint
                  onEnter={() => hasMore && this._handleLoadMore()}
                  viewportBottom={20}
                />
              </Fragment>
            )
          }
          {
            isFetching && (
              <FullLoader
                tint="mediumGray"
                title="Cargando estudios"
              />
            )
          }
          {
            studies.length === 0 && !isFetching && (
              <FullPlaceholder />
            )
          }
        </div>
        <AddStudyPopup />
        <AddStudySubmissionPopup />
        <UpdateStudyPopup />
      </div>
    )
  }
};

export default connect(
  state => {
    const data = selectors.getStudies(state);
    const isAdmin = selectors.getIsCurrentUserAdmin(state);

    const studies = data.map(el => ({
      id: el.id,
      name: el.name,
      type: el.study_type ? el.study_type.name : '',
      nameInstrument: `Instrumento: ${el.instrument.name}`,
      startDate: `Fecha de inicio: ${moment(el.start_date).format('L')}`,
      phases: `Mediciones: ${el.phases ? el.phases.length : 0}`,
      submissions: `Ingresos: ${el.submissions_count || 0}`
    }));

    return ({
      studies,
      isAdmin,
      isFetching: selectors.isFetchingStudies(state),
      error: selectors.getStudiesError(state),
      hasMore: selectors.hasMoreStudies(state),
    })
  },
  dispatch => ({
    fetchStudies() {
      dispatch(actions.startFetchStudies());
    },
    onStudyClick(id) {
      dispatch(push(`/studies/${id}`));
    },
    onEditClicked(id) {
      dispatch(actions.selectStudy(id));
      dispatch(actions.startFetchStudy(id, true));
      dispatch(closeableActions.open(UPDATE_STUDY_POPUP));
    },
    onCreateClick(id) {
      dispatch(actions.selectStudy(id));
      dispatch(actions.startFetchStudy(id, true));
      dispatch(closeableActions.open('addStudySubmission'));
    }
  })
)(StudiesList);
