// @flow
import type { Node, ComponentType } from 'react';
import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';


type ClosePopupProviderPropTypes = {
  closePopup: () => void,
  children?: Node
};

export class ClosePopupProvider extends Component<ClosePopupProviderPropTypes> {
  static propTypes = {
    closePopup: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    closePopup: PropTypes.func.isRequired,
  };

  getChildContext() {
    const { closePopup } = this.props;
    return { closePopup };
  }

  render() {
    const { children } = this.props;
    return Children.only(children);
  }
}

/* eslint react/prefer-stateless-function: 0 */
export const withClosePopup = (WrappedComponent: ComponentType<*>) => (
  class WithClosePopupComponent extends Component<*> {
    static contextTypes = {
      closePopup: PropTypes.func.isRequired,
    }

    render() {
      const { closePopup } = this.context;

      return (
        <WrappedComponent
          {...this.props}
          closePopup={closePopup}
        />
      );
    }
  }
);
