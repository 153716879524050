// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/lib/md';
import debounce from 'lodash/debounce';

import Input from '../Input';

import * as actions from '../../actions/participants';
import styles from './studyParticipants.css';


type SearchBarPropTypes = {
  updateFilters: Function
};


export class SearchBar extends Component<SearchBarPropTypes> {
  constructor(props: SearchBarPropTypes) {
    super(props);

    this._debouncedMethod = debounce(this._onInputChange, 300);
  }

  componentDidMount() {
    this._debouncedMethod('');
  }

  _onInputChange(text) {
    this.props.updateFilters('text', text);
  };

  render() {
    return (
      <div className={styles.search}>
        <Input
          isRound
          clearMargins
          Icon={MdSearch}
          placeholder="Realiza tu búsqueda aquí"
          onChange={(e) => {
            const text = e.target.value;
            this._debouncedMethod(text);
          }}
        />
      </div>
    )
  }
};

export default connect(
  undefined,
  dispatch => ({
    updateFilters(key, value) {
      dispatch(actions.updateStudyParticipantsFilters(key, value));
      dispatch(actions.startFetchStudyParticipants());
    } 
  })
)(SearchBar);
