// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdMenu } from 'react-icons/lib/md';

import Button from '../../Button';
import styles from '../navbar.css';

import * as actions from '../../../actions/menuFixed';


const NavbarMenuFixed = ({
  showMenuFixed
}) => (
  <Button
    Icon={MdMenu}
    isTransparent
    clearMargins
    className={styles.navbarMenuFixed}
    onClick={() => showMenuFixed()}
  />
);


export default connect(
  undefined,
  dispatch => ({
    showMenuFixed() {
      dispatch(actions.showMenuFixed());
    }
  })
)(NavbarMenuFixed);
