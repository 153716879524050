// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import { submit, change, initialize } from 'redux-form';
import moment from 'moment';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import {
  ADD_KID_STARTED,
  FETCH_KIDS_STARTED,
  FETCH_KID_FORM_DATA_STARTED,
} from '../types/kids';
import { Kids } from '../api';
import { arrayOfKids } from '../api/schemas/kids';

import * as selectors from '../reducers';
import * as actions from '../actions/kids';


function* fetchKids(action) {
  try {
    const token = yield select(selectors.getToken);
    const page = yield select(selectors.getKidsNextPage);
    const filters = yield select(selectors.getKidsFilters);

    const nextPage = page != null ? page : 1;

    const { kidsResponse, timeout } = yield race({
      kidsResponse: call(
        [Kids, 'list'],
        {
          token,
          filters: {
            page: nextPage,
            page_size: 15,
            ...filters
          }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const {
      entities: {
        kids,
      },
      result,
    } = normalize(kidsResponse.results, arrayOfKids);

    yield put(actions.completeFetchKids(kids, result, kidsResponse.next));
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchKids({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}

function* addKid(action) {
  try {
    const kid = action.payload;
    const token = yield select(selectors.getToken);

    const { kidResponse, timeout } = yield race({
      kidResponse: call(
        [Kids, 'create'],
        {
          token,
          data: { ...kid }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    yield put(actions.completeAddKid(kid.id, kidResponse.id));

    yield put(change('addInstrumentSubmission', 'participant', kidResponse.mother));
    yield put(change('addInstrumentSubmission', 'kid', kidResponse.id));
    yield put(submit('addInstrumentSubmission'));

    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failAddKid({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchKidDataForm(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;

    const { response, timeout } = yield race({
      response: call(
        [Kids, 'detail'],
        {
          token,
          id,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const selectedStudy = yield select(selectors.getSelectedStudy) || {};
    const {
      locations = [],
      sublocations = [],
      organizations = [],
    } = selectedStudy.organizations;

    const organization = organizations.find(el => el.organization == response.organization);
    const sublocation = sublocations.find(el => el.id == organization.sublocation_id);
    const location = locations.find(el => el.id == organization.location_id);

    yield put(initialize('addKidForm', {
      ...response,
      location: location.id,
      sublocation: sublocation.id,
      birthdate: moment(response.birthdate).toDate(),
      kid: response.id,
    }));
    yield put(actions.completeFetchKidDataForm(response));


  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchKidDataForm({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}

export function* watchAddKid(): Iterator<any> {
  yield takeEvery(
    ADD_KID_STARTED,
    addKid,
  );
};

export function* watchFetchKids(): Iterators<any> {
  yield takeEvery(
    FETCH_KIDS_STARTED,
    fetchKids,
  )
};

export function* watchFetchKidFormData(): Iterator<any> {
  yield takeEvery(
    FETCH_KID_FORM_DATA_STARTED,
    fetchKidDataForm,
  );
}
