// @flow
import type { ID_TYPE } from '../types/common';

import type {
  KID_TYPE,
  ADD_KID_STARTED_TYPE,
  ADD_KID_COMPLETED_TYPE,
  FETCH_KIDS_STARTED_TYPE,
  FETCH_KIDS_COMPLETED_TYPE,
  KIDS_FILTERS_UPDATED_TYPE,
  KID_SELECTED_TYPE,
  ALL_KIDS_UNSELECTED_TYPE,
  KID_UPDATED_TYPE,
  FETCH_KID_FORM_DATA_STARTED_TYPE,
  FETCH_KID_FORM_DATA_COMPLETED_TYPE,
} from '../types/kids';

import * as types from '../types/kids';
import { error } from './common';


export const startAddKid = (kid: KID_TYPE): ADD_KID_STARTED_TYPE => ({
  type: types.ADD_KID_STARTED,
  payload: kid,
});

export const completeAddKid = (oldId: ID_TYPE, newId: ID_TYPE): ADD_KID_COMPLETED_TYPE => ({
  type: types.ADD_KID_COMPLETED,
  payload: {
    oldId,
    newId,
  },
});

export const failAddKid = error(types.ADD_KID_FAILED);

export const startFetchKids = (
): FETCH_KIDS_STARTED_TYPE => ({
  type: types.FETCH_KIDS_STARTED,
  payload: {}
});

export const completeFetchKids = (
  entities: {[number]: KID_TYPE},
  order: Array<ID_TYPE>,
  nextPage: number,
) => ({
  type: types.FETCH_KIDS_COMPLETED,
  payload: {
    entities,
    order,
    nextPage,
  }
});

export const failFetchKids = error(types.FETCH_KIDS_FAILED);
export const updateKidsFilters = (
  key: string,
  value: any,
):KIDS_FILTERS_UPDATED_TYPE => ({
  type: types.KIDS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const selectKid = (id: ID_TYPE) => ({
  type: types.KID_SELECTED,
  payload: id,
});

export const unselectKids = () => ({
  type: types.ALL_KIDS_UNSELECTED,
  payload: {},
});

export const updateKid = (kid: KID_TYPE) => ({
  type: types.KID_UPDATED,
  payload: kid
});

export const startFetchKidDataForm = (id: ID_TYPE) => ({
  type: types.FETCH_KID_FORM_DATA_STARTED,
  payload: id,
});

export const completeFetchKidDataForm = (kid: KID_TYPE) => ({
  type: types.FETCH_KID_FORM_DATA_COMPLETED,
  payload: kid
});

export const failFetchKidDataForm = error(types.FETCH_KID_FORM_DATA_FAILED);
