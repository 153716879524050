// @flow

export type MENU_FIXED_TYPE = {
  isShow: boolean,
};

export type MENU_FIXED_SHOWED_TYPE = {
  type: 'MENU_FIXED_SHOWED'
};
export const MENU_FIXED_SHOWED = 'MENU_FIXED_SHOWED';

export type MENU_FIXED_HIDDEN_TYPE= {
  type: 'MENU_FIXED_HIDDEN',
};
export const MENU_FIXED_HIDDEN = 'MENU_FIXED_HIDDEN';


export type MENU_FIXED_ACTION_TYPE = 
  | MENU_FIXED_SHOWED_TYPE
  | MENU_FIXED_HIDDEN_TYPE;
