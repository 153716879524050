// @flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { SUBLOCATION_TYPE } from '../types/sublocations';
import * as common from './common';
import * as types from '../types/sublocations';


export type SublocationsState = {
  byId: { [ID_TYPE]: SUBLOCATION_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: ID_TYPE,
};

const byId = common.byId({
  fetched: [types.SUBLOCATIONS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.SUBLOCATIONS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.SUBLOCATIONS_FETCH_STARTED],
  succeed: [types.SUBLOCATIONS_FETCH_COMPLETED],
  failed: [types.SUBLOCATIONS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.SUBLOCATIONS_FETCH_STARTED,
    types.SUBLOCATIONS_FETCH_COMPLETED,
  ],
  populate: [types.SUBLOCATIONS_FETCH_FAILED],
});

const selected = common.mux({
  selected: [types.SUBLOCATION_SELECTED],
  allDeselected: [],
  default: -1,
});

const sublocations = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
});

export default sublocations;

export const getSublocation = (
  state: SublocationsState,
  id: ID_TYPE): SUBLOCATION_TYPE => state.byId[id];
export const getSublocations = (state: SublocationsState) => state.order.map(id => state.byId[id]);
export const isFetchingSublocations = (state: SublocationsState): boolean => state.isFetching;
export const getSublocationsError = (state: SublocationsState): ERROR_TYPE => state.error;
export const getSelectedSublocation = (state: SublocationsState): SUBLOCATION_TYPE => state.byId[state.selected];
