// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import Button from '../Button';
import Input from '../Input';

import type { ID_TYPE } from '../../types/common';
import OrganizationGraphics from '../OrganizationGraphics';

import styles from './orgGraphicsContent.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/anthropometryResults';

type OrganizationGraphicsContentPropTypes = {
  onLoad: Function,
  organization: Object,
  study: ID_TYPE,
  isFetching: boolean,
  results: Array<Object>
};


const OrganizationGraphicsContent = ({
  onLoad,
  organization,
  study,
  isFetching,
  results
}: OrganizationGraphicsContentPropTypes) => {

  const  [initialMonth, setInitialMonth] = useState(0);
  const  [finalMonth, setFinalMonth] = useState(0);

  useEffect(() => {
    onLoad(study, organization.id);
  }, []);

  const onFilterMonth = () => {
    onLoad(study, organization.id, initialMonth, finalMonth)
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>
          {"Gráficas de comunidad"}
        </span>
        <div className={styles.separator} />
        <div className={`
            ${styles.row}
            ${styles.monthFilters}
        `}>
          <span className={styles.monthFiltersTitle}>Edades: </span>
          <span>De </span>
          <div className={styles.monthInputContainer}>
            <Input
              className={styles.monthInput}
              type="number"
              clearMargins
              onChange={(e) => {
                setInitialMonth(parseInt(e.target.value, 10));
              }}
            />
            <span className={styles.months}>Meses</span>
          </div>
          <span>Hasta </span>
          <div className={styles.monthInputContainer}>
            <Input
              className={styles.monthInput}
              type="number"
              clearMargins
              onChange={(e) => {
                setFinalMonth(parseInt(e.target.value, 10));
              }}
            />
            <span className={styles.months}>Meses</span>
          </div>
          <Button
            className={styles.applyButton}
            type="button"
            tint="primary"
            title="Aplicar"
            isRound
            onClick={() => onFilterMonth()}
          />
        </div>
      </div>
      <OrganizationGraphics
        loading={isFetching}
        data={results}
        name={organization.name}
        olderChildrenFilters={
          initialMonth !== null && initialMonth > 24
        }
      />
    </div>
  )
};


export default connect(
  state => {
    const organization = selectors.getSelectedOrganization(state) || {};
    const { id } = selectors.getSelectedStudy(state);

    return ({
      organization,
      study: id,
      results: selectors.getOrganizationAnthropometryResults(state, organization.id),
      isFetching: selectors.isFetchingAnthropometryResults(state),
    });
  },
  dispatch => ({
    onLoad(study, organization, initial_month_age = null, final_month_age = null) {
      const filters = pickBy({ organization, initial_month_age, final_month_age }, identity);
      dispatch(actions.startFetchAnthropometryResults(study, filters));
    }
  })
)(OrganizationGraphicsContent);
