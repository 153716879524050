// @flow
import { INDICATOR_COLORS } from './colors';

type GenSelectorsType = {
  selectors: {},
  stateKey: string,
  excluded?: Array<string>
};

// Sum arrays of different size
export const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
}

export const arrayReplace = (
  list: Array<mixed>,
  element: any,
  newElement: any,
): Array<mixed> => list.map(e => (e === element ? newElement : e));

export const listToMatrix = (list, elementsPerSubArray) => {
  var matrix = [], i, k;
  for (i = 0, k = -1; i < list.length; i++) {
      if (i % elementsPerSubArray === 0) {
          k++;
          matrix[k] = [];
      }
      matrix[k].push(list[i]);
  }
  return matrix;
}

export const genSelector = (
  selector: Function,
  stateKey: string,
) => (state: Object, ...args: Array<any>) => selector(state[stateKey], ...args);

export const genSelectors = ({ selectors, stateKey, excluded = [] }: GenSelectorsType) => {
  const wSelectors = {};
  Object.keys(selectors).filter(
    selectorName => selectorName !== 'default'
      && !excluded.includes(selectorName),
  ).forEach((selectorName) => {
    wSelectors[selectorName] = (
      state,
      ...args
    ) => selectors[selectorName](state[stateKey], ...args);
  });

  return wSelectors;
};

export const onEnter = (fn: Function, nfn: Function = _ => _): Function => (e: any) => {
  if (e.key === 'Enter') {
    return fn(e);
  }

  return nfn(e);
};

export function flattenMessages(nestedMessages: Object, prefix: string = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

export const generateChoices = (
  choices: Array<string>,
  getLabel?: Function, 
  getValue?: Function,
) => choices.map(el => ({
  value: getValue != null ? getValue(el) : el.id,
  label: getLabel != null ? getLabel(el) : el,
}));

export const getRandomColor = () => {
  const x =  Math.floor((Math.random() * INDICATOR_COLORS.length) + 1);
  return INDICATOR_COLORS[x];
};

export const getFileExtension = (file: string) => {
  const splitted = file.split('.');

  return splitted[splitted.length - 1];
}

export const getFileName = (file: string) => {
  const splitted = file.split('.');

  return splitted.length <= 1 ? file : splitted.slice(0, splitted.length - 1).join('');
}

export const normalize = (data: any[], id: any = 'id'): Object => {
  var ret = {};

  data.forEach((item) => {
    if(item[id]) {
      ret[item[id]] = item; 
    }
  });

  return ret;
}
