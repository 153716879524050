// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Popup from '../Popup';
import AddStudySubmissionContent from './addStudySubmissionContent';

import * as selectors from '../../reducers';


type AddStudySubmissionPopupPropTypes = {
  instrument: Object,
};

const AddStudySubmissionPopup = ({
  instrument = {},
  fetchStudyInstrument,
}: AddStudySubmissionPopupPropTypes) => (
  <Popup
    id="addStudySubmission"
    theme="main"
    transitionMs={600}
    dontCloseOnEscape
  >
    <AddStudySubmissionContent
      id={instrument.id}
      instrumentType={instrument.type}
    />
  </Popup>
);


export default connect(
  state => {
    const study = selectors.getSelectedStudy(state) || {};
    const { instrument } = study;

    return ({
      instrument: instrument,
    })
  },
)(AddStudySubmissionPopup);
