exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-ParticipantForm-__participantForm___section {\n  display: flex;\n  flex-direction: column;\n  padding: 3rem;\n  border-bottom: 1px solid var(--mediumGrayColor);\n}\n\n.src-scripts-components-ParticipantForm-__participantForm___row {\n  display: flex;\n  flex-direction: row;\n}\n\n.src-scripts-components-ParticipantForm-__participantForm___col {\n  flex: 1 1;\n  padding: 1rem;\n}\n\n.src-scripts-components-ParticipantForm-__participantForm___form {\n  margin-bottom: 8rem;\n}\n\n.src-scripts-components-ParticipantForm-__participantForm___separator {\n  border-bottom: 1px solid var(--mediumGrayColor);\n  margin-top: 18rem;\n  margin-bottom: 1rem;\n  position: relative;\n  text-align: center;\n}\n.src-scripts-components-ParticipantForm-__participantForm___separator span {\n  position: absolute;\n  color: var(--almostGrayColor);\n  font-size: 2rem;\n  font-weight: 800;\n  bottom: -3.2rem;\n  background: #fff;\n  padding: 2rem 3rem;\n  -webkit-transform: translateX(-9rem);\n          transform: translateX(-9rem);\n}\n\n@media (max-width: 768px) {\n  .src-scripts-components-ParticipantForm-__participantForm___col {\n    min-width: 36rem;\n  }\n\n  .src-scripts-components-ParticipantForm-__participantForm___row {\n    flex-wrap: wrap;\n    display: inline;\n    display: initial;\n  }\n\n  .src-scripts-components-ParticipantForm-__participantForm___section {\n    padding: 2rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"section": "src-scripts-components-ParticipantForm-__participantForm___section",
	"row": "src-scripts-components-ParticipantForm-__participantForm___row",
	"col": "src-scripts-components-ParticipantForm-__participantForm___col",
	"form": "src-scripts-components-ParticipantForm-__participantForm___form",
	"separator": "src-scripts-components-ParticipantForm-__participantForm___separator"
};