// @flow
import React from 'react';

import styles from './tag.css';

export type TagPropTypes = {
  value: value,
  tint: string,
};


class Tag extends React.Component<TagPropTypes> {
  render() {
    const { value, tint = 'yellow' } = this.props;
    return (
      <div className={`${styles.tag} ${styles[tint]}`}>
        {value}
      </div>
    );
  }
}

export default Tag;
