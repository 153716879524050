// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';
import { submit } from 'redux-form';

import { ID_TYPE } from '../../types/common';
import { UPDATE_ACTION_FORM } from '../../settings';
import Button from '../Button';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import InstrumentSubmissionForm from '../InstrumentSubmissionForm';
import { FullLoader } from '../Loader';

import styles from './updateStudySubmission.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/instruments';
import * as locationsActions from '../../actions/locations';
import * as sublocationsActions from '../../actions/sublocations';
import * as organizationsActions from '../../actions/organizations';
import * as instrumentSubmissionsActions from '../../actions/instrumentSubmissions';


type UpdateStudySubmissionContentType = {
  instrumentId: ID_TYPE,
  onLoad: Function,
  loading: boolean,
  isSubmitting: boolean,
  clearForm: Function,
  submitForm: Function,
};

const UpdateStudySubmissionContent = ({
  instrumentId,
  onLoad,
  loading,
  isSubmitting,
  clearForm,
  submitForm,
}: UpdateStudySubmissionContentType) => {
  useEffect(() => {
    onLoad();
    return clearForm();
  }, []);

  return (
    <PopupContainer isFullscreen>
      <div className={
        `${styles.loader} ${!loading ? styles.hidden : ''}`
      }>
        <FullLoader size="large" title="Cargando datos..."/>
      </div>
      <div className={styles.close}>
        <Button
          tint="primary"
          className={styles.approveButton}
          isLoading={isSubmitting}
          title="Guardar"
          isRound
          onClick={() => submitForm()}
        />
        <PopupCloseButton
          Icon={MdClose}
          tint="mediumGray"
          isRound={true}
        />
      </div>
      <div className={styles.content}>
        <InstrumentSubmissionForm
          id={instrumentId}
          action={UPDATE_ACTION_FORM}
          globalFormName="addInstrumentSubmission"

        />
      </div>
    </PopupContainer>
  );
}

export default connect(
  (state, ownProps) => ({
    instrumentId: ownProps.instrument,
    loading: selectors.isInstrumentSubmissionFetching(state, ownProps.submission),
    isSubmitting: selectors.isSubmittingInstrument(state)
    || selectors.isAddingKid(state)
    || selectors.isAddingUser(state),
  }),
  (dispatch, ownProps) => ({
    onLoad() {
      const { instrument, submission } = ownProps;

      // Fetch form description
      dispatch(actions.startFetchInstrumentQuestions(instrument));

      // Fetch organzations data
      dispatch(locationsActions.startFetchLocations());
      dispatch(sublocationsActions.startFetchSublocations());
      dispatch(organizationsActions.startFetchOrganizations());

      // Fetch initial values
      dispatch(instrumentSubmissionsActions.startFetchInstrumentSubmission(submission));
      
    },
    clearForm() {
      dispatch(instrumentSubmissionsActions.clearErrorRequiredValues());
    },
    submitForm() {
      dispatch(submit('addInstrumentSubmission'));
    }
  })
)(UpdateStudySubmissionContent);
