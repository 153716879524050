// @flow
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import InstrumentSubmissionReduxForm from './formCreator';

import * as selectors from '../../reducers';
import * as fobiServices from '../FobiRenderer/services';


const FormDummy = ({
  id,
  validator,
  action
}) => (
  <InstrumentSubmissionReduxForm
    id={id}
    action={action}
    validator={validator}
  />
);

const InstrumentFormDecorator = connect(
  (state, { id, action, globalFormName}) => {
    const questionsSets = selectors.getInstrumentQuestionsSets(state, id);
    const formDescription = questionsSets.map(({
      id,
    }) => selectors.getQuestionsSetFormDescription(state, id));
    const { type } = selectors.getInstrument(state, id) || {};

    let generalFormDescription = { form_elements: [] };

    formDescription.map(el => {
      generalFormDescription = {
        form_elements: [
          ...generalFormDescription.form_elements,
          ...el.form_elements,
        ]
      };
    });
    const formValues = getFormValues(globalFormName)(state);
    const validator = fobiServices.generateValidator(generalFormDescription, type, formValues);
    return {
      id,
      validator,
      action,
    }
  }
)(FormDummy);


export default InstrumentFormDecorator;

