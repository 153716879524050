// @flow
import React from 'react';

import styles from './navbarSeparator.css';


const NavbarSeparator = () => <div className={styles.navbarSeparator} />;


export default NavbarSeparator;
