// @flow
// import React from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../../reducers';
import User from '../../User';


export default connect(
  (state) => {
    const {
      first_name,
      last_name,
      email,
      profile_picture,
    } = selectors.getCurrentUser(state) || {};

    return {
      pictureURL: profile_picture,
      firstName: first_name,
      lastName: last_name,
      userRole: email,
      size: 'small',
      theme: 'navbar',
    };
  },
)(User);
