// @flow
import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/lib/md';

import DataTable from '../DataTable';
import TextColumn from '../DataTable/Columns/Text';
import ButtonColumn from '../DataTable/Columns/Button';


type SubmissionsTablePropTypes = {
  data: Array<Object>,
  showActions: boolean,
  isOfflineQueue: boolean,
  isAnthropometricInstrument: boolean,
  onDelete: Function,
  onUpdate: Function,
};


const SubmissionsTable = ({
  data = [],
  showActions = true,
  isOfflineQueue = false,
  isAnthropometricInstrument = false,
  onDelete,
  onUpdate,
}: SubmissionsTablePropTypes) => {

  const actionHeaders = [
    ButtonColumn({
      Header: '',
      accessor: 'edit',
      buttonProps: {
        Icon: MdEdit,
        size: 'small',
        tint: 'gray',
        isTransparent: true,
      },
      maxWidth: 50,
      getOnClick: (params: { id: number }) => () => {
        onUpdate(params.id);
      },
    }),
    ButtonColumn({
      Header: '',
      accessor: 'delete',
      buttonProps: {
        Icon: MdDelete,
        size: 'small',
        tint: 'gray',
        isTransparent: true,
      },
      maxWidth: 50,
      getOnClick: (params: { id: number }) => () => {
        onDelete(params.id);
      },
    }),
  ]
  
  const anthropometryHeaders = [
    TextColumn({
      Header: 'Niño',
      accessor: 'kid_name',
      editable: false,
      minWidth: 150,
    })
  ]

  return (
    <DataTable
      data={data}
      columns={[
        (!isOfflineQueue && TextColumn({
          Header: 'ID',
          accessor: 'id',
          editable: false,
          maxWidth: showActions ? 50 : 120,
        })),
        TextColumn({
          Header: 'Fecha',
          accessor: 'date',
          editable: false,
          maxWidth: 120,
        }),
        TextColumn({
          Header: 'Hora',
          accessor: 'time',
          editable: false,
          maxWidth: 60,
        }),
        TextColumn({
          Header: 'Subido por',
          accessor: 'user',
          editable: false,
          minWidth: 100,
        }),
        (!isOfflineQueue && TextColumn({
          Header: 'Participante',
          accessor: 'participant_name',
          editable: false,
          minWidth: 150,
        })),
        ...(!isOfflineQueue && isAnthropometricInstrument) ? anthropometryHeaders : [],
        TextColumn({
          Header: 'Comunidad',
          accessor: 'organization',
          editable: false,
        }),
        TextColumn({
          Header: 'Departamento',
          accessor: 'location',
          editable: false,
          minWidth: 130,
          // maxWidth: 120,
        }),
        TextColumn({
          Header: 'Municipio',
          accessor: 'sublocation',
          editable: false,
          minWidth: 140,
          // maxWidth: 120,
        }),
        ...showActions ? actionHeaders : []
      ]}
      minRows={0}
      showPagination={false}
      defaultPageSize={99999}
    />
  )
};

export default SubmissionsTable;

