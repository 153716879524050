// @flow
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  CHART_VIEW_TYPE,
  TABLE_VIEW_TYPE,
  RESULTS_EXIT_PROFILE_IS_READY_KEY,
  RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY,
} from '../../settings';
import { INSTRUMENT_FILTERS_ID } from '../PopupInstrumentFilters';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import ToggleView from '../ToggleView';
import ChartsView from '../ExitProfileChart';
import TableView from '../ExitProfileTable';
import Button from '../Button';
import styles from './instrumentExitProfileResults.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/resultsExitProfile';
import * as closeableActions from '../../actions/closeable';


type ExitProfileResultsPropTypes = {
  results: Array<Object>,
  instrument: Object,
  isFetching: boolean,
  isReady: boolean,
  lastUpdated: string,
  error: Object,
  onLoad: Function,
  openMoreFilters: Function,
};

const IndicatorsResults = ({
  results,
  instrument,
  isFetching,
  isReady,
  lastUpdated,
  error,
  onLoad,
  openMoreFilters,
}: ExitProfileResultsPropTypes) => {
  const [ viewType, setViewType ] = useState(TABLE_VIEW_TYPE);

  useEffect(() => {
    onLoad(instrument.id);
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.spaced}`}>
        <Button
          title="Más filtros"
          size='small'
          tint='mediumGray'
          onClick={() => openMoreFilters()}
          className={styles.approveButton}
        />
        <ToggleView
          value={viewType}
          onChange={val => setViewType(val)}
        />
      </div>
      <div className={`${styles.row} ${styles.detail}`}>
        <p>
          <b>{'Última actualización: '}</b>
          {moment(lastUpdated).format('DD/MM/YY h:mm A').toString()}
        </p>
      </div>
      <div className={styles.content}>
        {
          (!isFetching && results.length)
            && isReady && (
            <> 
              {
                viewType === TABLE_VIEW_TYPE
                ? <TableView results={results}/>
                : (
                  <div className={styles.chartContainer}>
                    <div className={styles.charElement}>
                      <ChartsView results={results}/>
                    </div>
                  </div>
                )
              }
            </>
          )
        }
        {
          isFetching && (
            <FullLoader
              tint="mediumGray"
              title="Cargando resultados"
            />
          )
        }
        {
          !isFetching && !isReady && (
            <FullPlaceholder
              replaceMessage={'Estamos calculando los resultados. Vuelve en unos minutos.'}
            />
          )
        }
        {
          !results.length && !isFetching && isReady && (
            <FullPlaceholder />
          )
        }
      </div>
    </div>
  )
};

export default connect(
  state => ({
    results: selectors.getResultsExitProfile(state) || [],
    isReady: selectors.getResultExitProfileExtraInfoKeyValue(state, RESULTS_EXIT_PROFILE_IS_READY_KEY),
    lastUpdated: selectors.getResultExitProfileExtraInfoKeyValue(state, RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY),
    instrument: selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state),
    isFetching: selectors.isFetchingResultsExitProfile(state),
    error: selectors.getResultsExitProfileError(state),
  }),
  dispatch => ({
    onLoad(id) {
      dispatch(actions.startFetchInstrumentResultsExitProfile(id));
    },
    openMoreFilters() {
      dispatch(closeableActions.open(INSTRUMENT_FILTERS_ID));
    },
  })
)(IndicatorsResults);
