// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupHeaderLeftGroup.css';


type PopupHeaderLeftGroupPropTypes = {
  children?: Node,
  padding?: string,
};

const PopupHeaderLeftGroup = ({
  children,
  padding='nonePadding'
}: PopupHeaderLeftGroupPropTypes) => (
  <div className={`
    ${styles.popupHeaderLeftGroup}
    ${styles[padding]}
  `}>
    { children }
  </div>
);

export default PopupHeaderLeftGroup;
