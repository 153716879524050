// @flow
import React from 'react';

import Input from '../Input';
import styles from './dataTable.css';

type FilterPropTypes = {
  filter: {
    value?: string,
  },
  onChange: Function,
  column: {
    Placeholder: string,
  },
  className: string,
};

const Filter = ({
  filter,
  onChange,
  column,
  className = '',
  ...rest
}: FilterPropTypes) => (
  <Input
    type="text"
    size="small"
    // style={{ width: '100%' }}
    placeholder={column.Placeholder}
    value={filter ? filter.value : ''}
    onChange={event => onChange(event.target.value)}
    className={`${styles.filter} ${className}`}
    {...rest}
    clearMargins
    isSquare
  />
);

export default Filter;
