// @flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { KID_TYPE, KIDS_ACTION_TYPE } from '../types/kids';
import * as common from './common';
import * as types from '../types/kids';


export type KidsState = {
  byId: { [ID_TYPE]: KID_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  fetching: Array<ID_TYPE>,
  error: ERROR_TYPE,
  selected: ID_TYPE,
  filters: Object,
  nextPage: number,
  adding: boolean,
};

const byId = common.byId({
  added: [
    types.ADD_KID_STARTED,
    // types.FETCH_KID_FORM_DATA_COMPLETED,
  ],
  confirmed: [types.ADD_KID_COMPLETED],
  fetched: [types.FETCH_KIDS_COMPLETED],
  updated: [types.KID_UPDATED]
});

const order = common.order({
  added: [types.ADD_KID_STARTED],
  confirmed: [types.ADD_KID_COMPLETED],
  fetched: [types.FETCH_KIDS_COMPLETED],
  cleared: [types.KIDS_FILTERS_UPDATED],
  preferPrepend: true,
});

const isFetching = common.isFetching({
  started: [types.FETCH_KIDS_STARTED],
  succeed: [types.FETCH_KIDS_COMPLETED],
  failed: [types.ADD_KID_FAILED],
});

const adding = common.isFetching({
  started: [types.ADD_KID_STARTED],
  succeed: [types.ADD_KID_COMPLETED],
  failed: [types.ADD_KID_FAILED],
});

const fetching = common.fetching({
  started: [types.FETCH_KID_FORM_DATA_STARTED],
  succeed: [types.FETCH_KID_FORM_DATA_COMPLETED],
  failed: [types.FETCH_KID_FORM_DATA_FAILED],
});

const error = common.error({
  clear: [
    types.FETCH_KIDS_STARTED,
    types.FETCH_KIDS_COMPLETED,
    types.ADD_KID_STARTED,
    types.ADD_KID_COMPLETED,
  ],
  populate: [
    types.ADD_KID_FAILED,
    types.FETCH_KIDS_FAILED,
  ],
});

const selected = common.mux({
  selected: [types.KID_SELECTED],
  allDeselected: [types.ALL_KIDS_UNSELECTED],
  default: -1,
});

const filters = (
  state: Object = {},
  action: STUDY_PARTICIPANTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.KIDS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      }
    }
    default: {
      return state;
    }
  }
};

const nextPage = common.nextPage({
  fetched: [types.FETCH_KIDS_COMPLETED],
  failed: [types.FETCH_KIDS_FAILED],
  reset: [types.KIDS_FILTERS_UPDATED],
});

const kids = combineReducers({
  byId,
  order,
  isFetching,
  fetching,
  error,
  selected,
  filters,
  nextPage,
  adding
});

export default kids;

export const getKid = (state: KidsState, id: ID_TYPE): KID_TYPE => state.byId[id];
export const getKids = (state: KidsState): Array<KID_TYPE> => state.order.map(id => getKid(state, id));

export const getKidsFilter = (state: KidsState, filter: string): string => state.filters[filter];
export const getkidsFilters = (state: KidsState): Object => state.filters;
export const isFetchingKids = (state: KidsState): boolean => state.isFetching;
export const getKidsNextPage = (state: KidsState): ?number => state.nextPage;
export const hasMoreKids = (state: KidsState): boolean => {
  return (state.nextPage != null) && !state.isFetching && state.nextPage !== -1;
};
export const getKidSelected = (state: KidsState): KID_TYPE => getKid(state, state.selected);
export const isAddingKid = (state: KidsState): boolean => state.creating;
export const isFetchingKid = (state: KidsState, id: ID_TYPE): boolean => state.fetching.includes(id);
