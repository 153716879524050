// @flow
import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import * as selectors from '../../reducers';
import { Users } from '../../api/index';
import TypeheadLoadingResults from '../TypeheadLoadingResults';
import {
  UserRenderResult,
} from '../ParticipantTypeahead/customSelectComponents';
import { RenderTaggedInput } from '../FieldRenderer';

import styles from './usersTaggedTypeahead.css';


type UsersTaggedTypeaheadPropTypes = {
  fetchItems: string => Promise<Array<Object>>,
  title: string,
};

const UsersTaggedTypeahead = ({
  fetchItems,
  title = '',
}: UsersTaggedTypeaheadPropTypes) => (
  <div className={styles.usersTaggedTypeaheadContainer}>
    <div className={styles.title}>
      {title}
    </div>
    <div className={styles.usersTaggedTypeahead}>
      <Field
        name="users"
        className={styles.tags}
        // containerClassName={styles.tags}
        fetchItems={fetchItems}
        inputDebounce={300}
        renderLoading={() => <TypeheadLoadingResults size="small" count={3} />}
        renderItem={
          (item, isHighlighted) => (
            <UserRenderResult
              {...item}
              isSelected={isHighlighted}
              key={item.value}
              size="small"
            />
          )
        }
        inputProps={{
          isClearContained: true,
          clearMargins: true,
        }} 
        component={RenderTaggedInput}
        size="small"
      />
    </div>
  </div>
)

const transformResults = (response) => (
  response.map((result) => ({
    ...result,
    firstName: result.first_name,
    lastName: result.last_name,
    username: result.username,
    profilePicture: result.profile_picture,
    label: `${result.first_name} ${result.last_name}`,
    textIndex: `${result.first_name} ${result.last_name}`,
    value: result.id,
    object_id: result.id,
    result_type: result.type,
    base_model_id: result.id,
  }))
);

export default connect(
  (state) => {
    const token = selectors.getToken(state);
    const fetchItems = (text: string) => Users.list({
      token,
      filters: {
        text,
      },
    }).then(transformResults);

    return {
      fetchItems
    };
  },
)(UsersTaggedTypeahead);
