// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdClearAll } from 'react-icons/lib/md';
import includes from 'lodash/includes';

import Button from '../Button';
import styles from './buttonClearFilters.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/instruments';
import * as resultsStoplightActions from '../../actions/results';
import * as resultsIndicatorsActions from '../../actions/indicatorResults';
import * as resultsActions from '../../actions/studyResults';
import * as resultsExitProfile from '../../actions/resultsExitProfile';


type ButtonClearFiltersPropTypes = {
  location: Object,
  instrument: Object,
  onPress: Function,
};


const ButtonClearFilters = ({
  location,
  instrument,
  onPress,
}: ButtonClearFiltersPropTypes) => (
  <div className={styles.container}>
    <Button
      isRound
      Icon={MdClearAll}
      onClick={() => onPress(location, instrument.id)}
    />
  </div>
);

export default connect(
  state => ({
    location: selectors.getRouteLocation(state),
    instrument: selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state),
  }),
  dispatch => ({
    onPress(location, instrument) {
      dispatch(actions.clearInstrumentsFilters());
      const path = location.pathname;

      if (includes(path, 'semaphore')) {
        dispatch(resultsStoplightActions.startFetchStudyResults('semaphore'));
      } else if (includes(path, 'intrument-results')) {
        dispatch(resultsActions.startFetchInstrumentResults(instrument));
      } else if (includes(path, 'indicators')) {
        dispatch(resultsIndicatorsActions.startFetchInstrumentIndicatorResults(instrument));
      } else if (includes(path, 'exit-profiles')) {
        dispatch(resultsExitProfile.startFetchInstrumentResultsExitProfile(instrument));
      }
    }
  })
)(ButtonClearFilters);
