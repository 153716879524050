// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type STOPLIGHT_RESULT_TYPE = {
  id: number,
  labels: Array<string>,
  values: Array<number>,
  result: string,
  phase: number,
};

export type STOPLIGHT_RESULTS_FETCH_STARTED_TYPE = {
  type: 'STOPLIGHT_RESULTS_FETCH_STARTED',
  payload: {
    studyId: number,
    phase: number,
  },
};
export const STOPLIGHT_RESULTS_FETCH_STARTED = 'STOPLIGHT_RESULTS_FETCH_STARTED';

export type STOPLIGHT_RESULTS_FETCH_COMPLETED_TYPE = {
  type: 'STOPLIGHT_RESULTS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: STOPLIGHT_RESULT_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const STOPLIGHT_RESULTS_FETCH_COMPLETED = 'STOPLIGHT_RESULTS_FETCH_COMPLETED';

export type STOPLIGHT_RESULTS_FETCH_FAILED_TYPE = {
  type: 'STOPLIGHT_RESULTS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const STOPLIGHT_RESULTS_FETCH_FAILED = 'STOPLIGHT_RESULTS_FETCH_FAILED';


export type STOPLIGHT_RESULTS_ACTION_TYPE =
  | STOPLIGHT_RESULTS_FETCH_STARTED_TYPE
  | STOPLIGHT_RESULTS_FETCH_COMPLETED_TYPE
  | STOPLIGHT_RESULTS_FETCH_FAILED_TYPE;
  
