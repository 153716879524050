// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdHome } from 'react-icons/lib/md';

import NavbarTab from '../NavbarTab';
import image from './home.png';

import styles from './navbarHome.css';


type NavbarNotificationsPropTypes = {
};

const NavbarNotifications = ({
  count = 0,
  onOpen,
}: NavbarNotificationsPropTypes) => (
    <NavbarTab
      Icon={() => <img className={styles.home} src={image} />}
      to="/studies"
    />
  );


export default connect(
  _state => ({
    count: 0,
  }),
)(NavbarNotifications);
