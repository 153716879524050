// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import includes from 'lodash/includes';

import { ID_TYPE } from '../../types/common';
import ButtonGroup from '../ButtonGroup';
import Field from '../MultipleFilters/field';
import Select from '../Select';
import { FILTER_STUDY_KEY } from '../../settings';
import styles from './resultsFilters.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/instruments';
import * as resultsActions from '../../actions/results';
import * as studiesActions from '../../actions/studies';
import * as studyResultsActions from '../../actions/studyResults';
import * as indicatorsActions from '../../actions/indicatorResults';
import * as exitProfileResultsActions from '../../actions/resultsExitProfile';


type ResultsFiltersPropTypes = {
  isLoading: boolean,
  filters: Array<Object>,
  instruments: Array<Object>,
  onChangeFilter: Function,
  onSelectInstrument: Function,
  fetchResults: Function,
  initialValues: Object,
  instrument: ID_TYPE,
};

const getValues = (options: Array<Object>) => options.map(el => ({ value: el.id, label: el.name }));

const ResultsFilters = ({
  filters,
  instruments,
  isLoading,
  location,
  onChangeFilter,
  onSelectInstrument,
  fetchResults,
  initialValues,
  instrument,
}: ResultsFiltersPropTypes) => {

  if (isLoading) {
    return null;
  }

  const newFilters = [{
    name: "Instrumento",
    values: instruments,
    onChange: onSelectInstrument,
    value: instrument,
  }, ...filters];

  const handleOnChange = (el, value) => {
    if (el.onChange) {
      el.onChange(value);
    } else {
      onChangeFilter(el.type, value);
    }
    fetchResults(location, instrument);
  }

  // TODO: this component support only seven elements
  return (
    <ButtonGroup 
      isFlex
      hasMultipleItems
    >
      {
        newFilters.map((el, i) => (
          <Select
            key={i}
            placeholder={el.name}
            options={getValues(el.values)}
            isLeft={i === 0}
            isRight={i === (filters.length)}
            isMiddle={i !== 0 && i !== filters.length}
            className='selectGroup'
            onChange={(val) => handleOnChange(el, val)}
            value={el.value ? el.value : initialValues[el.type]}
          />
        ))
      }
    </ButtonGroup>
  )
};

const ResultsFiltersWithRoutes = withRouter(ResultsFilters);

export default connect(
  state => {
    const instrument = selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state);
    const instruments = selectors.getInstruments(state) || [];
    const isFetching = selectors.isFetchingInstruments(state);
    const initialValues = selectors.getInstrumentsFilters(state);

    if (!instrument || isFetching) {
      return ({
        filters: [],
        isLoading: true,
        instruments,
        initialValues,
      })
    }

    return ({
      instrument: instrument.id,
      filters: instrument.genericFilters,
      isLoading: false,
      instruments,
      initialValues,
    })
  },
  dispatch => ({
    onChangeFilter(key, value) {
      dispatch(actions.updateInstrumentsFilters(key, value));

      if (key === FILTER_STUDY_KEY) {
        dispatch(studiesActions.startFetchStudy(value));
      }
    },
    onSelectInstrument(id) {
      dispatch(actions.selectInstrument(id));
      dispatch(actions.clearInstrumentsFilters());
    },
    fetchResults(location, instrument) {
      const path = location.pathname;

      if (includes(path, 'semaphore')) {
        dispatch(resultsActions.startFetchStudyResults('semaphore'));
      } else if (includes(path, 'intrument-results')) {
        dispatch(studyResultsActions.startFetchInstrumentResults(instrument));
      } else if (includes(path, 'indicators')) {
        dispatch(indicatorsActions.startFetchInstrumentIndicatorResults(instrument));
      } else if (includes(path, 'exit-profiles')) {
        dispatch(exitProfileResultsActions.startFetchInstrumentResultsExitProfile(instrument));
      } else {
        dispatch(resultsActions.startFetchStudyResults('semaphore'));
      }
    }
  })
)(ResultsFiltersWithRoutes);
