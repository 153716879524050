// @flow
import React from "react";
import { connect } from "react-redux";
import groupBy from "lodash/groupBy";

import { ERROR_TYPE } from "../../types/common";
import IndicatorTable from "../IndicatorsTable";
import FullPlaceholder from "../ListPlaceholder";
import { FullLoader } from "../Loader";
import styles from "./studyIndicatorResults.css";

import ChartView from "./chartView";
import { TABLE_VIEW_TYPE } from "../../settings";
import * as selectors from "../../reducers";

type IndicatorsListPropTypes = {
  results: Array<Object>,
  groupedResults: any,
  isFetching: Boolean,
  error: ERROR_TYPE,
  viewType: string,
};

const IndicatorsList = ({
  results,
  groupedResults,
  isFetching,
  error,
  viewType,
}: IndicatorsListPropTypes) => (
  <>
    {!isFetching &&
      results.length > 0 &&
      (viewType === TABLE_VIEW_TYPE ? (
        <IndicatorTable results={groupedResults} />
      ) : (
        <ChartView results={results} />
      ))}
    {isFetching && (
      <div className={styles.fullView}>
        <FullLoader tint="mediumGray" title="Cargando indicadores" />
      </div>
    )}
    {results.length === 0 && !isFetching && <FullPlaceholder />}
  </>
);

export default connect((state, { study }) => {
  const resultsOrder =
    selectors.getStudyIndicatorResultsOrder(state, study) || [];
  const results = resultsOrder.map((el) =>
    selectors.getIndicatorResult(state, el)
  );
  const groupedResults = groupBy(results, "axis_id");

  Object.keys(groupedResults).forEach((key) => {
    groupedResults[key] = groupBy(groupedResults[key], "category_id");
  });

  return {
    results,
    groupedResults,
    isFetching: selectors.isFetchingIndicatorResults(state),
    error: selectors.getIndicatorResultsError(state),
  };
})(IndicatorsList);
