// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './dataTable.css';

type TableSectionPropTypes = {
  children?: Node,
  className: string,
};

export const Thead = ({ children, className = '', ...rest }: TableSectionPropTypes) => (
  <thead className={`${styles.thead} ${className}`} {...rest}>
    { children }
  </thead>
);

export const Tbody = ({ children, className = '', ...rest }: TableSectionPropTypes) => (
  <tbody className={`${styles.tbody} ${className}`} {...rest}>
    { children }
  </tbody>
);

export const Tfoot = ({ children, className = '', ...rest }: TableSectionPropTypes) => (
  <tfoot className={`${styles.tfoot} ${className}`} {...rest}>
    { children }
  </tfoot>
);
