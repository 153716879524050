// @flow
import React from 'react';

import styles from './inputPreview.css';

type InputPreviewPropTypes = {
  size?: string,
  className?: string,
  clearMargins?: boolean,
  hasError?: boolean,
  isSingleChar?: boolean,
  value: string
};

const InputPreview = ({
  size = 'medium',
  className,
  clearMargins = false,
  hasError = false,
  isSingleChar = false,
  value,
}: InputPreviewPropTypes) => (
  <div
    className={
      `
        ${styles.inputPreview}
        ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
        ${typeof className !== 'undefined' ? className : ''}
        ${clearMargins ? styles.clearMargins : ''}
        ${hasError ? styles.error : ''}
        ${isSingleChar ? styles.singleChar : ''}
      `
    }
  >
    { value }
  </div>
);


export default InputPreview;
