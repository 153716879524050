// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import { SUBLOCATIONS_FETCH_STARTED } from '../types/sublocations';
import { Sublocations } from '../api';
import { arrayOfSublocations } from '../api/schemas/sublocations';

import * as selectors from '../reducers';
import * as actions from '../actions/sublocations';


function* fetchSublocations(action) {
  try {
    const token = yield select(selectors.getToken);

    const { sublocationsResponse, timeout } = yield race({
      sublocationsResponse: call(
        [Sublocations, 'list'],
        {
          token,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetch sublocations saga');
    }

    const {
      entities: { sublocations },
      result,
    } = normalize(sublocationsResponse.results, arrayOfSublocations);

    yield put(actions.completeFetchSublocations(sublocations, result));
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchSublocations({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchSublocations(): Iterator<any> {
  yield takeEvery(
    SUBLOCATIONS_FETCH_STARTED,
    fetchSublocations,
  );
};

