// @flow
import React, { Component, Fragment } from 'react';
import debounce from 'lodash/debounce';

import Input from './input';
import InputText from '../Input';
import styles from './radioOptions.css';


type SelectPropTypes = {
  name: String,
  value: Object,
  choices: Array<Object>,
  onChange: Function,
};

class Select extends Component<SelectPropTypes> {
  constructor(props: SelectManyPropTypes) {
    super(props);
    this._debouncedMethod = debounce(this._onInputChange, 0);
  }

  _onInputChange(text) {
    const { value = {}, onChange } = this.props;
    const { choices = [] } = value;
    const nVal = choices[0];

    onChange({
      choices: [{ ...nVal, text }]
    });
  };

  _onChange(id) {
    const { value, onChange } = this.props;
    const { choices = [] } = value;

    onChange({
      choices: [{ id }]
    });
  }

  render() {
    const {
      name,
      value,
      choices,
    } = this.props;
    const vChoices = value.choices || [];
    const vIds = vChoices.map(el => el.id);

    return (
      <Fragment>
        {
          choices.map((el, i) => {

            return (
              <div className={styles.option} key={el.id}>
                <Input
                  name={name}
                  value={el.value}
                  label={`${i + 1}. ${el.name}`}
                  isSelected={vIds.includes(el.value)}
                  onChange={el => this._onChange(parseInt(el))}
                />
                {
                  el.explanation_needed && vIds.includes(el.value) && (
                    <div className={styles.detailContainer}>
                      <div className={styles.indicator}/>
                      <div className={styles.detailText}>
                        {"ESPECIFIQUE: "}
                      </div>
                      <InputText
                        placeholder="Escribe acá"
                        value={el.text}
                        onChange={(e) => {
                          const text = e.target.value;
                          this._debouncedMethod(text);
                        }}
                      />
                    </div>
                  )
                }
              </div>

            )
          })
        }
      </Fragment>
    )
  }
};

export default Select;
