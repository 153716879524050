exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-OfflineModeWarningPopup-__warningPopup___footer {\n  display: flex;\n  flex: 1 1;\n  padding: 5rem 2rem 2rem 0;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.src-scripts-components-OfflineModeWarningPopup-__warningPopup___approveButton {\n  margin-left: 1rem;\n}\n\n.src-scripts-components-OfflineModeWarningPopup-__warningPopup___popUpTitle {\n  font-weight: bold;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 0;\n  margin-left: 3rem;\n  font-size: 4rem;\n  color: var(--errorColor);\n}\n\n.src-scripts-components-OfflineModeWarningPopup-__warningPopup___popUpContainer {\n  padding: 1rem 2rem 5rem !important;\n}\n\n.src-scripts-components-OfflineModeWarningPopup-__warningPopup___warningMessage {\n  font-size: 16px;\n  text-align: center;\n  color: var(--grayColor);\n  margin-bottom: 3rem;\n  margin-left: 1rem;\n  margin-right: 1rem\n}\n\n.src-scripts-components-OfflineModeWarningPopup-__warningPopup___warningMessage b {\n    font-weight: 600;\n  }\n", ""]);
// Exports
exports.locals = {
	"footer": "src-scripts-components-OfflineModeWarningPopup-__warningPopup___footer",
	"approveButton": "src-scripts-components-OfflineModeWarningPopup-__warningPopup___approveButton",
	"popUpTitle": "src-scripts-components-OfflineModeWarningPopup-__warningPopup___popUpTitle",
	"popUpContainer": "src-scripts-components-OfflineModeWarningPopup-__warningPopup___popUpContainer",
	"warningMessage": "src-scripts-components-OfflineModeWarningPopup-__warningPopup___warningMessage"
};