// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type STUDY_TYPE = {
  id: ID_TYPE,
  name: string,
};

export const FETCH_STUDY_TYPES_STARTED_TYPE = {
  type: 'FETCH_STUDY_TYPES_STARTED',
};
export const FETCH_STUDY_TYPES_STARTED = 'FETCH_STUDY_TYPES_STARTED';

export type FETCH_STUDY_TYPES_COMPLETED_TYPE = {
  type: 'FETCH_STUDY_TYPES_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: STUDY_TYPE},
    order: Array<ID_TYPE>
  },
};
export const FETCH_STUDY_TYPES_COMPLETED = 'FETCH_STUDY_TYPES_COMPLETED';

export type FETCH_STUDY_TYPES_FAILED_TYPE = {
  type: 'FETCH_STUDY_TYPES_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_STUDY_TYPES_FAILED = 'FETCH_STUDY_TYPES_FAILED';
