exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-ExitProfileTable-__exitProfileTable___container {\n  display: flex;\n  flex: 1 1;\n  padding: 3rem 2rem;\n  overflow: scroll;\n  max-height: 62vh;\n}\n\n.src-scripts-components-ExitProfileTable-__exitProfileTable___table {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid var(--almostGrayColor);\n  font-size: 1.6rem;\n  margin-bottom: 10rem\n}\n\n.src-scripts-components-ExitProfileTable-__exitProfileTable___table th {\n    border: 1px solid var(--almostGrayColor);\n    color: var(--almostGrayColor);\n    padding: 0.5rem;\n  }\n\n.src-scripts-components-ExitProfileTable-__exitProfileTable___table th:last-child {\n    width: 20rem;\n    text-align: center;\n  }\n\n.src-scripts-components-ExitProfileTable-__exitProfileTable___table .src-scripts-components-ExitProfileTable-__exitProfileTable___name {\n    text-align: left !important;\n  }\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-ExitProfileTable-__exitProfileTable___table {\n    overflow-x:auto;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-ExitProfileTable-__exitProfileTable___container",
	"table": "src-scripts-components-ExitProfileTable-__exitProfileTable___table",
	"name": "src-scripts-components-ExitProfileTable-__exitProfileTable___name"
};