// @flow
import { submit } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import * as actions from '../../actions/kids';

export const triggerSubmit = (
  values,
  dispatch,
) => {

  dispatch(actions.startAddKid({
    id: uuidv4(),
    ...values,
    birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
  }));
}
