// @flow
import type { Node } from 'react';
import React from 'react';


type TemplateComponentPropTypes = {
  children: Node,
  className?: string,
};

export const makeTemplateComponent = (compClass: string, displayName: string) => {
  if (!displayName) {
    throw new Error(`No displayName found for template component: ${compClass}`);
  }

  const cmp = ({ children, className = '', ...rest }: TemplateComponentPropTypes) => (
    <div className={`${compClass} ${className}`} {...rest}>
      {children}
    </div>
  );

  cmp.displayName = displayName;
  return cmp;
};
