// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupHeader.css';


type PopupHeaderPropTypes = {
  children?: Node,
  size?: string,
  clearPadding?: boolean,
  isBorderless?: boolean,
  className?: string,
};

const PopupHeader = ({
  children,
  size = 'medium',
  clearPadding = false,
  isBorderless = false,
  className = '',
}: PopupHeaderPropTypes) => (
  <div className={
    `
      ${styles.popupHeader}
      ${styles[size]}
      ${clearPadding ? styles.clearPadding : ''}
      ${isBorderless ? styles.borderless : ''}
      ${className}
    `
  }
  >
    { children }
  </div>
);

export default PopupHeader;
