exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-StudySubmissionsQueue-__studySubmissionsQueue___description {\n  color: var(--almostGrayColor);\n  font-size: 12pt;\n  font-weight: normal;\n  margin-bottom: 20px;\n}\n\n.src-scripts-components-StudySubmissionsQueue-__studySubmissionsQueue___submissionsList {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  max-height: 28vh;\n  margin: 24px 180px;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-StudySubmissionsQueue-__studySubmissionsQueue___submissionsList {\n    margin: 0.5rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"description": "src-scripts-components-StudySubmissionsQueue-__studySubmissionsQueue___description",
	"submissionsList": "src-scripts-components-StudySubmissionsQueue-__studySubmissionsQueue___submissionsList"
};