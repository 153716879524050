// @flow
import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';

import StudySemaphoreResults from './studySemaphoreResults';

import Button from '../Button';
import FullPlaceholder from '../ListPlaceholder';
import {
  getStudy,
  getStoplightResults,
} from '../../reducers';
import styles from './studySemaphore.css';
import { listToMatrix } from '../../utils';
import SliderFilter from '../SlideFilter';
import PopupFilters, { POPUP_FILTERS_STOPLIGHT_ID } from './popupFilters';
import * as actions from '../../actions/stoplightResults';
import * as studyActions from '../../actions/studies';
import * as closableActions from '../../actions/closeable';
import * as selectors from '../../reducers';
import { FullLoader } from '../Loader';
import MultipleSelect from '../MultipleSelect';


type StudySemaphorePropTypes = {
  studyId: number,
  study: Object,
  spotlightResults: any[],
  ignoreStoplightColors: boolean,
  loadResults: Function,
  openModal: Function,
  isLoadingStopLightResults: boolean,
};


const StudySemaphore = ({
  loadResults,
  study,
  studyId,
  spotlightResults,
  ignoreStoplightColors,
  openModal,
  isLoadingStopLightResults,
}: StudySemaphorePropTypes) => {
  const [phaseFilter, setPhaseFilter] = useState();

  useEffect(() => {
    loadResults('phase', 1, studyId);
  }, []);

  return (
    <Fragment>
      <div className={styles.row}>
        <div className={styles.sliderContainer}>
          {/* <SliderFilter
            phases={!!study && study.phases ? study.phases : []}
            onChange={value => loadResults('phase', value, studyId)}
          /> */}
          <span>Selecciona las fases que deseas filtrar:</span>
          <MultipleSelect
            options={(!!study && study.phases ? study.phases : []).map(x => ({ value: x, label: x.name }))}
            value={phaseFilter}
            onChange={(vals) => {
              setPhaseFilter(vals);
              loadResults('phase', vals.map(x => x.id), studyId);
            }}
          />
        </div>
        <Button
          title="Más filtros"
          size='small'
          tint='mediumGray'
          onClick={() => openModal()}
          className={styles.approveButton}
        />
      </div>
      {!!spotlightResults && spotlightResults.length > 0 
        ? (
          <StudySemaphoreResults
            spotlightResults={spotlightResults}
            ignoreStoplightColors={ignoreStoplightColors}  
          />
        ) : (
          isLoadingStopLightResults ? (
            <div className={styles.fullView}>
              <FullLoader
                tint="mediumGray"
                title="Cargando resultados"
              />
            </div>
          ) : (<FullPlaceholder />)
        )
      }
      <PopupFilters />
    </Fragment>
  );
} 

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);
    const study = getStudy(state, studyId);
    const { instrument } = study || {};
    const { ignore_stoplight_colors } = instrument || {};
    const results = getStoplightResults(state) || [];

    let gridResults = listToMatrix(results, 2);
    return ({
      studyId,
      study,
      spotlightResults: gridResults,
      ignoreStoplightColors: ignore_stoplight_colors,
      isLoadingStopLightResults: selectors.isFetchingStoplightResults(state),
    });
  },
  dispatch => ({
    loadResults(key, value, studyId) {
      dispatch(studyActions.updateStudyStoplightResultsFilters(key, value));
      dispatch(actions.startFetchStoplightResults(studyId));
    },
    openModal() {
      dispatch(closableActions.open(POPUP_FILTERS_STOPLIGHT_ID));
    }
  }),
)(StudySemaphore);
