exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/shadows.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, ":root {\n  --popupWindowPadding: 2rem;\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___popupContainer {\n  background: white;\n  width: calc(100% - 2 * 2rem);\n  width: calc(100% - 2 * var(--popupWindowPadding))\n  /* border-radius: 2rem; */\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___popupContainer.src-scripts-components-PopupContainer-__popupContainer___fullscreen {\n    max-width: none;\n    max-width: initial;\n    min-height: 100vh;\n    width: 100%;\n  }\n\n.src-scripts-components-PopupContainer-__popupContainer___popoverContainer {\n  background-color: transparent;\n  width: 495px;\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___round {\n  border-radius: var(--containerBorderRadius);\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___top {\n  align-self: flex-start;\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___center {\n  align-self: center;\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___bottom {\n  align-self: flex-end;\n}\n\n@media(min-width: 551px) {\n\n.src-scripts-components-PopupContainer-__popupContainer___small { max-width: 550px\n} }\n\n\n@media(min-width: 751px) {\n\n\n.src-scripts-components-PopupContainer-__popupContainer___medium { max-width: 750px\n} }\n\n@media(min-width: 961px) {\n\n.src-scripts-components-PopupContainer-__popupContainer___big { max-width: 1200px\n} }\n\n.src-scripts-components-PopupContainer-__popupContainer___elevation2 {\n  box-shadow: var(--layerBoxShadow);\n}\n\n.src-scripts-components-PopupContainer-__popupContainer___elevation3 {\n  box-shadow: var(--layerBoxShadow2);\n}", ""]);
// Exports
exports.locals = {
	"popupContainer": "src-scripts-components-PopupContainer-__popupContainer___popupContainer",
	"fullscreen": "src-scripts-components-PopupContainer-__popupContainer___fullscreen",
	"popoverContainer": "src-scripts-components-PopupContainer-__popupContainer___popoverContainer",
	"round": "src-scripts-components-PopupContainer-__popupContainer___round",
	"top": "src-scripts-components-PopupContainer-__popupContainer___top",
	"center": "src-scripts-components-PopupContainer-__popupContainer___center",
	"bottom": "src-scripts-components-PopupContainer-__popupContainer___bottom",
	"small": "src-scripts-components-PopupContainer-__popupContainer___small",
	"medium": "src-scripts-components-PopupContainer-__popupContainer___medium",
	"big": "src-scripts-components-PopupContainer-__popupContainer___big",
	"elevation2": "src-scripts-components-PopupContainer-__popupContainer___elevation2",
	"elevation3": "src-scripts-components-PopupContainer-__popupContainer___elevation3"
};