exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-scripts-components-PopupFooter-__popupFooter___popupFooter {\n  align-items: center;\n  display: flex;\n  justify-content: center\n}\n\n.src-scripts-components-PopupFooter-__popupFooter___popupFooter * {\n    margin-bottom: 0;\n    margin-top: 0;\n  }\n\n.src-scripts-components-PopupFooter-__popupFooter___small {\n  padding: 2rem;\n}\n\n.src-scripts-components-PopupFooter-__popupFooter___medium {\n  padding: 2rem;\n}\n\n.src-scripts-components-PopupFooter-__popupFooter___large {\n  padding: 4rem;\n}\n\n.src-scripts-components-PopupFooter-__popupFooter___clearPaddings {\n  padding: 0;\n}\n\n.src-scripts-components-PopupFooter-__popupFooter___left { justify-content: flex-start; }\n.src-scripts-components-PopupFooter-__popupFooter___center {\n  justify-content: center\n}\n.src-scripts-components-PopupFooter-__popupFooter___center button {\n    margin-left: 1rem;\n  }\n.src-scripts-components-PopupFooter-__popupFooter___right {\n  justify-content: flex-end\n}\n.src-scripts-components-PopupFooter-__popupFooter___right button {\n    margin-left: 1rem;\n  }\n", ""]);
// Exports
exports.locals = {
	"popupFooter": "src-scripts-components-PopupFooter-__popupFooter___popupFooter",
	"small": "src-scripts-components-PopupFooter-__popupFooter___small",
	"medium": "src-scripts-components-PopupFooter-__popupFooter___medium",
	"large": "src-scripts-components-PopupFooter-__popupFooter___large",
	"clearPaddings": "src-scripts-components-PopupFooter-__popupFooter___clearPaddings",
	"left": "src-scripts-components-PopupFooter-__popupFooter___left",
	"center": "src-scripts-components-PopupFooter-__popupFooter___center",
	"right": "src-scripts-components-PopupFooter-__popupFooter___right"
};