exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../assets/styles/variables/boxsizes.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../assets/styles/variables/font.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-DataTable-Cells-Tag-__tag___tag {\n  color: #FFF;\n  border-radius: var(--roundBorderRadius);\n  font-family: var(--secondaryFont);\n  font-size: 1.2rem;\n  font-weight: 700;\n  padding: 1rem 2rem;\n  text-transform: uppercase;\n}\n\n.src-scripts-components-DataTable-Cells-Tag-__tag___red {\n  background-color: tomato;\n}\n\n.src-scripts-components-DataTable-Cells-Tag-__tag___orange {\n  background-color: var(--orangeColor);\n}\n\n.src-scripts-components-DataTable-Cells-Tag-__tag___blue {\n  background-color: blue;\n}\n\n.src-scripts-components-DataTable-Cells-Tag-__tag___yellow {\n  background-color: burlywood;\n}\n\n.src-scripts-components-DataTable-Cells-Tag-__tag___gray {\n  background-color: var(--mainColor);\n}\n", ""]);
// Exports
exports.locals = {
	"tag": "src-scripts-components-DataTable-Cells-Tag-__tag___tag",
	"red": "src-scripts-components-DataTable-Cells-Tag-__tag___red",
	"orange": "src-scripts-components-DataTable-Cells-Tag-__tag___orange",
	"blue": "src-scripts-components-DataTable-Cells-Tag-__tag___blue",
	"yellow": "src-scripts-components-DataTable-Cells-Tag-__tag___yellow",
	"gray": "src-scripts-components-DataTable-Cells-Tag-__tag___gray"
};