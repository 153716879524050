export const CIVIL_STATES = [
  {
    value: 'Soltera',
    label: 'Soltera',
  },
  {
    value: 'Unida o Casada',
    label: 'Unida o Casada',
  },
  {
    value: 'Divorciada o separada',
    label: 'Divorciada o separada',
  },
  {
    value: 'Viuda',
    label: 'No sabe/ no responde',
  }
];

export const ETHNICITIES = [
  {
    label: 'Maya',
    value: 'Maya',
  },
  {
    label: 'Ladina',
    value: 'Ladina',
  },
  {
    label: 'Garifuna',
    value: 'Garifuna',
  },
  {
    label: 'Xinca',
    value: 'Xinca',
  },
  {
    label: 'No sabe',
    value: 'No sabe',
  }
];

export const GRADES = [
  {
    label: 'Solo a primer año primaria',
    value: 'Solo a primer año primaria',
  },
  {
    label: 'Solo a tercero primaria',
    value: 'Solo a tercero primaria',
  },
  {
    label: 'Solo a Sexto primaria',
    value: 'Solo a Sexto primaria',
  },
  {
    label: 'Solo a los Basicos',
    value: 'Solo a los Basicos',
  },
  {
    label: 'Tiene titulo de maestro o equivalente',
    value: 'Tiene titulo de maestro o equivalente',
  },
  {
    label: 'Estudios universitarios',
    value: 'Estudios universitarios',
  },
  {
    label: 'Ninguno',
    value: 'Ninguno',
  }
];


export const SMALLEST_KID_AGE = [
  {
    label: 'menos de 6 meses',
    value: 'menos de 6 meses',
  },
  {
    label: 'entre 6 y 12 meses',
    value: 'entre 6 y 12 meses',
  },
  {
    label: '12 a 18 meses',
    value: '12 a 18 meses',
  },
  {
    label: '18 meses  a 24 meses',
    value: '18 meses  a 24 meses',
  },
  {
    label: '24 meses a 5 años',
    value: '24 meses a 5 años',
  },
]

export const WOMAN_STATES = [
  {
    label: 'Embarazada',
    value: 'Embarazada',
  },
  {
    label: 'Lactante',
    value: 'Lactante',
  },
  {
    label: 'Edad fertil',
    value: 'Edad fertil',
  },
]

export const PARTICIPANT_AGES = [
  {
    label: 'entre 15 y 19 años',
    value: 'entre 15 y 19 años',
  },
  {
    label: 'entre 20 y 24 años',
    value: 'entre 20 y 24 años',
  },
  {
    label: 'entre 25 y 29 años',
    value: 'entre 25 y 29 años',
  },
  {
    label: 'entre 30 y 34 años',
    value: 'entre 30 y 34 años',
  },
  {
    label: 'entre 35 y 39 años',
    value: 'entre 35 y 39 años',
  },
  {
    label: '40 años o mas',
    value: '40 años o mas',
  },
]

export const YEARS_LIVING_IN_COMMUNITY = [
  {
    value: 'menos de 6 meses',
    label: 'menos de 6 meses',
  },
  {
    value: 'de 6 meses a 1  año',
    label: 'de 6 meses a 1  año',
  },
  {
    value: 'de 1 a 5 años',
    label: 'de 1 a 5 años',
  },
  {
    value: 'de 6 a 10 años',
    label: 'de 6 a 10 años',
  },
  {
    value: 'mas de 10 años',
    label: 'mas de 10 años',
  },
]

export const GENDERS_VALUES = [
  {
    value: 'Femenino',
    label: 'Femenino',
  },
  {
    value: 'Masculino',
    label: "Masculino"
  }
]

export const HOUSE_TYPES = [
  {
    value: 'Propia',
    label: 'Propia',
  },
  {
    value: 'Alquilada',
    label: 'Alquilada',
  },
  {
    value: 'Cuarto o apartamento rentado',
    label: 'Cuarto o apartamento rentado',
  },
  {
    value: 'Otra',
    label: 'Otra',
  },
];

export const HOUSE_MATES_TYPES = Array.from({ length: 30 }, (x,i) => ({ value: i, label: i }));
