// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './dataTable.css';

type TrGroupPropTypes = {
  children?: Node,
  className: string,
};

const TrGroup = ({ children, className = '', ...rest }: TrGroupPropTypes) => (
  <td className={`${styles.trGroup} ${className}`} role="rowgroup" {...rest}>
    { children }
  </td>
);

export default TrGroup;
