//@flow
import React, { Fragment } from 'react';
import { List } from 'react-virtualized';

import useWindowSize from '../../hooks/useWindowSize';
import GenericResultsChar from '../GenericResultsChart';
import { listToMatrix } from '../../utils';
import styles from './instrumentResults.css';

// CSS Breakpoint(s)
const TABLET_BREAKPOINT = 768;

type CharViewPropTypes = {
  results: Array<Object>,
  phases: Array<number>,
  windowDiference?: number,
};

const CharView = ({
  results = [],
  phases,
  windowDiference = 335,
}: CharViewPropTypes) => {
  const matrix = listToMatrix(results, 2);
  const { height, width } = useWindowSize();

  const renderRow = ({ index, key, style }) => {
    return (
      matrix[index] ? (
        <div style={style} className={styles.row} key={key}>
          {
            matrix[index].map((y, i) => ( y ? (
              <div className={styles.col}>
                <GenericResultsChar
                  key={y.id}
                  name={y.name}
                  phases={phases}
                  results={y.charResults}
                />
              </div>
            ) : null
            ))
          }
        </div>
      ) : null
    )
  };

  return (
    <List
      height={height - windowDiference}
      width={width > TABLET_BREAKPOINT ? width - 400 : ((width - 30) * 0.92)}
      rowHeight={width > TABLET_BREAKPOINT ? 380 : (height * 0.5)}
      rowCount={matrix.length}
      rowRenderer={renderRow}
    />
  )
};

export default CharView;
