// @flow
import type { ComponentType } from 'react';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// import i18n from 'i18n-js';

import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './navbarMainTabs.css';


type NavbarTabPropTypes = {
  to: string,
  title?: string,
  Icon?: ComponentType<*>,
};

const NavbarTab = ({
  title,
  to,
  Icon,
}: NavbarTabPropTypes) => (
  <NavLink
    key={to}
    className={styles.navbarTab}
    activeClassName={styles.active}
    to={to}
  >
    <Fragment>
      { Icon && <Icon size={20} /> }
      <span className={styles.text}>
        { title }
      </span>
    </Fragment>
  </NavLink>
);

export default withRouter(NavbarTab);
