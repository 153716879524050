// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type LOCATION_TYPE = {
  id: ID_TYPE,
  name: string,
};

export type LOCATIONS_FETCH_STARTED_TYPE = {
  type: 'LOCATIONS_FETCH_STARTED',
};
export const LOCATIONS_FETCH_STARTED = 'LOCATIONS_FETCH_STARTED';

export type LOCATIONS_FETCH_COMPLETED_TYPE = {
  type: LOCATIONS_FETCH_COMPLETED,
  payload: {
    order: Array<number>,
    entities: {[number]: LOCATION_TYPE}
  },
};
export const LOCATIONS_FETCH_COMPLETED = 'LOCATIONS_FETCH_COMPLETED';

export type LOCATIONS_FETCH_FAILED_TYPE = {
  type: 'LOCATIONS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const LOCATIONS_FETCH_FAILED = 'LOCATIONS_FETCH_FAILED'; 

export type LOCATION_SELECTED_TYPE = {
  type: 'LOCATION_SELECTED',
  payload: ID_TYPE,
};
export const LOCATION_SELECTED = 'LOCATION_SELECTED';


export type LOCATION_ACTION_TYPE =
  | LOCATIONS_FETCH_STARTED_TYPE
  | LOCATIONS_FETCH_COMPLETED_TYPE
  | LOCATIONS_FETCH_FAILED_TYPE
  | LOCATION_SELECTED_TYPE;
