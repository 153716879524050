exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-PopupHeader-__popupHeader___popupHeader {\n  border-bottom: 1px solid var(--lightGrayColor);\n  display: flex;\n  align-items: center;\n}\n\n.src-scripts-components-PopupHeader-__popupHeader___borderless {\n  border: none;\n}\n\n.src-scripts-components-PopupHeader-__popupHeader___small {\n  padding: 1rem 2rem;\n}\n\n.src-scripts-components-PopupHeader-__popupHeader___medium {\n  padding: 2rem 3rem;\n}\n\n.src-scripts-components-PopupHeader-__popupHeader___big {\n  padding: 2rem 4rem;\n}\n\n.src-scripts-components-PopupHeader-__popupHeader___clearPadding {\n  padding: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"popupHeader": "src-scripts-components-PopupHeader-__popupHeader___popupHeader",
	"borderless": "src-scripts-components-PopupHeader-__popupHeader___borderless",
	"small": "src-scripts-components-PopupHeader-__popupHeader___small",
	"medium": "src-scripts-components-PopupHeader-__popupHeader___medium",
	"big": "src-scripts-components-PopupHeader-__popupHeader___big",
	"clearPadding": "src-scripts-components-PopupHeader-__popupHeader___clearPadding"
};