// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/lib/md';

import type { ID_TYPE } from '../../types/common';
import OrganizationsTable from '../OrganizationsTable';
import Input from '../Input';
import Searchbar from './searchbar';

import * as actions from '../../actions/organizations';


type StudyOrganizationsPropTypes = {
  studyId: ID_TYPE,
  onLoad: Function,
  onUnmount: Function,
};


const StudyOrganizations = ({
  onLoad,
  studyId,
  onUnmount,
}: StudyOrganizationsPropTypes) => {
  useEffect(() => {
    onLoad(studyId);
    
    return () => onUnmount();
  });

  return (
    <div style={{ marginBottom: '8rem' }}>
      <Searchbar />
      <OrganizationsTable studyId={studyId}/>
    </div>
  )
};

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);

    return ({
      studyId,
    });
  },
  dispatch => ({
    onLoad(study) {
      dispatch(actions.updateOrganizationsFilters('study', study));
      dispatch(actions.startFetchPaginatedOrganizations());
    },
    onUnmount() {
      dispatch(actions.updateOrganizationsFilters('text', ''));
    }
  })
)(StudyOrganizations);

