//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  INDICATOR_RESULT_TYPE,
  INDICATOR_RESULTS_ACTION_TYPE,
} from '../types/indicatorResults';
import * as common from './common';
import * as types from '../types/indicatorResults';


export type IndicatorresultsState = {
  byId: { [ID_TYPE]: INDICATOR_RESULT_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: number,
  counter: number,
  filters: Object,
  requestTimestamp: any,
};

const byId = common.byId({
  fetched: [
    types.INDICATOR_RESULTS_FETCH_COMPLETED,
    types.STUDY_INDICATOR_RESULTS_COMPLETED,
    types.INSTRUMENT_INDICATOR_RESULTS_COMPLETED,
  ],
  defaultAttributes: {},
});

const order = common.order({
  replaced: [
    types.INDICATOR_RESULTS_FETCH_COMPLETED,
    types.INSTRUMENT_INDICATOR_RESULTS_COMPLETED,
  ]
});

const isFetching = common.isFetching({
  started: [
    types.INDICATOR_RESULTS_FETCH_STARTED,
    types.STUDY_INDICATOR_RESULTS_STARTED,
    types.INSTRUMENT_INDICATOR_RESULTS_STARTED,
  ],
  succeed: [
    types.INDICATOR_RESULTS_FETCH_COMPLETED,
    types.STUDY_INDICATOR_RESULTS_COMPLETED,
    types.INSTRUMENT_INDICATOR_RESULTS_COMPLETED,
  ],
  failed: [
    types.INDICATOR_RESULTS_FETCH_FAILED,
    types.STUDY_INDICATOR_RESULTS_FAILED,
    types.INSTRUMENT_INDICATOR_RESULTS_FAILED,
  ]
});

const error = common.error({
  clear: [
    types.INDICATOR_RESULTS_FETCH_STARTED,
    types.INDICATOR_RESULTS_FETCH_COMPLETED,
    types.STUDY_INDICATOR_RESULTS_STARTED,
    types.STUDY_INDICATOR_RESULTS_COMPLETED,
    types.INSTRUMENT_INDICATOR_RESULTS_STARTED,
    types.INSTRUMENT_INDICATOR_RESULTS_COMPLETED,
  ],
  populate: [
    types.INDICATOR_RESULTS_FETCH_FAILED,
    types.STUDY_INDICATOR_RESULTS_FAILED,
    types.INSTRUMENT_INDICATOR_RESULTS_FAILED,
  ],
});

const selected = common.mux({
  selected: [],
  allDeselected: [],
  default: -1,
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const requestTimestamp = (
  state: ID_TYPE = "",
  action: INDICATOR_RESULTS_ACTION_TYPE,
): ID_TYPE => {
  switch (action.type) {
    case types.STUDY_INDICATOR_RESULTS_STARTED:
      const { requestTimestamp } = action.payload;
      return requestTimestamp;

    case types.INDICATOR_RESULTS_FILTERS_CLEARED:
      return "";

    default:
      return state;
  }
};


const filters = (
  state: Object = {},
  action: INDICATOR_RESULTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.INDICATOR_RESULTS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      }
    }

    case types.INDICATOR_RESULTS_FILTERS_CLEARED: {
      return {};
    }

    default: {
      return state;
    }
  }
};

const indicatorResults = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
  counter,
  filters,
  requestTimestamp,
});


export default indicatorResults;


// Selectors
export const getIndicatorResult = (state: IndicatorresultsState, id: ID_TYPE): ?INDICATORRESULT_TYPE => state.byId[id];
export const getIndicatorResults = (state: IndicatorresultsState): Array<?INDICATORRESULT_TYPE> => state.order.map(i => getIndicatorResult(state, i));
export const isIndicatorResultFetching = (state: IndicatorresultsState, id: ID_TYPE): boolean => state.fetching.includes(id);
export const isFetchingIndicatorResults = (state: IndicatorresultsState): boolean => state.isFetching;
export const getIndicatorResultsError = (state: IndicatorresultsState): ERROR_TYPE => state.error;
export const getSelectedIndicatorResult = (state: IndicatorresultsState): ?INDICATORRESULT_TYPE => getIndicatorResult(state, state.selected);
export const getIndicatorResultCounter = (state: IndicatorresultsState): number => state.counter;
export const getIndicatorsResultsFilters = (state: IndicatorresultsState): Object => state.filters;
export const getStudyIndicatorResultsRequestTimestamp = (state: IndicatorresultsState): ID_TYPE => state.requestTimestamp;
