// @flow
import { combineReducers } from 'redux';

import * as common from './common';
import * as types from '../types/menuFixed';


export type MenuFixedState = {
  isShow: boolean,
};

const isShow = common.isFetching({
  started: [types.MENU_FIXED_SHOWED],
  succeed: [types.MENU_FIXED_HIDDEN],
});

const MenuFixed = combineReducers({
  isShow,
});

export default MenuFixed;

export const isShowMenuFixed = (state: MenuFixedState): boolean => state.isShow;
