// @flow
import { ANTHROPOMETRY_TYPE } from '../../settings';

const validate = (values) => {
  const errors = {};

  if (!values.name) { errors.name = 'Requerido'; }
  if (!values.instrument) { errors.instrument = 'Requerido'; }
  if (!values.study_type) { errors.study_type = 'Requerido'; }
  if (!values.start_date) { errors.start_date = 'Requerido'; }
  if (!values.end_date) { errors.end_date = 'Requerido'; }

  if (values.instrument) {
    // New requirement: anthropometry studies needs a phase...
    // if (values.instrument.type !== ANTHROPOMETRY_TYPE && !values.phases) {
    if (!values.phases) {
      errors.phases = 'Requerido';
    }
  }

  if (!values.organizations || values.organizations.lenght == 0) {
    errors.organizations = 'Requerido';
  }

  if (!values.users || values.users.length === 0) {
    errors.users = 'Requerido';
  }

  return errors;
}

export default validate;
