// @flow
import type { ComponentType, Node } from 'react';
import React from 'react';
import { connect } from 'react-redux';

import type { ID_TYPE } from '../../types/common';
import { ReduxClickPopover } from '../Closeable';
import * as closeableActions from '../../actions/closeable';

import styles from './popMenu.css';


type PopMenuOptionPropTypes = {
  title: string,
  Icon?: ComponentType<*>,
  disabled?: boolean,
  onClick?: Function,
  close?: Function,
  tint?: string,
  href?: string,
  target?: string
};

type PopMenuPropTypes = {
  options: Array<PopMenuOptionPropTypes>,
  children: Node,
};

type PopMenuOptionsPropTypes = {
  options: Array<PopMenuOptionPropTypes>,
  peId: ID_TYPE
};

type PopMenuOptionsContainerPropTypes = {
  children: Node
};

const PopMenuOptionDummy = ({
  title,
  Icon,
  disabled = false,
  onClick,
  close,
  tint,
  href,
  target,
}: PopMenuOptionPropTypes) => (
  <a
    className={
      `
        ${styles.option}
        ${disabled ? styles.disabled : ''}
        ${typeof styles[tint] !== 'undefined' ? styles[tint] : ''}
      `
    }
    onClick={(e) => {
      if (onClick) { onClick(e); }
      if (close) { close(e); }
    }}
    href={href}
    target={target}
  >
    <span className={styles.iconContainer}>
      { typeof Icon !== 'undefined' && <Icon size={20} /> }
    </span>
    <span className={styles.title}>
      { title }
    </span>
  </a>
);

const PopMenuOption = connect(
  undefined,
  (dispatch, { peId }) => ({
    close() {
      dispatch(closeableActions.close(peId));
    },
  }),
)(PopMenuOptionDummy);

const PopMenuOptionsContainer = ({ children }: PopMenuOptionsContainerPropTypes) => (
  <div className={styles.optionsContainer}>
    { children }
  </div>
);

const PopMenuOptions = ({ options, peId }: PopMenuOptionsPropTypes) => (
  <PopMenuOptionsContainer>
    { options.map(option => <PopMenuOption {...option} key={option.title} peId={peId} />) }
  </PopMenuOptionsContainer>
);

const PopMenu = ({ options, children, ...props }: PopMenuPropTypes) => (
  <ReduxClickPopover
    Content={lprops => <PopMenuOptions {...lprops} options={options} />}
    {...props}
  >
    { children }
  </ReduxClickPopover>
);

export default PopMenu;
