//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { CHOICE_TYPE } from '../types/choices';
import * as common from './common';
import * as types from '../types/choices';

export type ChoicesState = {
  byId: { [ID_TYPE]: CHOICE_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
};

const byId = common.byId({
  fetched: [types.CHOICES_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.CHOICES_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.CHOICES_FETCH_STARTED],
  succeed: [types.CHOICES_FETCH_COMPLETED],
  failed: [types.CHOICES_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.CHOICES_FETCH_STARTED,
    types.CHOICES_FETCH_COMPLETED,
  ],
  populate: [types.CHOICES_FETCH_FAILED],
});

const choices = combineReducers({
  byId,
  order,
  isFetching,
  error,
});


export default choices;


// Selectors
export const getChoice = (state: ChoicesState, id: ID_TYPE): ?CHOICE_TYPE => state.byId[id];
export const getChoices = (state: ChoicesState): Array<?CHOICE_TYPE> => state.order.map(i => getChoice(state, i));
export const isFetchingChoices = (state: ChoicesState): boolean => state.isFetching;
export const getChoicesError = (state: ChoicesState): ERROR_TYPE => state.error;
