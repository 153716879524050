//@flow
import React, { Fragment } from 'react';
import { Grid } from 'react-virtualized';

import useWindowSize from '../../hooks/useWindowSize';
import GenericResultsChar from '../GenericResultsChart';
import { listToMatrix } from '../../utils';
/* import styles from './studyResults.css'; */

// CSS Breakpoint(s)
const TABLET_BREAKPOINT = 768;

type ChartViewPropTypes = {
  results: Array<Object>,
  phases: Array<number>
};

const ChartView = ({
  results = [],
  phases
}: ChartViewPropTypes) => {
  const matrix = listToMatrix(results, 2);
  const { height, width } = useWindowSize();

  const renderRow = ({ columnIndex, key, rowIndex, style }) => {
    return (
      matrix[rowIndex][columnIndex] 
        ? (
          <div
            style={style}
            key={key}
          >
            <GenericResultsChar
              key={matrix[rowIndex][columnIndex].id}
              name={matrix[rowIndex][columnIndex].name}
              phases={phases}
              results={matrix[rowIndex][columnIndex].charResults}
            />
          </div>
        ) : null
    )
  };

  return (
    <Grid
    height={height - 325}
    rowCount={matrix.length}
    columnCount={ width > TABLET_BREAKPOINT ? 2 : 1}
    columnWidth={width > TABLET_BREAKPOINT ? ((width - 400) * .49) : ((width - 30) * .85) }
    rowHeight={width > TABLET_BREAKPOINT ? 350 : (height * 0.5)}
    width={width > TABLET_BREAKPOINT ? width - 400 : ((width - 30) * .85)}
    cellRenderer={renderRow}
    />
  )
};

export default ChartView;
