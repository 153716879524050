// @flow
import React from 'react';
import { MdFolder, MdEdit, MdDelete } from 'react-icons/lib/md';

import DataTable from '../DataTable';
import TextColumn from '../DataTable/Columns/Text';
import ButtonColumn from '../DataTable/Columns/Button';
import { connect } from 'react-redux';

import * as selectors from '../../reducers';
import styles from './participantsTable.css';

type ParticipantsTablePropTypes = {
  isAdmin: boolean,
  data: Array<Object>,
  filesOnClick: Function,
  onEditClicked: Function,
  onDeleteClicked: Function,
};

const ParticipantsTable = ({
  isAdmin,
  data,
  hasScorableInstrument,
  filesOnClick,
  onEditClicked,
  onDeleteClicked,
}: ParticipantsTablePropTypes) => {

  const scorableColumns = (hasScorableInstrument && [
    TextColumn({
      Header: 'Punteo',
      accessor: 'points',
      editable: false,
    }),
    TextColumn({
      Header: 'Categoría',
      accessor: 'category',
      editable: false,
    }),
  ]) || []
  
  return (
    <DataTable
      data={data}
      columns={[
        TextColumn({
          Header: 'ID',
          accessor: 'id',
          editable: false,
          maxWidth: 50,
        }),
        TextColumn({
          Header: 'Nombres',
          accessor: 'first_name',
          editable: false,
        }),
        TextColumn({
          Header: 'Apellidos',
          accessor: 'last_name',
          editable: false,
        }),
        TextColumn({
          Header: 'Código de identificación',
          accessor: 'cui',
          editable: false,
        }),
        TextColumn({
          Header: 'Rango de edad',
          accessor: 'age',
          editable: false,
        }),
        ...scorableColumns,
        ButtonColumn({
          Header: '',
          accessor: 'files',
          buttonProps: {
            Icon: MdFolder,
            size: 'small',
            tint: 'primary',
            isRound: true,
            title: 'Archivos',
          },
          getOnClick: (params) => () => {
            filesOnClick(params.id);
          },
          minWidth: 160,
          maxWidth: 160,
          show: true,
          className:styles.center,
        }),
        ButtonColumn({
          Header: '',
          accessor: 'edit',
          buttonProps: {
            Icon: MdEdit,
            size: 'small',
            tint: 'gray',
            isRound: true,
            title: '',
          },
          getOnClick: (params: { id: number }) => () => {
            onEditClicked(params.id);
          },
          maxWidth: 50,
          show: true,
          className:styles.center,
        }),
        isAdmin ? (
          ButtonColumn({
            Header: '',
            accessor: 'delete',
            buttonProps: {
              Icon: MdDelete,
              size: 'small',
              tint: 'error',
              isRound: true,
              title: '',
            },
            getOnClick: (params: { id: number }) => () => {
              onDeleteClicked(params.id);
            },
            align: 'center',
            maxWidth: 50,
            show: true,
            className:styles.center,
          })
        ) : undefined,
      ].filter(item => item !== undefined)}
      minRows={0}
      defaultPageSize={99999}
      showPagination={false}
    />
  )
};

export default connect(
  state => ({
    isAdmin: selectors.getIsCurrentUserAdmin(state),
  })
)(ParticipantsTable);

