// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import range from 'lodash/range';

import {
  STUDIES_FETCH_STARTED,
  STUDY_FETCH_STARTED,
  STUDY_ADD_STARTED,
  STUDY_UPDATED_STARTED,
  STUDY_ORGANIZATIONS_UPDATE_STARTED
} from '../types/studies';
import { Studies, StudyOrganization, Users } from '../api';
import { arrayOfStudies, study } from '../api/schemas/studies';
import { arrayOfUsers } from '../api/schemas/users';
import { REQUEST_TIMEOUT, ANTHROPOMETRY_TYPE } from '../settings';
import { throwTimeout } from '../lib/http';
import { UPDATE_STUDY_POPUP } from '../components/UpdateStudyPopup';
import { ADD_STUDY_POPUP_ID } from '../components/AddStudyPopup';

import * as selectors from '../reducers';
import * as actions from '../actions/studies';
import * as closableActions from '../actions/closeable';


function* fetchStudies(action)  {
  try {
    
    const token = yield select(selectors.getToken);
    const page = yield select(selectors.getStudiesNextPage);
    const filters = yield select(selectors.getStudiesFilters);

    const nextPage = page != null ? page : 1;

    const { studiesResponse, timeout } = yield race({
      studiesResponse: call(
        [Studies, 'list'],
        {
          token,
          filters: {
            page: nextPage,
            page_size: 15,
            ...filters
          }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchUsers saga');
    }

    const {
      entities: {
        studies,
        organizations,
      },
      result,
    } = normalize(studiesResponse.results, arrayOfStudies);

    yield put(actions.completeFetchStudies(studies, result, studiesResponse.next));

  } catch (error) {

    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchStudies({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
};

function* fetchStudy(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;

    const { studyResponse, timeout } = yield race({
      studyResponse: call(
        [Studies, 'detail'],
        {
          token,
          id,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchUser saga');
    }

    yield put(actions.completeFetchStudy(studyResponse));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;

    yield put(actions.failFetchStudy({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* addStudy(action) {
  const nValues = action.payload;
  const token = yield select(selectors.getToken);
  const studyType = yield select(selectors.getStudyType, nValues.study_type);
  const instrument = yield select(selectors.getInstrument, nValues.instrument);

  try {
    const phase = parseInt(nValues.phases);

    const payload = {
      ...nValues,
      type: nValues.study_type,
      phases: nValues.phases ? range(1, (phase + 1)) : undefined,
    };

    delete payload['study_type'];
    const { response, timeout } = yield race({
      response: call(
        [Studies, 'create'],
        {
          token,
          data: payload,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchUser saga');
    }

    yield put(actions.completeAddStudy({
      ...nValues,
      study_type: {
        id: studyType.id,
        name: studyType.name,
        key: studyType.key
      },
      instrument: {
        id: instrument.id,
        name: instrument.name,
      },
      id: response.id,
    }));
    yield put(actions.startFetchStudies());
    yield put(closableActions.close(ADD_STUDY_POPUP_ID));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;

    yield put(actions.failAddStudy({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* updateStudy(action) {
  try {
    const token = yield select(selectors.getToken);
    const study = yield select(selectors.getSelectedStudy);
    const values = action.payload;
    // const type = yield select(selectors.getStudyType, values.study_type);

    const { response, timeout } = yield race({
      response: call(
        [Studies, 'put'],
        {
          token,
          id: study.id,
          data: {
            ...values,
            phases: values.phases != null ? values.phases : undefined,
          },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchUser saga');
    }

    yield put(actions.completeUpdateStudy(response));
    yield put(actions.startUpdateStudyOrganizations(study.id, values.organizations));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;

    yield put(actions.failUpdateStudy({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* updateStudyOrganizations(action) {
  try {
    const token = yield select(selectors.getToken);
    const { study, organizations } = action.payload;
    const { study_organizations = [] } = yield select(selectors.getStudy, study);

    const orgList = organizations.map((organization, i) => ({
      sample: organizations[i][1],
      organization: organizations[i][0],
      relationId: organizations[i][2],
      study,
    }));

    // Remove org relations
    const relationsToRemove = [];
    const orgListIds = orgList.map(el => el.relationId).filter(el => el != null);
    
    study_organizations.forEach(el => {
      if (!orgListIds.includes(el.relation_id)) {
        relationsToRemove.push(el);
      }
    });

    for (let i = 0; i < relationsToRemove.length; i++) {
      const element = relationsToRemove[i];
      
      const { response, timeout } = yield race({
        response: call(
          [StudyOrganization, 'remove'],
          {
            token,
            id: element.relation_id,
          },
        ),
        timeout: call(delay, REQUEST_TIMEOUT),
      });

      if (timeout) {
        throwTimeout('update study organization saga');
      }
    }


    // Update and create relations
    for (let i = 0; i < orgList.length; i++) {
      const element = orgList[i];
      
      // If not exist relation comunity/study create
      if (element.relationId) {
        const { response, timeout } = yield race({
          response: call(
            [StudyOrganization, 'put'],
            {
              token,
              id: element.relationId,
              data: element,
            },
          ),
          timeout: call(delay, REQUEST_TIMEOUT),
        });

        if (timeout) {
          throwTimeout('update study organization saga');
        }

      } else {
        const { response, timeout } = yield race({
          response: call(
            [StudyOrganization, 'create'],
            {
              token,
              data: element,
            },
          ),
          timeout: call(delay, REQUEST_TIMEOUT),
        });

        if (timeout) {
          throwTimeout('create study organization saga');
        }
      }
    }

    yield put(actions.completeUpdateStudyOrganizations());
    yield put(closableActions.close(UPDATE_STUDY_POPUP));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;

    yield put(actions.failUpdateStudyOrgnizations({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchStudies():Iterator<any> {
  yield takeEvery(
    STUDIES_FETCH_STARTED,
    fetchStudies,
  );
}

export function* watchFetchStudy(): Iterator<any> {
  yield takeEvery(
    STUDY_FETCH_STARTED,
    fetchStudy,
  );
}

export function* watchAddStudy(): Iterator<any> {
  yield takeEvery(
    STUDY_ADD_STARTED,
    addStudy, 
  );
}

export function* watchUpdateStudy(): Iterator<any> {
  yield takeEvery(
    STUDY_UPDATED_STARTED,
    updateStudy,
  );
}

export function* watchUpdateOrganizationStudy(): Iterator<any> {
  yield takeEvery(
    STUDY_ORGANIZATIONS_UPDATE_STARTED,
    updateStudyOrganizations,
  )
}
