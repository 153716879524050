exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/font.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/shadows.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
exports.push([module.id, ".src-scripts-components-InputPreview-__inputPreview___inputPreview {\n  /* align-content: center; */\n  /* align-items: center; */\n  /* box-sizing: border-box; */\n  color: var(--grayColor);\n  display: flex;\n  font-size: 2rem;\n  margin-bottom: 1.5rem;\n  padding: 0 1rem 0.5rem 1rem;\n}\n\n.src-scripts-components-InputPreview-__inputPreview___clearMargins {\n  margin-bottom: 0;\n}\n\n.src-scripts-components-InputPreview-__inputPreview___singleChar {}\n\n/* .small {\n  min-height: var(--smallHeightSize);\n}\n.medium {\n  min-height: var(--mediumHeightSize);\n}\n.large {\n  min-height: var(--largeHeightSize);\n} */\n\n.src-scripts-components-InputPreview-__inputPreview___error {\n}", ""]);
// Exports
exports.locals = {
	"inputPreview": "src-scripts-components-InputPreview-__inputPreview___inputPreview",
	"clearMargins": "src-scripts-components-InputPreview-__inputPreview___clearMargins",
	"singleChar": "src-scripts-components-InputPreview-__inputPreview___singleChar",
	"error": "src-scripts-components-InputPreview-__inputPreview___error"
};