// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import identity from 'lodash/identity';

import type { ID_TYPE } from '../../types/common';
import StudyParticipantGraphics from '../StudyParticipantGraphics';

import styles from './participantGraphicsContent.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/participants';

type StudyParticipantGraphicsContentPropTypes = {
  onLoad: Function,
  participant: Object,
  study: ID_TYPE,
  studyType: string,
  studyName: string,
  isFetching: boolean,
  phases: Array<string>,
  results: Array<Object>
};


const StudyParticipantGraphicsContent = ({
  onLoad,
  participant,
  study,
  studyType,
  studyName,
  isFetching,
  results,
  phases,
}: StudyParticipantGraphicsContentPropTypes) => {

  const { id, first_name, last_name} = participant || {};

  useEffect(() => {
    onLoad(study, id);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>
          {`Gráficas de ${studyType}`}
        </span>        
      </div>
      <div className={styles.subtitle}>
        <strong>Estudio:&nbsp;</strong>
        <span>{`${studyName}`}</span>
      </div>
      <StudyParticipantGraphics
        loading={isFetching}
        results={results}
        phases={phases}
        name={studyType}
        participant={`${first_name} ${last_name}`}
      />
    </div>
  )
};


export default connect(
  state => {
    const participant = selectors.getSelectedStudyParticipant(state) || {};
    const study = selectors.getSelectedStudy(state);
    const phases = study ? sortBy(study.phases, ['ordinal']).map(el => el.name) : [];

    return ({
      participant,
      study: study.id,
      studyName: study.name,
      phases,
      studyType: study && study.study_type ? study.study_type.name : 'Emprendimiento',
      results: selectors.getStudyParticipantResults(state),
      isFetching: selectors.isFetchingStudyParticipantsResults(state),
    });
  },
  dispatch => ({
    onLoad(study, participant) {
      const filters = pickBy({ participant }, identity);
      dispatch(actions.startFetchStudyParticipantResults(study, filters));
    }
  })
)(StudyParticipantGraphicsContent);
