// @flow
import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';

import Navbar from '../../Navbar';
import MenuFixed from '../../MenuFixed';
import Alert from '../../Alert';
import StudyHeader from '../../StudyHeader';
import ViewContent from '../../ViewContent';
import BackendSidebar from '../../BackendSidebar';
import StudySubmissions from '../../StudySubmissions';
import StudySemaphore from '../../StudySemaphore';
import StudyResults from '../../StudyResults';
import StudyParticipants from '../../StudyParticipants';
import AddStudySubmissionPopup from '../../AddStudySubmissionPopup';
import DeleteStudySubmissionPopup from '../../DeleteSubmissionPopup';
import UpdateStudySubmissionPopup from '../../UpdateStudySubmissionPopup';
// Offline Mode Support
import OfflineModePopup from '../../OfflineModePopup';
import OfflineModeWarningPopup from '../../OfflineModeWarningPopup';
import OfflineModeOrganizationsPopup from '../../OfflineModeOrganizationsPopup';

import StudyKids from '../../StudyKids';
import StudyOrganizations from '../../StudyOrganizations';
import StudyIndicators from '../../StudyIndicatorResults';
import StudyExitProfileResults from '../../StudyResultsExitProfile';
import StudyConnectionFooter from '../../StudyConnectionFooter';

import styles from './study.css';
import * as selectors from '../../../reducers';
import * as actions from '../../../actions/studies';
import * as connectionActions from './../../../actions/connection';
import * as instrumentsActions from '../../../actions/instruments';


type StudyPropTypes = {
  id: Number,
  isOnline: Boolean,
  isRecentStatus: Boolean,
  onLoad: Function,
  clearStudySelected: Function,
  clearRecentStatus: Function,
};


const Study = ({
  id,
  isOnline,
  isRecentStatus,
  onLoad,
  clearStudySelected,
  clearRecentStatus,
}: StudyPropTypes) => {
  useEffect(() => {
    onLoad(id);

    // Bored when unmount component
    return () => clearStudySelected()
  });
  
  return (
    <div className={styles.viewPort}>
      <Navbar />
      <MenuFixed />
      {
        isOnline && 
        isRecentStatus !== null &&
        <Alert 
          message={'Aplicación conectada'}
          color={"#5ac75a"}
          duration={5000}
          closeable
          onClose={() => clearRecentStatus()}
        />
      }
      <StudyHeader id={id}/>
      <ViewContent>
        <div className={styles.aside}>
          <BackendSidebar
            studyId={id}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.card}>
            <Route path={`/studies/:id`} exact component={StudySubmissions} />
            <Route path={`/studies/:id/submissions`} component={StudySubmissions} />
            <Route path={`/studies/:id/participants`} component={StudyParticipants} />
            <Route path={`/studies/:id/results-anthropometry`} component={StudyKids} />
            <Route path={`/studies/:id/organizations`} component={StudyOrganizations} />
            <Route path={`/studies/:id/semaphore`} component={StudySemaphore} />
            <Route path={`/studies/:id/results`} component={StudyResults}/>
            <Route path={`/studies/:id/indicators`} component={StudyIndicators}/>
            <Route path={`/studies/:id/exit-profiles`} component={StudyExitProfileResults}/>
          </div>
        </div>
      </ViewContent>
      <StudyConnectionFooter />
      <AddStudySubmissionPopup />
      <DeleteStudySubmissionPopup />
      <UpdateStudySubmissionPopup />
      <OfflineModePopup />
      <OfflineModeWarningPopup />
      <OfflineModeOrganizationsPopup />
    </div>
  )
}


export default connect(
  (state, ownProps) => {
    const id = parseInt(ownProps.match.params.studyId, 10);
    const isOnline = selectors.hasInternetConnection(state);
    const isRecentStatus = selectors.isConnectionRecentStatus(state);
    return { 
      id, 
      isOnline,
      isRecentStatus,
    };
  },
  dispatch => ({
    onLoad(id, isOnline) {
      dispatch(actions.selectStudy(id));
      if (!isOnline) return;
      dispatch(actions.startFetchStudy(id));
      // dispatch(instrumentsActions.startFetchInstruments());
    },
    clearStudySelected() {
      dispatch(actions.clearStudySelected());
    },
    clearRecentStatus() {
      dispatch(connectionActions.clearConnectionStatus())
    }
  }),
  (stateProps, { onLoad, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onLoad() {
      onLoad(stateProps.id, stateProps.isOnline);
    }
  })
)(Study);
