// @flow
import * as EmailValidator from 'email-validator';
import _ from "lodash";

import { ANTHROPOMETRY_MEAN_DIFF } from '../../settings'

import type {
  BOOLEAN_ELEMENT_TYPE,
  DATE_ELEMENT_TYPE,
  DECIMAL_ELEMENT_TYPE,
  EMAIL_ELEMENT_TYPE,
  FLOAT_ELEMENT_TYPE,
  NUMERIC_OPEN_ELEMENT_TYPE,
  NULL_BOOLEAN_ELEMENT_TYPE,
  SELECT_ELEMENT_TYPE,
  TEXT_ELEMENT_TYPE,
  TEXTAREA_ELEMENT_TYPE,
  FOBI_ELEMENT_TYPE,
} from './types';

import * as errorMessages from './errorMessages';


export const validateRequired = (value: any, formElement: FOBI_ELEMENT_TYPE): boolean => {
  if (formElement.required) {
    return value && value !== undefined;
  }

  return true;
};

export const validateMeanDuplicated = (currentId: Number, formElements: Array<FOBI_ELEMENT_TYPE>, formValues: Object) => {
  const duplicityRelation = "num_repetitions";
  const duplicatedItems = _(formElements).filter(elem => elem.id === currentId && _.has(elem, duplicityRelation) && elem[duplicityRelation] > 1 ).flatten().value();
  if (!duplicatedItems.length || !formValues) return true;

  let isValid = true;
  // Extract first element from duplicatedItems (they are the same)
  const { id, type } = duplicatedItems[0];
  const key = `${id}-${type}`

  // console.log(formValues, key)
  const duplicatedKeys = Object.keys(formValues).filter(value => value.indexOf(key) !== -1);
  
  duplicatedKeys.forEach( duplicated => {
    const duplicatedValue = formValues[duplicated];
    if (Number.isNaN(duplicatedValue) || !parseFloat(duplicatedValue) > 0.0 ) return;

    duplicatedKeys.filter( key => key !== duplicated).forEach( diffKey => {
      const diffValue = formValues[diffKey];
      if (Number.isNaN(diffValue) || !parseFloat(diffValue) > 0.0 ) return;

      // Compare difference between original value and its duplicated value(s)
      if (Math.abs(duplicatedValue - diffValue) > ANTHROPOMETRY_MEAN_DIFF) isValid = false;
    })
  }) 
  
  return isValid;
}
 
export const validateBooleanElement = (
  value: boolean,
  formElement: BOOLEAN_ELEMENT_TYPE,
): ?string => {
  // if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }
  // if (typeof value !== 'boolean') { return errorMessages.INVALID_TYPE; }
  if (typeof value === 'undefined' && formElement.required) { return errorMessages.REQUIRED; }

  return undefined;
};

export const validateDateElement = (value: Date, formElement: DATE_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (value && typeof value === 'string') {
    if (Number.isNaN(Date.parse(value))) {
      return errorMessages.INVALID_TYPE;
    }
    return undefined;
  }
  if (value && value.constructor !== Date) { return errorMessages.INVALID_TYPE; }

  return undefined;
};

export const validateEmailElement = (value: string, formElement: EMAIL_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }
  if (typeof value !== 'string') { return errorMessages.INVALID_TYPE; }

  const { plugin_data: { max_length } } = formElement;

  if (max_length && value.length > max_length) {
    return errorMessages.maxLength(max_length);
  }

  if (!EmailValidator.validate(value)) {
    return errorMessages.EMAIL;
  }

  return undefined;
};

// Numeric validators
export const validateIntegerElement = (
  value: number,
  formElement: INTEGER_ELEMENT_TYPE,
): ?string => {
  const {
    min_digit_places,
    max_digit_places,
  } = formElement;
  
  if (!Number.isInteger(parseFloat(value))) {
    return errorMessages.integer(value);
  }

  const digitsCount = parseInt(value).toString().length;

  // Validate max digits
  if (max_digit_places && max_digit_places < digitsCount) {
    return errorMessages.maxDigits(max_digit_places);
  }

  // Validate min digits
  if (min_digit_places && min_digit_places > digitsCount) {
    return errorMessages.minDigits(min_digit_places);
  }

  return undefined;
};


export const validateDecimalElement = (
  value: number,
  formElement: DECIMAL_ELEMENT_TYPE,
): ?string => {
  const {
    min_digit_places,
    max_digit_places,
    min_decimal_places,
    max_decimal_places,
  } = formElement;
  
  // Validate digit & decimal places constraints
  const stringValue = value.toString();
  const splittedValue = stringValue.split('.');
  const decimalPlaces = splittedValue.length > 2 ? splittedValue[1].length : 0;
  const digitsCount = splittedValue.reduce((acc, part) => acc + part.length, 0);
  
  // Validate integer (just in the case max_decimals==min_decimals==0)
  if (min_decimal_places !== undefined && max_decimal_places !== undefined && 
    min_decimal_places === 0 && max_decimal_places === 0) {
    return validateIntegerElement(value, formElement);
  }

  // Validate max digits
  if (max_digit_places && max_digit_places < digitsCount) {
    return errorMessages.maxDigits(max_digit_places);
  }

  // Validate min digits
  if (min_digit_places && min_digit_places > digitsCount) {
    return errorMessages.minDigits(min_digit_places);
  }

  // Validate max decimal places
  if (max_decimal_places && max_decimal_places < decimalPlaces) {
    return errorMessages.maxDecimalPlaces(max_decimal_places);
  }

  // Validate min decimal places
  if (min_decimal_places && min_decimal_places > decimalPlaces) {
    return errorMessages.minDecimalPlaces(min_decimal_places);
  }

  return undefined;
};

export const validateNumericOpenElement = (
  value: number,
  formElement: NUMERIC_OPEN_ELEMENT_TYPE,
  formElements: Array<FOBI_ELEMENT_TYPE>,
  formValues: Object,
): ?string => {

  // Validate required
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value !== 'undefined') {

    // Validate duplicated
    const {
      id, 
      min_input_value,
      max_input_value, 
      min_digit_places,
      max_digit_places,
      min_decimal_places,
      max_decimal_places,
    } = formElement;
    if (!validateMeanDuplicated(id, formElements, formValues)) {
      return errorMessages.INVALID_ANTHROPOMETRY_DIFF;
    }

    if (min_digit_places !== undefined || max_digit_places !== undefined ||
      min_decimal_places !== undefined || max_decimal_places !== undefined) {
      return validateDecimalElement(value, formElement);
    }

    if(min_input_value && value < (min_input_value - Number.EPSILON)) 
      return errorMessages.minInputValue(min_input_value);

    if(max_input_value && value > (max_input_value - Number.EPSILON)) 
      return errorMessages.maxInputValue(max_input_value);
  }

  return undefined;
};

export const validateNullBooleanElement = (
  value: ?boolean,
  formElement: NULL_BOOLEAN_ELEMENT_TYPE,
): ?string => {
  // if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }
  // if (typeof value === 'undefined') { return undefined; }
  // if (typeof value !== 'boolean') { return errorMessages.INVALID_TYPE; }

  // if (value == null) { return errorMessages.REQUIRED; }

  return undefined;
};

export const validateSelectElement = (value: any, formElement: SELECT_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }

  const { plugin_data: { choices } } = formElement;

  const choicesValues = choices.map(choice => choice.value.toString());
  // const choicesNames = choices.map(choice => choice.name);

  if (!choicesValues.includes(value.toString())) {
    return errorMessages.choices();
  }

  return undefined;
};

export const validateTextElement = (value: string, formElement: TEXT_ELEMENT_TYPE): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }
  return undefined;
};

export const validateTextareaElement = (
  value: string,
  formElement: TEXTAREA_ELEMENT_TYPE,
): ?string => {
  if (!validateRequired(value, formElement)) { return errorMessages.REQUIRED; }

  if (typeof value === 'undefined') { return undefined; }

  const { plugin_data: { max_length } } = formElement;

  if (max_length && value.length > max_length) {
    return errorMessages.maxLength(max_length);
  }

  return undefined;
};

export const validateRadioOptionsElement = (
  value: Object,
  formElement: any,
): ?string => {

  if (typeof value === 'undefined' && formElement.required) { return errorMessages.REQUIRED; }

  if (typeof value !== 'undefined' && formElement.required) {
    const choices = value.choices || [];
    if (formElement.required && choices.length === 0) {
      return errorMessages.REQUIRED;
    }
  }

  // if (value.text == null || value.text === '') {
  //   return errorMessages.REQUIRED;
  // }
}
