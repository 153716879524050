// @flow
import { ANTHROPOMETRY_MEAN_DIFF } from '../../settings'

export const REQUIRED = 'Este valor es requerido';

export const INVALID_TYPE = 'Tipo de dato no esperado';

export const maxDigits = (digits: number): string => `La cantidad máxima de dígitos (parte entera) es ${digits}`;
export const minDigits = (digits: number): string => `La cantidad mínima de dígitos (parte entera) es ${digits}`;
export const maxDecimalPlaces = (decimalPlaces: number): string => `La cantidad máxima de decimales es ${decimalPlaces}`;
export const minDecimalPlaces = (decimalPlaces: number): string => `La cantidad mínima de decimales es ${decimalPlaces}`;

export const maxInputValue = (maxValue: number): string => `El valor máximo permitido es ${maxValue}`;
export const minInputValue = (minValue: number): string => `El valor mínimo permitido es  ${minValue}`;


export const EMAIL = 'Este email no está bien formateado';

export const maxLength = (length: number): string => `La longitud del campo ingresado no debe ser mayor a ${length}`;

export const minLength = (length: number): string => `La longitud del campo ingresado no debe ser menor a ${length}`;

export const integer = (value: any): string => `El valor ${value} no es un entero`;

export const boolean = (value: any, valueType: string): string => `El valor ${value} con tipo ${valueType} no es un booleano válido`;

export const choices = (): string => 'El valor seleccionado no es una opción disponible';

export const INVALID_ANTHROPOMETRY_DIFF = `La diferencia entre los campos duplicados no puede ser mayor a ${ANTHROPOMETRY_MEAN_DIFF.toFixed(1)}`;
