// @flow
import React, { Node } from 'react';
import styles from './header.css';

type HeaderPropTypes = {
  children: Node,
};

const Header = ({
  children,
}: HeaderPropTypes) => (
  <div className={styles.header}>
    {children}
  </div>
);

export default Header;
