//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  STUDY_PARTICIPANTS_ACTION_TYPE,
  STUDY_PARTICIPANT_TYPE,
} from '../types/studyParticipants';
import * as common from './common';
import * as types from '../types/participants';
import * as userTypes from '../types/users';


export type StudyParticipantsState = {
  byId: { [ID_TYPE]: STUDY_PARTICIPANT_TYPE },
  order: Array<ID_TYPE>,
  resultsById: { [ID_TYPE]: STUDY_PARTICIPANT_TYPE },
  resultsOrder: Array<ID_TYPE>,
  offlineById: { [ID_TYPE]: STUDY_PARTICIPANT_TYPE },
  offlineOrder: Array<ID_TYPE>,
  isFetching: boolean,
  isFetchingResults: boolean,
  fetching: Array<ID_TYPE>,
  error: ERROR_TYPE,
  selected: number,
  counter: number,
  filters: Object,
  nextPage: ?number,
};

const byId = common.byId({
  added: [
    types.STUDY_PARTICIPANTS_ADD_COMPLETED,
    userTypes.USERS_ADD_COMPLETED,
    types.STUDY_PARTICIPANT_FETCH_COMPLETED
  ],
  updated: [userTypes.USERS_UPDATE_COMPLETED],
  fetched: [types.STUDY_PARTICIPANTS_FETCH_COMPLETED],
  removed: [types.STUDY_PARTICIPANTS_REMOVED],
});

const order = common.order({
  added: [
    types.STUDY_PARTICIPANTS_ADD_COMPLETED,
    userTypes.USERS_ADD_COMPLETED,
  ],
  fetched: [types.STUDY_PARTICIPANTS_FETCH_COMPLETED],
  cleared: [types.STUDY_PARTICIPANTS_FILTERS_UPDATED],
  removed: [types.STUDY_PARTICIPANTS_REMOVED],
  preferPrepend: true,
});

// Study Participant Results
const resultsById = common.byId({
  fetched: [
    types.FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED,
  ],
  cleared: [
    types.FETCH_STUDY_PARTICIPANT_RESULTS_STARTED,
  ]
});

const resultsOrder = common.order({
  replaced: [
    types.FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED,
  ],
});

// Offline Mode Support
const offlineById = common.byId({
  fetched: [types.OFFLINE_PARTICIPANTS_FETCH_COMPLETED],
  removed: [],
});

const offlineOrder = common.order({
  fetched: [types.OFFLINE_PARTICIPANTS_FETCH_COMPLETED],
  cleared: [types.OFFLINE_PARTICIPANTS_FETCH_STARTED],
  preferPrepend: true,
});

const isFetching = common.isFetching({
  started: [
    types.STUDY_PARTICIPANTS_FETCH_STARTED,
    types.OFFLINE_PARTICIPANTS_FETCH_STARTED,
  ],
  succeed: [
    types.STUDY_PARTICIPANTS_FETCH_COMPLETED,
    types.OFFLINE_PARTICIPANTS_FETCH_COMPLETED,
  ],
  failed: [
    types.STUDY_PARTICIPANTS_FETCH_FAILED,
    types.OFFLINE_PARTICIPANTS_FETCH_FAILED,
  ],
});

const isFetchingResults = common.isFetching({
  started: [
    types.FETCH_STUDY_PARTICIPANT_RESULTS_STARTED,
  ],
  succeed: [
    types.FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED,
  ],
  failed: [
    types.FETCH_STUDY_PARTICIPANT_RESULTS_FAILED,
  ],
});

const fetching = common.fetching({
  started: [
    types.STUDY_PARTICIPANT_FETCH_STARTED,
  ],
  succeed: [
    types.STUDY_PARTICIPANT_FETCH_COMPLETED,
  ],
  failed: [
    types.STUDY_PARTICIPANT_FETCH_FAILED,
  ],
});

const error = common.error({
  clear: [
    types.STUDY_PARTICIPANTS_FETCH_STARTED,
    types.STUDY_PARTICIPANTS_FETCH_COMPLETED,
    types.FETCH_STUDY_PARTICIPANT_RESULTS_STARTED,
    types.FETCH_STUDY_PARTICIPANT_RESULTS_COMPLETED,
    types.OFFLINE_PARTICIPANTS_FETCH_STARTED,
    types.OFFLINE_PARTICIPANTS_FETCH_COMPLETED,
  ],
  populate: [
    types.STUDY_PARTICIPANTS_FETCH_FAILED,
    types.FETCH_STUDY_PARTICIPANT_RESULTS_FAILED,
    types.OFFLINE_PARTICIPANTS_FETCH_FAILED,
  ],
});

const selected = common.mux({
  selected: [types.STUDY_PARTICIPANT_SELECTED],
  allDeselected: [types.STUDY_PARTICIPANT_UNSELECTED],
  default: -1,
});

const filters = (
  state: Object = {},
  action: STUDY_PARTICIPANTS_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.STUDY_PARTICIPANTS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }
    default: {
      return state;
    }
  }
};

const nextPage = common.nextPage({
  fetched: [types.STUDY_PARTICIPANTS_FETCH_COMPLETED],
  failed: [types.STUDY_PARTICIPANTS_FETCH_FAILED],
  reset: [types.STUDY_PARTICIPANTS_FILTERS_UPDATED],
});

const studyParticipants = combineReducers({
  byId,
  order,
  resultsById,
  resultsOrder,
  offlineById,
  offlineOrder,
  isFetching,
  isFetchingResults,
  error,
  selected,
  filters,
  nextPage,
  fetching,
});


export default studyParticipants;


// Selectors
export const getStudyParticipant = (state: StudyParticipantsState, id: ID_TYPE): ?STUDY_PARTICIPANT_TYPE => state.byId[id];
export const getStudyParticipants = (state: StudyParticipantsState): Array<?STUDY_PARTICIPANT_TYPE> => state.order.map(i => getStudyParticipant(state, i));
export const isStudyParticipantFetching = (state: StudyParticipantsState, id: ID_TYPE): boolean => state.fetching.includes(id);
export const getStudyParticipantsError = (state: StudyParticipantsState): ERROR_TYPE => state.error;
export const getSelectedStudyParticipant = (state: StudyParticipantsState): ?STUDY_PARTICIPANT_TYPE => getStudyParticipant(state, state.selected);
export const getStudyParticipantCounter = (state: StudyParticipantsState): number => state.counter;

export const isFetchingStudyParticipants = (state: StudyParticipantsState): boolean => state.isFetching;
export const isFetchingStudyParticipantsResults = (state: StudyParticipantsState): boolean => state.isFetchingResults;

export const getAllStudyParticipants = (state: StudyParticipantsState): Array<STUDY_PARTICIPANT_TYPE> => state.order.map(id => state.byId[id]);
export const getAllStudyParticipantsIds = (state: StudyParticipantsState): Array<ID_TYPE> => state.order;

export const getStudyParticipantsFilter = (state: StudyParticipantsState, filter: string): string => state.filters[filter];
export const getStudyParticipantsFilters = (state: StudyParticipantsState): Object => state.filters;

export const getStudyParticipantsNextPage = (state: StudyParticipantsState): ?number => state.nextPage;
export const hasMoreStudyParticipants = (state: StudyParticipantsState): boolean => {
  return (state.nextPage != null) && !state.isFetching && state.nextPage !== -1;
};

// Study Participant Results
export const getStudyParticipantResult = (state: StudyParticipantsState, id: ID_TYPE): ?STUDY_PARTICIPANT_TYPE => state.resultsById[id];
export const getStudyParticipantResults = (state: StudyParticipantsState): Array<?STUDY_PARTICIPANT_TYPE> => state.resultsOrder.map(i => getStudyParticipantResult(state, i));

// Offline Mode Support
export const getOfflineParticipant = (state: StudyParticipantsState, id: ID_TYPE): ?STUDY_PARTICIPANT_TYPE => state.offlineById[id];
export const getOfflineParticipants = (state: StudyParticipantsState): Array<?STUDY_PARTICIPANT_TYPE> => state.offlineOrder.map(i => getOfflineParticipant(state, i));
