// @flow
import React from 'react';
import { MdGraphicEq } from 'react-icons/lib/md';

import DataTable from '../DataTable';
import TextColumn from '../DataTable/Columns/Text';
import ButtonColumn from '../DataTable/Columns/Button';


type KidsTablePropTypes = {
  data: Array<Object>,
  openOrganizationGraphics: Function,
};


const OrganizationsTable = ({
  data,
  openOrganizationGraphics
}: KidsTablePropTypes) => (
  <DataTable
    data={data}
    columns={[
      TextColumn({
        Header: 'ID',
        accessor: 'id',
        editable: false,
        maxWidth: 50,
      }),
      TextColumn({
        Header: 'Comunidad',
        accessor: 'name',
        editable: false,
      }),
      TextColumn({
        Header: 'Municipio',
        accessor: 'sublocation',
        editable: false,
        minWidth: 150,
      }),
      TextColumn({
        Header: 'Departamento',
        accessor: 'location',
        editable: false,
        minWidth: 150,
      }),
      ButtonColumn({
        Header: '',
        accessor: 'files',
        buttonProps: {
          Icon: MdGraphicEq,
          size: 'small',
          tint: 'primary',
          isRound: true,
          title: 'Gráfica',
        },
        getOnClick: (params: { id: number }) => () => {
          openOrganizationGraphics(params.id);
        },
        minWidth: 150,
      }),
    ]}
    minRows={0}
    showPagination={false}
  />
);

export default OrganizationsTable;
