// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupContent from '../PopupContent';
import PopupHeader from '../PopupHeader';
import PopupCloseButton from '../PopupCloseButton';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import Button from '../Button';

import * as closableActions from '../../actions/closeable';
import styles from './warningPopup.css';


type OfflineModeWarningPopupPropTypes = {
  onApprove: Function,
};

const OfflineModeWarningPopup = ({
  onApprove
}: OfflineModeWarningPopupPropTypes) => (
  <Popup
    id="offlineModeWarningPopup"
    theme="main"
    transitionMs={600}
  >
    <PopupContainer
      elevation={8}
      isRound
      size="small"
    >
      <Fragment>
        <PopupHeader clearPadding isAlert>
          <PopupHeaderLeftGroup>
            <h1 className={styles.popUpTitle}>
              {"Selecciona un estudio"}
            </h1>
          </PopupHeaderLeftGroup>
          <PopupHeaderRightGroup>
            <PopupCloseButton
              tint="mediumGray"
              Icon={MdClose}
              clearMargins
              isTransparent
              isOutlined
            />
          </PopupHeaderRightGroup>
        </PopupHeader>
        <PopupContent className={styles.popUpContainer}>
          <p className={styles.warningMessage}>
            {'Primero necesitas '}
            <strong>seleccionar un estudio</strong>
             {' para poder activar el modo "Desconectado"'}
          </p>
        </PopupContent>
        <div className={styles.footer}>
          <Button
            type="button"
            title="Aceptar"
            tint="error"
            className={styles.approveButton}
            onClick={() => onApprove()}
          />
        </div>
      </Fragment>
    </PopupContainer>
  </Popup>
);


export default connect(
  undefined,
  dispatch => ({
    onApprove() {
      dispatch(closableActions.close('offlineModeWarningPopup'));
    }
  })
)(OfflineModeWarningPopup);
