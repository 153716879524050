// @flow
import React from 'react';

import styles from './dataTable.css';

type ExpanderPropTypes = {
  isExpanded?: boolean,
  className: string,
};

const Expander = ({ isExpanded = false, className = '', ...rest }: ExpanderPropTypes) => (
  <div className={`${styles.expander} ${isExpanded ? styles.open : ''} ${className}`} {...rest}>
    &bull;
  </div>
);

export default Expander;
