exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-Switch-__switch___switch {\n  display: flex;\n  flex: 1 1\n  /* flex-direction: row; */\n}\n\n.src-scripts-components-Switch-__switch___switch button {\n    flex: 1 1;\n  }\n\n.src-scripts-components-Switch-__switch___disabled {\n  pointer-events: none !important;\n}\n", ""]);
// Exports
exports.locals = {
	"switch": "src-scripts-components-Switch-__switch___switch",
	"disabled": "src-scripts-components-Switch-__switch___disabled"
};