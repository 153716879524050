exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-AddParticipantForm-__addParticipantForm___section {\n  display: flex;\n  /* border: 1px solid red; */\n  flex-direction: column;\n  padding: 4rem;\n  border-bottom: 1px solid var(--mediumGrayColor);\n}\n\n.src-scripts-components-AddParticipantForm-__addParticipantForm___row {\n  /* border: 1px solid green; */\n  display: flex;\n  flex-direction: row;\n}\n\n.src-scripts-components-AddParticipantForm-__addParticipantForm___col {\n  flex: 1 1;\n  padding: 2rem 1rem;\n}\n\n.src-scripts-components-AddParticipantForm-__addParticipantForm___form {\n  margin-bottom: 8rem;\n}\n\n.src-scripts-components-AddParticipantForm-__addParticipantForm___separator {\n  border-bottom: 1px solid var(--mediumGrayColor);\n  margin-top: 8rem;\n  margin-bottom: 1rem;\n  position: relative;\n  text-align: center\n}\n\n.src-scripts-components-AddParticipantForm-__addParticipantForm___separator span {\n    position: absolute;\n    color: var(--almostGrayColor);\n    font-size: 2rem;\n    font-weight: 800;\n    bottom: -3.2rem;\n    background: #fff;\n    padding: 2rem 3rem;\n    -webkit-transform: translateX(-9rem);\n            transform: translateX(-9rem);\n  }\n\n@media(max-width: 768px) {\n  .src-scripts-components-AddParticipantForm-__addParticipantForm___col {\n    min-width: 36rem;\n  }\n\n  .src-scripts-components-AddParticipantForm-__addParticipantForm___row {\n    flex-wrap: wrap;\n    display: inline;\n    display: initial;\n  }\n\n  .src-scripts-components-AddParticipantForm-__addParticipantForm___section {\n    padding: 2rem;\n  }\n}\n\n.src-scripts-components-AddParticipantForm-__addParticipantForm___errorMessage {\n  background: var(--errorColor);\n  color: #fff;\n  font-weight: 600;\n  padding: 2rem;\n  font-size: 1.5rem;\n  border-radius: 1rem;\n  max-width: 65rem;\n  margin: 3rem auto;\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"section": "src-scripts-components-AddParticipantForm-__addParticipantForm___section",
	"row": "src-scripts-components-AddParticipantForm-__addParticipantForm___row",
	"col": "src-scripts-components-AddParticipantForm-__addParticipantForm___col",
	"form": "src-scripts-components-AddParticipantForm-__addParticipantForm___form",
	"separator": "src-scripts-components-AddParticipantForm-__addParticipantForm___separator",
	"errorMessage": "src-scripts-components-AddParticipantForm-__addParticipantForm___errorMessage"
};