// @flow
export const API_BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ? 'http://127.0.0.1:8000'
  : 'https://api.nutricion.monad-labs.com';

export const IS_API_IN_DEV_MODE = false;
// export const SOCKET_HOST = 'wss://ws.onthebus.io/socket';
export const REQUEST_TIMEOUT = 2000000;
export const PAGE_SIZE = 50;
export const DEFAULT_SOCKET_ROLE = 'ADMIN';

export const DEFAULT_LOCATION = {
  longitude: -90.51756466528514,
  latitude: 14.6025202460013,
};

// Avoid Exit Profile Idx
export const EXIT_PROFILE_AVOIDABLE_AXIS = [
  'eje 3',
  'eje 2',
  '[v5] eje 1 - orientando a mejorar la alimentación',
]

export const flagTest = false

export const ANTHROPOMETRY_TYPE = 'anthropometry';
export const ANTHROPOMETRY_MEAN_DIFF = 0.300000001;
export const PARTICIPANT_TYPES = {
  KID_TYPE: 'kid',
  PARTICIPANT: 'participant',
};
export const ADD_ACTION_FORM = 'ADD';
export const UPDATE_ACTION_FORM = 'UPDATE';

export const CHART_VIEW_TYPE = 'CHART';
export const TABLE_VIEW_TYPE = 'TABLE';

export const FILTER_STUDY_KEY = 'study';

export const RESULTS_EXIT_PROFILE_IS_READY_KEY = 'is_ready';
export const RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY = 'last_updated';

export const calculateSample = (population) =>{
  /*
  * Calculates sample size  for a given population
  * with 95% and 5 interval
  * */
  let conInt = 5;
  let zVal=1.96; // Z value for 95%
  let zValC=3.8416; // Z value calc
  let ss=0;  // Sample size result
  if (conInt === 0) {
    alert("You must enter a confidence interval between .1 and 50.")
  }
  else {
    if (population === 0) {
      ss = 0
    } else {
      ss = ((zVal *zVal) * 0.25) / ((conInt / 100) *(conInt / 100));
      ss=ss/(1+(ss-1)/population)
    }
    return ss+.5
  }
};

// Entrepreneur points category
export const ENTREPRENEUR_POINTS_CATEGORIES = [
  {
    name: 'Naciente',
    value: 0
  },
  {
    name: 'Intermedio',
    value: 30
  },
  {
    name: 'Avanzado',
    value: 70
  }
]