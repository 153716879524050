// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';

import {
  CHART_VIEW_TYPE,
  TABLE_VIEW_TYPE,
  FILTER_STUDY_KEY,
} from '../../settings';
import { INSTRUMENT_FILTERS_ID } from '../PopupInstrumentFilters';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import ToggleView from '../ToggleView';
import ChartsView from './charts';
import TableView from '../IndicatorsTable';
import Button from '../Button';
import styles from './instrumentResults.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/indicatorResults';
import * as closeableActions from '../../actions/closeable';


type IndicatorsResultsProps = {
  results: Array<Object>,
  instrument: Object,
  isFetching: boolean,
  error: Object,
  onLoad: Function,
  openMoreFilters: Function,
};

const IndicatorsResults = ({
  results,
  instrument,
  isFetching,
  error,
  onLoad,
  openMoreFilters,
}: IndicatorsResultsProps) => {
  const [ viewType, setViewType ] = useState(TABLE_VIEW_TYPE);

  useEffect(() => {
    onLoad(instrument.id);
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.spaced}`}>
        <Button
          title="Más filtros"
          size='small'
          tint='mediumGray'
          onClick={() => openMoreFilters()}
          className={styles.approveButton}
        />
        <ToggleView
          value={viewType}
          onChange={val => setViewType(val)}
        />
      </div>
      <div className={styles.content}>
        {
          (!isFetching && Object.keys(results).length > 0) && (
            <> 
              {
                viewType === TABLE_VIEW_TYPE
                ? <TableView results={results} />
                : <ChartsView results={results} />
              }
            </>
          )
        }
        {
          isFetching && (
            <FullLoader
              tint="mediumGray"
              title="Cargando resultados"
            />
          )
        }
        {
          Object.keys(results).length === 0 && !isFetching && (
            <FullPlaceholder />
          )
        }
      </div>
    </div>
  )
};

export default connect(
  state => {
    // const studyId = selectors.getInstrumentsFilter(state, FILTER_STUDY_KEY);
    const results = selectors.getIndicatorResults(state) || [];

    const groupedResults = groupBy(results, "axis_id");

    Object.keys(groupedResults).forEach((key) => {
      groupedResults[key] = groupBy(groupedResults[key], "category_id");
    });
    
    return ({
      results: groupedResults,
      instrument: selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state),
      isFetching: selectors.isFetchingIndicatorResults(state),
      error: selectors.getIndicatorResultsError(state),
    })
  },
  dispatch => ({
    onLoad(id) {
      dispatch(actions.startFetchInstrumentIndicatorResults(id));
    },
    openMoreFilters() {
      dispatch(closeableActions.open(INSTRUMENT_FILTERS_ID));
    },
  })
)(IndicatorsResults);
