// @flow
import range from 'lodash/range';
import React, { Fragment } from 'react';

import styles from '../OmnisearchResultDummy/omnisearchResultDummy.css';


export const TypeheadLoadingResult = ({
  size = 'medium',
  clearPaddings = false,
}: {
  size?: string,
  clearPaddings?: boolean,
}) => (
  <div className={
    `
      ${styles.result}
      ${styles.loading}
      ${styles[size]}
      ${clearPaddings ? styles.noPaddings : ''}
    `
  }
  >
    <div className={styles.iconContainer} />
    <div className={styles.data}>
      <div className={`${styles.subtitle} loadingContent`}>
        { 'Do not go' }
      </div>
      {
        size !== 'small' && (
          <div className={`${styles.subtitle} loadingContent`}>
            { 'Gentle into' }
          </div>
        )
      }
      <div className={`${styles.subtitle} ${styles.last} loadingContent`}>
        {'That good night'}
      </div>
    </div>
  </div>
);

const TypeheadLoadingResults = ({
  count = 1,
  size = 'medium',
  clearPaddings = false,
}: {
    size?: string,
    count?: number,
    clearPaddings?: boolean,
  }) => (
    <Fragment>
      {
        range(count).map(
          i => (
            <TypeheadLoadingResult
              key={i}
              size={size}
              clearPaddings={clearPaddings}
            />
          ),
        )
      }
    </Fragment>);


export default TypeheadLoadingResults;
