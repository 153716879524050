// @flow
import type { ComponentType, Node } from 'react';
import React from 'react';

import Button from '../Button';
import styles from './input.css';

type InputPropTypes = {
  size?: string,
  className?: string,
  isSquare?: boolean,
  clearMargins?: boolean,
  buttonSize?: string,
  buttonPosition?: string,
  buttonIconPosition?: string,
  buttonTint?: string,
  buttonIcon?: ComponentType<*>,
  buttonTitle?: string,
  placeholderOffsetLeft?: number,
  placeholderOffsetRight?: number,
  isButtonRound?: boolean,
  Icon?: ComponentType<*>,
  renderTags?: () => Node,
  elevation?: number,
  iconPosition?: string,
  iconColor?: string,
  iconOffsetLeft?: number,
  iconOffsetRight?: number,
  hasError?: boolean,
  isButtonOutlined?: boolean,
  isButtonTransparent?: boolean,
  isStacked?: boolean,
  isLoading?: boolean,
  isBottom?: boolean,
  isTop?: boolean,
  isRound?: boolean,
  isSingleChar?: boolean,
  isHorizontalWrapped?: boolean,
  inputRef?: ?HTMLInputElement => mixed,
  isClearContained?: boolean,
  // autofocus?: boolean
};

class Input extends React.Component<InputPropTypes> {
  getInput() {
    return this._input;
  }

  select() {
    if (this._input != null) {
      this._input.select();
    }
  }

  focus() {
    if (this._input != null) {
      this._input.focus();
    }
  }

  clear() {
    if (this._input != null) {
      this._input.value = '';
    }
  }

  _input: ?HTMLInputElement;

  // componentDidMount() {
  //   this.props.autofocus && this.focus();
  // }

  render() {
    const {
      size = 'medium',
      className = '',
      buttonSize,
      buttonPosition = 'right',
      buttonIconPosition = 'left',
      buttonTint,
      buttonIcon,
      buttonTitle,
      placeholderOffsetLeft,
      placeholderOffsetRight,
      // isButtonRound = false,
      Icon,
      renderTags,
      elevation = 1,
      iconPosition = 'right',
      iconColor,
      iconOffsetLeft,
      iconOffsetRight,
      inputRef,
      hasError = false,
      isButtonOutlined = false,
      isButtonTransparent = false,
      isSquare = false,
      clearMargins = false,
      isStacked = false,
      isLoading = false,
      isTop = false,
      isBottom = false,
      isRound = false,
      isSingleChar = false,
      isHorizontalWrapped = false,
      isClearContained = false,
      ...props
    } = this.props;

    return (
      <div className={
        `
          ${styles.inputWrapper}
          ${styles[size] ? styles[size] : ''}
          ${styles[`elevation${elevation}`]}
          ${Icon ? styles[`${iconPosition}IconPosition`] : ''}
          ${className}
          ${isButtonTransparent ? styles.inputButtonTransparent : ''}
          ${size === buttonSize && buttonPosition === 'right' ? styles.rightOutsideInput : ''}
          ${size === buttonSize && buttonPosition === 'left' ? styles.leftOutsideInput : ''}
          ${buttonPosition === 'right' ? styles.buttonRightInput : ''}
          ${buttonPosition === 'left' ? styles.buttonLeftInput : ''}
          ${hasError ? styles.error : ''}
          ${isSquare ? styles.square : ''}
          ${clearMargins ? styles.clearMargins : ''}
          ${isRound ? styles.roundInput : ''}
          ${isStacked ? styles.stacked : ''}
          ${isTop ? styles.top : ''}
          ${isBottom ? styles.bottom : ''}
          ${isSingleChar ? styles.singleChar : ''}
          ${isHorizontalWrapped ? styles.horizontalWrapped : ''}
          ${renderTags ? styles.tagged : ''}
          ${isClearContained ? styles.clearContained : ''}
        `
      }
      >
        <div className={styles.inputContainer}>
          { renderTags && renderTags() }
          <input
            className={
              `
                ${isClearContained ? styles.clearContained : ''}
              `
            }
            style={{
              paddingLeft: typeof placeholderOffsetLeft !== 'undefined'
                ? `${placeholderOffsetLeft}rem` : undefined,
              paddingRight: typeof placeholderOffsetRight !== 'undefined'
                ? `${placeholderOffsetRight}rem` : undefined,
            }}
            ref={(node) => {
              this._input = node;

              if (inputRef) {
                inputRef(node);
              }
              return node;
            }}
            {...props}
            // required={false}
          />
          {
            typeof iconPosition === 'string' && Icon
            && <Icon
              style={{
                left: typeof iconOffsetLeft !== 'undefined'
                  ? `${iconOffsetLeft}rem` : undefined,
                right: typeof iconOffsetRight !== 'undefined'
                  ? `${iconOffsetRight}rem` : undefined,
              }}
              size={24}
              className={
                `
                  ${styles[iconColor]}
                `
              }
            />
          }
        </div>
        {
          typeof buttonSize === 'string'
            && <Button
              isRight={size === buttonSize && buttonPosition === 'right'}
              isLeft={size === buttonSize && buttonPosition === 'left'}
              size={buttonSize}
              tint={buttonTint}
              Icon={buttonIcon}
              iconPosition={buttonIconPosition}
              isRound={isRound}
              isOutlined={isButtonOutlined}
              isTransparent={isButtonTransparent}
              elevation={elevation}
              title={buttonTitle}
              isLoading={isLoading}
            />
        }
      </div>
    );
  }
}


export default Input;
