// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type STUDY_TYPE = {
  id: ID_TYPE,
  name: string,
  instrument: ID_TYPE,
  type: string,
  allowed_users: Array<ID_TYPE>,
  organizations: Array<ID_TYPE>,
  phases: Array<Object>,
  filters: Array<Object>,
};

export type STUDIES_FETCH_STARTED_TYPE = {
  type: 'STUDIES_FETCH_STARTED',
  payload: {
    page: number,
    filters: Object,
  },
};
export const STUDIES_FETCH_STARTED = 'STUDIES_FETCH_STARTED';

export type STUDIES_FETCH_COMPLETED_TYPE = {
  type: 'STUDIES_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: STUDY_TYPE},
    order: Array<ID_TYPE>,
    nextPage: number,
  },
};
export const STUDIES_FETCH_COMPLETED = 'STUDIES_FETCH_COMPLETED';

export type STUDIES_FETCH_FAILED_TYPE = {
  type: 'STUDIES_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const STUDIES_FETCH_FAILED = 'STUDIES_FETCH_FAILED';

export type STUDY_ADD_STARTED_TYPE = {
  type: 'STUDY_ADD_STARTED',
  payload: STUDY_TYPE,
};
export const STUDY_ADD_STARTED = 'STUDY_ADD_STARTED';

export type STUDY_ADD_COMPLETED_TYPE = {
  type: 'STUDY_ADD_COMPLETED',
  payload: {
    id: ID_TYPE,
    oldId: ID_TYPE,
  },
};
export const STUDY_ADD_COMPLETED = 'STUDY_ADD_COMPLETED';

export type STUDY_ADD_FAILED_TYPE = {
  type: 'STUDY_ADD_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_ADD_FAILED = 'STUDY_ADD_FAILED';

export type STUDIES_FILTERS_UPDATED_TYPE = {
  type: 'STUDIES_FILTERS_UPDATED',
  payload: {
    key: string,
    value: any,
  }
};
export const STUDIES_FILTERS_UPDATED = 'STUDIES_FILTERS_UPDATED';

export type STUDY_FETCH_STARTED_TYPE = {
  type: 'STUDY_FETCH_STARTED',
  payload: ID_TYPE,
};
export const STUDY_FETCH_STARTED = 'STUDY_FETCH_STARTED';

export type STUDY_FETCH_COMPLETED_TYPE = {
  type: 'STUDY_FETCH_COMPLETED',
  payload: STUDY_TYPE,
};
export const STUDY_FETCH_COMPLETED = 'STUDY_FETCH_COMPLETED';

export type STUDY_FETCH_FAILED_TYPE = {
  type: 'STUDY_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_FETCH_FAILED = 'STUDY_FETCH_FAILED';

export type STUDY_SELECTED_TYPE = {
  type: 'STUDY_SELECTED',
  payload: ID_TYPE,
};
export const STUDY_SELECTED = 'STUDY_SELECTED';

export type STUDY_SELECTED_CLEARED_TYPE = {
  type: 'STUDY_SELECTED_CLEARED',
};
export const STUDY_SELECTED_CLEARED = 'STUDY_SELECTED_CLEARED';

export type STUDIES_STASH_CLEARED_TYPE = {
  type: 'STUDIES_STASH_CLEARED',
  payload: Array<number>,
};
export const STUDIES_STASH_CLEARED = 'STUDIES_STASH_CLEARED_TYPE';

export type STUDY_UPDATED_STARTED_TYPE = {
  type: 'STUDY_UPDATED_STARTED',
  payload: STUDY_TYPE,
};
export const STUDY_UPDATED_STARTED = 'STUDY_UPDATED_STARTED';

export type STUDY_UPDATED_COMPLETED_TYPE = {
  type: 'STUDY_UPDATED_COMPLETED',
};
export const STUDY_UPDATED_COMPLETED = 'STUDY_UPDATED_COMPLETED';

export type STUDY_UPDATED_FAILED_TYPE = {
  type: 'STUDY_UPDATED_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_UPDATED_FAILED = 'STUDY_UPDATED_FAILED';

export type STUDY_ORGANIZATIONS_UPDATE_STARTED_TYPE = {
  type: 'STUDY_ORGANIZATIONS_UPDATE_STARTED',
  payload: {
    study: ID_TYPE,
    organizations: Array<any>,
  }
};
export const STUDY_ORGANIZATIONS_UPDATE_STARTED = 'STUDY_ORGANIZATIONS_UPDATE_STARTED';

export type STUDY_ORGANIZATIONS_UPDATE_COMPLETED_TYPE = {
  type: 'STUDY_ORGANIZATIONS_UPDATE_COMPLETED',
};
export const STUDY_ORGANIZATIONS_UPDATE_COMPLETED = 'STUDY_ORGANIZATIONS_UPDATE_COMPLETED';

export type STUDY_ORGANIZATIONS_UPDATE_FAILED_TYPE = {
  type: 'STUDY_ORGANIZATIONS_UPDATE_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_ORGANIZATIONS_UPDATE_FAILED = 'STUDY_ORGANIZATIONS_UPDATE_FAILED';

export type STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED_TYPE = {
  type: 'STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED',
  payload: {
    id: number,
    order: [ID_TYPE],
  },
};
export const STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED = 'STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED';

export type STUDY_RESULTS_UPDATED_TYPE = {
  type: 'STUDY_RESULTS_UPDATED',
  payload: {
    id: ID_TYPE,
    order: Array<ID_TYPE>,
  },
};
export const STUDY_RESULTS_UPDATED = 'STUDY_RESULTS_UPDATED';

export type STUDY_INDICIATOR_RESULTS_UPDATED_TYPE = {
  type: 'STUDY_INDICATOR_RESULTS_UPDATED',
  payload: {
    id: ID_TYPE,
    order: Array<ID_TYPE>
  }
};
export const STUDY_INDICATOR_RESULTS_UPDATED = 'STUDY_INDICATOR_RESULTS_UPDATED';

export type STUDIES_INDICATORS_FILTERS_UPDATED_TYPE = {
  type: 'STUDIES_INDICATORS_FILTERS_UPDATED',
  payload: {
    key: String,
    value: any,
  }
};
export const STUDIES_INDICATORS_FILTERS_UPDATED = 'STUDIES_INDICATORS_FILTERS_UPDATED';

export type STUDIES_INDICATORS_FILTERS_CLEARED_TYPE = {
  type: 'STUDIES_INDICATORS_FILTERS_CLEARED',
};
export const STUDIES_INDICATORS_FILTERS_CLEARED = 'STUDIES_INDICATORS_FILTERS_CLEARED';

export type STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED_TYPE = {
  type: 'STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED',
  payload: {
    key: String,
    value: any,
  }
};
export const STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED = 'STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED';

export type STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED_TYPE = {
  type: 'STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED',
};
export const STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED = 'STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED';

export type STUDY_RESULTS_EXIT_PROFILE_UPDATED_TYPE ={
  type: 'STUDY_RESULTS_EXIT_PROFILE_UPDATED',
  payload: {
    id: ID_TYPE,
    order: Array<ID_TYPE>,
  }
};
export const STUDY_RESULTS_EXIT_PROFILE_UPDATED = 'STUDY_RESULTS_EXIT_PROFILE_UPDATED';

export type STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED_TYPE = {
  type: 'STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED',
  payload: {
    key: String,
    value: any,
  }
};
export const STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED = 'STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED';

export type STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED_TYPE = {
  type: 'STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED',
};
export const STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED = 'STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED';

export type STUDIES_ACTION_TYPE =
  | STUDIES_FETCH_STARTED_TYPE
  | STUDIES_FETCH_COMPLETED_TYPE
  | STUDIES_FETCH_FAILED_TYPE
  | STUDY_ADD_STARTED_TYPE
  | STUDY_ADD_COMPLETED_TYPE
  | STUDY_ADD_FAILED_TYPE
  | STUDIES_FILTERS_UPDATED_TYPE
  | STUDY_FETCH_STARTED_TYPE
  | STUDY_FETCH_COMPLETED_TYPE
  | STUDY_FETCH_FAILED_TYPE
  | STUDIES_STASH_CLEARED_TYPE
  | STUDY_SELECTED_TYPE
  | STUDY_SELECTED_CLEARED_TYPE
  | STUDY_UPDATED_STARTED_TYPE
  | STUDY_UPDATED_COMPLETED_TYPE
  | STUDY_UPDATED_FAILED_TYPE
  | STUDY_ORGANIZATIONS_UPDATE_STARTED_TYPE
  | STUDY_ORGANIZATIONS_UPDATE_COMPLETED_TYPE
  | STUDY_ORGANIZATIONS_UPDATE_FAILED_TYPE
  | STUDY_RESULTS_UPDATED_TYPE
  | STUDY_INDICIATOR_RESULTS_UPDATED_TYPE
  | STUDIES_INDICATORS_FILTERS_UPDATED_TYPE
  | STUDIES_INDICATORS_FILTERS_CLEARED_TYPE
  | STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED_TYPE
  | STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED_TYPE
  | STUDY_RESULTS_EXIT_PROFILE_UPDATED_TYPE
  | STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED_TYPE
  | STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED_TYPE;
