exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/font.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-Alert-__alert___alert {\n  color: white;\n  font-family: var(--secondaryFont);\n  font-size: 12pt;\n  font-weight: bold;\n  padding: 20px;\n  text-align: center;\n}\n\n.src-scripts-components-Alert-__alert___closeBtn {\n  color: white;\n  cursor: pointer;\n  margin-left: 15px;\n  float: right;\n  font-size: 22px;\n  font-weight: bold;\n  line-height: 20px;\n  -webkit-transition: 0.3s;\n  transition: 0.3s;\n}\n\n.src-scripts-components-Alert-__alert___hide {\n  display: none;\n  -webkit-transition: 0.5s;\n  transition: 0.5s;\n}\n\n.src-scripts-components-Alert-__alert___closeBtn:hover {\n  color: black;\n}", ""]);
// Exports
exports.locals = {
	"alert": "src-scripts-components-Alert-__alert___alert",
	"closeBtn": "src-scripts-components-Alert-__alert___closeBtn",
	"hide": "src-scripts-components-Alert-__alert___hide"
};