// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type INDICATOR_RESULT_TYPE = {
  id: ID_TYPE,
  value: number,
  name: string,
};

export type INDICATOR_RESULTS_FETCH_STARTED_TYPE = {
  type: 'INDICATOR_RESULTS_FETCH_STARTED',
  payload: Object,
};
export const INDICATOR_RESULTS_FETCH_STARTED = 'INDICATOR_RESULTS_FETCH_STARTED';

export type INDICATOR_RESULTS_FETCH_COMPLETED_TYPE = {
  type: 'INDICATOR_RESULTS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: INDICATOR_RESULT_TYPE},
    order: Array<number>,
  },
};
export const INDICATOR_RESULTS_FETCH_COMPLETED = 'INDICATOR_RESULTS_FETCH_COMPLETED';

export type INDICATOR_RESULTS_FETCH_FAILED_TYPE = {
  type: 'INDICATOR_RESULTS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const INDICATOR_RESULTS_FETCH_FAILED = 'INDICATOR_RESULTS_FETCH_FAILED';

export type INDICATOR_RESULTS_FILTERS_UPDATED_TYPE = {
  type: 'INDICATOR_RESULTS_FILTERS_UPDATED',
  payload: {
    key: String,
    value: any,
  }
};
export const INDICATOR_RESULTS_FILTERS_UPDATED = 'INDICATOR_RESULTS_FILTERS_UPDATED';

export type INDICATOR_RESULTS_FILTERS_CLEARED_TYPE = {
  type: 'INDICATOR_RESULTS_FILTERS_CLEARED',
}
export const INDICATOR_RESULTS_FILTERS_CLEARED = 'INDICATOR_RESULTS_FILTERS_CLEARED';


export type STUDY_INDICATOR_RESULTS_STARTED_TYPE = {
  type: 'STUDY_INDICATOR_RESULTS_STARTED',
  payload: {
    studyId: ID_TYPE,
    phase: number,
  }
};
export const STUDY_INDICATOR_RESULTS_STARTED = 'STUDY_INDICATOR_RESULTS_STARTED';

export type STUDY_INDICATOR_RESULTS_COMPLETED_TYPE = {
  type: 'STUDY_INDICATOR_RESULTS_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: INDICATOR_RESULT_TYPE},
    order: Array<number>,
  }
};
export const STUDY_INDICATOR_RESULTS_COMPLETED = 'STUDY_INDICATOR_RESULTS_COMPLETED';

export type STUDY_INDICATOR_RESULTS_FAILED_TYPE = {
  type: 'STUDY_INDICATOR_RESULTS_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_INDICATOR_RESULTS_FAILED = 'STUDY_INDICATOR_RESULTS_FAILED';

export type INSTRUMENT_INDICATOR_RESULTS_STARTED_TYPE = {
  type: 'INSTRUMENT_INDICATOR_RESULTS_STARTED',
  payload: {
    id: ID_TYPE,
  }
};
export const INSTRUMENT_INDICATOR_RESULTS_STARTED = 'INSTRUMENT_INDICATOR_RESULTS_STARTED';

export type INSTRUMENT_INDICATOR_RESULTS_COMPLETED_TYPE = {
  type: 'INSTRUMENT_INDICATOR_RESULTS_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: INDICATOR_RESULT_TYPE},
    order: Array<number>,
  }
};
export const INSTRUMENT_INDICATOR_RESULTS_COMPLETED = 'INSTRUMENT_INDICATOR_RESULTS_COMPLETED';

export type INSTRUMENT_INDICATOR_RESULTS_FAILED_TYPE = {
  type: 'INSTRUMENT_INDICATOR_RESULTS_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_INDICATOR_RESULTS_FAILED = 'INSTRUMENT_INDICATOR_RESULTS_FAILED';


export type INDICATOR_RESULTS_ACTION_TYPE =
  | INDICATOR_RESULTS_FETCH_STARTED_TYPE
  | INDICATOR_RESULTS_FETCH_COMPLETED_TYPE
  | INDICATOR_RESULTS_FETCH_FAILED_TYPE
  | INDICATOR_RESULTS_FILTERS_UPDATED_TYPE
  | INDICATOR_RESULTS_FILTERS_CLEARED_TYPE
  | STUDY_INDICATOR_RESULTS_STARTED_TYPE
  | STUDY_INDICATOR_RESULTS_COMPLETED_TYPE
  | STUDY_INDICATOR_RESULTS_FAILED_TYPE
  | INSTRUMENT_INDICATOR_RESULTS_STARTED_TYPE
  | INSTRUMENT_INDICATOR_RESULTS_COMPLETED_TYPE
  | INSTRUMENT_INDICATOR_RESULTS_FAILED_TYPE;
