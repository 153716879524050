// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { FILTER_STUDY_KEY } from '../../settings';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import Results from './results';

import * as selectors from '../../reducers';


type StoplightResultsPropTypes = {
  isLoading: boolean,
  hasInstrument: boolean,
};

const StoplightResults = ({
  isLoading,
  hasInstrument
}: StoplightResultsPropTypes) => {
  if (isLoading) {
    return <FullLoader title="Cargando datos ..." tint="mediumGray"/>
  }
  if (!isLoading && !hasInstrument) {
    return <FullPlaceholder customMessage="Elija un instrumento"/>
  }

  return (
    <Results />
  )
};

export default connect(
  state => {
    const studyId = selectors.getInstrumentsFilter(state, FILTER_STUDY_KEY);
    const study = selectors.getStudy(state, studyId);
    const instrument = selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state);
    const isFetchingInstrument = selectors.isFetchingInstruments(state);
    const isFetchingStudy = study ? selectors.isFetchingStudy(state, study) : false;
    
    return ({
      hasInstrument: !!instrument,
      isLoading: isFetchingStudy || isFetchingInstrument,
    })
  },
)(StoplightResults);

