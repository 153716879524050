// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import Navbar from '../../Navbar';
import ViewContent from '../../ViewContent';
import ResultsFilters from '../../ResultsFilters';
// Offline Mode Support
import OfflineModeWarningPopup from '../../../components/OfflineModeWarningPopup'

import StopLightResults from '../../StoplightResults';
import IndicatorsResults from '../../InstrumentIndicators';
import ResultsPhasesFilter from '../../ResulsPhasesFilter';
import ResultsExitProfile from '../../InstrumentExitProfileResults';
import BackendSidebar from '../../BackendSidebar/BackendSidebarResults';
import InstrumentResults from '../../InstrumentResults';
import PopupInstrumentFilters from '../../PopupInstrumentFilters';
import ButtonClearFilters from '../../ButtonClearFilters';
import MenuFixed from '../../MenuFixed';

import styles from './results.css';
import * as actions from '../../../actions/instruments';


type ResultsPropTypes = {
  onLoad: Function,
  clearFilters: Function,
};

const Results = ({
  onLoad,
  clearFilters,
}) => {
  useEffect(() => {
    onLoad();

    return () => clearFilters();
  }, []);

  return (
    <div className={styles.viewPort}>
      <Navbar />
      <MenuFixed />
      <ViewContent overflow='scroll'>
        <div className={styles.aside}>
          <BackendSidebar />
        </div>
        <div className={styles.content}>
          <div className={styles.card}>
            <div className={styles.row}>
              <ResultsFilters />
            </div>
            <ResultsPhasesFilter />
            <Route path={`/results/`} exact component={StopLightResults}/>
            <Route path={`/results/semaphore`} component={StopLightResults}/>
            <Route path={`/results/intrument-results`} component={InstrumentResults}/>
            <Route path={'/results/indicators'} component={IndicatorsResults}/>
            <Route path={'/results/exit-profiles'} component={ResultsExitProfile}/>
          </div>
        </div>
        <ButtonClearFilters />
      </ViewContent>
      <PopupInstrumentFilters />
      <OfflineModeWarningPopup />
    </div>
  )
};

export default connect(
  undefined,
  dispatch => ({
    onLoad() {
      dispatch(actions.startFetchInstruments());
    },
    clearFilters() {
      dispatch(actions.clearInstrumentsFilters());
    }
  })
)(Results);
