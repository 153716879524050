// @flow
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import LoginForm from '../../LoginForm';
import styles from '../Login/login.css';
import * as selectors from '../../../reducers';


type LoginPropTypes = {
  isAuth: boolean,
  handleAuthenticated: Function,
};

class Login extends React.Component<LoginPropTypes>{
  componentDidMount() {
    const { isAuth, handleAuthenticated } = this.props;

    if (isAuth) {
      handleAuthenticated();
    }
  }

  render() {
    return(
      <div className={styles.login}>
        <div className={styles.loginForm}>
          <LoginForm />
        </div>
        <div className={styles.content}>
          <div className={styles.text}>
            {''}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    isAuth: selectors.isAuthenticated(state),
  }),
  dispatch => ({
    handleAuthenticated() {
      dispatch(push('studies/'));
    },
  })
)(Login);
