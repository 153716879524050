// @flow
import React from 'react';
import { MdClose } from 'react-icons/lib/md';

import Button from '../Button';

import styles from './closeableInputSearchResult.css';


type CloseableInputSearchPropTypes = {
  pictureURL?: string,
  title?: string,
  subtitle?: string,
  size?: string,
  className?: string,
  closeAction: Function
};

const CloseableInputSearchResult = ({
  pictureURL,
  title = '',
  subtitle,
  closeAction,
  size = 'medium',
  className,
}: CloseableInputSearchPropTypes) => (
  <div className={
    `
      ${styles.closeableInputSearchResult}
      ${typeof className === 'string' ? className : ''}
      ${styles[size]}
      ${typeof subtitle !== 'string' ? styles.noSubtitle : ''}
    `
  }
  >
    <div className={styles.result}>
      {
        pictureURL != null
          && (
            <div className={styles.pictureContainer}>
              <img src={pictureURL} alt="" />
            </div>
          )
      }
      <div className={styles.data}>
        <div className={styles.title}>
          { title }
        </div>
        {
        typeof subtitle !== 'undefined'
          && (
            <div className={styles.subtitle}>
              { subtitle }
            </div>
          )
        }
      </div>
    </div>
    <Button
      Icon={MdClose}
      onClick={closeAction}
      size="small"
      tint="mediumGray"
      isOutlined
      isTransparent
      clearMargins
    />
  </div>
);

export default CloseableInputSearchResult;
