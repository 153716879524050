// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Results from './results';

import Button from '../Button';
import ToggleView from '../ToggleView';
import PopupFilters, {
  POPUP_FILTERS_EXIT_PROFILE_ID,
} from './popupFilters';
import { FullLoader } from '../Loader';
import { TABLE_VIEW_TYPE } from '../../settings';
import styles from './studyResultsExitProfile.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/resultsExitProfile';
import * as studyActions from '../../actions/studies';
import * as closableActions from '../../actions/closeable';


type StudyResultsExitProfilePropTypes = {
  studyId: ID_TYPE,
  loading: booelan,
  filters: Object,
  onLoad: Function,
  clearFilters: Function,
  openModal: Function,
};


const StudyResultsExitProfile = ({
  studyId,
  loading,
  filters,
  onLoad,
  clearFilters,
  openModal,
}: StudyResultsExitProfilePropTypes) => {
  const [ viewType, setViewType ] = useState(TABLE_VIEW_TYPE);

  useEffect(() => {
    onLoad(studyId);
    return () => clearFilters();
  }, []);

  if (loading) {
    return <FullLoader tint="mediumGray" title="Cargando datos"/>
  } else {
    return(
      <div className={styles.viewContainer}>
        <div className={`${styles.row} ${styles.alignRight}`}>
          <Button
            title="Más filtros"
            size='small'
            tint='mediumGray'
            onClick={() => openModal()}
            className={styles.approveButton}
          />
          <ToggleView
            value={viewType}
            onChange={(val) => setViewType(val)}
          />
        </div>
        <Results
          study={studyId}
          viewType={viewType}
        />
        <PopupFilters />
      </div>
    );
  }

}


export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);

    const isFetchingStudy = selectors.isFetchingStudy(state, studyId);
    const study = selectors.getSelectedStudy(state);

    let loading = true;

    if (study != null && !isFetchingStudy) {
      loading = false;
    };

    return ({
      studyId,
      loading,
      filters: study ? study.filters : {},
    })
  },
  dispatch => ({
    onLoad(study) {
      dispatch(actions.startStudyResultsExitProfile(study));
    },
    onChangeFilters(key, value, study) {
      dispatch(actions.startStudyResultsExitProfile(study));
    },
    clearFilters() {
      dispatch(studyActions.clearStudiesResultsExitProfileFilters());
    },
    openModal() {
      dispatch(closableActions.open(POPUP_FILTERS_EXIT_PROFILE_ID));
    }
  })
)(StudyResultsExitProfile);
