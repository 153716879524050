// @flow
import React from 'react';

import styles from './loader.css';


type LoaderPropTypes = {
  className?: string,
  tint?: string,
  size?: string,
  isRound?: boolean,
  isInline?: boolean
};

type FullLoaderPropTypes = {
  tint?: string,
  title?: string | React$Node
} & LoaderPropTypes;

const Loader = ({
  className = '',
  tint = 'white',
  size = 'medium',
  isRound = false,
  isInline = false,
}: LoaderPropTypes) => (
  <div
    className={
      `
        ${styles.loader}
        ${typeof styles[tint] !== 'undefined' ? styles[tint] : ''}
        ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
        ${isRound ? styles.roundLoader : ''}
        ${isInline ? styles.inlineLoader : ''}
        ${typeof className !== 'undefined' ? className : ''}
      `
    }
  >
    <div className={`${styles.bounceOne}`} />
    <div className={`${styles.bounceTwo}`} />
    <div className={`${styles.bounceThree}`} />
  </div>
);


export default Loader;


export const FullLoader = ({ title, tint = 'white', ...props }: FullLoaderPropTypes) => (
  <div
    className={
      `
        ${styles.fullLoader}
        ${typeof styles[tint] !== 'undefined' ? styles[tint] : ''}
      `
    }
  >
    <Loader tint={tint} {...props} />
    {
      typeof title !== 'undefined' && (
        <div className={styles.title}>
          { title }
        </div>
      )
    }
  </div>
);
