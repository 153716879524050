// @flow
import type { ID_TYPE } from '../types/common';

import type {
  LOCATION_TYPE,
  LOCATIONS_FETCH_STARTED_TYPE,
  LOCATIONS_FETCH_COMPLETED_TYPE,
  LOCATION_SELECTED_TYPE,
} from '../types/locations';

import * as types from '../types/locations';
import { error } from './common';


export const startFetchLocations = (): LOCATIONS_FETCH_STARTED_TYPE => ({
  type: types.LOCATIONS_FETCH_STARTED,
});

export const completeFetchLocations = (
  entities: {[ID_TYPE]: LOCATION_TYPE},
  order: Array<ID_TYPE>,
) => ({
  type: types.LOCATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchLocations = error(types.LOCATIONS_FETCH_FAILED);

export const selectLocation = (id: ID_TYPE): LOCATION_SELECTED_TYPE => ({
  type: types.LOCATION_SELECTED,
  payload: id,
});
