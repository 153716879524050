// @flow
import React from 'react';

import styles from './dataTable.css';

type LoadingPropTypes = {
  loading?: boolean,
  loadingText: string,
  className: string,
};

const Loading = ({
  loading = false,
  loadingText,
  className = '',
  ...rest
}: LoadingPropTypes) => (
  loading ? (
    <div className={`${styles.loading} ${className}`} {...rest}>
      { loadingText }
    </div>
  ) : null
);

export default Loading;
