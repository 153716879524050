exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-BackendSidebar-__backendSidebar___sidebar {\n  background-color: #fff;\n  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);\n  border-radius: var(--elementBorderRadius);\n  box-sizing: border-box;\n  border: 1px solid var(--mediumGrayColor);\n  display: flex;\n  flex-direction: column;\n  padding: 4rem;\n  max-width: 200px;\n  min-width: 200px;\n  z-index: 1;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-BackendSidebar-__backendSidebar___sidebar {\n    flex-direction: column;\n    max-width: unset;\n    min-width: unset;\n    width: auto;\n  }\n}\n\n", ""]);
// Exports
exports.locals = {
	"sidebar": "src-scripts-components-BackendSidebar-__backendSidebar___sidebar"
};