// @flow
import type { Node } from 'react';
import React from 'react';

import NavbarLogo from './NavbarLogo';
import NavbarHome from './NavbarHome';
import NavbarResults from './NavbarResults';
import NavbarSeparator from './NavbarSeparator';
import NavbarSettings from './NavbarSettings';
import NavbarMenuFixed from './NavbarMenuFixed';


import styles from './navbar.css';

type NavbarContainerPropTypes = {
  children?: Node
};

type NavbarLeftGroupPropTypes = {
  children?: Node
};

type NavbarCenterGroupPropTypes = {
  children?: Node
};

type NavbarRightGroupPropTypes = {
  children?: Node
};

const NavbarContainer = ({ children }: NavbarContainerPropTypes) => (
  <div className={styles.navbar}>
    { children }
  </div>
);

export const NavbarLeftGroup = ({ children }: NavbarLeftGroupPropTypes) => (
  <div className={styles.leftGroup}>
    { children }
  </div>
);

export const NavbarCenterGroup = ({ children }: NavbarCenterGroupPropTypes) => (
  <div className={styles.centerGroup}>
    { children }
  </div>
);

export const NavbarRightGroup = ({ children }: NavbarRightGroupPropTypes) => (
  <div className={styles.rightGroup}>
    { children }
  </div>
);


export default () => (
  <NavbarContainer>
    <NavbarLeftGroup>
      <NavbarLogo />
    </NavbarLeftGroup>
    <NavbarCenterGroup />
    <NavbarRightGroup>
      <NavbarHome />
      <NavbarSeparator />
      <NavbarResults />
      <NavbarSeparator />
      <NavbarSettings />
    </NavbarRightGroup>
    <div className={styles.logoMovil}>
      <NavbarLogo />
    </div>
    <NavbarMenuFixed />
  </NavbarContainer>
);
