//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { STOPLIGHT_RESULT_TYPE } from '../types/stoplightResults';
import * as common from './common';
import * as types from '../types/stoplightResults';

export type StoplightResultsState = {
  byId: { [ID_TYPE]: STOPLIGHT_RESULT_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  filters: Object,
};

const byId = common.byId({
  fetched: [types.STOPLIGHT_RESULTS_FETCH_COMPLETED],
});

const order = common.order({
  replaced: [types.STOPLIGHT_RESULTS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.STOPLIGHT_RESULTS_FETCH_STARTED],
  succeed: [types.STOPLIGHT_RESULTS_FETCH_COMPLETED],
  failed: [types.STOPLIGHT_RESULTS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.STOPLIGHT_RESULTS_FETCH_STARTED,
    types.STOPLIGHT_RESULTS_FETCH_COMPLETED,
  ],
  populate: [
    types.STOPLIGHT_RESULTS_FETCH_FAILED
  ],
});

const stoplightResults = combineReducers({
  byId,
  order,
  isFetching,
  error,
});


export default stoplightResults;


// Selectors
export const getStoplightResult = (state: StoplightResultsState, id: ID_TYPE): ?STOPLIGHT_RESULT_TYPE => state.byId[id];
export const getStoplightResults = (state: StoplightResultsState): Array<?STOPLIGHT_RESULT_TYPE> => state.order.map(i => getStoplightResult(state, i));
export const isFetchingStoplightResults = (state: StoplightResultsState): boolean => state.isFetching;
export const getStoplightResultsError = (state: StoplightResultsState): ERROR_TYPE => state.error;
