// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import { ORGANIZATIONS_FETCH_STARTED, PAGINATED_ORGANIZATIONS_FETCH_STARTED } from '../types/organizations';
import { Organizations } from '../api';
import { arrayOfOrganizations } from '../api/schemas/organizations';

import * as selectors from '../reducers';
import * as actions from '../actions/organizations';
import * as locationsActions from '../actions/locations';
import * as sublocationsActions from '../actions/sublocations';


function* fetchOrganizations(action) {
  try {
    const token = yield select(selectors.getToken);
    let data = {
      token,
    }

    const { organizationsResponse, timeout } = yield race({
      organizationsResponse: call(
        [Organizations, 'list'],
        data,
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetch organizations saga');
    }   

    const {
      entities: { organizations, locations, sublocations },
      result,
    } = normalize(organizationsResponse, arrayOfOrganizations);

    yield put(sublocationsActions.completeFetchSublocations(sublocations, []));
    yield put(locationsActions.completeFetchLocations(locations, []));
    yield put(actions.completeFetchOrganizations(organizations, result));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchOrganizations({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchPaginatedOrganizations(action) {
  try {
    const token = yield select(selectors.getToken);
    const page = yield select(selectors.getOrganizationsNextPage);
    const filters = yield select(selectors.getOrganizationsFilters);
    const nextPage = page != null ? page : 1;

    let data = {
      token,
      filters: {
        page: nextPage,
        page_size: 15,
        ...filters,
      }
    }

    const { organizationsResponse, timeout } = yield race({
      organizationsResponse: call(
        [Organizations, 'list'],
        data,
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetch organizations saga');
    }   

    const {
      entities: { organizations },
      result,
    } = normalize(organizationsResponse.results, arrayOfOrganizations);
    yield put(actions.completeFetchPaginatedOrganizations(organizations, result, organizationsResponse.next));
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchPaginatedOrganizations({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchOrganizations(): Iterator<any> {
  yield takeEvery(
    ORGANIZATIONS_FETCH_STARTED,
    fetchOrganizations,
  );
};

export function* watchFetchPaginatedOrganizations(): Iterator<any> {
  yield takeEvery(
    PAGINATED_ORGANIZATIONS_FETCH_STARTED,
    fetchPaginatedOrganizations,
  );
};

