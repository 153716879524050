// @flow
import {
  post,
  RESTfulAPI,
  Resource,
} from '../lib/http';
import { API_BASE_URL, IS_API_IN_DEV_MODE } from '../settings';
import HTTPError from '../lib/HTTPError';

export const api = new RESTfulAPI(API_BASE_URL, 'api/v1', IS_API_IN_DEV_MODE);// process.env.NODE_ENV !== 'production');

export const login = (username: string, password: string) => post({
  url: api.getURL('token-auth'),
  data: { username, password },
  headers: { 'Content-Type': 'application/json' },
}).catch((error) => {
  const { isPlain, statusCode } = error;

  if (isPlain) {
    throw new HTTPError({
      statusCode,
      data: 'Error del servidor',
    });
  }

  throw error;
});


export const Studies = new Resource({
  name: 'studies',
  api,
  customization: {
    participantsKidSearch: {
      method: 'GET',
      urlPart: 'participant-kid-search',
      // isDetail: true,
    },
    indicatorResults: {
      method: 'GET',
      urlPart: 'results-indicators',
      isDetail: true,
    },
    anthropometryResults: {
      method: 'GET',
      urlPart: 'results-anthropometry',
      isDetail: true,
    },
    types: {
      method: 'GET',
      urlPart: 'study-types',
    },
    stoplightResults: {
      method: 'GET',
      urlPart: 'results-semaphore',
      isDetail: true,
    },
    results: {
      method: 'GET',
      urlPart: 'results',
      isDetail: true,
    },
    resultsExitProfile: {
      method: 'GET',
      urlPart: 'results-exit-profile-index',
      isDetail: true,
    }
  }
});

export const StudyOrganization = new Resource({
  name: 'studies/study-organization',
  api,
})

export const User = new Resource({
  name: 'users',
  api,
});

export const Participants = new Resource({
  name: 'participants',
  api,
  customization: {
    addFile: {
      method: 'POST',
      urlPart: 'add-file',
      isDetail: true,
    },
    files: {
      method: 'GET',
      urlPart: 'files',
      isDetail: true,
    }
  }
});

export const Files = new Resource({
  name: 'files',
  api,
  customization: {
    sign: {
      method: 'POST',
      urlPart: 'sign',
      isDetail: false,
    },
  },
});

export const Instruments = new Resource({
  name: 'instruments',
  api,
  customization: {
    questions: {
      method: 'GET',
      urlPart: 'questions',
      isDetail: true,
    },
    semaphore: {
      method: 'GET',
      urlPart: 'results-semaphore',
      isDetail: true,
    },
    results: {
      method: 'GET',
      urlPart: 'results',
      isDetail: true,
    },
    indicators: {
      method: 'GET',
      urlPart: 'results-indicators',
      isDetail: true,
    },
    resultsExitProfile: {
      method: 'GET',
      urlPart: 'results-exit-profile-index',
      isDetail: true,
    },
  },
});

export const InstrumentSubmissions = new Resource({
  name: 'instrument-submission',
  api,
  customization: {
    answers: {
      method: 'GET',
      urlPart: 'answers',
      isDetail: true,
    }
  }
});

export const Locations = new Resource({
  name: 'locations',
  api,
});

export const Sublocations = new Resource({
  name: 'sublocations',
  api,
});

export const Users = new Resource({
  name: 'users',
  api,
  customization: {
    profile: {
      method: 'GET',
      urlPart: 'profile',
      isDetail: true,
    }
  }
});

export const Kids = new Resource({
  name: 'kids',
  api,
});

export const Organizations = new Resource({
  name: 'organizations',
  api,
});