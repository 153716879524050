// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdSearch, MdPrint, MdMoreVert } from 'react-icons/lib/md';
import debounce from 'lodash/debounce';
import { api } from '../../api';
import Input from '../Input';
import PopMenu from '../PopMenu';
import Button from '../Button';

import * as actions from '../../actions/instrumentSubmissions';
import * as selectors from '../../reducers';
import styles from './studySubmissions.css';


type SearchBarPropTypes = {
  updateFilters: Function,
  reportURL: String,
};


export class SearchBar extends Component<SearchBarPropTypes> {
  constructor(props: SearchBarPropTypes) {
    super(props);

    this._debouncedMethod = debounce(this._onInputChange, 300);
  }

  componentDidMount() {
    this._debouncedMethod('');
  }

  _onInputChange(text) {
    // Some Custom Pagination methods use text field, while Django Rest Pagination uses search field
    this.props.updateFilters('search', text);
    this.props.updateFilters('text', text);
  };

  render() {
    const { reportURL } = this.props;

    return (
      <div className={styles.search}>
        <Input
          isRound
          clearMargins
          Icon={MdSearch}
          placeholder="Realiza tu búsqueda aquí"
          onChange={(e) => {
            const text = e.target.value;
            this._debouncedMethod(text);
          }}
        />
        {/* <PopMenu
          id="exportSubmissions"
          vertical="below"
          horizontal="right"
          tipPosition="right"
          isRound
          options={[
            {
              Icon: MdPrint,
              title: 'Exportar ingresos',
              href: reportURL,
              target: '_blank',
            },
          ]}
        >
          <Button
            clearBottomMargin
            isTransparent
            size="medium"
            isRound
            tint="main"
            Icon={MdMoreVert}
          />
        </PopMenu> */}
      </div>
    )
  }
};

export default connect(
  (state, ownProps) => {
    const { id } = selectors.getSelectedStudy(state) || {};;

    return ({
      reportURL: api.getURL('instrument-submission/submission-report', { study: id }),
    });
  },
  dispatch => ({
    updateFilters(key, value) {
      dispatch(actions.updateInstrumentSubmissionsFilters(key, value));
      dispatch(actions.startFetchInstrumentSubmissions());
    } 
  })
)(SearchBar);
