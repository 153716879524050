// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';

import Table from './table';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import KidGraphicsPopup from '../KidGraphicsPopup';

import * as selectors from '../../reducers';
import * as actions from '../../actions/kids';
import * as closeableActions from '../../actions/closeable';


type KidsListPropTypes = {
  kids: Array<Object>,
  isFetching: boolean,
  error: ERROR_TYPE,
  hasMore: boolean,
  fetchKids: Function,
  openKidGraphics: Function,
};


const KidsList = ({
  kids = [],
  submissions,
  isFetching,
  hasMore,
  fetchKids,
  openKidGraphics,
}: KidsListPropTypes) => (
  <div style={{ maxHeight: '62rem', overflow: 'scroll', marginBottom: "8rem" }}>
    {
      kids.length > 0 && (
        <Fragment>
          <Table
            data={kids}
            openKidGraphics={openKidGraphics}
          />
          <Waypoint
            onEnter={() => hasMore && fetchKids()}
            viewportBottom={10}
          />
        </Fragment>
      )
    }
    {
      isFetching && (
        <FullLoader
          tint="mediumGray"
          title="Cargando ingresos"
        />
      )
    }
    {
      kids.length === 0 && !isFetching && (
        <FullPlaceholder />
      )
    }
    <KidGraphicsPopup />
  </div>
);

export default connect(
  state => ({
    hasMore: selectors.hasMoreKids(state),
    isFetching: selectors.isFetchingKids(state),
    kids: selectors.getKids(state),
  }),
  dispatch => ({
    fetchKids() {
      dispatch(actions.startFetchKids());
    },
    openKidGraphics(id) {
      dispatch(actions.selectKid(id));
      dispatch(closeableActions.open('kidGraphics'));
    }
  }),
)(KidsList);
