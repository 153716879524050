// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import range from 'lodash/range';
import uuid from 'uuid';
import sortBy from 'lodash/sortBy';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import {
  INDICATOR_RESULTS_FETCH_STARTED,
  STUDY_INDICATOR_RESULTS_STARTED,
  INSTRUMENT_INDICATOR_RESULTS_STARTED,
} from '../types/indicatorResults';
import { getRandomColor } from '../utils';
import { Studies, Instruments } from '../api'
import { arrayOfIndicatorResults } from '../api/schemas/indicatorResults';

import * as selectors from '../reducers';
import * as actions from '../actions/indicatorResults';
import * as studyActions from '../actions/studies';


function* fetchStudyIndicatorsResults(action) {
  try {
    const token = yield select(selectors.getToken);
    const study = yield select(selectors.getSelectedStudy);
    let filterQuery = yield select(selectors.getIndicatorsResultsFilters);
    let studiesFilterQuery = yield select(selectors.getStudiesIndicatorsFilters);

    const query = {
      ...filterQuery,
      ...studiesFilterQuery,
    };
    const { studyId, requestTimestamp } = action.payload;
    // let filters = { phase };

    const { response, timeout } = yield race({
      response: call(
        [Studies.custom, 'indicatorResults'],
        {
          token,
          id: studyId,
          filters: { filters: JSON.stringify(query) },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });


    // Check if there is another active request
    const lastRequestTimestamp = yield select(selectors.getStudyIndicatorResultsRequestTimestamp);
    if (lastRequestTimestamp <= requestTimestamp) {
      
      const resultsUpdated = response.map(el => {
        const { value = 0, total_count = 0 } = el;
  
        const totalToPercentage = total_count != 0 ? total_count : 1;
        const percentage = (value * 100) / totalToPercentage;
  
        return ({
          ...el,
          studyId,
          percentage: percentage.toFixed(1),
        })
      });
      
      const {
        entities: { indicatorResult },
        result,
      } = normalize(resultsUpdated, arrayOfIndicatorResults);
  
      yield put(actions.completeFetchStudyIndicatorResults(indicatorResult, result));
      yield put(studyActions.updateStudyIndicatorResults(studyId, result));
    }

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchStudyInsdicatorResults({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}


function* fetchIndicatorResults(action) {
  try {
    const filters = action.payload;
    const token = yield select(selectors.getToken);

    if (filters['instrument']) {
      
    }

    const { response, timeout } = yield race({
      response: call(
        [Studies.custom, 'indicatorResults'],
        {
          token,
          id: 15,
          filters: {
            kid: 1,
          }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchIndicatorResults({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}

function* fetchInstrumentIndicators(action) {
  try {
    const token = yield select(selectors.getToken);
    let instrument = yield select(selectors.getSelectedInstrument);
    instrument = instrument ? instrument : yield select(selectors.getDefaultInstrument);
    const filters = yield select(selectors.getInstrumentsFilters);
    const id = action.payload;

    const query = {
      ...filters,
      id: undefined,
    };

    const { response, timeout } = yield race({
      response: call(
        [Instruments.custom, 'indicators'],
        {
          token,
          id: id,
          filters: { filters: JSON.stringify(query) },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    const resultsUpdated = response.map(el => {
      const { value = 0, total_count = 0 } = el;
      const percentage = (value * 100) / total_count;

      return ({
        ...el,
        percentage: percentage.toFixed(1),
      })
    });

    const {
      entities: { indicatorResult },
      result,
    } = normalize(resultsUpdated, arrayOfIndicatorResults);

    yield put(actions.completeFetchInstrumentIndicatorResults(indicatorResult, result));
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchInstrumentIndicatorResults({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchIndicatorResults(): Iterator<any> {
  yield takeEvery(
    INDICATOR_RESULTS_FETCH_STARTED,
    fetchIndicatorResults,
  );
}

export function* watchFetchStudyIndicatorResults(): Iterator<any> {
  yield takeEvery(
    STUDY_INDICATOR_RESULTS_STARTED,
    fetchStudyIndicatorsResults,
  )
}

export function* watchFetchInstrumentIndicators(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_INDICATOR_RESULTS_STARTED,
    fetchInstrumentIndicators,
  )
}
