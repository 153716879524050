//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { STUDIES_ACTION_TYPE, STUDY_TYPE } from '../types/studies';
import * as common from './common';
import * as types from '../types/studies';

export type StudiesState = {
  byId: { [ID_TYPE]: STUDY_TYPE },
  studySpotlightResultsOrder: {[ID_TYPE]: [ID_TYPE]},
  studyResults: {[ID_TYPE]: [ID_TYPE]},
  studyIndicatorResults: {[ID_TYPE]: [ID_TYPE]},
  studyExitProfileResults: {[ID_TYPE]: [ID_TYPE]},
  order: Array<ID_TYPE>,
  isFetching: boolean,
  fetching: Array<ID_TYPE>,
  error: ERROR_TYPE,
  selected: number,
  nextPage: number,
  filters: Object,
  isSubmitting: boolean,
  indicatorsFilters: Object,
  stoplightFilters: Object,
  exitProfileFilters: Object,
};

const byId = common.byId({
  added: [
    types.STUDY_ADD_COMPLETED
  ],
  fetched: [types.STUDIES_FETCH_COMPLETED],
  updated: [
    types.STUDY_UPDATED_COMPLETED,
    types.STUDY_FETCH_COMPLETED,
  ],
});

const studySpotlightResultsOrder = common.keyExtractorById({
  extractionKey: 'order',
  set: [types.STUDY_SPOTLIGHT_RESULTS_ORDER_UPDATED],
});

const studyResults = common.keyExtractorById({
  extractionKey: 'order',
  set: [types.STUDY_RESULTS_UPDATED]
});

const studyIndicatorResults = common.keyExtractorById({
  extractionKey: 'order',
  set: [types.STUDY_INDICATOR_RESULTS_UPDATED],
});

const studyExitProfileResults = common.keyExtractorById({
  extractionKey: 'order',
  set: [types.STUDY_RESULTS_EXIT_PROFILE_UPDATED]
});

const order = common.order({
  added: [
    types.STUDY_ADD_COMPLETED
  ],
  fetched: [types.STUDIES_FETCH_COMPLETED],
  cleared: [types.STUDIES_FILTERS_UPDATED],
  preferPrepend: true,
});

const isFetching = common.isFetching({
  started: [types.STUDIES_FETCH_STARTED],
  succeed: [types.STUDIES_FETCH_COMPLETED],
  failed: [types.STUDIES_FETCH_FAILED],
});

const fetching = common.fetching({
  started: [types.STUDY_FETCH_STARTED],
  succeed: [types.STUDY_FETCH_COMPLETED],
  failed: [types.STUDY_FETCH_FAILED],
});

const isSubmitting = common.isFetching({
  started: [
    types.STUDY_ADD_STARTED,
    types.STUDY_UPDATED_STARTED,
    types.STUDY_ORGANIZATIONS_UPDATE_STARTED,
  ],
  succeed: [
    types.STUDY_ADD_COMPLETED,
    types.STUDY_UPDATED_COMPLETED,
    types.STUDY_ORGANIZATIONS_UPDATE_COMPLETED,
  ],
  failed: [
    types.STUDY_ADD_FAILED,
    types.STUDY_UPDATED_FAILED,
    types.STUDY_ORGANIZATIONS_UPDATE_FAILED,
  ],
});

const error = common.error({
  clear: [
    types.STUDIES_FETCH_STARTED,
    types.STUDIES_FETCH_COMPLETED,
  ],
  populate: [
    types.STUDIES_FETCH_FAILED,
  ],
});

const selected = common.mux({
  selected: [types.STUDY_SELECTED],
  allDeselected: [types.STUDY_SELECTED_CLEARED],
  default: -1,
});

const nextPage = common.nextPage({
  fetched: [types.STUDIES_FETCH_COMPLETED],
  failed: [types.STUDIES_FETCH_FAILED],
  reset: [types.STUDIES_FILTERS_UPDATED],
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const filters = (
  state: Object = {},
  action: STUDIES_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.STUDIES_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    }
    default: {
      return state;
    }
  }
};

const indicatorsFilters = (
  state: Object = {},
  action: STUDIES_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.STUDIES_INDICATORS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    };
    case types.STUDIES_INDICATORS_FILTERS_CLEARED: {
      return {};
    };
    default: {
      return state;
    }
  }
};

const stoplightFilters = (
  state: Object = {},
  action: STUDIES_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.STUDIES_STOPLIGHT_RESULTS_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    };
    case types.STUDIES_STOPLIGHT_RESULTS_FILTERS_CLEARED: {
      return {};
    };
    default: {
      return state;
    }
  }
};

const exitProfileFilters = (
  state: Object = {},
  action: STUDIES_ACTION_TYPE,
): Object => {
  switch (action.type) {
    case types.STUDY_RESULTS_EXIT_PROFILE_FILTERS_UPDATED: {
      const { payload } = action;
      return {
        ...state,
        [payload.key]: payload.value,
      };
    };
    case types.STUDY_RESULTS_EXIT_PROFILE_FILTERS_CLEARED: {
      return {};
    };
    default: {
      return state;
    }
  }
};

const studies = combineReducers({
  byId,
  studySpotlightResultsOrder,
  order,
  isFetching,
  fetching,
  error,
  selected,
  nextPage,
  filters,
  isSubmitting,
  studyResults,
  studyIndicatorResults,
  indicatorsFilters,
  stoplightFilters,
  studyExitProfileResults,
  exitProfileFilters,
});

export default studies;


// Selectors
export const getStudy = (state: StudiesState, id: ID_TYPE): ?STUDY_TYPE => state.byId[id];
export const getStudies = (state: StudiesState): Array<?STUDY_TYPE> => state.order.map(id => getStudy(state, id));
export const isFetchingStudies = (state: StudiesState): boolean => state.isFetching;
export const isFetchingStudy = (state: StudiesState, id: ID_TYPE): boolean => state.fetching.includes(id);
export const getStudiesError = (state: StudiesState): ERROR_TYPE => state.error;
export const getSelectedStudy = (state: StudiesState): ?STUDY_TYPE => getStudy(state, state.selected);
export const getStudiesNextPage = (state: StudiesState): ?number => state.nextPage;

export const hasMoreStudies = (state: StudiesState): boolean => {
  return (state.nextPage != null) && !state.isFetching && state.nextPage !== -1;
};
export const getStudiesFilters = (state: StudiesState): Object => state.filters;
export const getStudiesFilter = (state: StudiesState, key: string): string => state.filters[key];
export const isSubmittingStudy = (state: StudiesState): boolean => state.isSubmitting;
export const getStudySpotlightResultsOrder = (state: StudiesState, id: number): [number] => state.studySpotlightResultsOrder[id];
export const getStudyResults = (state: StudiesState, id: ID_TYPE): Array<ID_TYPE> => state.studyResults[id];
export const getStudyIndicatorResults = (state: StudiesState, id: ID_TYPE) => state.studyIndicatorResults[id];
export const getStudiesIndicatorsFilters = (state: StudiesState): Obeject => state.indicatorsFilters;
export const getStudiesIndicatorsFilter = (state: StudiesState, key: string): any => state.indicatorsFilters[key];
export const getStudiesStoplightFilters = (state: StudiesState) => state.stoplightFilters;
export const getStudiesStoplightFilter = (state: StudiesState, key: string): any => state.stoplightFilters[key];
export const getStudyExitProfileResultsOrder = (state: StudiesState, id: ID_TYPE): Array<ID_TYPE> => state.studyExitProfileResults[id];
export const getStudiesExitProfileResultsFilters = (state: StudiesState): Object => state.exitProfileFilters;
export const getStudiesExitProfileResultsFilter = (state: StudiesState, key: string): any => state.studyExitProfileResults[key];
