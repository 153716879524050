// @flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { QUESTION_SET_TYPE } from '../types/questionsSets';
import * as common from './common';
import * as types from '../types/questionsSets';


export type QuestionsSetsState = {
  byId: {[ID_TYPE]: QUESTION_SET_TYPE},
  order: Array<ID_TYPE>,
};

const byId = common.byId({
  fetched: [types.QUESTIONS_SETS_FETCH_COMPLETED],
});

const order = common.order({
  fetched: [types.QUESTIONS_SETS_FETCH_COMPLETED],
});

const questionsSets = combineReducers({
  byId,
  order,
});

export default questionsSets;


export const getQuestionsSet = (state: QuestionsSetsState, id: ID_TYPE): ?QUESTION_SET_TYPE => state.byId[id];
export const getQuestionsSets = (state: QuestionsSetsState): Array<QUESTION_SET_TYPE> => state.order.map(id => getQuestionsSet(state, id));
