// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './dataTable.css';

type TdPropTypes = {
  children?: Node,
  className: string,
};

const Td = ({ children, className = '', ...rest }: TdPropTypes) => (
  <td className={`${styles.td} ${className}`} {...rest}>
    { children }
  </td>
);

export default Td;
