// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import AddStudyForm from '../AddStudyForm';

import * as locationsActions from '../../actions/locations';
import * as sublocationsActions from '../../actions/sublocations';
import * as organizationsActions from '../../actions/organizations';
import * as studyTypesActions from '../../actions/studyTypes';

import styles from './addStudyPopup.css';

export const ADD_STUDY_POPUP_ID = 'addStudyPopup';


type AddStudyPopupPropTypes = {
  onLoad: Function,
};


const AddStudyPopup = ({
  onLoad,
}: AddStudyPopupPropTypes) => {
  useEffect(() => {
    onLoad();
  });

  return (
    <Popup 
      id={ADD_STUDY_POPUP_ID}
      theme="main"
      transitionMs={600}
    >
      <PopupContainer isFullscreen>
        <PopupCloseButton
          className={styles.closeButton}
          Icon={MdClose}
          tint="mediumGray"
          isRound
        />
        <div className={styles.content}>
          <AddStudyForm />
        </div>
      </PopupContainer>
    </Popup>
  )
}

export default connect(
  undefined,
  (dispatch, ownProps) => {
    return ({
      onLoad() {
        // Fetch organzations data
        dispatch(locationsActions.startFetchLocations());
        dispatch(sublocationsActions.startFetchSublocations());
        dispatch(organizationsActions.startFetchOrganizations());

        dispatch(studyTypesActions.startFetchStudyTypes());
      }
    })
  }
)(AddStudyPopup);
