exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-PopupContent-__popupContent___popupContent {\n  border-bottom: 1px solid var(--lightGrayColor)\n}\n\n.src-scripts-components-PopupContent-__popupContent___popupContent:last-child {\n    border-bottom: 0;\n  }\n\n.src-scripts-components-PopupContent-__popupContent___round:first-child {\n    border-top-left-radius: var(--containerBorderRadius);\n    border-top-right-radius: var(--containerBorderRadius);\n  }\n\n.src-scripts-components-PopupContent-__popupContent___round:last-child {\n    border-bottom-left-radius: var(--containerBorderRadius);\n    border-bottom-right-radius: var(--containerBorderRadius);\n  }\n\n.src-scripts-components-PopupContent-__popupContent___contrasted {\n  background: var(--paleGrayColor);\n}\n\n.src-scripts-components-PopupContent-__popupContent___small {\n  padding: 3rem 1rem 0rem;\n}\n\n.src-scripts-components-PopupContent-__popupContent___medium {\n  padding: 2rem;\n}\n\n.src-scripts-components-PopupContent-__popupContent___large {\n  padding: 3rem;\n}\n\n.src-scripts-components-PopupContent-__popupContent___clearPaddings {\n  padding: 0;\n}\n\n.src-scripts-components-PopupContent-__popupContent___borderless {\n  border: 0;\n}\n\n@media(max-width: 768px) {\n  .src-scripts-components-PopupContent-__popupContent___popupContent {\n    padding: 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"popupContent": "src-scripts-components-PopupContent-__popupContent___popupContent",
	"round": "src-scripts-components-PopupContent-__popupContent___round",
	"contrasted": "src-scripts-components-PopupContent-__popupContent___contrasted",
	"small": "src-scripts-components-PopupContent-__popupContent___small",
	"medium": "src-scripts-components-PopupContent-__popupContent___medium",
	"large": "src-scripts-components-PopupContent-__popupContent___large",
	"clearPaddings": "src-scripts-components-PopupContent-__popupContent___clearPaddings",
	"borderless": "src-scripts-components-PopupContent-__popupContent___borderless"
};