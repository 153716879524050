import { useState, useEffect } from "react";

export function useWindowSize(initialHeight = undefined, initialWidth = undefined) {
  const [windowSize, setWindowSize] = useState({
    height: initialHeight || window.innerHeight,
    width: initialWidth || window.innerWidth,
  });

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });

    return () => {
      window.removeEventListener('resize', () => {});
    }
  }, []);

  return windowSize;
}

export default useWindowSize;
