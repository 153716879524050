// @flow
import React from 'react';

import NavbarTab from '../NavbarTab';

const NavbarResults = () => (
  <NavbarTab
    title="Resultados"
    to="/results"
  />
);

export default NavbarResults;
