// @flow
import find from 'lodash/find';
import keycode from 'keycode';
import React from 'react';
import { MdClose } from 'react-icons/lib/md';

import type { OPTION_TYPE } from '../Typeahead';
import Typeahead from '../Typeahead';
import AsyncTypeahead from '../Typeahead/async';
import Tags from '../Tags';

import styles from './taggedInput.css';


type TaggedInputPropTypes = {
  size?: string,
  onChange?: Array<OPTION_TYPE> => mixed,
  onBlur?: Array<OPTION_TYPE> => mixed,
  value?: Array<OPTION_TYPE>,
  items?: Array<OPTION_TYPE>,
  fetchItems?: string => Promise<Array<OPTION_TYPE>>,
  // typeaheadProps?: Object,
  tagsProps?: Object,
  inputProps?: Object,
  className?: string,
};

class TaggedInput extends React.Component<TaggedInputPropTypes> {
  _typeahead: ?Typeahead | ?AsyncTypeahead;

  render() {
    const {
      size,
      value = [],
      onChange,
      onBlur,
      items = [],
      fetchItems,
      tagsProps = {},
      inputProps = {},
      className = '',
      ...typeaheadProps
    } = this.props;

    let rValue = [];
    if (value) { rValue = value; }

    const RTypeahead = fetchItems ? AsyncTypeahead : Typeahead;

    return (
      <div
        className={
          `
            ${styles.taggedInput}
            ${className}
          `
        }
      >
        <RTypeahead
          {...typeaheadProps}
          items={
            fetchItems ? (
              undefined
            ) : items.filter(
              item => !find(rValue, ['value', item.value]),
            )
          }
          fetchItems={
            fetchItems ? (
              text => fetchItems(
                text,
              ).then(
                results => {
                  return results.filter(
                    // Extract this from this.props.value (because rValue is outdated)
                    item => !find(this.props.value, ['value', item.value]), // eslint-disable-line
                  )
                },
              )
            ) : undefined
          }
          inputProps={{
            ...inputProps,
            size,
            renderTags: () => (
              <Tags
                tagProps={{ Icon: MdClose }}
                {...tagsProps}
                iconAction={
                  (tag) => {
                    if (onChange) {
                      onChange(rValue.filter(cTag => cTag.value !== tag.value));
                    }

                    if (this._typeahead) {
                      const input = this._typeahead.getInput();

                      if (input) {
                        input.focus();
                      }
                    }
                  }
                }
                tags={rValue}
                size={size}
              />
            ),
          }}
          onBlur={() => onBlur && onBlur(rValue)}
          size={size}
          value={undefined}
          ref={(node) => {
            this._typeahead = node;

            if (this._typeahead) {
              const input = this._typeahead.getInput();

              if (input) {
                input.onkeydown = (event: KeyboardEvent) => {
                  switch (event.keyCode) {
                    case keycode('backspace'):
                      if (input.value.length === 0 && rValue.length > 0) {
                        if (onChange) {
                          onChange(rValue.slice(0, rValue.length - 1));
                        }
                      }
                      break;
                    default: break;
                  }
                };
              }
            }
          }}
          onChange={
            (tag) => {
              if (onChange) {
                onChange([
                  ...rValue,
                  ...(rValue.filter(cTag => cTag.value === tag.value).length === 0 ? [tag] : []),
                ]);
              }

              setTimeout(() => {
                if (this._typeahead != null) {
                  const input = this._typeahead.getInput();
                  if (input) {
                    input.select();
                  }
                }
              }, 100);
            }
          }
          isLocallyContained
          clearAfterSelected
        />
      </div>
    );
  }
}


export default TaggedInput;
