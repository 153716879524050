// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  ORGANIZATION_TYPE,
  ORGANIZATIONS_FETCH_STARTED_TYPE,
  ORGANIZATIONS_FETCH_COMPLETED_TYPE,
  ORGANIZATIONS_FETCH_FAILED_TYPE,
  PAGINATED_ORGANIZATIONS_FETCH_STARTED_TYPE,
  PAGINATED_ORGANIZATIONS_FETCH_COMPLETED_TYPE,
  PAGINATED_ORGANIZATIONS_FETCH_FAILED_TYPE,
  ORGANIZATIONS_ADD_STARTED_TYPE,
  ORGANIZATIONS_ADD_COMPLETED_TYPE,
  ORGANIZATIONS_ADD_FAILED_TYPE,
  ORGANIZATIONS_UPDATE_STARTED_TYPE,
  ORGANIZATIONS_UPDATE_COMPLETED_TYPE,
  ORGANIZATIONS_UPDATE_FAILED_TYPE,
  ORGANIZATIONS_REMOVED_TYPE,
  ORGANIZATIONS_REMOVE_STARTED_TYPE,
  ORGANIZATIONS_REMOVE_COMPLETED_TYPE,
  ORGANIZATIONS_REMOVE_FAILED_TYPE,
  ORGANIZATION_SELECTED_TYPE,
  ORGANIZATIONS_FILTERS_UPDATED_TYPE,
  ALL_ORGANIZATIONS_UNSELECTED_TYPE,
} from '../types/organizations';
import * as types from '../types/organizations';
import { error } from './common';

// All Organizations
export const startFetchOrganizations = (): ORGANIZATIONS_FETCH_STARTED_TYPE => ({
  type: types.ORGANIZATIONS_FETCH_STARTED,
});

export const completeFetchOrganizations = (
  entities: {[ID_TYPE]: ORGANIZATION_TYPE},
  order: Array<ID_TYPE>,
): ORGANIZATIONS_FETCH_COMPLETED_TYPE => ({
  type: types.ORGANIZATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});

export const failFetchOrganizations = error(types.ORGANIZATIONS_FETCH_FAILED);

// Paginated Organizations
export const startFetchPaginatedOrganizations = (): PAGINATED_ORGANIZATIONS_FETCH_STARTED_TYPE => ({
  type: types.PAGINATED_ORGANIZATIONS_FETCH_STARTED,
});

export const completeFetchPaginatedOrganizations = (
  entities: {[ID_TYPE]: ORGANIZATION_TYPE},
  order: Array<ID_TYPE>,
  nextPage: number,
): PAGINATED_ORGANIZATIONS_FETCH_COMPLETED_TYPE => ({
  type: types.PAGINATED_ORGANIZATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    nextPage
  },
});

export const failFetchPaginatedOrganizations = error(types.PAGINATED_ORGANIZATIONS_FETCH_FAILED);

export const updateOrganizationsFilters = (
  key: string,
  value: any,
):ORGANIZATIONS_FILTERS_UPDATED_TYPE => ({
  type: types.ORGANIZATIONS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  }
});

export const selectOrganization = (id: ID_TYPE): ORGANIZATION_SELECTED_TYPE => ({
  type: types.ORGANIZATION_SELECTED,
  payload: id,
});

export const unselectOrganizations = () => ({
  type: types.ALL_ORGANIZATIONS_UNSELECTED,
  payload: {},
});

export const startAddOrganizations = (): ORGANIZATIONS_ADD_STARTED_TYPE => ({
  type: types.ORGANIZATIONS_ADD_STARTED,
  payload: {},
});

export const completeAddOrganizations = (): ORGANIZATIONS_ADD_COMPLETED_TYPE => ({
  type: types.ORGANIZATIONS_ADD_COMPLETED,
  payload: {},
});

export const failAddOrganizations = (): ORGANIZATIONS_ADD_FAILED_TYPE => ({
  type: types.ORGANIZATIONS_ADD_FAILED,
  payload: {},
});

export const startUpdateOrganizations = (): ORGANIZATIONS_UPDATE_STARTED_TYPE => ({
  type: types.ORGANIZATIONS_UPDATE_STARTED,
  payload: {},
});

export const completeUpdateOrganizations = (): ORGANIZATIONS_UPDATE_COMPLETED_TYPE => ({
  type: types.ORGANIZATIONS_UPDATE_COMPLETED,
  payload: {},
});

export const failUpdateOrganizations = (): ORGANIZATIONS_UPDATE_FAILED_TYPE => ({
  type: types.ORGANIZATIONS_UPDATE_FAILED,
  payload: {},
});

export const removeOrganizations = (): ORGANIZATIONS_REMOVED_TYPE => ({
  type: types.ORGANIZATIONS_REMOVED,
  payload: {},
});

export const startRemoveOrganizations = (): ORGANIZATIONS_REMOVE_STARTED_TYPE => ({
  type: types.ORGANIZATIONS_REMOVE_STARTED,
  payload: {},
});

export const completeRemoveOrganizations = (): ORGANIZATIONS_REMOVE_COMPLETED_TYPE => ({
  type: types.ORGANIZATIONS_REMOVE_COMPLETED,
  payload: {},
});

export const failRemoveOrganizations = (): ORGANIZATIONS_REMOVE_FAILED_TYPE => ({
  type: types.ORGANIZATIONS_REMOVE_FAILED,
  payload: {},
});


