// @flow
import React from 'react';

import styles from './listPlaceholder.css';

const ListPlaceHolder = ({
  customMessage = '',
  replaceMessage,
}) => (
  <div className={styles.container}>
    {`${ replaceMessage ? replaceMessage : 'No hay elementos'} ${customMessage}`}
  </div>
);

export default ListPlaceHolder;
