exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-FileUploader-__fileUploader___popUpTitle {\n  font-weight: bold;\n  text-align: center;\n  margin-top: 0;\n  margin-bottom: 0;\n  margin-left: 3rem;\n  font-size: 4rem;\n  color: var(--mainColor);\n}\n\n.src-scripts-components-FileUploader-__fileUploader___popUpContainer {\n  padding: 1rem 2rem 5rem !important;\n}\n\n.src-scripts-components-FileUploader-__fileUploader___popUpMessage {\n  font-size: 16px;\n  text-align: center;\n  color: var(--greyColor);\n  margin-bottom: 3rem;\n  margin-left: 1rem;\n  margin-right: 1rem\n}\n\n.src-scripts-components-FileUploader-__fileUploader___popUpMessage b {\n    font-weight: 600;\n  }\n\n.src-scripts-components-FileUploader-__fileUploader___dropFilesMessage {\n  font-size: 20px;\n  font-weight: bolder;\n  color: var(--grayColor);\n}\n\n.src-scripts-components-FileUploader-__fileUploader___dropZoneContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 45rem;\n}\n\n.src-scripts-components-FileUploader-__fileUploader___buttonsContainer {\n  display: flex;\n  flex-direction: row;\n  margin-top: 2rem;\n  margin-bottom: -2rem;\n  justify-content: center\n}\n\n.src-scripts-components-FileUploader-__fileUploader___buttonsContainer button:nth-child(1) {\n      margin-right: 10px;\n    }", ""]);
// Exports
exports.locals = {
	"popUpTitle": "src-scripts-components-FileUploader-__fileUploader___popUpTitle",
	"popUpContainer": "src-scripts-components-FileUploader-__fileUploader___popUpContainer",
	"popUpMessage": "src-scripts-components-FileUploader-__fileUploader___popUpMessage",
	"dropFilesMessage": "src-scripts-components-FileUploader-__fileUploader___dropFilesMessage",
	"dropZoneContainer": "src-scripts-components-FileUploader-__fileUploader___dropZoneContainer",
	"buttonsContainer": "src-scripts-components-FileUploader-__fileUploader___buttonsContainer"
};