// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import StudyResultsParticipantsList from '../StudyResultsParticipantsList';
import SearchBar from './searchBar';

import * as actions from '../../actions/participants';
import * as selectors from '../../reducers';
import styles from './studyParticipants.css';


type StudyResultsParticipantsPropTypes = {
  studyId: number,
  hasScorableInstrument: boolean,
  onLoad: Function,
  clearFilters: Function
};


const StudyResultsParticipants = ({
  studyId,
  hasScorableInstrument,
  onLoad,
  clearFilters
}: StudyResultsParticipantsPropTypes) => {
  useEffect(() => {
    onLoad();
    return () => clearFilters()
  });

  return (
    <Fragment>
      <SearchBar />
      <div className={styles.container}>
        <StudyResultsParticipantsList studyId={studyId}/>
      </div>
    </Fragment>
  );
}

export default connect(
  (state) => {
    const { id } = selectors.getSelectedStudy(state) || {};
    const currentStudy = selectors.getStudy(state, id) || {};
    const hasScorableInstrument = currentStudy.instrument && currentStudy.instrument.is_scorable;

    return ({
      studyId: id,
      hasScorableInstrument,
    });
  },
  dispatch => ({
    onLoad(studyId, hasScorableInstrument) {
      dispatch(actions.updateStudyParticipantsFilters('only-with-submissions', true));
      dispatch(actions.updateStudyParticipantsFilters('study', studyId));
      hasScorableInstrument && dispatch(actions.updateStudyParticipantsFilters('scorable', true));
      dispatch(actions.startFetchStudyParticipants());
    },
    clearFilters() {
      dispatch(actions.updateStudyParticipantsFilters('only-with-submissions', ''));
      dispatch(actions.updateStudyParticipantsFilters('text', ''));
      dispatch(actions.updateStudyParticipantsFilters('scorable', ''));
    }
  }),
  (stateProps, { onLoad, ...dispatchProps }, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onLoad() {
      onLoad(stateProps.studyId, stateProps.hasScorableInstrument);
    }
  })
)(StudyResultsParticipants);
