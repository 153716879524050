// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupContent from '../PopupContent';
import PopupHeader from '../PopupHeader';
import PopupCloseButton from '../PopupCloseButton';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import Button from '../Button';

import * as selectors from '../../reducers';
import * as actions from '../../actions/instrumentSubmissions';
import * as closableActions from '../../actions/closeable';
import styles from './deleteSubmission.css';


type DeleteSubmissionPopupPropTypes = {
  submission: Object,
  deleteSubmission: Function,
  onCancel: Function,
};

const DeleteSubmissionPopup = ({
  submission,
  deleteSubmission,
  onCancel
}: DeleteSubmissionPopupPropTypes) => (
  <Popup
    id="deleteSubmissionPopup"
    theme="main"
    transitionMs={600}
  >
    <PopupContainer
      elevation={8}
      isRound
      size="small"
    >
      <PopupContent>
        <PopupHeader clearPadding isAlert>
          <PopupHeaderLeftGroup>
            <h1>
              {"¿Desea eliminar el registro?"}
            </h1>
          </PopupHeaderLeftGroup>
          <PopupHeaderRightGroup>
            <PopupCloseButton
              tint="mediumGray"
              Icon={MdClose}
              clearMargins
              isTransparent
              isOutlined
            />
          </PopupHeaderRightGroup>
        </PopupHeader>
        <div className={styles.footer}>
          <Button
            type="button"
            title="Aceptar"
            tint="gray"
            onClick={() => deleteSubmission(submission.id)}
          />
          <Button
            type="button"
            title="Cancelar"
            tint="secondary"
            className={styles.approveButton}
            onClick={() => onCancel()}
          />
        </div>
      </PopupContent>
    </PopupContainer>
  </Popup>
);


export default connect(
  state => ({
    submission: selectors.getSelectedInstrumentsubmission(state),
  }),
  dispatch => ({
    deleteSubmission(id) {
      dispatch(actions.removeInstrumentSubmissions(id));
      dispatch(closableActions.close('deleteSubmissionPopup'));
    },
    onCancel() {
      dispatch(closableActions.close('deleteSubmissionPopup'));
      dispatch(actions.unselectAllInstrumentSubmission());
    }
  })
)(DeleteSubmissionPopup);
