// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga'
import { normalize } from 'normalizr';
import { reset, initialize, getFormValues } from 'redux-form';
import { getQuestionsVisibilities } from '../components/FobiRenderer/services';
import moment from 'moment';
import _ from 'lodash';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout, del } from '../lib/http';

import {
  INSTRUMENT_SUBMISSIONS_FETCH_STARTED,
  INSTRUMENT_SUBMISSIONS_ADD_STARTED,
  INSTRUMENT_SUBMISSIONS_REMOVED,
  INSTRUMENT_SUBMISSION_FETCH_STARTED,
  INSTRUMENT_SUBMISSIONS_UPDATE_STARTED
} from '../types/instrumentSubmissions';
import { InstrumentSubmissions } from '../api';
import { ANTHROPOMETRY_TYPE } from '../settings';
import { arrayOfInstrumentSubmissions } from '../api/schemas/instrumentSubmissions';
import { arrayOfQuestions } from '../api/schemas/questions';

import * as selectors from '../reducers';
import * as actions from '../actions/instrumentSubmissions';
import * as closeableActions from '../actions/closeable';
import * as participantsActions from '../actions/participants';
import * as kidActions from '../actions/kids';
import * as questionsActions from '../actions/questions';


function* fetchInstrumentSubmissions(action) {
  const { instrumentId } = action.payload;

  try {
    const token = yield select(selectors.getToken);
    const page = yield select(selectors.getInstrumentSubmissionsNextPage);
    const filters = yield select(selectors.getInstrumentSubmissionsFilters);

    const nextPage = page != null ? page : 1;

    const { submissionsResponse, timeout } = yield race({
      submissionsResponse: call(
        [InstrumentSubmissions, 'list'],
        {
          token,
          filters: {
            page: nextPage,
            page_size: 25,
            ...filters
          },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const {
      entities: {
        instrumentSubmissions
      },
      result,
    } = normalize(submissionsResponse.results, arrayOfInstrumentSubmissions);

    yield put(actions.completeFetchInstrumentSubmissions(instrumentSubmissions, result, submissionsResponse.next));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchInstrumentSubmissions({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}

function* addInstrumentSubmission(action) {
  const { payload, meta } = action;
  let { values, offlineId } = payload;
  
  try {
    // Offline Queue Support
    // console.log(meta)
    if (meta !== undefined && ( meta.success || meta.completed )) {
      values = meta.values;
      offlineId = meta.offlineId;
    }
    
    const token = yield select(selectors.getToken);
    const study = yield select(selectors.getSelectedStudy);
    const user = yield select(selectors.getUserId);
    const username = yield select(selectors.getUsername);
    const isOnline = yield select(selectors.hasInternetConnection);

    const { phase, participant, kid } = values;

    let formValues = yield select(getFormValues("addKidForm"));
    if(!formValues) {
      formValues = yield select(getFormValues("addParticipantForm"));
    }

    let name, location, sublocation = "";
    if (formValues) {
      // The following values are just id's
      const { organization } = formValues;    
      const organizationObj = yield select(selectors.getOrganization, organization);
      
      // The following values are string
      // Offline Queue Support
      if (organizationObj) {
        name = organizationObj.name;
        location = organizationObj.location;
        sublocation = organizationObj.sublocation;
      }
    }

    // Keep just the main data as answer values...
    values = _.omit(values, ['phase', 'participant', 'instrument_type', 'kid']);

    // Remove duplicated values
    const duplicityField = "_duplicated";
    const duplicatedItems = _(Object.keys(values)).filter(key => key.indexOf(duplicityField) !== -1).map(key => {
      const splited = key.split('-');
      const id = parseInt(splited[0], 10);
      const duplicatedType = splited[1];
      let value = values[key];

      const type = duplicatedType.substring(0, duplicatedType.indexOf(duplicityField));
      if(type === 'numeric_open') {
        // float for a better mean value
        value = parseFloat(value);
      }
      // Delete duplicated from original values
      delete values[key]
      return { id, type, value };
    }).flatten().value();
    const duplicatedAnswers = _.chain(duplicatedItems).groupBy("id").value();
    
    Object.keys(duplicatedAnswers).forEach(key => {
      const duplicatedValues = duplicatedAnswers[key];
      const sample = duplicatedValues[0];
      // numeric_open is the only type supported to calculate mean values
      if(sample.type === 'numeric_open') {
        const key = `${sample.id}-${sample.type}`;
        values[key] = _(duplicatedValues).meanBy('value');
      }
      // Do nothing for other non-numeric types...
    }) 

    // Get study info & submission answer values from Redux-Form selector
    const { instrument, id } = study;
    const formValuesAnswers = yield select(getFormValues("addInstrumentSubmission"));

    // NEW: send visibilities to backend... WHY? Because the client view
    //      is our Source of Truth & we've already calculated before...
    //      Also, is a good way to reuse code.
    const questionSets = yield select(selectors.getInstrumentQuestionsSets, instrument.id);
    // Collect all instrument question IDs, in one array
    const questionsIds = questionSets.map(item => item.questions).reduce((acc, val) => acc.concat(val))
    let questions = {}
    let visibilities = {}

    for (let questionsId of questionsIds) {
      const question = yield select(selectors.getQuestion, questionsId)
      questions = {...questions, [question.ordinal]: question}
    }
    
    for (let questionSet of questionSets) {
      const { form_elements } = yield select(selectors.getQuestionsSetFormDescription, questionSet.id)
      visibilities = {
        ...visibilities,
        ...getQuestionsVisibilities(form_elements, formValuesAnswers, questions)
      } 
    }    

    // Pack answers as simple objects...
    const answers = Object.keys(values).map(key => {
      const splited = key.split('-');
      const question = parseInt(splited[0], 10);
      const type = splited[1];

      let answer = {};
      if (type === 'multiple_choice') {
        answer = {
          ...values[key]
        };
      }

      if (type === 'yes_no') {
        answer = {
          is_yes: values[key],
        }
      }

      if (type === 'open') {
        answer = {
          text: values[key],
        }
      }

      if (type === 'date') {
        answer = {
          date: moment(values[key]).format('DD/MM/YYYY'),
        }
      }

      if (type === 'numeric_open') {
        answer = {
          number: parseFloat(values[key])
        }
      }

      return ({
        question,
        type,
        ...answer
      });
    });

    const payload = {
      id: offlineId,
      participant,
      study: id,
      user,
      phase,
      answers,
      // NEW: visibilities
      visibilities,
      instrument: instrument.id,
      kid,
      creation_date: moment(),
      user_name: username,
      isConfirmed: false,
      // Temp offline data
      organization: name,
      location,
      sublocation,
    };

    if (meta !== undefined && meta.offline) {
      yield put(actions.completeOfflineAddInstrumentSubmissions({
        ...payload
      }));
    }

    // Offline Mode Support
    if (isOnline) {
      // Avoid duplicates when Online Mode is active
      if (meta !== undefined && meta.offline) {
        return
      }

      const { submissionResponse, timeout } = yield race({
        submissionResponse: call(
          [InstrumentSubmissions, 'create'],
          {
            token,
            data: { ...payload }
          },
        ),
        timeout: call(delay, REQUEST_TIMEOUT),
      });

      if (timeout) {
        throwTimeout('fetchInstruments saga');
      }

      yield put(actions.completeAddInstrumentSubmissions({
        ...submissionResponse,
        oldId: offlineId,
        newId: submissionResponse.id, 
      }));
    }

    yield put(reset('addInstrumentSubmission'));
    yield put(closeableActions.close('addStudySubmission'));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
    } = error;
    
    yield put(actions.failAddInstrumentSubmissions({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
    // Offline Support: close 
    yield delay(1500);
    yield put(closeableActions.close('addStudySubmission'));
  }
}

function* removeInstrumentSubmission(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;

    const { response, timeout } = yield race({
      submissionsResponse: call(
        [InstrumentSubmissions, 'remove'],
        {
          token,
          id
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    yield put(actions.unselectAllInstrumentSubmission());

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failRemoveInstrumentSubmissions({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchInstrumentSubmission(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;

    const { response, timeout } = yield race({
      response: call(
        [InstrumentSubmissions.custom, 'answers'],
        {
          token,
          id
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const { question_sets } = response;
    let questions = [];

    for (let i = 0; i < question_sets.length; i++) {
      const element = question_sets[i];
      questions = [ ...questions, ...element.questions ];
    };

    let answers = {};
    for (let i = 0; i < questions.length; i++) {
      const element = questions[i];

      const answer = {};
      answer['name'] = `${element.id}-${element.type}`;

      const duplicityRelation = "num_repetitions";
      if (_(element).has(duplicityRelation) && element[duplicityRelation] > 1) {
        answer['name'] += `_duplicated_${i}`;
      }

      switch (element.type) {
        case 'date':
          if (element.answer) {
            answer['value'] = new Date(`${element.answer} `);
          }
          break;

        case 'multiple_choice':
          answer['value'] = {
            choices: element.answer,
            text: element.text,
          }
          break;
      
        default:
          answer['value'] = element.answer;
          break;
      }

      answers = {
        ...answers,
        [answer.name]: answer.value,
      };
    };

    // console.log('Answers are: ', answers);

    if (response.type === ANTHROPOMETRY_TYPE) {
      yield put(kidActions.startFetchKidDataForm(response.kid));
    } else {
      yield put(participantsActions.startFetchStudyParticipant(response.participant));
    }

    yield put(initialize('addInstrumentSubmission', {
      ...answers,
      phase: response.phase,
      kid: response.kid,
      participant: response.participant,
      instrument_type: response.type,
    }));

    // Save questions and their answers
    const {
      entities,
      result,
    } = normalize(questions, arrayOfQuestions);

    yield put(questionsActions.completeFetchQuestions(entities.questions, result));
    
    yield delay(200);
    yield put(actions.completeFetchInstrumentSubmission(id));


  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchInstrumentSubmission({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* updateInstrumentSubmission(action) {
  try {
    const token = yield select(selectors.getToken);
    const { id } = yield select(selectors.getSelectedInstrumentsubmission);
    const study = yield select(selectors.getSelectedStudy);
    const user = yield select(selectors.getUserId);
    let values = action.payload;

    const { phase, participant, kid } = values;

    let formValues = yield select(getFormValues("addKidForm"));
    if(!formValues) {
      formValues = yield select(getFormValues("addParticipantForm"));
    }

    // Keep just the main data as answer values...
    values = _.omit(values, ['phase', 'participant', 'instrument_type', 'kid']);

    // Remove duplicated values
    const duplicityField = "_duplicated";
    const duplicatedItems = _(Object.keys(values)).filter(key => key.indexOf(duplicityField) !== -1).map(key => {
      const splited = key.split('-');
      const id = parseInt(splited[0], 10);
      const duplicatedType = splited[1];
      let value = values[key];

      const type = duplicatedType.substring(0, duplicatedType.indexOf(duplicityField));
      if(type === 'numeric_open') {
        // float for a better mean value
        value = parseFloat(value);
      }
      // Delete duplicated from original values
      delete values[key]
      return { id, type, value };
    }).flatten().value();
    const duplicatedAnswers = _.chain(duplicatedItems).groupBy("id").value();
    
    Object.keys(duplicatedAnswers).forEach(key => {
      const duplicatedValues = duplicatedAnswers[key];
      const sample = duplicatedValues[0];
      // numeric_open is the only type supported to calculate mean values
      if(sample.type === 'numeric_open') {
        const key = `${sample.id}-${sample.type}`;
        values[key] = _(duplicatedValues).meanBy('value');
      }
      // Do nothing for other non-numeric types...
    })

    // Get study info
    const { instrument } = study;
    const formValuesAnswers = yield select(getFormValues("addInstrumentSubmission"));

    // NEW: send visibilities to backend... WHY? Because the client view
    //      is our Source of Truth & we've already calculated before...
    //      Also, is a good way to reuse code.
    const questionSets = yield select(selectors.getInstrumentQuestionsSets, instrument.id);
    // Collect all instrument question IDs, in one array
    const questionsIds = questionSets.map(item => item.questions).reduce((acc, val) => acc.concat(val))
    
    // Maps 
    let questions = {}
    let visibilities = {}

    for (let questionsId of questionsIds) {
      const question = yield select(selectors.getQuestion, questionsId)
      questions = {...questions, [question.ordinal]: question}
    }
    
    for (let questionSet of questionSets) {
      const { form_elements } = yield select(selectors.getQuestionsSetFormDescription, questionSet.id)
      const innerVisibilities = getQuestionsVisibilities(form_elements, formValuesAnswers, questions)
      visibilities = {
        ...visibilities,
        ...innerVisibilities
      } 
    }

    // Pack answers as simple objects...
    const answers = Object.keys(values).map(key => {
      const splited = key.split('-');
      const question = parseInt(splited[0], 10);
      const type = splited[1];

      let answer = {};
      switch (type) {
        case 'multiple_choice':
          answer = {
            choices: values[key].choices 
              ? values[key].choices
              : [],
          };
          break;
        case 'yes_no':
          if (values[key] != null) {
            answer = {
              is_yes: values[key],
            }
          } else {
            return undefined;
          }
          break;
        case 'open':
          if (values[key]) {
            answer = {
              text: values[key],
            }
          } else {
            return undefined;
          }
          break;
        case 'date':
          if (values[key]) {
            answer = {
              date: moment(values[key]).format('DD/MM/YYYY'),
            }
          } else {
            return undefined;
          }
          break;
        case 'numeric_open':
          if (values[key]) {
            answer = {
              number: parseFloat(values[key])
            }
          } else {
            return undefined;
          }
          break;
        default:
          break;
      }

      return ({
        question,
        type,
        ...answer
      });
    }).filter(val => val !== undefined);

    for (let i = 0; i < answers.length; i++) {
      const { question } = answers[i];
      const { answer_id } = yield select(selectors.getQuestion, question);

      answers[i]['id'] = answer_id;
    };

    const payload = {
      participant,
      study: study.id,
      user,
      answers,
      // NEW: visibilities
      visibilities,
      instrument: study.instrument.id,
      kid,
      phase
    };

    // New requirement: anthropometry studies needs a phase...
    /* if (instrument_type !== ANTHROPOMETRY_TYPE) {
      payload['phase'] = phase;
    } */

    const { response, timeout } = yield race({
      response: call(
        [InstrumentSubmissions, 'update'],
        {
          token,
          id,
          data: payload
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    yield put(actions.completeUpdateInstrumentSubmissions());
    yield put(closeableActions.close('updateStudySubmission'));
    yield put(reset('addInstrumentSubmission'));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failUpdateInstrumentSubmissions({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchInstrumentSubmissions(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_SUBMISSIONS_FETCH_STARTED,
    fetchInstrumentSubmissions,
  );
};

export function* watchAddInstrumentSubmission(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_SUBMISSIONS_ADD_STARTED,
    addInstrumentSubmission,
  );
}

export function* watchRemoveInstrumentSubmission(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_SUBMISSIONS_REMOVED,
    removeInstrumentSubmission,
  )
}

export function* watchFetchInstrumentSubmission(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_SUBMISSION_FETCH_STARTED,
    fetchInstrumentSubmission,
  )
}

export function* watchUpdateInstrumentSubmission(): Iterator<any> {
  yield takeEvery(
    INSTRUMENT_SUBMISSIONS_UPDATE_STARTED,
    updateInstrumentSubmission,
  )
}
