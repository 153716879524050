// @flow
import React, { Fragment } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';

import type { FOBI_FORM_TYPE } from '../FobiRenderer/types';
import FobiReduxFormRenderer from '../FobiRenderer';

import * as selectors from '../../reducers';
import styles from './questionSetFormRenderer.css';


type QuestionsSetFormRendererPropTypes = {
  formDescription: FOBI_FORM_TYPE,
  showPreviews?: boolean,
  baseColor?: string,
  label: tring,
};

type ElementWrapperPropTypes = {
  wrapperKey: string,
  plugin_uid: string,
  children?: Node
};

const ElementWrapper = ({ children }: ElementWrapperPropTypes) => (
  <div className={styles.elementWraper}>
    {children}
  </div>
);


const QuestionsSetFormRenderer = ({
  formDescription,
  showPreviews = false,
  baseColor,
  label,
  ...props
}: QuestionsSetFormRendererPropTypes) => (
  <div className={styles.setContainer}>
    <label className={styles.title}>
      { label && label.includes('Componente') ? label : `Sección: ${label}`}
    </label>
    <FobiReduxFormRenderer
      globalFormName="addInstrumentSubmission"
      formDescription={formDescription}
      ElementWrapper={ElementWrapper}
      showPreviews={showPreviews}
      baseColor={baseColor}
      {...props}
    />
  </div>
);


export default connect(
  (state, { id }) => {
    
    const formDescription = selectors.getQuestionsSetFormDescription(state, id);

    return ({
      formDescription,
    })
  },
)(QuestionsSetFormRenderer);
