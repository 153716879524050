// @flow
import React from 'react';

import type { ID_TYPE } from '../../types/common';
import styles from './radioOptions.css';


type InputTypes = {
  name: string,
  value: ID_TYPE,
  label: String,
  isSelected: boolean,
  type: string,
  onChange: Function,
};


const Input = ({
  name,
  value,
  label,
  type = "radio",
  isSelected = false,
  onChange,
}: InputTypes) => (
  <label className={`
      ${isSelected ? styles.selected : ''}
    `}
  >
    <input
      type={type}
      name={name}
      value={value}
      className={styles.inputRadio}
      checked={isSelected}
      onChange={e => onChange && onChange(e.target.value)}
    />
    <p
      className={styles.label}
      dangerouslySetInnerHTML={{ __html: label }}/>
  </label>
);

export default Input;
