// @flow
import React from 'react';
import type { ComponentType } from 'react';

import type { TagPropTypes } from '../Cells/Tag';
import TagCell from '../Cells/Tag';


type TagPropsTypes = {
  accessor: string | Function,
  Header: string | ComponentType<*> | React$Node,
  editable?: boolean,
  tint?: string,
  // onChange?: (Object, string | number) => mixed,
  // inputType?: string,
};

const Tag = ({
  accessor,
  Header,
  tint,
  editable = false,
  ...props
}: TagPropsTypes) => ({
  Header,
  accessor,
  Cell: ({value, ...cProps}: TagPropTypes) => (
    typeof value === 'object' ? (
      <TagCell
        {...value}
        editable={editable}
        {...cProps}
      />
    ) : (
      <TagCell
        value={value}
        tint={tint}
        editable={editable}
        {...cProps}
      />
    )
  ),
  ...props
});

export default Tag;
