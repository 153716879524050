// @flow
import { fork, all } from 'redux-saga/effects';

import { sagas as jwtSagas } from 'nozzmo-redux-jwt';
import * as api from '../api';

import {
  watchRedirectAfterLogin,
  watchLogout,
} from './login';
import {
  watchFetchStudies,
  watchFetchStudy,
  watchAddStudy,
  watchUpdateStudy,
  watchUpdateOrganizationStudy,
} from './studies';
import {
  watchFetchInstruments,
  watchFetchInstrumentQuestions,
  watchFetchResults,
} from './instruments';
import {
  watchFetchInstrumentSubmissions,
  watchAddInstrumentSubmission,
  watchRemoveInstrumentSubmission,
  watchFetchInstrumentSubmission,
  watchUpdateInstrumentSubmission,
} from './instrumentSubmissions';
import { watchFetchLocations } from './locations';
import { watchFetchSublocations } from './sublocations';
import { watchFetchOrganizations, watchFetchPaginatedOrganizations } from './organizations';
import {
  watchFetchParticipants,
  watchFetchParticipant,
  watchFetchOfflineParticipants,
  watchRemoveParticipant,
} from './participants';
import { watchAddUser, watchUpdateUser } from './participants';
import { watchAddKid, watchFetchKids, watchFetchKidFormData } from './kids';
import {
  watchFetchIndicatorResults,
  watchFetchStudyIndicatorResults,
  watchFetchInstrumentIndicators,
} from './indicatorResults';
import { watchFetchAnthropometryResults } from './anthropometryResults';
import {
  watchFetchStoplightResults,
} from './stoplightResults';
import { watchFetchStudyTypes } from './studyTypes';
import {
  watchFetchStudyResults,
  watchFetchStudyParticipantResults,
  watchFetchInstrumentResults,
} from './studyResults';
import {
  watchFetchSignFileUrl,
  watchFilesFetch,
  watchFileDelete,
} from './files'
import {
  watchFetchResultsExitProfile,
  watchFetchStudyResultsExitProfile,
  watchFetchInstrumentResultsExitProfile,
} from './resultsExitProfile';

const extractToken = ({ token }) => token;
const getDecodedPayload = (decode, { token, user }) => ({
  ...decode(token),
  ...user,
});

const watchLogin = jwtSagas.genLoginSaga(
  api.login,
  extractToken,
  getDecodedPayload,
);

function* mainSaga(): Iterator<any> {
  yield all([
    fork(watchLogin),
    fork(watchRedirectAfterLogin),
    fork(watchLogout),
    fork(watchFetchStudies),
    fork(watchFetchInstruments),
    fork(watchFetchInstrumentQuestions),
    fork(watchFetchStudy),
    fork(watchAddStudy),
    fork(watchUpdateStudy),
    fork(watchFetchInstrumentSubmissions),
    fork(watchAddInstrumentSubmission),
    fork(watchFetchLocations),
    fork(watchFetchOrganizations),
    fork(watchFetchPaginatedOrganizations),
    fork(watchFetchSublocations),
    fork(watchFetchParticipants),
    fork(watchFetchParticipant),
    fork(watchRemoveParticipant),
    fork(watchFetchOfflineParticipants),
    fork(watchAddUser),
    fork(watchUpdateUser),
    fork(watchAddKid),
    fork(watchFetchKids),
    fork(watchFetchIndicatorResults),
    fork(watchFetchAnthropometryResults),
    fork(watchRemoveInstrumentSubmission),
    fork(watchFetchInstrumentSubmission),
    fork(watchFetchKidFormData),
    fork(watchUpdateInstrumentSubmission),
    fork(watchFetchStudyTypes),
    fork(watchUpdateOrganizationStudy),
    fork(watchFetchStoplightResults),
    fork(watchFetchStudyResults),
    fork(watchFetchStudyParticipantResults),
    fork(watchFetchStudyIndicatorResults),
    fork(watchFetchSignFileUrl),
    fork(watchFilesFetch),
    fork(watchFileDelete),
    fork(watchFetchResultsExitProfile),
    fork(watchFetchStudyResultsExitProfile),
    fork(watchFetchResults),
    fork(watchFetchInstrumentResults),
    fork(watchFetchInstrumentIndicators),
    fork(watchFetchInstrumentResultsExitProfile),
  ]);
}

export default mainSaga;
