import type {
  FILES_FETCH_FAILED_TYPE,
  FILES_FETCH_STARTED_TYPE,
  FILES_FETCH_SUCCEED_TYPE,
  FILE_UPLOAD_FAILED_TYPE,
  FILE_UPLOAD_STARTED_TYPE,
  FILE_UPLOAD_SUCCEED_TYPE,
} from '../types/files';
import * as files from '../types/files';

export const startFileUpload = (
  file: any,
  fileName: string,
  fileExtension:  string,
  uuid: string,
  participantId: ID_TYPE,
): FILE_UPLOAD_STARTED_TYPE => ({
  type: files.FILE_UPLOAD_STARTED,
  payload: {
    file,
    fileName,
    fileExtension,
    uuid,
    participantId,
  },
});

export const startFileFetch = (
  participantId: ID_TYPE,
): FILES_FETCH_STARTED_TYPE => ({
  type: files.FILES_FETCH_STARTED,
  payload: participantId,
});

export const fileFetchSucceed = (
  id: ID_TYPE,
  filesResult: any,
): FILES_FETCH_SUCCEED_TYPE => ({
  type: files.FILES_FETCH_SUCCEED,
  payload: {
    id,
    files: filesResult,
  },
});

export const startFileDelete = (
  uuid: string,
  participantId: ID_TYPE,
): FILE_DELETE_STARTED_TYPE => ({
  type: files.FILE_DELETE_STARTED,
  payload: {
    uuid,
    participantId,
  },
});

export const fileUploadSucceed = (): FILE_UPLOAD_SUCCEED_TYPE => ({
  type: files.FILE_UPLOAD_SUCCEED,
});
