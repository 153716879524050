// @flow
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import max from 'lodash/max';
import get from 'lodash/get';

type GenericStackedChartPropTypes = {
  results: {[string]: any},
  phases: Array<number>,
  name: string,
  participant: string,
};

const GenericStackedChart = ({
  results,
  phases,
  name,
  participant,
}: GenericStackedChartPropTypes) => {
  const series = [
    ...Object.keys(results).map(el => ({
      name: el.length > 10 ? `${el.slice(0, 10)}...` : el,
      data: results[el],
      type: 'column'
    })),
  ];
  
  const totals = series.map(el => el.data)
    .reduce((acc, curr) => {
      curr.forEach(function (b, i) {
        acc[i] = (acc[i] || 0) + b;
      });
      return acc;
    }, [])
    .map(el => Number.isInteger(el) ? el : el.toFixed(2));

  return (
    <ReactApexChart
      type="bar"
      options={{
        chart: {
          type: 'bar',
          stacked: true,
          zoom: {
            enabled: true,
            type: 'xy'
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 35,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          },
          formatter: function(value, { seriesIndex, dataPointIndex, w}) {
            /* let indices = w.config.series.map((item, i) => i);
            indices = indices.filter(i => !w.globals.collapsedSeriesIndices.includes(i) && get(w.config.series, `${i}.data.${dataPointIndex}`) > 0);
            if (seriesIndex === max(indices))
              return w.globals.stackedSeriesTotals[dataPointIndex]; */
            return '';
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
          },
          line: {

          }
        },
        stroke: {
          width: 1,
        },
        title: {
          text: `${name.length > 64 ? name.substr(0, 64) + '...' : name}`,
          align: 'left',
        },
        subtitle: {
          text: participant ? `${participant.length > 64 ? participant.substr(0, 64) + '...' : participant}` : '',
          align: 'left',
        },
        markers: {
          size: [0],
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter: (val) => {
              return val + ' pts'
            },
          },
        },
        xaxis: {
          categories: phases.map((el, idx) => `${el} (${totals[idx]} pts)`),
          type: 'category',
          tickAmount: 'dataPoints',
          labels: {
            rotateAlways: true,
          }
          // tickAmount: phases.lenght,
          // step: 1,
          // min: 1,
          // max: phases.lenght,
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: '#a07273',
              borderWidth: 4,
              label: {
                borderColor: '#a07273',
                style: {
                  color: '#fff',
                  background: '#a07273'
                },
                text: 'Naciente'
              }
            },
            {
              y: 30,
              borderColor: '#517dae',
              borderWidth: 4,
              label: {
                borderColor: '#517dae',
                style: {
                  color: '#fff',
                  background: '#517dae'
                },
                text: 'Intermedio'
              }
            },
            {
              y: 70,
              borderColor: '#9cb266',
              borderWidth: 4,
              label: {
                borderColor: '#9cb266',
                style: {
                  color: '#fff',
                  background: '#9cb266'
                },
                text: 'Avanzado'
              }
            },
          ]
        }
      }}
      series={series}
    />
  )
}

export default GenericStackedChart;
