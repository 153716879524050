exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___close {\n  position: absolute;\n  right: 6rem;\n  top: 5rem;\n  z-index: 4;\n  display: flex;\n  flex-direction: row;\n}\n\n.src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___approveButton {\n  margin-right: 3rem;\n}\n\n.src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___content {\n  box-sizing: border-box;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  padding: 4rem;\n  position: relative;\n  overflow: scroll;\n}\n\n.src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___loader {\n  background: rgba(0, 0, 0, 0.3);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 5;\n  -webkit-transition: opacity;\n  transition: opacity;\n  opacity: 1\n}\n\n.src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___loader.src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___hidden {\n    opacity: 0;\n    -webkit-transition-delay: .6s;\n            transition-delay: .6s;\n    z-index: -1;\n  }\n", ""]);
// Exports
exports.locals = {
	"close": "src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___close",
	"approveButton": "src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___approveButton",
	"content": "src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___content",
	"loader": "src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___loader",
	"hidden": "src-scripts-components-UpdateStudySubmissionContent-__updateStudySubmission___hidden"
};