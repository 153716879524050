// @flow
import React from 'react';
import { connect } from 'react-redux';
import { actions as jwtActions } from 'nozzmo-redux-jwt';
import { MdClose } from 'react-icons/lib/md';
import { push } from 'react-router-redux';

import Button from '../Button';
import NavbarTab from '../Navbar/NavbarTab';
import { SELECT_ORGANIZATIONS_POPUP_ID } from '../OfflineModeOrganizationsPopup';

import * as selectors from '../../reducers';
import * as actions from '../../actions/menuFixed';
import * as closeableActions from '../../actions/closeable';
import * as connectionActions from '../../actions/connection';
import * as participantActions from '../../actions/participants';

import styles from './menuFixed.css';

const MenuFixed = ({
  onLogout,
  isShow,
  offlineMode,
  onClose,
  onRedirect,
  onToggleOfflineMode,
}) =>  {
  // console.log(isShow, 'isShow');
  return (
    <div
      className={
        `
          ${styles.fullContainer}
          ${isShow ? styles.show : ''}
        `
      }>
      <Button
        title="Estudios"
        onClick={() => onRedirect('studies')}
        isTransparent
        clearMargins
        className={styles.menuItem}
      />
      <Button
        title="Resultados"
        onClick={() => onRedirect('results')}
        isTransparent
        clearMargins
        className={styles.menuItem}
      />
      <Button
        title="Cerrar sesión"
        onClick={() => onLogout()}
        isTransparent
        clearMargins
        className={styles.menuItem}
      />
      <Button
        title={`${offlineMode ? 'Desactivar' : 'Activar'} modo "Desconectado"`}
        onClick={() => onToggleOfflineMode()}
        isTransparent
        clearMargins
        className={styles.menuItem}
      />
      <Button
        isRound
        Icon={MdClose}
        onClick={() => onClose()}
        className={styles.closeButton}
      />
    </div>
  )
};

export default connect(
  state => ({
    _persist: selectors.getPersist(state),
    isShow: selectors.isShowMenuFixed(state),
    offlineMode: selectors.isOfflineMode(state),
    study: selectors.getSelectedStudy(state),
  }),
  dispatch => ({
    onLogout(_persist) {
      dispatch({
        ...jwtActions.logout(),
        state: { _persist },
      });
    },
    onClose() {
      dispatch(actions.hiddenMenuFixed());
    },
    onRedirect(route) {
      dispatch(push(`/${route}`));
      dispatch(actions.hiddenMenuFixed());
    },
    onToggleOfflineMode(active, study) {
      if (!study) {
        dispatch(closeableActions.open('offlineModeWarningPopup'));
        return;
      }

      if (active) {
        dispatch(connectionActions.setOfflineModeOff())
      } else {
        dispatch(actions.hiddenMenuFixed());
        dispatch(closeableActions.open(SELECT_ORGANIZATIONS_POPUP_ID));
      }
    }
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onLogout: () => {
      dispatchProps.onLogout(stateProps._persist);
    },
    onToggleOfflineMode: () => {      
      dispatchProps.onToggleOfflineMode(stateProps.offlineMode, stateProps.study)
    }
  }),
)(MenuFixed);

