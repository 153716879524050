// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { actions as jwtActions } from 'nozzmo-redux-jwt';

import Logo from '../../../assets/images/logofjbg.png';
import Button from '../Button';
import { RenderInput } from '../FieldRenderer';

import styles from './loginForm.css';
import * as selectors from '../../reducers';

const triggerLogin = (
  values,
  dispatch,
) => dispatch(jwtActions.startLogin(values.username, values.password));

const validate = (values) => {
  const errors = {};

  if (!values.username) { errors.username = 'Requerido'; }
  if (!values.password) { errors.password = 'Requerido'; }

  return errors;
};

type LoginFormPropTypes = {
  handleSubmit: Function,
  isLoading?: boolean,
};


const LoginForm = ({
  handleSubmit,
  isLoading = false,
}: LoginFormPropTypes) => (
  <div className={styles.loginContainer}>
    <img
      src={Logo}
      className={styles.logo}
      alt="Fundación JBG"
    />
    <hr className={styles.hr}/>
    <div className={styles.container}>
      <div className={styles.title}>
        <b>{'PROGRAMA DE NUTRICIÓN'}</b>
        <br/>
        {'MONITOREO Y EVALUACIÓN'}
      </div>
      <form
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <Field
          name="username"
          placeholder="Email"
          component={RenderInput}
        />
        <Field
          type="password"
          name="password"
          placeholder="Contraseña"
          component={RenderInput}
          className={styles.approve}
        />
        <Button
          title="INGRESAR"
          type="submit"
          isFullWidth
          isRound
          isLoading={isLoading}
        />
      </form>
    </div> 
  </div>
);


const Form = connect(
  state => ({
    isLoading: selectors.isLoginLoading(state),
  }),
)(LoginForm);

export default reduxForm({
  form: 'loginForm',
  onSubmit: triggerLogin,
  validate,
})(Form);
