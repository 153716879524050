// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import isEmptyObject from 'is-empty-object';

import { ID_TYPE } from '../../types/common';
import Button from '../Button';
import ButtonGroup from '../ButtonGroup';
import FullPlaceholder from '../ListPlaceholder';
import TableView from './table';
import ChartView from './chart';
import ParticipantsView from './participants';
import EntrepreneurChartView from './entrepreneurChart';
import PopupFilters, {
  POPUP_FILTERS_RESULTS_ID,
} from './popupFilters';
import { FullLoader } from '../Loader';
import { EXIT_PROFILE_AVOIDABLE_AXIS } from '../../settings';

import styles from './studyResults.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/studyResults';
import * as closableActions from '../../actions/closeable';


type ResultsPropTypes = {
  study: any,
  onLoad: Function,
  results: Array<Object>,
  isFetching: boolean,
  isExitProfileAvoidableAxis: boolean,
  phases: Array<number>,
  filters: Array<Object>,
  fetchStudyResults: Function,
  openModal: Function,
};

const CHART_VIEW_TYPE = 'CHART';
const TABLE_VIEW_TYPE = 'TABLE';
const PARTICIPANT_VIEW_TYPE = 'PARTICIPANT';

const ResultsList = ({
  study,
  onLoad,
  results,
  isFetching,
  phases,
  type = 'char',
  filters,
  isExitProfileAvoidableAxis,
  fetchStudyResults,
  clearStudyResults,
  openModal,
}: ResultsPropTypes) => {
  const [viewType, steViewType] = useState(TABLE_VIEW_TYPE);

  useEffect(() => {
    onLoad();

    return () => clearStudyResults();
  }, []);

  return (
    <div>
      <div className={`${styles.row} ${styles.alignRight}`}>
        <Button
          title="Más filtros"
          size='small'
          tint='mediumGray'
          onClick={() => openModal()}
          className={styles.approveButton}
        />
        <ButtonGroup
          horizontalAlign={'right'}
        >
          {
            study && 
            study.instrument && 
            study.instrument.is_scorable && (
              <Button
                title="Participantes"
                isLeft
                size='small'
                onClick={() => steViewType(PARTICIPANT_VIEW_TYPE)}
                tint={viewType === PARTICIPANT_VIEW_TYPE ? 'main' : 'mediumGray'}
              />
            )
          }
          <Button
            title="Grafica"
            isLeft={study && study.instrument && !study.instrument.is_scorable}
            isMiddle={study && study.instrument && study.instrument.is_scorable}
            size='small'
            onClick={() => steViewType(CHART_VIEW_TYPE)}
            tint={viewType === CHART_VIEW_TYPE ? 'main' : 'mediumGray'}
          />
          <Button
            title="Tabla"
            isRight
            size='small'
            onClick={() => steViewType(TABLE_VIEW_TYPE)}
            tint={viewType === TABLE_VIEW_TYPE ? 'main' : 'mediumGray'}
          />
        </ButtonGroup>
      </div>
      {
        (!isFetching && results.length) && (
          <>
            {
              viewType === TABLE_VIEW_TYPE && (
                <div className={styles.tableView}>
                  <TableView results={results} phases={phases}/>
                  <div className={styles.footer} />
                </div>
              )
            }
            { 
              viewType === CHART_VIEW_TYPE && (
                <div>
                  {
                    study && 
                    study.instrument && 
                    study.instrument.is_scorable && (
                      <>
                        <span className={styles.subtitle}> Puntos </span>
                        <EntrepreneurChartView results={results} phases={phases} name={study.name} />
                      </>
                    )
                  }
                  {
                    !isExitProfileAvoidableAxis && (
                      <>
                        <span className={styles.subtitle}> Resultados </span>
                        <ChartView results={results} phases={phases}/>
                      </>
                    )
                  }
                </div>
              ) 
            }
            { 
              viewType === PARTICIPANT_VIEW_TYPE && (
                <div>
                  <ParticipantsView />
                </div>
              ) 
            }
          </>
        )
      }
      {
        isFetching && (
          <div className={styles.fullView}>
            <FullLoader
              tint="mediumGray"
              title="Cargando resultados"
            />
          </div>
        )
      }
      {
        results.length === 0 && !isFetching && (
          <FullPlaceholder />
        )
      }
      <PopupFilters />
    </div>
  )
}

export default connect(
  (state, { studyId }) => {
    const study = selectors.getSelectedStudy(state);
    const order = selectors.getStudyResultsOrder(state, studyId) || [];
    const results = order.map(id => selectors.getStudyResult(state, id)).filter(
      item => item && item.type && (item.type !== 'open' || (item.type === 'open' && !isEmptyObject(item.pointsResults)))
    );
    // const { is_scorable, name } = study && study.instrument;
    let isExitProfileAvoidableAxis = false;

    if (study && study.instrument) {
      const { is_scorable, name } = study.instrument;
      isExitProfileAvoidableAxis = 
        name && EXIT_PROFILE_AVOIDABLE_AXIS.some(axis => name.toLowerCase().includes(axis)) && is_scorable
    }

    return ({
      study,
      results,
      isFetching: selectors.isFetchingStudyResults(state),
      phases: study ? sortBy(study.phases, ['ordinal']).map(el => el.name) : [],
      filters: study ? study.filters || [] : [],
      isExitProfileAvoidableAxis,
    })
  },
  (dispatch, { studyId }) => ({
    onLoad() {
      dispatch(actions.startFetchStudyResults(studyId));
    },
    fetchStudyResults(key, val, study) {
      dispatch(actions.updateStudyResultsFilters(key, val));
      dispatch(actions.startFetchStudyResults(study));
    },
    clearStudyResults() {
      dispatch(actions.clearStudyResultsFilters());
    },
    openModal() {
      dispatch(closableActions.open(POPUP_FILTERS_RESULTS_ID));
    }
  })
)(ResultsList);
