//@flow
import React from 'react';

import GenericResultsTable from '../GenericResultsTable';
import styles from './studyResults.css';


type TablePropTypes = {
  phases: Array<number>,
  results: Array<Object>,
};

const Table = ({
  results,
  phases,
}: TablePropTypes) => results.map((el, i) => (
    <div className={styles.row} key={el.id}>
      <GenericResultsTable
        key={el.id}
        name={`${i + 1}. ${el.name}`}
        phases={phases}
        results={el.charResults}
      />
    </div>
  )
);

export default Table;
