import { combineReducers } from 'redux';

import * as commons from './common';
import * as files from '../types/files';

import type {
  ID_TYPE,
  ERROR_TYPE,
} from '../types/common';

export type FilesState = {
  byId: {
    // Id del usuario
    [key: ID_TYPE]: {
      // Se almacena un objecto, donde el key es el uuid del archivo
      // y el value es la informacion del archivo
      [UUID: ID_TYPE]: Objects,
    }
  },
  isFetching: boolean,
  isUploading: boolean,
  errors: ERROR_TYPE[],
}

const byUserId = commons.keyExtractorById({
  extractionKey: 'files',
  idKey: 'id',
  set: [files.FILES_FETCH_SUCCEED],
});

const isFetching = commons.isFetching({
  started: [files.FILES_FETCH_STARTED],
  succeed: [files.FILES_FETCH_SUCCEED],
  failed: [files.FILES_FETCH_FAILED],
});

const isUploading = commons.isFetching({
  started: [files.FILE_UPLOAD_STARTED],
  succeed: [files.FILES_FETCH_SUCCEED],
  failed: [files.FILE_UPLOAD_FAILED],
});

const errors = commons.errors({
  populate: [files.FILES_FETCH_FAILED, files.FILE_UPLOAD_FAILED],
  clear: [files.FILES_FETCH_SUCCEED, files.FILE_UPLOAD_SUCCEED],
});

export default combineReducers({
  byUserId,
  isFetching,
  isUploading,
  errors,
});

export const getFilesByUserId = (state: FilesStateType, id: ID_TYPE) => state.byUserId[id] | {};
export const isFetchingFiles = (state: FilesStateType) => state.isFetching;
export const isUploadingFile = (state: FilesStateType) => state.isUploading;
export const getErrors = (state: FilesStateType) => state.errors;
