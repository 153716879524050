import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import FileList from './FileList';
import { getFileName, getFileExtension } from '../../utils';

import styles from './fileUploader.css';

type DropzonePropsType = {
  files: any[],
  uploadFile: Function,
  deleteFile: Function,
}

const Dropzone = ({
  files,
  uploadFile,
  isLoading,
  isUploading,
  deleteFile,
  error,
}: DropzonePropsType) => {
  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file: any) => {
      uploadFile(file, getFileName(file.name), getFileExtension(file.name), uuidv4());
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: 'image/*,.pdf,.doc,.docx,.xls,.xlsx',
    multiple: false,
  });

  return (
    <div {...getRootProps({
      onClick: (e) => {
        if (files.length > 0 && e.nativeEvent.srcElement.localName !== 'path') {
          e.stopPropagation();
        }
      },
    })}>
      <input {...getInputProps()} />
      {
        isDragActive
          ? (
            <div className={styles.dropZoneContainer}>
              <p className={styles.dropFilesMessage}>Suelta aqui para subir...</p>
            </div>
          )
          : isUploading
            ? (
              <div className={styles.dropZoneContainer}>
                <p className={styles.dropFilesMessage}>Subiendo el archivo...</p>
              </div>
            ) : (
              <FileList
                onDelete={deleteFile}
                files={files}
                isFetching={isLoading}
                error={error}
              />
            )
      }
    </div>
  );
}

export default Dropzone;