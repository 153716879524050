import { connect } from 'react-redux';
import { change, formValueSelector, reduxForm } from 'redux-form';

import validate from './validate';
import ParticipantForm from '../ParticipantForm';
import { generateChoices } from '../../utils';
import { triggerSubmit } from './submit';
import * as selectors from '../../reducers';

const addParticipantForm = connect(
  (state) => {
    const formSelector = formValueSelector('addParticipantForm');
    const selectedStudy = selectors.getSelectedStudy(state) || {};
    const studyOrganizations = selectedStudy.organizations || [];

    const locations = studyOrganizations.locations || [];

    const location = formSelector(state, 'location');
    const sublocation = formSelector(state, 'sublocation');

    let sublocations = [];
    let organizations = [];

    if (location != null) {
      sublocations = (studyOrganizations.sublocations || []).filter(
        (el) => el.location === location
      );
    }

    if (sublocation != null) {
      organizations = (studyOrganizations.organizations || []).filter(
        (el) => el.sublocation_id === sublocation
      );
    }

    sublocations.push({ id: -1, name: 'Select...' });
    organizations.push({ id: -1, name: 'Select...' });

    return {
      locations: generateChoices(locations, (el) => el.name),
      sublocations: generateChoices(sublocations, (el) => el.name),
      organizations: generateChoices(
        organizations,
        (el) => el.name,
        (el) => el.organization
      ),
    };
  },
  (dispatch) => ({
    onSelectLocation() {
      dispatch(change('addParticipantForm', 'sublocation', -1));
      dispatch(change('addParticipantForm', 'community', -1));
    },
    onSelectSublocation() {
      dispatch(change('addParticipantForm', 'community', -1));
    },
  })
)(ParticipantForm);

export default reduxForm({
  form: 'addParticipantForm',
  onSubmit: triggerSubmit,
  validate,
})(addParticipantForm);
