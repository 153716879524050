// @flow
import React, { Fragment } from 'react';
import orderBy from 'lodash/orderBy';

import { FullLoader } from '../Loader';
import AntropometryChart from '../AntropometryChart';
import styles from './kidGraphics.css';


type KidGraphicsType = {
  loading: boolean,
  data: Array<Object>,
  gender: string,
  name: string,
};


const KidGraphics = ({
  loading = true,
  data = [],
  gender,
  name,
}: KidGraphicsType) => {

  const gChart = gender === 'Femenino' ? 'girls' : 'boys';
  const kids = gender === 'Femenino' ? 'niñas' : 'niños';

  const resultsAge = orderBy(data, ['age'], ['asc']);
  const resultsByHeight = orderBy(data, ['height'], ['asc']);
  const resultsLength = orderBy(data, ['length'], ['asc']);

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <FullLoader
          tint="mediumGray"
          title="Cargando graficas"
        />
      </div>
    )
  } else {
    return (
      <Fragment>
  
        <div className={styles.row}>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="age"
              fromUnits="cm"
              to="length"
              toUnits="Kg"
              title={`Longitud para la edad (${kids} menores de 2 años)`}
              measurements={resultsAge}
              entityName={name}
            />
          </div>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="age"
              fromUnits="cm"
              to="height"
              toUnits="cm"
              title={`Talla para la edad (${kids} mayores de 2 años)`}
              measurements={resultsAge}
              entityName={name}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="age"
              fromUnits="cm"
              to="weight"
              toUnits="Kg"
              title={`Peso para la edad (${kids})`}
              measurements={data}
              entityName={name}
            />
          </div>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="height"
              fromUnits="cm"
              to="weight"
              toUnits="Kg"
              title={`Peso para la talla (${kids} mayores de 2 años)`}
              measurements={resultsByHeight}
              entityName={name}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.chart}`}>
            <AntropometryChart
              gender={gChart}
              from="length"
              fromUnits="cm"
              to="weight"
              toUnits="Kg"
              title={`Peso para la longitud (${kids} menores de 2 años)`}
              measurements={resultsLength}
              entityName={name}
            />
          </div>
          <div className={styles.filled} />
        </div>
  
      </Fragment>
    )
  }

};


export default KidGraphics;
