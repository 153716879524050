import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';

import {
  CIVIL_STATES,
  ETHNICITIES,
  PARTICIPANT_AGES,
  YEARS_LIVING_IN_COMMUNITY,
} from '../../api/mock';
import { RenderInput, RenderSelect } from '../FieldRenderer';

import styles from './participantForm.css';

const ParticipantForm = ({
  isParticipantSelected,
  locations,
  sublocations,
  organizations,
  onSelectLocation,
  onSelectSublocation,
  handleSubmit,
}) => {
  return (
    <>
      <div className={styles.separator}>
        <span>{isParticipantSelected ? 'Editar' : 'Crear'} participante</span>
      </div>

      <Form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name='first_name'
                label='Nombre de la madre:'
                component={RenderInput}
                placeholder='Escriba el nombre ...'
              />
            </div>

            <div className={styles.col}>
              <Field
                name='last_name'
                label='Apellido de la madre:'
                component={RenderInput}
                placeholder='Escriba el appellido acá..'
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name='location'
                label='Departamento'
                component={RenderSelect}
                options={locations}
                onChange={({ id }) => onSelectLocation(id)}
              />
            </div>

            <div className={styles.col}>
              <Field
                name='sublocation'
                label='Municipio'
                component={RenderSelect}
                options={sublocations}
                onChange={({ id }) => onSelectSublocation(id)}
              />
            </div>

            <div className={styles.col}>
              <Field
                name='community'
                label='Comunidad'
                component={RenderSelect}
                options={organizations}
              />
            </div>

            <div className={styles.col}>
              <Field name='group' label='Grupo' component={RenderInput} />
            </div>
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name='cui'
                label='Código unico de identificación'
                component={RenderInput}
                placeholder='Escriba acá...'
              />
            </div>

            <div className={styles.col}>
              <Field
                name='age'
                label='¿Cuántos años cumplidos tiene?'
                component={RenderSelect}
                options={PARTICIPANT_AGES}
              />
            </div>

            <div className={styles.col}>
              <Field
                name='years_living_in_community'
                label='¿Hace cuánto vive en esta comunidad?'
                component={RenderSelect}
                options={YEARS_LIVING_IN_COMMUNITY}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name='civil_state'
                label='¿Cuál es su estado civil?'
                component={RenderSelect}
                options={CIVIL_STATES}
              />
            </div>

            <div className={styles.col}>
              <Field
                name='ethnicity'
                label='¿A qué grupo cultural pertenece?'
                component={RenderSelect}
                options={ETHNICITIES}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

ParticipantForm.propTypes = {
  isParticipantSelected: PropTypes.bool,
  locations: PropTypes.array,
  sublocations: PropTypes.array,
  organizations: PropTypes.array,
  onSelectLocation: PropTypes.func,
  onSelectSublocation: PropTypes.func,
  handleSubmit: PropTypes.func,
};

ParticipantForm.defaultProps = {
  isParticipantSelected: false,
};

export default ParticipantForm;
