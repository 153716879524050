// @flow
import React from 'react';
import { connect } from 'react-redux';

import { Field, Form, formValueSelector, initialize } from 'redux-form';

import * as selectors from '../../reducers';
import {
  RenderInput,
  RenderSelect,
  RenderDatePicker,
  RenderAddOrganizationsTable,
} from '../FieldRenderer';
import Button from '../Button';
import UsersTaggedTypeahead from '../UsersTaggedTypeahead';
import { ANTHROPOMETRY_TYPE } from '../../settings';

import styles from './studyForm.css';

type StudyFormPropTypes = {
  isSubmitting: boolean,
  instruments: Array<Object>,
  types: Array<Object>,
  study: string,
  isFetching: Boolean,
  form: String,
  initialValues: Object,
  initialize: Boolean,
  initValues: Function,
};


class StudyForm extends React.Component<StudyFormPropTypes> {
  componentDidUpdate(prev) {
    const {
      form,
      isFetching,
      initialValues,
      loadValues = false,
      initValues,
    } = this.props;

    if (prev.isFetching && !isFetching && loadValues) {
      initValues(form, initialValues);
    }
  }

  render() {
    const {
      isSubmitting,
      instruments,
      handleSubmit,
      types,
      study = 'None',
      loadValues = false,
    } = this.props;

    return (
      <Form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name='name'
                label="Nombre del estudio:"
                component={RenderInput}
                placeholder="Escriba el nombre acá..."
              />
            </div>
            <div className={styles.col}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name="instrument"
                label="Instrumento"
                component={RenderSelect}
                options={instruments}
              />
            </div>
            <div className={styles.col}>
              <Field
                name="study_type"
                label="Tipo de estudio"
                component={RenderSelect}
                options={types}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                name="start_date"
                label="Fecha de inicio"
                component={RenderDatePicker}
              />
            </div>
            <div className={styles.col}>
              <Field
                name="end_date"
                label="Fecha de fin"
                component={RenderDatePicker}
              />
            </div>
            {
              /* New requirement: anthropometry studies needs a phase...  */
              /* study !== ANTHROPOMETRY_TYPE && ( */
              <div className={styles.col}>
                <Field
                  name="phases"
                  label="Fases"
                  component={RenderInput}
                  type="number"
                  max="200"
                />
              </div>
            }
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <UsersTaggedTypeahead title="Usuarios invitados" />
            </div>
            <div className={styles.col}></div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Field
                value={[]}
                name="organizations"
                label="Configuración de muestra"
                component={RenderAddOrganizationsTable}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}></div>
          <div className={styles.col}></div>
          <div className={styles.col}>
            <Button
              type="submit"
              title={loadValues ? "Editar estudio" : "Agregar estudio"}
              isLoading={isSubmitting}
              isRound
              isFullWidth
            />
          </div>
        </div>
      </Form>
    );
  }
}


export default connect(
  (state, { form }) => {
    const formSelector = formValueSelector(form);
    const instruments = selectors.getInstruments(state).map(instrument => ({
      value: instrument.id,
      label: instrument.name,
    }));
    const types = selectors.getStudyTypes(state).map(type => ({
      value: type.id,
      label: type.name,
    }));
    const instrumentId = formSelector(state, 'instrument') || {};
    const instrumentSelected = selectors.getInstrument(state, instrumentId) || {};

    const {
      id,
      name,
      instrument = {},
      study_type = {},
      start_date,
      end_date,
      phases = [],
      users = [],
      study_organizations = []
    } = selectors.getSelectedStudy(state) || {};

    const vUsers = users.map(el => ({
      ...el,
      label: `${el.first_name} ${el.last_name}`
    }));
    const organizations = study_organizations.map(el => [el.organization_id, el.sample, el.relation_id]);

    const initialValues = {
      name,
      instrument: instrument.id,
      study_type: study_type.id,
      start_date: new Date(start_date),
      end_date: new Date(end_date),
      phases: phases.length,
      users: vUsers,
      organizations,
    };

    return {
      types,
      instruments,
      study: instrumentSelected.type,
      isSubmitting: selectors.isSubmittingStudy(state),
      isFetching: selectors.isFetchingStudy(state, id),
      initialValues,
    };
  },
  dispatch => ({
    initValues(form, initialValues) {
      dispatch(initialize(form, initialValues));
    }
  })
)(StudyForm);
