exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-StudiesHeader-__studiesHeader___selectContainer {\n  width: 32rem;\n}\n\n.src-scripts-components-StudiesHeader-__studiesHeader___approveInput {\n  margin-right: 3rem;\n}\n\n@media(max-width: 768px) {\n  .src-scripts-components-StudiesHeader-__studiesHeader___approveInput {\n    padding-bottom: 4rem;\n    min-width: 30rem;\n    margin-right: 0;\n  }\n  \n  .src-scripts-components-StudiesHeader-__studiesHeader___approveInput input {\n    height: var(--mediumHeightSize) !important;\n  }\n\n  .src-scripts-components-StudiesHeader-__studiesHeader___selectContainer {\n    width: 40rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"selectContainer": "src-scripts-components-StudiesHeader-__studiesHeader___selectContainer",
	"approveInput": "src-scripts-components-StudiesHeader-__studiesHeader___approveInput"
};