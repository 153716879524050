// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import { LOCATIONS_FETCH_STARTED } from '../types/locations';
import { Locations } from '../api';
import { arrayOfLocations } from '../api/schemas/locations';

import * as selectors from '../reducers';
import * as actions from '../actions/locations';


function* fetchLocations(action) {
  try {
    const token = yield select(selectors.getToken);

    const { locationsResponse, timeout } = yield race({
      locationsResponse: call(
        [Locations, 'list'],
        {
          token,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    const {
      entities: { locations },
      result,
    } = normalize(locationsResponse.results, arrayOfLocations);

    yield put(actions.completeFetchLocations(locations, result));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchLocations({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchFetchLocations(): Iterator<any> {
  yield takeEvery(
    LOCATIONS_FETCH_STARTED,
    fetchLocations,
  );
};
