// @flow
import type { Node } from 'react';
import React from 'react';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupHeader from '../PopupHeader';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import PopupCloseButton from '../PopupCloseButton';
import FiltersContent from './filters';
import styles from './popupStudyFilters.css';


type PopupInstrumentFiltersPropTypes = {
  id: string,
  children: Node,
};

const PopupInstrumentFilters = ({
  id,
  children,
}: PopupInstrumentFiltersPropTypes) => (
  <Popup id={id}>
    <PopupContainer
      elevation={8}
      isRound
      size="small"
    >
      <PopupHeader
        isAlert
        className={styles.header}
      >
        <PopupHeaderLeftGroup>
          <div className={styles.title}>
            {"Más filtros"}
          </div>
        </PopupHeaderLeftGroup>
        <PopupHeaderRightGroup>
          <PopupCloseButton
            tint="mediumGray"
            Icon={MdClose}
            clearMargins
            isTransparent
            isOutlined
          />
        </PopupHeaderRightGroup>
      </PopupHeader>
      { children }
    </PopupContainer>
  </Popup>
);

export default PopupInstrumentFilters;
