// @flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { ANTHROPOMETRY_RESULT_TYPE } from '../types/anthropometryResults';

import * as common from './common';
import * as types from '../types/anthropometryResults';
import StateManager from 'react-select';


export type AnthropometryResultsState = {
  byId: { [ID_TYPE]: ANTHROPOMETRY_RESULT_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
};


const byId = common.byId({
  fetched: [types.ANTHROPOMETRY_RESULTS_FETCH_COMPLETED],
  defaultAttributes: {},
});

const order = common.order({
  replaced: [types.ANTHROPOMETRY_RESULTS_FETCH_COMPLETED],
});

const isFetching = common.isFetching({
  started: [
    types.ANTHROPOMETRY_RESULTS_FETCH_STARTED,
  ],
  succeed: [types.ANTHROPOMETRY_RESULTS_FETCH_COMPLETED],
  failed: [types.ANTHROPOMETRY_RESULTS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.ANTHROPOMETRY_RESULTS_FETCH_STARTED,
    types.ANTHROPOMETRY_RESULTS_FETCH_COMPLETED,
  ],
  populate: [
    types.ANTHROPOMETRY_RESULTS_FETCH_FAILED,
  ],
});


const anthropometryResults = combineReducers({
  byId,
  order,
  isFetching,
  error,
});

export default anthropometryResults;


export const getAnthropometryResult = (
  state: AnthropometryResultsState,
  id: ID_TYPE): ANTHROPOMETRY_RESULT_TYPE => state.byId[id];
export const getAnthropometryResults = (
  state: AnthropometryResultsState,
): Array<ANTHROPOMETRY_RESULT_TYPE> => state.order.map(id => getAnthropometryResult(state, id));
export const isFetchingAnthropometryResults = (state: AnthropometryResultsState): boolean => state.isFetching;
export const getAnthropometryResultError = (state: AnthropometryResultsState): ERROR_TYPE => state.error;
