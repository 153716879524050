// @flow
import React from 'react';
import ReactApexChart from 'react-apexcharts';


type ExitProfileResultsChartPropTypes = {
  results: {[string]: Array<number>},
  name: ?string,
};

const ExitProfileResultsChart = ({
  results,
  name,
}: ExitProfileResultsChartPropTypes) => (
  <ReactApexChart
    options={{
      chart: {
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        curve: 'smooth',
        width: [5, ...results.map(el => 5)],
      },
      title: {
        show: false,
        text: '',
        align: 'left'
      },
      markers: {
        size: [5, ...results.map(el => 5)],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        axisBorder: {
          show: true,
      },
      },
      xaxis: {
        // categories: results.map(el => el.phase),
        type: 'numeric',
        tickAmount: 'dataPoints'
      }
    }}
    series={
      [{
        name: '',
        data: results.map(el => el.value)
      }]
    }
  />
)

export default ExitProfileResultsChart;
