// @flow
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import uuid from 'uuid';

import StudyForm from '../StudyForm';
import validate from './validation';

import * as selectors from '../../reducers';
import * as actions from '../../actions/studies';


export const CREATE_FORM_ID = 'addStudyForm';

const onSubmit = (values, dispatch) => {
  const users = values.users.map(el => el.id);

  dispatch(actions.startAddStudy({
    // id: uuid(),
    ...values,
    users,
  }));
};

export default reduxForm({
  form: CREATE_FORM_ID,
  onSubmit,
  validate,
})(StudyForm);
