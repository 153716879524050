// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './dataTable.css';

type ThPropTypes = {
  children?: Node,
  className: string,
  toggleSort?: Function,
};

const Th = ({
  toggleSort,
  children,
  className = '',
  ...rest
}: ThPropTypes) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <th
    className={`${styles.theadTh} ${styles.th} ${className}`}
    {...rest}
    onClick={e => toggleSort && toggleSort(e)}
    role="columnheader"
    tabIndex="-1"
  >
    { children }
  </th>
);

export default Th;
