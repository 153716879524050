// @flow
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { reducer as form } from 'redux-form';
import { reducer as auth, selectors as fromAuth } from 'nozzmo-redux-jwt';

import { genSelector } from '../utils';

import login, * as fromLogin from './login';

import type { FilesState } from './files';
import files, * as fromFiles from './files';

import type { StudiesState } from './studies';
import studies, * as fromStudies from './studies';

import type { CloseableState } from './closeable';
import closeable, * as fromCloseable from './closeable';

import type { InstrumentsState } from './instruments';
import instruments, * as fromInstruments from './instruments';

import type { InstrumentsubmissionsState } from './instrumentSubmissions';
import instrumentSubmissions, * as fromInstrumentSubmissions from './instrumentSubmissions';

import type { QuestionsState } from './questions';
import questions, * as fromQuestions from './questions';

import type { StudyParticipantsState } from './studyParticipants';
import studyParticipants, * as fromStudyParticipants from './studyParticipants';

import type { QuestionsSetsState, QUESTIONS_SET_TYPE } from './questionsSets';
import questionsSets, * as fromQuestionsSets from './questionsSets';

import type { ChoicesState } from './choices';
import choices, * as fromChoices from './choices';

import type { LocationsState } from './locations';
import locations, * as fromLocations from './locations';

import type { SublocationsState } from './sublocations';
import sublocations, * as fromSublocations from './sublocations';

import type { OrganizationsState } from './organizations';
import organizations, * as fromOrganizations from './organizations';

import type { UsersState } from './users';
import users, * as fromUsers from './users';

import type { KidsState } from './kids';
import kids, * as fromKids from './kids';

import type { IndicatorResultsState } from './indicatorResults';
import indicatorResults, * as fromIndicatorResults from './indicatorResults';

import type { AnthropometryResultsState } from './anthropometryResults';
import anthropometryResults, * as fromAnthropometryResults from './anthropometryResults';

import type { StoplightResultsState } from './stoplightResults';
import stoplightResults, * as fromStoplightResults from './stoplightResults';

import type { StudyTypesState } from './studyTypes';
import studyTypes, * as fromStudyTypes from './studyTypes';

import type { StudyResultsState } from './studyResults';
import studyResults, * as fromStudyResults from './studyResults';

import type { ResultsExitProfileState } from './resultsExitProfile';
import resultsExitProfile, * as fromResultsExitProfile from './resultsExitProfile';

import type { MenuFixedState } from './menuFixed';
import menuFixed, * as fromMenuFixed from './menuFixed';

import type { ConnectionState } from './connection';
import connection, * as fromConnection from './connection';

export type AppState = {
  router: Object,
  form: Object,
  _persist: Object,
  auth: {
    token: string,
    decoded: Object,
    error: {
      message: string,
      extra: Object
    }
  },
  files: FilesState,
  login: LoginState,
  studies: StudiesState,
  studyParticipants: StudyParticipantsState,
  closeable: CloseableState,
  instruments: InstrumentsState,
  instrumentSubmissions: InstrumentsubmissionsState,
  questions: QuestionsState,
  questionsSets: QuestionsSetsState,
  choices: ChoicesState,
  locations: LocationsState,
  sublocations: SublocationsState,
  organizations: OrganizationsState,
  users: UsersState,
  kids: KidsState,
  indicatorResults: IndicatorresultsState,
  anthropometryResults: AnthropometryResultsState,
  stoplightResults: StoplightResultsState,
  studyTypes: StudyTypesState,
  studyResults: StudyResultsState,
  resultsExitProfile: ResultsExitProfileState,
  menuFixed: MenuFixedState,
  connection: ConnectionState,
};

const reducer = combineReducers({
  router: routerReducer,
  form,
  auth,
  files,
  login,
  studies,
  studyParticipants,
  closeable,
  instruments,
  instrumentSubmissions,
  questions,
  questionsSets,
  choices,
  locations,
  sublocations,
  organizations,
  users,
  kids,
  indicatorResults,
  anthropometryResults,
  stoplightResults,
  studyTypes,
  studyResults,
  resultsExitProfile,
  menuFixed,
  connection,
});

export default reducer;

export const getPersist = (state: AppState) => state._persist;

export const getLoginError = (state: AppState) => fromAuth.getError(state.auth);
export const isOfflineMode = genSelector(fromConnection.isOfflineMode, 'connection');
export const hasInternetConnection = (state: AppState) => ((state.offline && state.offline.online) || state.offline.netInfo) && !isOfflineMode(state);

export const getCurrentUser = genSelector(fromLogin.getCurrentUser, 'login');
export const getIsCurrentUserAdmin = genSelector(fromLogin.isCurrentUserAdmin, 'login');

export const getFilesByUserId = genSelector(fromFiles.getFilesByUserId, 'files');
export const isFetchingFiles = genSelector(fromFiles.isFetchingFiles, 'files');
export const isUploadingFile = genSelector(fromFiles.isUploadingFile, 'files');
export const getErrors = genSelector(fromFiles.getErrors, 'files');

export const isAuthenticated = genSelector(fromAuth.getIsAuth, 'auth');
export const getUser = genSelector(fromAuth.getDecoded, 'auth');
export const getToken = genSelector(fromAuth.getToken, 'auth');
export const getUsername = (state: AppState) => getUser(state).username;
export const getUserProfilePicture = (
  state: AppState,
) => (getUser(state).profile_picture ? getUser(state).profile_picture.url : undefined);
export const getUserId = (state: AppState) => getUser(state).user_id;
export const getProfiles = (state: AppState) => getUser(state).profiles;
export const getProfilesOfType = (
  state: AppState,
  testType: string,
) => getProfiles(state).filter(({ type }) => type === testType);
export const getRouteLocation = (state: AppState) => state.router.location;

export const getStoplightResult = genSelector(fromStoplightResults.getStoplightResult, 'stoplightResults');
export const getStoplightResults = genSelector(fromStoplightResults.getStoplightResults, 'stoplightResults');
export const isFetchingStoplightResults = genSelector(fromStoplightResults.isFetchingStoplightResults, 'stoplightResults');
export const getStoplightResultsError = genSelector(fromStoplightResults.getStoplightResultsError, 'stoplightResults');

export const getProfileTypes = (state: AppState) => getProfiles(state).map(({ type }) => type);
export const hasProfileType = (
  state: AppState,
  testType: string,
) => getProfileTypes(state).includes(testType);
export const getProfileOfType = (state: AppState, testType: string) => {
  if (hasProfileType(state, testType)) {
    return getProfilesOfType(state, testType)[0];
  }

  return undefined;
};

export const isLoginLoading = genSelector(fromLogin.isLoginLoading, 'login');

export const getCloseable = genSelector(fromCloseable.getCloseable, 'closeable');
export const getIsCloseableOpen = (
  state: AppState,
  id: string,
) => fromCloseable.getIsOpen(getCloseable(state, id));

export const getStudy = genSelector(fromStudies.getStudy, 'studies');
export const getStudies = genSelector(fromStudies.getStudies, 'studies');
export const getStudySpotlightResultsOrder = genSelector(fromStudies.getStudySpotlightResultsOrder, 'studies');
export const isFetchingStudies = genSelector(fromStudies.isFetchingStudies, 'studies');
export const getStudiesError = genSelector(fromStudies.getStudiesError, 'studies');
export const getSelectedStudy = genSelector(fromStudies.getSelectedStudy, 'studies');
export const getStudiesNextPage = genSelector(fromStudies.getStudiesNextPage, 'studies');
export const hasMoreStudies = genSelector(fromStudies.hasMoreStudies, 'studies');
export const getStudiesFilter = genSelector(fromStudies.getStudiesFilter, 'studies');
export const getStudiesFilters = genSelector(fromStudies.getStudiesFilters, 'studies');
export const isSubmittingStudy = genSelector(fromStudies.isSubmittingStudy, 'studies');
export const isFetchingStudy = genSelector(fromStudies.isFetchingStudy, 'studies');
export const getStudyResultsOrder = genSelector(fromStudies.getStudyResults, 'studies');
export const getStudyIndicatorResultsOrder = genSelector(fromStudies.getStudyIndicatorResults, 'studies');
export const getStudiesIndicatorsFilters = genSelector(fromStudies.getStudiesIndicatorsFilters, 'studies');
export const getStudiesIndicatorsFilter = genSelector(fromStudies.getStudiesIndicatorsFilter, 'studies');
export const getStudiesStoplightFilters = genSelector(fromStudies.getStudiesStoplightFilters, 'studies');
export const getStudiesStoplightFilter = genSelector(fromStudies.getStudiesStoplightFilter, 'studies');
export const getStudyExitProfileResultsOrder = genSelector(fromStudies.getStudyExitProfileResultsOrder, 'studies');
export const getStudiesExitProfileResultsFilters = genSelector(fromStudies.getStudiesExitProfileResultsFilters, 'studies');
export const getStudiesExitProfileResultsFilter = genSelector(fromStudies.getStudiesExitProfileResultsFilter, 'studies');


export const getStudyParticipant = genSelector(fromStudyParticipants.getStudyParticipant, 'studyParticipants');
export const getStudyParticipants = genSelector(fromStudyParticipants.getStudyParticipants, 'studyParticipants');
export const isStudyParticipantFetching = genSelector(fromStudyParticipants.isStudyParticipantFetching, 'studyParticipants');
export const isFetchingStudyParticipants = genSelector(fromStudyParticipants.isFetchingStudyParticipants, 'studyParticipants');
export const isFetchingStudyParticipantsResults = genSelector(fromStudyParticipants.isFetchingStudyParticipantsResults, 'studyParticipants');
export const getStudyParticipantsError = genSelector(fromStudyParticipants.getStudyParticipantsError, 'studyParticipants');
export const getSelectedStudyParticipant = genSelector(fromStudyParticipants.getSelectedStudyParticipant, 'studyParticipants');
export const getStudyParticipantCounter = genSelector(fromStudyParticipants.getStudyParticipantCounter, 'studyParticipants');
export const getAllStudyParticipantsIds = genSelector(fromStudyParticipants.getAllStudyParticipantsIds , 'studyParticipants');

// Study Participant Results
export const getStudyParticipantResult = genSelector(fromStudyParticipants.getStudyParticipantResult, 'studyParticipants');
export const getStudyParticipantResults = genSelector(fromStudyParticipants.getStudyParticipantResults, 'studyParticipants');

// Offline Mode Support
export const getOfflineParticipant = genSelector(fromStudyParticipants.getOfflineParticipant, 'studyParticipants');
export const getOfflineParticipants = genSelector(fromStudyParticipants.getOfflineParticipants, 'studyParticipants');


export const getStudyParticipantsFilter = genSelector(fromStudyParticipants.getStudyParticipantsFilter, 'studyParticipants');
export const getStudyParticipantsFilters = genSelector(fromStudyParticipants.getStudyParticipantsFilters, 'studyParticipants');
export const getStudyParticipantsNextPage = genSelector(fromStudyParticipants.getStudyParticipantsNextPage, 'studyParticipants');
export const hasMoreStudyParticipants = genSelector(fromStudyParticipants.hasMoreStudyParticipants, 'studyParticipants');

export const getInstrument = genSelector(fromInstruments.getInstrument, 'instruments');
export const getInstruments = genSelector(fromInstruments.getInstruments, 'instruments');
export const isFetchingInstruments = genSelector(fromInstruments.isFetchingInstruments, 'instruments');
export const getSelectedInstrument = genSelector(fromInstruments.getSelectedInstrument, 'instruments');
export const getInstrumentCounter = genSelector(fromInstruments.getInstrumentCounter, 'instruments');
export const areInstrumentQuestionsFetching = genSelector(fromInstruments.areInstrumentQuestionsFetching, 'instruments');
export const getInstrumentsFilters = genSelector(fromInstruments.getInstrumentsFilters, 'instruments');
export const getInstrumentsFilter = genSelector(fromInstruments.getInstrumentsFilter, 'instruments');
export const getDefaultInstrument = genSelector(fromInstruments.getDefaultInstrument, 'instruments');
export const getSemaphoreResultsByInstrumentId = genSelector(fromInstruments.getSemaphoreResultsByInstrumentId, 'instruments');
export const isFetchingInstrumentResults = genSelector(fromInstruments.isFetchingInstrumentResults, 'instruments');

export const getInstrumentSubmission = genSelector(fromInstrumentSubmissions.getInstrumentSubmission, 'instrumentSubmissions');
export const getInstrumentSubmissions = genSelector(fromInstrumentSubmissions.getInstrumentSubmissions, 'instrumentSubmissions');
export const isInstrumentSubmissionFetching = genSelector(fromInstrumentSubmissions.isInstrumentSubmissionFetching, 'instrumentSubmissions');
export const isFetchingInstrumentSubmissions = genSelector(fromInstrumentSubmissions.isFetchingInstrumentSubmissions, 'instrumentSubmissions');
export const getInstrumentSubmissionsError = genSelector(fromInstrumentSubmissions.getInstrumentsubmissionsError, 'instrumentSubmissions');
export const getSelectedInstrumentsubmission = genSelector(fromInstrumentSubmissions.getSelectedInstrumentsubmission, 'instrumentSubmissions');
export const getInstrumentSubmissionCounter = genSelector(fromInstrumentSubmissions.getInstrumentsubmissionCounter, 'instrumentSubmissions');
export const getAllInstrumentsSubmissionsIds = genSelector(fromInstrumentSubmissions.getAllInstrumentsSubmissionsIds , 'instrumentSubmissions');
export const isSubmittingInstrument = genSelector(fromInstrumentSubmissions.isSubmittingInstrument, 'instrumentSubmissions');
export const getSubmissionError = genSelector(fromInstrumentSubmissions.getSubmissionError, 'instrumentSubmissions');
export const getSubmissionErrorValues = genSelector(fromInstrumentSubmissions.getSubmissionErrorValues, 'instrumentSubmissions');

export const getInstrumentSubmissionsFilter = genSelector(fromInstrumentSubmissions.getInstrumentSubmissionsFilter, 'instrumentSubmissions');
export const getInstrumentSubmissionsFilters = genSelector(fromInstrumentSubmissions.getInstrumentSubmissionsFilters, 'instrumentSubmissions');
export const getInstrumentSubmissionsNextPage = genSelector(fromInstrumentSubmissions.getInstrumentSubmissionsNextPage, 'instrumentSubmissions');
export const hasMoreInstrumentSubmissions = genSelector(fromInstrumentSubmissions.hasMoreInstrumentSubmissions, 'instrumentSubmissions');

export const getStudySubmissions = (state: AppState, studyId) => {
  const submissionsOrder = getAllInstrumentsSubmissionsIds(state);

  return submissionsOrder.map(id => {
    const submission = getInstrumentSubmission(state, id);
    if (submission.study === studyId && submission.isConfirmed) {
      return submission;
    }
  }).filter(item => item !== undefined);
};

export const getOfflineStudySubmissions = (state: AppState) => {
  const submissionsOrder = getAllInstrumentsSubmissionsIds(state);

  return submissionsOrder.map(id => {
    const submission = getInstrumentSubmission(state, id);
    if (submission.isConfirmed === false) return submission;
  }).filter(item => item !== undefined);
};

export const getQuestion = genSelector(fromQuestions.getQuestion, 'questions');
export const getQuestions = genSelector(fromQuestions.getQuestions, 'questions');
export const isFetchingQuestions = genSelector(fromQuestions.isFetchingQuestions, 'questions');
export const getQuestionsError = genSelector(fromQuestions.getQuestionsError, 'questions');
export const getSelectedQuestion = genSelector(fromQuestions.getSelectedQuestion, 'questions');
export const getQuestionCounter = genSelector(fromQuestions.getQuestionCounter, 'questions');

export const getQuestionsSet = genSelector(fromQuestionsSets.getQuestionsSet, 'questionsSets');
export const getQuestionsSets = genSelector(fromQuestionsSets.getQuestionsSets, 'questionsSets');
export const getInstrumentQuestionsSetsIds = (state: AppState, id: ID_TYPE) => {
  const { questions_sets = [] } = getInstrument(state, id) || {};
  return questions_sets;
};
export const getInstrumentQuestionsSets = (state: AppState, id: ID_TYPE): Array<QUESTIONS_SET_TYPE> => {
  const { questions_sets = [] } = getInstrument(state, id) || {};
  return questions_sets.map(id => getQuestionsSet(state, id));
}

export const getQuestionsSetFormDescription = (state: AppState, id: ID_TYPE) => {
  const questionsSet = getQuestionsSet(state, id) || {};
  const questions = questionsSet.questions || [];

  return ({
    ...questionsSet,
    form_elements: questions.map(id => getQuestion(state, id)),
  });
}

export const getChoice = genSelector(fromChoices.getChoice, 'choices');
export const getChoices = genSelector(fromChoices.getChoices, 'choices');
export const isFetchingChoices = genSelector(fromChoices.isFetchingChoices, 'choices');
export const getChoicesError = genSelector(fromChoices.getChoicesError, 'choices');

export const getLocation = genSelector(fromLocations.getLocation, 'locations');
export const getLocations = genSelector(fromLocations.getLocations, 'locations');
export const isFetchingLocations = genSelector(fromLocations.isFetchingLocations, 'locations');
export const getLocationsError = genSelector(fromLocations.getLocationsError, 'locations');
export const getSelectedLocation = genSelector(fromLocations.getSelectedLocation, 'locations');

export const getSublocation = genSelector(fromSublocations.getSublocation, 'sublocations');
export const getSublocations = genSelector(fromSublocations.getSublocations, 'sublocations');
export const isFetchingSublocations = genSelector(fromSublocations.isFetchingSublocations, 'sublocations');
export const getSublocationsError = genSelector(fromSublocations.getSublocationsError, 'sublocations');
export const getSelectedSublocation = genSelector(fromSublocations.getSelectedSublocation, 'sublocations');
export const getSublocationsByLocation = (state: AppState, location: ID_TYPE) => {
  const sublocations = getSublocations(state) || [];
  return sublocations.filter(el => el.location === location);
};

export const getOrganization = genSelector(fromOrganizations.getOrganization, 'organizations');
export const getOrganizations = genSelector(fromOrganizations.getOrganizations, 'organizations');
export const getPaginatedOrganization = genSelector(fromOrganizations.getPaginatedOrganization, 'organizations');
export const getPaginatedOrganizations = genSelector(fromOrganizations.getPaginatedOrganizations, 'organizations');
export const isFetchingOrganizations = genSelector(fromOrganizations.isFetchingOrganizations, 'organizations');
export const getOrganizationsError = genSelector(fromOrganizations.getOrganizationsError, 'organizations');
export const getSelectedOrganization = genSelector(fromOrganizations.getSelectedOrganization, 'organizations');
export const getOrganizationCounter = genSelector(fromOrganizations.getOrganizationCounter, 'organizations');
export const getOrganizationsFilter = genSelector(fromOrganizations.getOrganizationsFilter, 'organizations');
export const getOrganizationsFilters = genSelector(fromOrganizations.getOrganizationsFilters, 'organizations');
export const getOrganizationsNextPage = genSelector(fromOrganizations.getOrganizationsNextPage, 'organizations');
export const hasMoreOrganizations = genSelector(fromOrganizations.hasMoreOrganizations, 'organizations');
export const getOrganizationAnthropometryResults = (state: AppState, id: ID_TYPE) => {
  // It is not necessary to filter the selected organization, it was already filtered in fetch call
  return getAnthropometryResults(state);
}

export const getSublocationOrganizations = (state: AppState, sublocation: ID_TYPE) => {
  const organizations = getOrganizations(state);
  return organizations.filter(el => el.sublocation_id === sublocation);
};

// export const getUser = genSelector(fromUsers.getUser, 'users');
export const getUsers = genSelector(fromUsers.getUsers, 'users');
export const isFetchingUsers = genSelector(fromUsers.isFetchingUsers, 'users');
export const getUsersError = genSelector(fromUsers.getUsersError, 'users');
export const getSelectedUser = genSelector(fromUsers.getSelectedUser, 'users');
export const getUserCounter = genSelector(fromUsers.getUserCounter, 'users');
export const isAddingUser = genSelector(fromUsers.isAddingUser, 'users');
export const isUpdatingUser = genSelector(fromUsers.isUpdatingUser, 'users');

export const getKid = genSelector(fromKids.getKid, 'kids');
export const getKids = genSelector(fromKids.getKids, 'kids');
export const getKidsFilter = genSelector(fromKids.getKidsFilter, 'kids');
export const getKidsFilters = genSelector(fromKids.getkidsFilters, 'kids');
export const getKidsNextPage = genSelector(fromKids.getKidsNextPage, 'kids');
export const hasMoreKids = genSelector(fromKids.hasMoreKids, 'kids');
export const isFetchingKids = genSelector(fromKids.isFetchingKids, 'kids');
export const getKidSelected = genSelector(fromKids.getKidSelected, 'kids');
export const isAddingKid = genSelector(fromKids.isAddingKid, 'kids');
export const isFetchingKid = genSelector(fromKids.isFetchingKid, 'kids');
export const getKidAnthropometryResults = (state: AppState, id: ID_TYPE) => {
  const { anthropometryResults = [] } = getKid(state, id);
  return anthropometryResults.map(key => getAnthropometryResult(state, key));
}

export const getIndicatorResult = genSelector(fromIndicatorResults.getIndicatorResult, 'indicatorResults');
export const getIndicatorResults = genSelector(fromIndicatorResults.getIndicatorResults, 'indicatorResults');
export const isIndicatorResultFetching = genSelector(fromIndicatorResults.isIndicatorResultFetching, 'indicatorResults');
export const isFetchingIndicatorResults = genSelector(fromIndicatorResults.isFetchingIndicatorResults, 'indicatorResults');
export const getIndicatorResultsError = genSelector(fromIndicatorResults.getIndicatorResultsError, 'indicatorResults');
export const getSelectedIndicatorResult = genSelector(fromIndicatorResults.getSelectedIndicatorResult, 'indicatorResults');
export const getIndicatorResultCounter = genSelector(fromIndicatorResults.getIndicatorResultCounter, 'indicatorResults');
export const getIndicatorsResultsFilters = genSelector(fromIndicatorResults.getIndicatorsResultsFilters, 'indicatorResults');
export const getStudyIndicatorResultsRequestTimestamp = genSelector(fromIndicatorResults.getStudyIndicatorResultsRequestTimestamp, 'indicatorResults');

export const getAnthropometryResult = genSelector(fromAnthropometryResults.getAnthropometryResult, 'anthropometryResults');
export const getAnthropometryResults = genSelector(fromAnthropometryResults.getAnthropometryResults, 'anthropometryResults');
export const isFetchingAnthropometryResults = genSelector(fromAnthropometryResults.isFetchingAnthropometryResults, 'anthropometryResults');
export const getAnthropometryResultError = genSelector(fromAnthropometryResults.getAnthropometryResultError, 'anthropometryResults');

export const getStudyType = genSelector(fromStudyTypes.getStudyType, 'studyTypes')
export const getStudyTypes = genSelector(fromStudyTypes.getStudyTypes, 'studyTypes');
export const getStudyTypesError = genSelector(fromStudyTypes.getStudyTypesError, 'studyTypes');
export const isFetchingStudyTypes = genSelector(fromStudyTypes.isFetchingStudyTypes, 'studyTypes');

export const getStudyResult = genSelector(fromStudyResults.getStudyResult, 'studyResults');
export const getStudyResults = genSelector(fromStudyResults.getStudyResults, 'studyResults');
export const getStudyResultsError = genSelector(fromStudyResults.getStudyResultsError, 'studyResults');
export const isFetchingStudyResults = genSelector(fromStudyResults.isFetchingStudyResults, 'studyResults');
export const getStudyResultsFilters = genSelector(fromStudyResults.getStudyResultsFilters, 'studyResults');
export const getStudyResultsFilter = genSelector(fromStudyResults.getStudyResultsFilter, 'studyResults');

export const getResultExitProfile = genSelector(fromResultsExitProfile.getResultExitProfile, 'resultsExitProfile');
export const getResultsExitProfile = genSelector(fromResultsExitProfile.getResultsExitProfile, 'resultsExitProfile');
export const isFetchingResultsExitProfile = genSelector(fromResultsExitProfile.isFetchingResultsExitProfile, 'resultsExitProfile');
export const getResultsExitProfileError = genSelector(fromResultsExitProfile.getResultsExitProfileError, 'resultsExitProfile');
export const getResultExitProfileExtraInfo = genSelector(fromResultsExitProfile.getResultExitProfileExtraInfo, 'resultsExitProfile');
export const getResultExitProfileExtraInfoKeyValue = genSelector(fromResultsExitProfile.getResultExitProfileExtraInfoKeyValue, 'resultsExitProfile');


export const isShowMenuFixed = genSelector(fromMenuFixed.isShowMenuFixed, 'menuFixed');
export const isConnectionRecentStatus = genSelector(fromConnection.isRecentStatus, 'connection');
