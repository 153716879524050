// @flow
import React from 'react';

import Input from '../Input';
import Select from './select';
import SelectMany from './selectMany';
import styles from './radioOptions.css';

import { v4 as uuidv4 } from 'uuid';


type OPTION_TYPE = {
  name: string,
};

type RadioOptionsPropTypes = {
  value?: ID_TYPE,
  choices: Array<OPTION_TYPE>,
  onChange: Function,
  selectMany: boolean,
  label: String,
};

const MultiSelect = ({
  name,
  value,
  choices,
  onChange,
  selectMany = false,
  ...props
}) => (
  <div className={styles.container}>
    {
      selectMany ? (
        <SelectMany
          name={name}
          value={value}
          choices={choices}
          onChange={onChange}
        />
      ) : (
        <Select
          name={name}
          value={value}
          choices={choices}
          onChange={onChange}
        />
      )
    }
  </div>
)

export default MultiSelect;
