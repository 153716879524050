// @flow
import type { ComponentType } from 'react';
import type { FieldProps } from 'redux-form';
import React from 'react';

import Label from '../Label';

import styles from './fieldRenderer.css';

type generateRendererParams = {
  FieldComponent: ComponentType<*>,
  ErrorComponent?: ComponentType<*>,
  PreviewComponent?: ComponentType<*>,
  realTimeErrors?: boolean,
  overrideOnBlur?: boolean
};

type GeneratedFieldProps = FieldProps & {
  showPreview?: boolean,
  customOnChange?: Function
};

export const generateRenderer = ({
  FieldComponent,
  ErrorComponent,
  PreviewComponent,
  realTimeErrors = false,
  overrideOnBlur = false,
}: generateRendererParams) => ({
  input,
  label,
  ordinal,
  ordinal_key,
  meta: { touched, error },
  showPreview = false,
  customOnChange,
  ...props
}: GeneratedFieldProps) => (
  <div className={styles.fieldRenderer}>
    {
      label && (
        <Label>
          {ordinal_key ? `${ordinal_key}. ${label}` : `${label}`}
        </Label>
      )
    }
    {
      (touched || realTimeErrors)
      && !showPreview
      && error
      && ErrorComponent
      && <ErrorComponent title={error} />

    }
    {
      showPreview && PreviewComponent
        ? <PreviewComponent {...props} value={input.value} />
        : (
          <FieldComponent
            {...input}
            {...props}
            onChange={
              (e) => {
                //console.log(e)
                input.onChange(e);
                if (customOnChange) {
                  customOnChange(e);
                }
              }
            }
            onBlur={overrideOnBlur ? () => input.onBlur(input.value) : input.onBlur}
          />
        )
    }
  </div>
);
