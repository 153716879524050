exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-CheckboxListItem-__listItem___checkboxItem {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.src-scripts-components-CheckboxListItem-__listItem___checkboxItem input[type=\"checkbox\"] {\n  margin-right: 8px;\n  cursor: pointer;\n}\n\n.src-scripts-components-CheckboxListItem-__listItem___checkboxItem input[type=\"checkbox\"] {\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  width: 18px;\n  height: 18px;\n  border: 2px solid #e2e8f0;\n  border-radius: 3px;\n  outline: none;\n  -webkit-transition: border-color 0.3s ease;\n  transition: border-color 0.3s ease;\n}\n\n.src-scripts-components-CheckboxListItem-__listItem___checkboxItem input[type=\"checkbox\"]:checked {\n  border-color: var(--secondaryColor);\n  background-color: var(--lightSecondaryColor);\n}\n\n.src-scripts-components-CheckboxListItem-__listItem___label {\n  font-size: 14px;\n  color: var(--mainColor);\n  margin-right: 8px;\n}\n\n.src-scripts-components-CheckboxListItem-__listItem___subLabel {\n  font-size: 12px;\n  color: var(--switchLabelColor);\n  margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"checkboxItem": "src-scripts-components-CheckboxListItem-__listItem___checkboxItem",
	"label": "src-scripts-components-CheckboxListItem-__listItem___label",
	"subLabel": "src-scripts-components-CheckboxListItem-__listItem___subLabel"
};