// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupContainer.css';


type PopupContainerPropTypes = {
  size?: string,
  isFullscreen?: boolean,
  elevation?: number,
  isRound?: boolean,
  children: Node,
  verticalAlign?: string,
  className?: string,
  isPopover?: boolean
};

const PopupContainer = ({
  size = 'medium',
  isFullscreen = false,
  elevation = 2,
  isRound = false,
  children,
  verticalAlign = 'center',
  className,
  isPopover = false,
}: PopupContainerPropTypes) => (
  <div className={
    `
      ${ isPopover ? styles.popoverContainer : styles.popupContainer}
      ${styles[size]}
      ${typeof className !== 'undefined' ? className : ''}
      ${ !isPopover ? (typeof styles[`elevation${elevation}`] !== 'undefined' ? styles[`elevation${elevation}`] : '') : '' }
      ${isFullscreen ? styles.fullscreen : ''}
      ${isRound ? styles.round : ''}
      ${typeof styles[verticalAlign] !== 'undefined' ? styles[verticalAlign] : ''}
    `
   }
  >
    { children }
  </div>
);


export default PopupContainer;
