// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/lib/md';

import type { ID_TYPE } from '../../types/common';
import KidsTable from '../KidsTable';
import Input from '../Input';
import Searchbar from './searchbar';

import * as actions from '../../actions/kids';


type StudyKidsPropTypes = {
  studyId: ID_TYPE,
  onLoad: Function,
  onUnmount: Function,
};


const StudyKids = ({
  onLoad,
  studyId,
  onUnmount,
}: StudyKidsPropTypes) => {
  useEffect(() => {
    onLoad(studyId);
    
    return () => onUnmount();
  });

  return (
    <div style={{ marginBottom: '8rem' }}>
      <Searchbar />
      <KidsTable studyId={studyId}/>
    </div>
  )
};

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);

    return ({
      studyId,
    });
  },
  dispatch => ({
    onLoad(study) {
      dispatch(actions.updateKidsFilters('study', study));
      dispatch(actions.startFetchKids());
    },
    onUnmount() {
      dispatch(actions.updateKidsFilters('text', ''));
    }
  })
)(StudyKids);

