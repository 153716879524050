exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-RadioOptions-__radioOptions___container {\n  padding: 2rem;\n  color: var(--almostGrayColor);\n}\n\n.src-scripts-components-RadioOptions-__radioOptions___selected {\n  background-color: var(--mainColor) !important;\n  color: #fff !important;\n}\n\n.src-scripts-components-RadioOptions-__radioOptions___option {\n  display: flex;\n  flex-direction: column;\n  font-size: 2rem;\n  padding: 1rem 0\n}\n\n.src-scripts-components-RadioOptions-__radioOptions___option label {\n    box-sizing: border-box;\n    align-items: center;\n    border: 1px solid var(--grayColor) !important;\n    display: flex;\n    padding: 2rem;\n    width: 100%;\n    border-radius: var(--elementBorderRadius);\n    -webkit-transition: all .2s ease-in-out;\n    transition: all .2s ease-in-out\n  }\n\n.src-scripts-components-RadioOptions-__radioOptions___option label:hover {\n      box-shadow: var(--boxShadow), var(--focusShadow);\n      -webkit-transform: scale(1.01);\n              transform: scale(1.01);\n      cursor: pointer;\n    }\n\n/* .inputRadio {\n  border: none !important;\n  height: 4rem !important;\n  width: 4rem !important;\n} */\n\n.src-scripts-components-RadioOptions-__radioOptions___detailContainer {\n  /* border: 1px solid; */\n  padding: 2rem 0 2rem 7rem;\n  position: relative;\n}\n\n.src-scripts-components-RadioOptions-__radioOptions___detailText {\n  color: var(--grayColor);\n  font-size: 1.6rem;\n  font-weight: bold;\n  padding: 1rem;\n}\n\n.src-scripts-components-RadioOptions-__radioOptions___indicator {\n  position: absolute;\n  border-left: 1px solid;\n  border-bottom: 1px solid;\n  border-color: var(--grayColor);\n  height: 8rem;\n  width: 2rem;\n  top: 1rem;\n  left: 3.7rem;\n}\n\n.src-scripts-components-RadioOptions-__radioOptions___label {\n  white-space: pre-wrap;\n  margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-RadioOptions-__radioOptions___container",
	"selected": "src-scripts-components-RadioOptions-__radioOptions___selected",
	"option": "src-scripts-components-RadioOptions-__radioOptions___option",
	"detailContainer": "src-scripts-components-RadioOptions-__radioOptions___detailContainer",
	"detailText": "src-scripts-components-RadioOptions-__radioOptions___detailText",
	"indicator": "src-scripts-components-RadioOptions-__radioOptions___indicator",
	"label": "src-scripts-components-RadioOptions-__radioOptions___label"
};