// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type RESULTS_TYPE = {
  id: ID_TYPE,
  label: String,
  result: String,
};

export type FETCH_RESULTS_STARTED_TYPE = {
  type: 'FETCH_RESULTS_STARTED',
  payload: {
    typeOfResults: string,
  }
};
export const FETCH_RESULTS_STARTED = 'FETCH_RESULTS_STARTED';

export type FETCH_RESULTS_COMPLETED_TYPE = {
  type: 'FETCH_RESULTS_COMPLETED',
  payload: {
    results: {[ID_TYPE]: RESULTS_TYPE},
    id: ID_TYPE,
  }
};
export const FETCH_RESULTS_COMPLETED = 'FETCH_RESULTS_COMPLETED';

export type FETCH_RESULTS_FAILED_TYPE = {
  type: 'FETCH_RESULTS_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_RESULTS_FAILED = 'FETCH_RESULTS_FAILED';

export type RESULTS_ACTION_TYPE =
  | FETCH_RESULTS_STARTED_TYPE
  | FETCH_RESULTS_COMPLETED_TYPE
  | FETCH_RESULTS_FAILED_TYPE;
