exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../../assets/styles/variables/font.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-DataTable-Cells-Highlight-__highlightCell___highlight {\n  display: flex;\n  font-family: var(--mainFont);\n  font-size: 1.7rem;\n  font-weight: bold;\n  color: #838383;\n}\n\n.src-scripts-components-DataTable-Cells-Highlight-__highlightCell___clickleable {\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n@media(max-width: 768px) {\n  .src-scripts-components-DataTable-Cells-Highlight-__highlightCell___highlight {\n    font-size: 2rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"highlight": "src-scripts-components-DataTable-Cells-Highlight-__highlightCell___highlight",
	"clickleable": "src-scripts-components-DataTable-Cells-Highlight-__highlightCell___clickleable"
};