exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "\n.src-scripts-components-StudyForm-__studyForm___addStudyForm {}\n\n\n.src-scripts-components-StudyForm-__studyForm___form {\n  display: flex;\n  flex-direction: column;\n  padding: 2rem 0;\n  max-width: 130rem;\n  width: 100%;\n  margin: auto;\n}\n\n.src-scripts-components-StudyForm-__studyForm___section {\n  display: flex;\n  flex-direction: column;\n  padding: 4rem;\n  border-bottom: 1px solid var(--mediumGrayColor);\n  margin-bottom: 4rem;\n}\n\n.src-scripts-components-StudyForm-__studyForm___row {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.src-scripts-components-StudyForm-__studyForm___col {\n  flex: 1 1;\n  padding: 2rem 1rem;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-StudyForm-__studyForm___row {\n    flex-direction: column;\n    flex-wrap: unset;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"addStudyForm": "src-scripts-components-StudyForm-__studyForm___addStudyForm",
	"form": "src-scripts-components-StudyForm-__studyForm___form",
	"section": "src-scripts-components-StudyForm-__studyForm___section",
	"row": "src-scripts-components-StudyForm-__studyForm___row",
	"col": "src-scripts-components-StudyForm-__studyForm___col"
};