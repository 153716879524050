// @flow
type ERROR_PROP_TYPES = {
  statusCode: number,
  message: string,
  data?: Object | string,
  object_id?: number,
  retryAction?: Object
};

export const error = (type: string) => (payload: ERROR_PROP_TYPES) => ({ type, payload });

// export const list = (type: string) =>
//   (next: ?number, count: number, normalizedResults: Object) => ({
//     type,
//     payload: {
//       next,
//       count,
//       response: normalizedResults
//     }
//   });

// export const get = (type: string) =>
//   (id: number, normalizedResults: Object) => ({
//     type,
//     payload: {
//       id,
//       response: normalizedResults
//     }
//   });

export const addMeta = (action: Object, meta: Object) => ({
  ...action,
  meta,
});
