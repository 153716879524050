// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  INSTRUMENT_SUBMISSION_TYPE,
  INSTRUMENT_SUBMISSIONS_FETCH_STARTED_TYPE,
  INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED_TYPE,
  INSTRUMENT_SUBMISSIONS_FETCH_FAILED_TYPE,
  INSTRUMENT_SUBMISSIONS_ADD_STARTED_TYPE,
  INSTRUMENT_SUBMISSIONS_ADD_COMPLETED_TYPE,
  INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED_TYPE,
  INSTRUMENT_SUBMISSIONS_ADD_FAILED_TYPE,
  INSTRUMENT_SUBMISSIONS_UPDATE_STARTED_TYPE,
  INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED_TYPE,
  INSTRUMENT_SUBMISSIONS_UPDATE_FAILED_TYPE,
  INSTRUMENT_SUBMISSIONS_REMOVED_TYPE,
  INSTRUMENT_SUBMISSIONS_REMOVE_FAILED_TYPE,
  INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED_TYPE,
  INSTRUMENT_SUBMISSION_SELECTED_TYPE,
  INSTRUMENT_SUBMISSION_FETCH_STARTED_TYPE,
  INSTRUMENT_SUBMISSION_FETCH_COMPLETED_TYPE,
  REQUIRED_VALUES_ERROR_TYPE,
  CLEAR_REQUIRED_VALUES_ERROR_TYPE,
} from '../types/instrumentSubmissions';

import { error } from './common';
import * as types from '../types/instrumentSubmissions';


export const startFetchInstrumentSubmissions = (
): INSTRUMENT_SUBMISSIONS_FETCH_STARTED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_FETCH_STARTED,
  payload: {},
});

export const completeFetchInstrumentSubmissions = (
  entities: {[number]: INSTRUMENT_SUBMISSION_TYPE},
  order: Array<number>,
  nextPage,
): INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    nextPage
  },
});

export const failFetchInstrumentSubmissions = error(types.INSTRUMENT_SUBMISSIONS_FETCH_FAILED);

export const startAddInstrumentSubmissions = (
  values: Object,
  offlineId: String,
): INSTRUMENT_SUBMISSIONS_ADD_STARTED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_ADD_STARTED,
  payload: {
    values,
    offlineId,
  },
  meta: {
    offline: {
      // the network action to execute:
      effect: { }, // Nothing because we do the effect with redux-sagas
      // action to dispatch when effect succeeds:
      commit: { type: types.INSTRUMENT_SUBMISSIONS_ADD_STARTED, meta: { values, offlineId, } },
      // action to dispatch if network action fails permanently:
      rollback: { type: types.INSTRUMENT_SUBMISSIONS_ADD_STARTED, meta: { values, offlineId, } }
    }
  }
});

export const completeAddInstrumentSubmissions = (
  submission: Object,
): INSTRUMENT_SUBMISSIONS_ADD_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_ADD_COMPLETED,
  payload: submission,
});

export const completeOfflineAddInstrumentSubmissions = (
  submission: Object,
): INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED,
  payload: submission,
});

export const failAddInstrumentSubmissions = error(types.INSTRUMENT_SUBMISSIONS_ADD_FAILED);


export const startUpdateInstrumentSubmissions = (
  submission: INSTRUMENT_SUBMISSION_TYPE
): INSTRUMENT_SUBMISSIONS_UPDATE_STARTED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_UPDATE_STARTED,
  payload: submission,
});

export const completeUpdateInstrumentSubmissions = (
): INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED,
});

export const failUpdateInstrumentSubmissions = error(types.INSTRUMENT_SUBMISSIONS_UPDATE_FAILED);

export const removeInstrumentSubmissions = (id: ID_TYPE): INSTRUMENT_SUBMISSIONS_REMOVED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_REMOVED,
  payload: id,
});

export const failRemoveInstrumentSubmissions = error(types.INSTRUMENT_SUBMISSIONS_REMOVE_FAILED);

export const updateInstrumentSubmissionsFilters = (
  key: string,
  value: any,
): INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED,
  payload: {
    key,
    value,
  },
});

export const selectInstrumentSubmission = (id: ID_TYPE): INSTRUMENT_SUBMISSION_SELECTED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSION_SELECTED,
  payload: id,
});

export const unselectAllInstrumentSubmission = (): UNSELECT_ALL_INSTRUMENT_SUBMISSIONS_TYPE => ({
  type: types.UNSELECT_ALL_INSTRUMENT_SUBMISSIONS,
});

export const startFetchInstrumentSubmission = (id: ID_TYPE): INSTRUMENT_SUBMISSION_FETCH_STARTED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSION_FETCH_STARTED,
  payload: id,
});

export const completeFetchInstrumentSubmission = (
  submission: INSTRUMENT_SUBMISSION_TYPE,
): INSTRUMENT_SUBMISSION_FETCH_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_SUBMISSION_FETCH_COMPLETED,
  payload: submission,
});

export const failFetchInstrumentSubmission = error(types.INSTRUMENT_SUBMISSION_FETCH_FAILED);

export const errorRequieredValues = (ids: Array<ID_TYPE>): REQUIRED_VALUES_ERROR_TYPE => ({
  type: types.REQUIRED_VALUES_ERROR,
  payload: {
    order: ids,
  },
});

export const clearErrorRequiredValues = (): CLEAR_REQUIRED_VALUES_ERROR_TYPE => ({
  type: types.CLEAR_REQUIRED_VALUES_ERROR,
});
