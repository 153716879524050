// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Waypoint from 'react-waypoint';

import type { ERROR_TYPE } from '../../types/common';
import SubmissionsTable from '../SubmissionsTable';
import FullPlaceholder from '../ListPlaceholder';
import { FullLoader } from '../Loader';
import { ANTHROPOMETRY_TYPE } from '../../settings';

import styles from './studySubmissionsList.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/instrumentSubmissions';
import * as closeableActions from '../../actions/closeable';


type StudySubmissionsListPropTypes = {
  submissions: Array<Object>,
  isFetching: boolean,
  error: ERROR_TYPE,
  hasMore: boolean,
  studyType: string,
  fetchInstrumentSubmissions: Function,
  onDelete: Function,
  onUpdate: Function,
};


const StudySubmissionsList = ({
  submissions,
  isFetching,
  hasMore,
  studyType,
  fetchInstrumentSubmissions,
  onDelete,
  onUpdate,
}: StudySubmissionsListPropTypes) => (
  <div className={styles.approve}>
    {
      submissions.length > 0 && (
        <Fragment>
          <SubmissionsTable
            data={submissions}
            isAnthropometricInstrument={studyType === ANTHROPOMETRY_TYPE}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
          <Waypoint
            onEnter={() => hasMore && fetchInstrumentSubmissions()}
            viewportBottom={10}
          />
        </Fragment>
      )
    }
    {
      isFetching && (
        <FullLoader
          tint="mediumGray"
          title="Cargando ingresos"
        />
      )
    }
    {
      submissions.length === 0 && !isFetching && (
        <FullPlaceholder />
      )
    }
  </div>
);


export default connect(
  (state, ownProps) => {
    const { studyId } = ownProps;
    const { instrument = {} } = selectors.getSelectedStudy(state) || {};
    const { type_key } = instrument;
    const data = selectors.getStudySubmissions(state, studyId);

    const submissions = data.map((el = {}) => ({
      ...el,
      date: moment(el.creation_date).format('L'),
      time: moment(el.creation_date).format('LT'),
      user: el.user_name || '',
    }));

    return ({
      submissions,
      studyType: type_key,
      isFetching: selectors.isFetchingInstrumentSubmissions(state),
      hasMore: selectors.hasMoreInstrumentSubmissions(state),
    });
  },
  dispatch => ({
    fetchInstrumentSubmissions() {
      dispatch(actions.startFetchInstrumentSubmissions());
    },
    onDelete(id) {
      dispatch(actions.selectInstrumentSubmission(id));
      dispatch(closeableActions.open('deleteSubmissionPopup'));
    },
    onUpdate(id) {

      dispatch(actions.selectInstrumentSubmission(id));
      dispatch(closeableActions.open('updateStudySubmission'));
      // dispatch(actions.selectInstrumentSubmission(id));
      // dispatch(closeableActions.open('updateSubmissionPopup'));
    }
  })
)(StudySubmissionsList);

