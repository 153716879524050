export type populateCurrentUserType = {
  type: 'POPULATE_CURRENT_USER',
  payload: Object,
}
export const POPULATE_CURRENT_USER = 'POPULATE_CURRENT_USER';

export type clearCurrentUserType = {
  type: 'CLEAR_CURRENT_USER',
  payload: Object,
}
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
