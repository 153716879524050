exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/font.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-PopMenu-__popMenu___optionsContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 1.5rem 0;\n  width: 180px;\n}\n\n.src-scripts-components-PopMenu-__popMenu___option {\n  align-items: center;\n  color: var(--grayColor);\n  cursor: pointer;\n  display: flex;\n  /* font-family: var(--secondaryFont); */\n  font-size: 1.5rem;\n  padding: 0.5rem 1.5rem;\n  /* text-transform: uppercase; */\n  -webkit-transition: 0.25s;\n  transition: 0.25s\n}\n\n.src-scripts-components-PopMenu-__popMenu___option:last-child {\n    margin-bottom: 0;\n  }\n\n.src-scripts-components-PopMenu-__popMenu___option:hover {\n    background: var(--paleGrayColor);\n  }\n\n.src-scripts-components-PopMenu-__popMenu___option svg {\n    fill: #9cb6b6;\n    opacity: 1.0;\n  }\n\n.src-scripts-components-PopMenu-__popMenu___active {\n  color: var(--errorColor);\n  font-weight: bold;\n}\n\n.src-scripts-components-PopMenu-__popMenu___iconContainer {\n  display: flex;\n  min-width: 3.5rem;\n}\n\n.src-scripts-components-PopMenu-__popMenu___text {\n  display: flex;\n  flex: 1 1;\n}\n\n.src-scripts-components-PopMenu-__popMenu___error {\n  color: var(--errorColor)\n}\n\n.src-scripts-components-PopMenu-__popMenu___error svg {\n    fill: var(--errorColor);\n    opacity: 0.5;\n  }\n\n.src-scripts-components-PopMenu-__popMenu___disabled {\n  color: var(--mediumGrayColor);\n  cursor: default\n}\n\n.src-scripts-components-PopMenu-__popMenu___disabled:hover {\n    background: none !important;\n  }\n\n.src-scripts-components-PopMenu-__popMenu___disabled svg {\n    fill: var(--mediumGrayColor);\n  }\n", ""]);
// Exports
exports.locals = {
	"optionsContainer": "src-scripts-components-PopMenu-__popMenu___optionsContainer",
	"option": "src-scripts-components-PopMenu-__popMenu___option",
	"active": "src-scripts-components-PopMenu-__popMenu___active",
	"iconContainer": "src-scripts-components-PopMenu-__popMenu___iconContainer",
	"text": "src-scripts-components-PopMenu-__popMenu___text",
	"error": "src-scripts-components-PopMenu-__popMenu___error",
	"disabled": "src-scripts-components-PopMenu-__popMenu___disabled"
};