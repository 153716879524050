// @flow
import React from 'react';
import ReactDatePicker from 'react-date-picker';
import { MdEvent } from 'react-icons/lib/md';

import Button from '../Button';


// import inputStyles from '../Input/input.css';
import styles from './datePicker.css';
import { debug } from 'util';


type DatePickerPropTypes = {
  size?: string,
  value?: Date,
  onChange?: Function,
  hideClearIcon?: boolean,
  isLeft?: boolean,
  isRight?: boolean,
  isMiddle?: boolean,
  clearMargins?: boolean,
};

class DatePicker extends React.Component<DatePickerPropTypes> {
  constructor(props: DatePickerPropTypes) {
    super(props);

    const { value } = this.props;

    if (value) {
      let rValue = new Date(value);

      if (rValue instanceof Date && isNaN(rValue)) { // eslint-disable-line
        rValue = new Date();
      }

      return props.onChange && props.onChange(rValue);
    }
  }

  render() {
    const {
      size = 'medium',
      hideClearIcon = false,
      isLeft = false,
      isRight = false,
      isMiddle = false,
      clearMargins = false,
      ...props
    } = this.props;

    return (
      <div className={styles.pickerContainer}>
        <ReactDatePicker
          {...props} 
          className={
            `
              ${styles.datePicker}
              ${isLeft ? styles.isLeft : ''}
              ${isRight ? styles.isRight : ''}
              ${isMiddle ? styles.isMiddle : ''}
              ${typeof styles[size] !== 'undefined' ? styles[size] : ''}
              ${clearMargins ? styles.clearMargins : ''}
            `
          }
          calendarClassName={styles.calendar}
          value={props.value}
          clearIcon={hideClearIcon ? null : undefined}
          calendarIcon={
            <Button
              Icon={MdEvent}
              tint="gray"
              size="small"
              isTransparent
              isOutlined
              clearMargins
              useAnchor
            />
          }
        />
      </div>
    );
  }
}


export default DatePicker;
