exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-views-Study-__study___viewPort {\n  background-color: #efefef;\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.src-scripts-components-views-Study-__study___aside {\n  flex: 1 1;\n}\n\n.src-scripts-components-views-Study-__study___content {\n  display: flex;\n  flex: 8 1;\n  margin-left: 4rem;\n  width: 100%;\n}\n\n.src-scripts-components-views-Study-__study___card {\n  background-color: #fff;\n  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06);\n  border-top-left-radius: var(--elementBorderRadius);\n  border-top-right-radius: var(--elementBorderRadius);\n  box-sizing: border-box;\n  border: 1px solid var(--mediumGrayColor);\n  border-bottom: none;\n  display: flex;\n  flex: 1 1;\n  flex-direction: column;\n  padding: 4rem 4rem 0 4rem;\n  z-index: 1;\n  max-width: 100%;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-views-Study-__study___viewPort {\n    overflow: auto;\n    min-height: -webkit-min-content;\n    min-height: -moz-min-content;\n    min-height: min-content;\n  }  \n\n  .src-scripts-components-views-Study-__study___content {\n    margin-left: 0rem;\n    margin-top: 4rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"viewPort": "src-scripts-components-views-Study-__study___viewPort",
	"aside": "src-scripts-components-views-Study-__study___aside",
	"content": "src-scripts-components-views-Study-__study___content",
	"card": "src-scripts-components-views-Study-__study___card"
};