// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type QUESTION_TYPE = {
  id: ID_TYPE,
  label: string,
  question_set: ID_TYPE,
  type: 'multiple_choices' | 'yes_no' | 'open' | 'date',
  choices?: Array<ID_TYPE>,
  plugin_uid: string,
  name: string,
  description: string,
  label: string,
  help_text: string,
  key: string,
  is_demographic: boolean,
  large_text: boolean,
  required: boolean,
  select_many: boolean,
  instrument: ID_TYPE,
  question_set: ID_TYPE,
  correct_choice: ID_TYPE
};


export type QUESTIONS_FETCH_STARTED_TYPE = {
  type: 'QUESTIONS_FETCH_STARTED',
  payload: {},
};
export const QUESTIONS_FETCH_STARTED = 'QUESTIONS_FETCH_STARTED';

export type QUESTIONS_FETCH_COMPLETED_TYPE = {
  type: 'QUESTIONS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: QUESTION_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const QUESTIONS_FETCH_COMPLETED = 'QUESTIONS_FETCH_COMPLETED';

export type QUESTIONS_FETCH_FAILED_TYPE = {
  type: 'QUESTIONS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const QUESTIONS_FETCH_FAILED = 'QUESTIONS_FETCH_FAILED';

export type QUESTIONS_ACTION_TYPE =
  | QUESTIONS_FETCH_STARTED_TYPE
  | QUESTIONS_FETCH_COMPLETED_TYPE
  | QUESTIONS_FETCH_FAILED_TYPE;
