// @flow
import type { ID_TYPE } from '../types/common';

import { 
  STUDY_TYPE,
  FETCH_STUDY_TYPES_STARTED_TYPE,
  FETCH_STUDY_TYPES_COMPLETED_TYPE
} from '../types/studyTypes';
import * as types from '../types/studyTypes';

import { error } from './common';


export const startFetchStudyTypes = (): FETCH_STUDY_TYPES_STARTED_TYPE => ({
  type: types.FETCH_STUDY_TYPES_STARTED,
});

export const completeFetchStudyTypes = (
  entities: {[ID_TYPE]: STUDY_TYPE},
  order: Array<ID_TYPE>,
): FETCH_STUDY_TYPES_COMPLETED_TYPE => ({
  type: types.FETCH_STUDY_TYPES_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchStudyTypes = error(types.FETCH_STUDY_TYPES_STARTED_TYPE);
