// @flow
import React from 'react';
import ReactTable from 'react-table';

import { makeTemplateComponent } from './services';
import Table from './Table';
import TrGroup from './TrGroup';
import Tr from './Tr';
import Th from './Th';
import Td from './Td';
import { Thead, Tbody, Tfoot } from './TableSections';
import Filter from './Filter';
import Expander from './Expander';
import PivotValue from './PivotValue';
import Aggregated from './Aggregated';
import Loading from './Loading';
import styles from './dataTable.css';


type DataTablePropTypes = {
  className?: string,
};

const DataTable = ({ className, ...props }: DataTablePropTypes) => (
  <ReactTable
    className={`
      ${styles.dataTable}
      ${className != null ? styles[className] : ''}
    `}
    TableComponent={Table}
    TheadComponent={Thead}
    TbodyComponent={Tbody}
    TfootComponent={Tfoot}
    TrGroupComponent={TrGroup}
    TrComponent={Tr}
    ThComponent={Th}
    TdComponent={Td}
    FilterComponent={Filter}
    ExpanderComponent={Expander}
    PivotValueComponent={PivotValue}
    AggregatedComponent={Aggregated}
    LoadingComponent={Loading}
    NoDataComponent={makeTemplateComponent(styles.noData, 'NoData')}
    ResizerComponent={makeTemplateComponent(styles.resizer, 'Resizer')}
    resizable={false}
    sortable={false}
    {...props}
  />
);

// PivotComponent: undefined, // this is a computed default generated using
// // the ExpanderComponent and PivotValueComponent at run-time in methods.js
// PaginationComponent: Pagination,
// PreviousComponent: undefined,
// NextComponent: undefined,

// NoDataComponent: _.makeTemplateComponent('rt-noData', 'NoData'),
// ResizerComponent: _.makeTemplateComponent('rt-resizer', 'Resizer'),
// PadRowComponent: () => <span>&nbsp;</span>,

export default DataTable;
