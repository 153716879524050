import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MdAdd } from 'react-icons/lib/md';

import Button from '../Button';
import AddParticipantPopup, { PARTICIPANT_POPUP } from '../AddParticipantPopup';
import * as closableActions from '../../actions/closeable';

import styles from './studyParticipants.css';

const ParticipantBar = ({ openPopupAddParticipant }) => {
  return (
    <>
      <Button
        tint='gray'
        title='Crear Participante'
        Icon={MdAdd}
        isRound
        clearMargins
        className={styles.participant}
        onClick={() => openPopupAddParticipant()}
      />

      <AddParticipantPopup />
    </>
  );
};

ParticipantBar.propTypes = {
  openPopupAddParticipant: PropTypes.func,
};

// export default ParticipantBar;
export default connect(
  (state) => ({}),
  (dispatch) => ({
    openPopupAddParticipant() {
      dispatch(closableActions.open(PARTICIPANT_POPUP));
    },
  })
)(ParticipantBar);
