// @flow
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';

import {
  CHART_VIEW_TYPE,
  TABLE_VIEW_TYPE,
  FILTER_STUDY_KEY,
  RESULTS_EXIT_PROFILE_IS_READY_KEY,
  RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY,
} from '../../settings';
import { INSTRUMENT_FILTERS_ID } from '../PopupInstrumentFilters';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import ToggleView from '../ToggleView';
import ChartsView from './charts';
import TableView from './table';
import Button from '../Button';
import styles from './instrumentResults.css';

import * as selectors from '../../reducers';
import * as actions from '../../actions/studyResults';
import * as closeableActions from '../../actions/closeable';


type ResultsPropTypes = {
  results: Array<Object>,
  instrument: Object,
  isFetching: boolean,
  isReady: boolean,
  error: Object,
  phases: Array<number>,
  onLoad: Function,
  openMoreFilters: Function,
};

const Results = ({
  results,
  instrument,
  isFetching,
  isReady,
  error,
  phasesNames,
  onLoad,
  openMoreFilters,
}) => {
  const [ viewType, setViewType ] = useState(TABLE_VIEW_TYPE);

  useEffect(() => {
    onLoad(instrument.id);
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.row} ${styles.spaced}`}>
        <Button
          title="Más filtros"
          size='small'
          tint='mediumGray'
          onClick={() => openMoreFilters()}
          className={styles.approveButton}
        />
        <ToggleView
          value={viewType}
          onChange={val => setViewType(val)}
        />
      </div>
      <div className={styles.content}>
        {
          (!isFetching && results.length) && (
            <> 
              {
                viewType === TABLE_VIEW_TYPE
                ? <TableView results={results} phases={phasesNames}/>
                : <ChartsView results={results} phases={phasesNames} windowDiference={240}/>
              }
            </>
          )
        }
        {
          isFetching && (
            <FullLoader
              tint="mediumGray"
              title="Cargando resultados"
            />
          )
        }
        {
          !results.length && !isFetching && (
            <FullPlaceholder />
          )
        }
      </div>
    </div>
  )
};

export default connect(
  state => {
    const styudyId = selectors.getInstrumentsFilter(state, FILTER_STUDY_KEY);
    const { phases = [] } = selectors.getStudy(state, styudyId);

    return ({
      results: selectors.getStudyResults(state) || [],
      isReady: selectors.getResultExitProfileExtraInfoKeyValue(state, RESULTS_EXIT_PROFILE_IS_READY_KEY),
      lastUpdated: selectors.getResultExitProfileExtraInfoKeyValue(state, RESULTS_EXIT_PROFILE_LAST_UPDATED_KEY),
      instrument: selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state),
      isFetching: selectors.isFetchingStudyResults(state),
      error: selectors.getStudyResultsError(state),
      phasesNames: sortBy(phases, ['ordinal']).map(el => el.name),
    })
  },
  dispatch => ({
    onLoad(id) {
      dispatch(actions.startFetchInstrumentResults(id));
    },
    openMoreFilters() {
      dispatch(closeableActions.open(INSTRUMENT_FILTERS_ID));
    },
  })
)(Results);
