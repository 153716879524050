// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type KID_TYPE = {
  id: ID_TYPE,
  full_name: string,
  code: string,
  gender: string,
  birthdate: Date,
  organization: ID_TYPE,
  mother: ID_TYPE,
  anthropometryResults: Array<ID_TYPE>,
};

export type FETCH_KIDS_STARTED_TYPE = {
  type: 'FETCH_KIDS_STARTED',
  payload: {
    page: number,
  }
};
export const FETCH_KIDS_STARTED = 'FETCH_KIDS_STARTED';

export type FETCH_KIDS_COMPLETED_TYPE = {
  type: 'FETCH_KIDS_COMPLETED',
  payload: {
    entities: {[number]: KID_TYPE},
    order: Array<number>,
    nextPage: ?number,
  }
};
export const FETCH_KIDS_COMPLETED = 'FETCH_KIDS_COMPLETED';

export type FETCH_KIDS_FAILED_TYPE = {
  type: 'FETCH_KIDS_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_KIDS_FAILED = 'FETCH_KIDS_FAILED';


export type ADD_KID_STARTED_TYPE = {
  type: 'ADD_KID_STARTED',
  payload: KID_TYPE,
};
export const ADD_KID_STARTED = 'ADD_KID_STARTED';

export type ADD_KID_COMPLETED_TYPE = {
  type: 'ADD_KID_COMPLETED',
  payload: {
    oldId: ID_TYPE,
    newId: ID_TYPE,
  },
};
export const ADD_KID_COMPLETED = 'ADD_KID_COMPLETED';

export type ADD_KID_FAILED_TYPE = {
  type: 'ADD_KID_FAILED',
  payload: ERROR_TYPE,
};
export const ADD_KID_FAILED = 'ADD_KID_FAILED';

export type KIDS_FILTERS_UPDATED_TYPE = {
  type: 'KIDS_FILTERS_UPDATED',
  payload: Object,
};
export const KIDS_FILTERS_UPDATED = 'KIDS_FILTERS_UPDATED';

export type KID_SELECTED_TYPE = {
  type: 'KID_SELECTED',
  payload: ID_TYPE,
};
export const KID_SELECTED = 'KID_SELECTED';

export type ALL_KIDS_UNSELECTED_TYPE = {
  type: 'ALL_KIDS_UNSELECTED',
};
export const ALL_KIDS_UNSELECTED = 'ALL_KIDS_UNSELECTED';

export type KID_UPDATED_TYPE = {
  type: 'KID_UPDATED',
  payload: {
    id: ID_TYPE,
    key: String,
  }
};
export const KID_UPDATED = 'KID_UPDATED';

export type FETCH_KID_FORM_DATA_STARTED_TYPE = {
  type: 'FETCH_KID_FORM_DATA_STARTED',
  payload: ID_TYPE,
};
export const FETCH_KID_FORM_DATA_STARTED = 'FETCH_KID_FORM_DATA_STARTED';

export type FETCH_KID_FORM_DATA_COMPLETED_TYPE = {
  type: 'FETCH_KID_FORM_DATA_COMPLETED',
  payload: KID_TYPE,
};
export const FETCH_KID_FORM_DATA_COMPLETED = 'FETCH_KID_FORM_DATA_COMPLETED';

export type FETCH_KID_FORM_DATA_FAILED_TYPE = {
  type: 'FETCH_KID_FORM_DATA_FAILED',
  payload: ERROR_TYPE,
};
export const FETCH_KID_FORM_DATA_FAILED = 'FETCH_KID_FORM_DATA_FAILED';


export type KID_ACTION_TYPE =
  | ADD_KID_STARTED_TYPE
  | ADD_KID_COMPLETED_TYPE
  | ADD_KID_FAILED_TYPE
  | FETCH_KIDS_STARTED_TYPE
  | FETCH_KIDS_COMPLETED_TYPE
  | FETCH_KIDS_FAILED_TYPE
  | KIDS_FILTERS_UPDATED_TYPE
  | KID_SELECTED_TYPE
  | ALL_KIDS_UNSELECTED_TYPE
  | KID_UPDATED_TYPE
  | FETCH_KID_FORM_DATA_STARTED_TYPE
  | FETCH_KID_FORM_DATA_COMPLETED_TYPE
  | FETCH_KID_FORM_DATA_FAILED_TYPE;
