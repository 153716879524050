// @flow
import type {
  CLOSEABLE_REGISTERED_TYPE,
  CLOSEABLE_ATTACHED_TYPE,
  CLOSEABLE_OPENED_TYPE,
  CLOSEABLE_CLOSED_TYPE,
  CLOSEABLE_GROUPS_CLOSED_TYPE,
  CLOSEABLE_GROUPS_OPENED_TYPE,
  CLOSEABLE_ALL_CLOSED_TYPE,
  CLOSEABLE_GROUP_ADDED_TYPE,
  CLOSEABLE_GROUP_REMOVED_TYPE,
  CLOSEABLE_ALL_GROUPS_CLEARED_TYPE,
  // CLOSEABLE_CONFIGURATION_UPDATED_TYPE,
  // CLOSEABLE_CONFIGURATION_RESET_TYPE
} from '../types/closeable';
import * as types from '../types/closeable';

export const register = (
  id: string, {
    isOpen = false,
    groups = [],
    configuration,
  }: {
    isOpen?: boolean,
    groups: Array<string>,
    configuration: Object
  },
): CLOSEABLE_REGISTERED_TYPE => ({
  type: types.CLOSEABLE_REGISTERED,
  payload: {
    id,
    isOpen,
    groups,
    configuration,
  },
});

export const attach = (id: string): CLOSEABLE_ATTACHED_TYPE => ({
  type: types.CLOSEABLE_ATTACHED,
  payload: { id },
});

export const open = (id: string): CLOSEABLE_OPENED_TYPE => ({
  type: types.CLOSEABLE_OPENED,
  payload: { id },
});

export const close = (id: string): CLOSEABLE_CLOSED_TYPE => ({
  type: types.CLOSEABLE_CLOSED,
  payload: { id },
});

export const addToAGroup = (id: string, name: string): CLOSEABLE_GROUP_ADDED_TYPE => ({
  type: types.CLOSEABLE_GROUP_ADDED,
  payload: { id, name },
});

export const removeFromAGroup = (id: string, name: string): CLOSEABLE_GROUP_REMOVED_TYPE => ({
  type: types.CLOSEABLE_GROUP_REMOVED,
  payload: { id, name },
});

export const clearItsGroups = (id: string): CLOSEABLE_ALL_GROUPS_CLEARED_TYPE => ({
  type: types.CLOSEABLE_ALL_GROUPS_CLEARED,
  payload: { id },
});

// Plugin Actions

export const closeGroups = (groups: Array<string>): CLOSEABLE_GROUPS_CLOSED_TYPE => ({
  type: types.CLOSEABLE_GROUPS_CLOSED,
  payload: { groups },
});

export const openGroups = (groups: Array<string>): CLOSEABLE_GROUPS_OPENED_TYPE => ({
  type: types.CLOSEABLE_GROUPS_OPENED,
  payload: { groups },
});

export const closeAll = (): CLOSEABLE_ALL_CLOSED_TYPE => ({
  type: types.CLOSEABLE_ALL_CLOSED,
});
