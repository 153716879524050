// @flow
import React from 'react';

import Button from '../../../Button';
import OpenButton from '../../../OpenButton';
import styles from './buttonCell.css';


export type ButtonCellPropTypes = {
  hidden?: boolean,
  popupId?: string,
};

const ButtonCell = ({
  hidden = false,
  popupId = '',
  ...props
}: ButtonCellPropTypes) => (
  popupId
    ? (
      <div className={styles.button}>
        { !hidden && <OpenButton id={popupId} clearBottomMargin {...props} /> }
      </div>
    )
    : (
      <div className={styles.button}>
        { !hidden && <Button clearBottomMargin {...props} /> }
      </div>
    )
);


export default ButtonCell;
