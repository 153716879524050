import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { contentType } from 'mime-types'
import axios from 'axios'

import { Files, Participants } from '../api';
import * as selectors from '../reducers';
import * as actions from '../actions/files';
import { normalize } from '../utils'
import {
  FILE_UPLOAD_STARTED,
  FILES_FETCH_STARTED,
  FILE_DELETE_STARTED,
} from '../types/files'

function* fetchFiles({ payload }) {
  const token = yield select(selectors.getToken);

  try {
    const response = yield call(
      [Participants.custom, 'files'],
      {
        token,
        id: payload,
      }
    );

    const files = normalize(response, 'UUID');

    yield put(actions.fileFetchSucceed(payload, files));
  } catch (e) {
    console.log(e);
  }
}

function* fetchSignFileUrl({ payload }) {
  const {
    file,
    fileName,
    fileExtension,
    uuid,
    participantId,
  } = payload;
  const token = yield select(selectors.getToken);
  const mimeType = contentType(`${fileName}.${fileExtension}`);

  try {
    const signedResponse = yield call(
      [Files.custom, 'sign'],
      {
        token,
        data: {
          key: `${uuid}/${fileName}.${fileExtension}`,
          type: mimeType,
        }
      }
    );

    const signedUrl = signedResponse.presigned_post;

    if (!signedUrl) {
      throw new Error('No presigned url provided by the server.');
    }

    const options = {
      headers: {
        'Content-Type': mimeType,
      },
    };

    const uploadResponse = yield call(
      [axios, 'put'],
      signedUrl,
      file,
      options,
    );

    const fileResponse = yield call(
      [Files, 'create'],
      {
        token,
        data: {
          name: file.name,
          extension: fileExtension,
          uuid,
        }
      }
    );

    const addFileResponse = yield call(
      [Participants.custom, 'addFile'],
      {
        token,
        id: participantId,
        data: {
          uuid,
        }
      }
    );

    yield put(actions.startFileFetch(participantId));
  } catch (e) {
    console.log(e);
  }
}

function* fetchFilesDelete({ payload }) {
  const token = yield select(selectors.getToken);
  const { uuid, participantId } = payload;

  try {
    const response = yield call(
      [Files, 'remove'],
      {
        token,
        id: uuid,
      }
    );
    
    yield put(actions.startFileFetch(participantId));
  } catch (e) {
    console.log(e);
  }
}

export function* watchFetchSignFileUrl(): Iterator<any> {
  yield takeLatest(
    FILE_UPLOAD_STARTED,
    fetchSignFileUrl,
  );
}

export function* watchFilesFetch(): Iterator<any> {
  yield takeLatest(
    FILES_FETCH_STARTED,
    fetchFiles,
  );
}

export function* watchFileDelete(): Iterator<any> {
  yield takeLatest(
    FILE_DELETE_STARTED,
    fetchFilesDelete
  );
}
