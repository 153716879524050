// @flow
import { submit } from 'redux-form';
import {v4 as uuidv4} from 'uuid';

import { ANTHROPOMETRY_TYPE } from '../../settings';
import * as actions from '../../actions/instrumentSubmissions';


export const addSubmission = (
  values,
  dispatch,
) => {
  
  if (values.kid != null || values.participant != null) {
    dispatch(actions.startAddInstrumentSubmissions(values, uuidv4()));
  } else {
    if (values.instrument_type === ANTHROPOMETRY_TYPE) {
      dispatch(submit('addKidForm'));
    } else {
      dispatch(submit('addParticipantForm'));
    }
  }
};

export const updateSubmission = (
  values,
  dispatch,
) => {
  dispatch(actions.startUpdateInstrumentSubmissions(values));
}
