exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-AddOrganizationRow-__addOrganizationRow___row {\n  /* border-bottom: 1px solid var(--mediumGrayColor); */\n  display: flex;\n  flex: 1 1;\n  padding: 0.5rem 1rem;\n  font-size: 2rem;\n  font-weight: 500;\n  color: var(--almostGrayColor)\n}\n\n.src-scripts-components-AddOrganizationRow-__addOrganizationRow___row td {\n    align-items: center;\n    display: flex;\n    flex: 1 1;\n    min-width: 100px;\n  }\n\n.src-scripts-components-AddOrganizationRow-__addOrganizationRow___row .src-scripts-components-AddOrganizationRow-__addOrganizationRow___button {\n    justify-content: flex-end;\n    max-width: 10rem;\n  }\n\n.src-scripts-components-AddOrganizationRow-__addOrganizationRow___row .src-scripts-components-AddOrganizationRow-__addOrganizationRow___name {\n    font-weight: 800;\n  }\n\n.src-scripts-components-AddOrganizationRow-__addOrganizationRow___row .src-scripts-components-AddOrganizationRow-__addOrganizationRow___sample {\n    justify-content: center;\n  }\n", ""]);
// Exports
exports.locals = {
	"row": "src-scripts-components-AddOrganizationRow-__addOrganizationRow___row",
	"button": "src-scripts-components-AddOrganizationRow-__addOrganizationRow___button",
	"name": "src-scripts-components-AddOrganizationRow-__addOrganizationRow___name",
	"sample": "src-scripts-components-AddOrganizationRow-__addOrganizationRow___sample"
};