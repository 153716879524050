// @flow
import type { ComponentType } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './backendSidebarLink.css';


type BackendSidebarLinkPropTypes = {
  Icon: ComponentType<*>,
  to: string,
  title: React$Node,
  badgeCount?: number,
  isActive?: Function,
};

type BackendSidebarLinkBadgePropTypes = {
  count?: number
};


const BackendSidebarLinkBadge = ({ count = 0 }: BackendSidebarLinkBadgePropTypes) => (
  count > 0 ? (
    <div className={styles.badge}>
      { count }
    </div>
  ) : null
);

const BackendSidebarLink = ({
  Icon,
  to,
  title,
  badgeCount = 0,
  isActive,
}: BackendSidebarLinkPropTypes) => (
  <NavLink
    to={to}
    className={styles.link}
    activeClassName={styles.active}
    isActive={isActive}
  >
    {/* <Icon size={26} /> */}
    <span className={styles.title}>
      { title }
    </span>
    <BackendSidebarLinkBadge count={badgeCount} />
  </NavLink>
);


export default BackendSidebarLink;
