// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdDelete } from 'react-icons/lib/md'

import styles from './addOrganizationRow.css';
import Input from '../Input';
import Button from '../Button';
import { calculateSample } from '../../settings';
import * as selectors from '../../reducers';

class AddOrganizationRow extends React.Component {
  render() {
    const {
      value,
      onChange,
      name,
      location,
      sublocation,
      onRemove,
    } = this.props;

    const [id, population = 0, relationId] = value;
    const sample = calculateSample(population);

    return (
      <tr className={styles.row}>
        <td className={styles.name}>{name}</td>
        <td>{location}</td>
        <td>{sublocation}</td>
        <td>
          <Input
            type="number"
            value={population}
            onChange={iValue => onChange([id, iValue.target.value, relationId])}
            clearMargins={true}
          />
        </td>
        <td className={styles.sample}>{sample.toFixed(2)}</td>
        <td className={styles.button}>
          <Button
            isTransparent={true}
            Icon={MdDelete}
            clearMargins={true}
            tint="error"
            onClick={() => onRemove(id)}
          />
        </td>
      </tr>
    )
  }
}

export default connect(
  (state, { value }) => {
    const id = value[0];
    const organization = selectors.getOrganization(state, id);
    return {
      name: organization.name,
      location: organization.location,
      sublocation: organization.sublocation,
    };
  },
)(AddOrganizationRow);
