// @flow
import type { ID_TYPE } from '../types/common';

export const arrayMove = (
  arr: Array<ID_TYPE>,
  previousIndex: number,
  newIndex: number,
): Array<ID_TYPE> => {
  const array = [...arr];
  if (newIndex && (newIndex >= array.length)) {
    let k = newIndex - array.length;
    while (k + 1) {
      k -= 1;
      array.push(-1); // Fill spaces with -1 if need to rotate with external index
    }
  }

  if (previousIndex && newIndex) {
    array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  }

  return array;
};
