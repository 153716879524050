export default {
  titles: {
    contacts: 'Contactos',
    personalInfo: 'Información personal',
  },
  labels: {
    requiredField: 'Este campo es requerido',
    name: {
      one: 'Nombre',
      other: 'Nombres',
    },
    userType: 'Tipo de Usuario',
    lastName: {
      one: 'Apellido',
      other: 'Apellidos',
    },
    phone: 'Teléfono',
    code: 'Código',
    email: 'Correo',
    address: 'Dirección',
    assignedRoutes: 'Rutas Asignadas',
    monitor: {
      one: 'Monitor',
      other: 'Monitores',
    },
    driver: 'Chofer',
    type: 'Tipo',
    stops: 'Paradas',
    route: {
      one: 'Ruta',
      other: 'Rutas',
    },
    allowsChangeRequests: 'Permite Cambios',
    allowsEntry: 'Permite Entrada',
    allowsExit: 'Permite Salida',
    students: {
      one: 'Estudiante',
      other: 'Estudiantes',
    },
    parent: {
      one: 'Padre',
      other: 'Padres',
    },
    plates: 'Placas',
    capacity: 'Capacidad',
    year: 'Año',
    identifier: 'Identificador',
  },
  buttons: {
    cancel: 'Cancelar',
    delete: 'Borrar',
    create: 'Crear {{Item}}',
    requestChange: 'Solicitar Cambio de Parada',
    pending: 'Pendiente',
    solved: 'Resueltos',
    aprove: 'Aprobar',
    reject: 'Rechazar',
    send: 'Enviar',
    dismissAll: 'Limpiar todas',
    edit: 'Editar',
    exploreStops: 'Ver Paradas',
  },
  common: {
    addTo: 'Agregar {{element}} a {{to}}',
    goingTo: 'Dirigiéndose a:',
    referenceOrAddress: 'punto de referencia o dirección',
    calendarization: 'Calendarización',
    settings: 'Configuración',
    liveMap: 'Mapa en Vivo',
    changeStopRequest: {
      one: 'Solicitud de Cambio',
      other: 'Solicitudes de Cambio',
    },
    user: {
      one: 'Usuario',
      other: 'Usuarios',
    },
    routesAndStops: 'Rutas y Paradas',
    routesOrStops: 'Rutas o Paradas',
    bus: {
      one: 'Bus',
      other: 'Buses',
    },
    gateway: {
      one: 'Medio de Salida',
      other: 'Medios de Salida',
    },
    gate: {
      one: 'Puerta',
      other: 'Puertas',
    },
    reports: 'Reportes',
    notifications: 'Notificaciones',
    results: 'resultados',
    passengers: {
      one: '1 pasajero',
      other: '{{count}} pasajeros',
    },
    search: 'Buscar...',
    searchItem: {
      one: 'Busca un {{Item}} aquí...',
      other: 'Busca {{Item}} aquí...',
    },
    booleanTrue: 'Si',
    booleanFalse: 'No',
    loading: 'Cargando {{Item}}...',
    noResults: 'No parecen haber {{Item}}',
    confirmDeletion: '¿Esta seguro de querer borrar a {{Item}}?',
    all: 'Todos',
    noCoincidences: 'No se han encontrado coincidencias con los filtros establecidos'
  },
  changeStopRequests: {
    changeFor: 'Cambio de ruta para ',
    fromTo: 'De {{stop}} a {{stop2}}',
    requestDate: 'Solicitado para fechas desde {{date}} hasta {{date2}} by {{user}}',
    requestType: 'Tipo de Cambio',
    dateRange: 'Dias para los que el cambio tomara efecto',
    toStop: 'Cambio hacia',
    commentary: 'Comentario',
    commented: '{{user}} comentó lo siguiente:',
    reason: 'Razón',
    entry: 'Entrada',
    exit: 'Salida',
    from: 'de',
    to: {
      one: 'a',
      other: 'hasta'
    },
    requestForDates: 'Solicitado para fechas desde',
    by: 'por',
  },
  gateways: {
    creatingGateway: 'Se creara el medio de salida',
    creatingGate: 'Se creara la puerta',
    addStudent: 'Agrega un estudiante a este medio de salida',
    addStudentToParent: 'Agrega un estudiante al padre'
  },
  routes: {
    expectedDepartureTime: 'Hora de inicio',
    expectedArrivalTime: 'Hora de finalización',
    routeTimeFrame: 'Dias que sucedera la ruta',
    recolection: 'Recolección',
    delivery: 'Entrega',
  },
  reports: {
    element: 'elemento',
    entryExitHours: 'Horas de Llegada y Salida',
    travelAttendance: 'Asistencias Por Viaje',
    stopsByTravel: 'Paradas de Viaje',
    routesStudentList: 'Listados por Ruta',
    changeStopRequests: 'Solicitudes de cambio de parada',
    travelCount: 'Conteo de Viajes por Estudiante',
    searchFor: 'Busca el objeto sobre el cuál deseas generar el reporte',
    helpText: 'Generalmente será una ruta, estudiante o todo el colegio.',
    noResults: 'No hay resultados'
  },
  weekdays: {
    mon: 'Lunes',
    tues: 'Martes',
    wed: 'Miércoles',
    thurs: 'Jueves',
    fri: 'Viernes',
    sat: 'Sábado',
    sun: 'Domingo',
  },
  notifications: {
    for: 'Para:',
    title: 'Título',
    writeMessage: 'Escribe acá el mensaje que deseas enviar...',
    onlyRoute: 'Notificar unicamente a los estudiantes en ruta',
  },
  temporalBanners: {
    defaultError: 'Ha ocurrido un error',
    fetchError: 'Ha ocurrido un error al recuperar {{Item}}',
    updateError: 'Ha ocurrido un error al actualizar {{Item}}',
    createError: 'Ha ocurrido un error al crear {{Item}}',
    removeError: 'Ha ocurrido un error al eliminar {{Item}}',
    addError: 'Ha ocurrido un error al añadir {{Item}}',
    unasignError: 'Ha ocurrido un error al desasignar {{Item}}',
    accessCodeError: 'Ha ocurrido un error al generar el código de ingreso',
    update: '{{Item}} actualizado con éxito',
    create: '{{Item}} creado con éxito',
    remove: '{{Item}} eliminado con éxito',
    add: '{{Item}} añadido con éxito',
    unasign: '{{Item}} desasignado con exito',
  },
  flashMessages: {
    tripStarted: 'El viaje de la ruta: {{Route}} ha comenzado',
    busArrived: 'El bus de la ruta: {{Route}} ha llegado a la parada: {{Stop}}',
    tripFinished: 'El viaje de la ruta: {{Route}} ha finalizado',
  },
};
