// @flow
import React, { Fragment } from 'react';
import { MdEdit } from 'react-icons/lib/md';

import Loader from '../../../Loader';

import styles from './textCell.css';


export type TextPropTypes = {
  value: string | number,
  original: Object,
  editable?: boolean,
  isLoading?: boolean,
  onChange?: (Object, string | number) => mixed,
  inputType?: string,
  isHighlight?: boolean,
  padded?: boolean,
};

type TextStateTypes = {
  isEditing?: boolean,
  editingValue: string | number,
};

class Text extends React.Component<TextPropTypes, TextStateTypes> {
  constructor(props: TextPropTypes) {
    super(props);

    this.state = {
      editingValue: props.value,
      isEditing: false,
    };
  }

  _input: ?HTMLInputElement;

  _onKeyDown(event: KeyboardEvent) {
    const { value, onChange, original } = this.props;
    switch (event.key) {
      case 'Escape': {
        event.preventDefault();
        this.setState({
          isEditing: false,
          editingValue: value,
        });
        break;
      }
      case 'Enter': {
        event.preventDefault();

        if (onChange) {
          // $FlowFixMe
          onChange(original, event.target.value);
        }

        this.setState({
          isEditing: false,
        });

        break;
      }
      default: {
        break;
      }
    }
  }

  _onChange(event: KeyboardEvent) {
    this.setState({
      // $FlowFixMe
      editingValue: event.target.value,
    });
  }

  _onClick() {
    this.setState(
      { isEditing: true },
      () => {
        if (this._input) {
          this._input.select();

          // $FlowFixMe
          this._input.focus();
        }
      },
    );
  }

  _onBlur() {
    const { value } = this.props;
    this.setState({
      isEditing: false,
      editingValue: value,
    });
  }

  render() {
    const {
      value,
      editable = false,
      inputType = 'text',
      isLoading = false,
      isHighlight = false,
      padded = false,
    } = this.props;
    const { editingValue, isEditing } = this.state;
    if (isLoading) {
      return (
        <div
          className={
            `
              ${styles.text}
              ${styles.loading}
            `
          }
        >
          <Loader tint="mediumGray" size="small" />
        </div>
      );
    }

    if (editable) {
      return (
        <div className={`${styles.text} ${padded ? styles.padded : ''}`}>
          {
            isEditing ? (
              <input
                type={inputType}
                value={editingValue}
                onKeyDown={this._onKeyDown.bind(this)}
                onChange={this._onChange.bind(this)}
                onBlur={this._onBlur.bind(this)}
                className={isHighlight ? styles.highlight : ''}
                ref={(node) => { this._input = node; }}
              />
            ) : (
              <Fragment>
                <span
                  className={`${styles.value} ${styles.pointer} ${isHighlight ? styles.highlight : ''}`}
                  onClick={this._onClick.bind(this)}
                  role="presentation"
                >
                  { value }
                </span>
                <MdEdit size={20} onClick={this._onClick.bind(this)} />
              </Fragment>
            )
          }
        </div>
      );
    }

    return (
      <div className={`${styles.text} ${padded ? styles.padded : ''}`}>
        <div className={`${styles.value} ${isHighlight ? styles.highlight : ''}`}>
          { value }
        </div>
      </div>
    );
  }
}


export default Text;
