exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-LoginForm-__loginForm___loginContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n}\n\n.src-scripts-components-LoginForm-__loginForm___container {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: -4rem;\n  margin-left: auto;\n  margin-right: auto;\n  width: 40rem;\n  z-index: 2;\n}\n\n.src-scripts-components-LoginForm-__loginForm___approve {\n  margin-bottom: 4rem;\n}\n\n.src-scripts-components-LoginForm-__loginForm___title {\n  color: white;\n  font-size: 2.5rem;\n  font-weight: 400;\n  text-align: center;\n  margin-bottom: 4rem;\n  /* font-weight: bold; */\n}\n\n.src-scripts-components-LoginForm-__loginForm___logo {\n  height: auto;\n  width: 35rem;\n  margin-bottom: 2rem;\n}\n\n.src-scripts-components-LoginForm-__loginForm___hr {\n  width: 40rem;\n  margin-bottom: 3rem;\n}\n\n@media(max-width: 576px) {\n  .src-scripts-components-LoginForm-__loginForm___container {\n    max-width: 35rem;\n  }\n\n  .src-scripts-components-LoginForm-__loginForm___hr {\n    max-width: 35rem;\n  }\n\n  .src-scripts-components-LoginForm-__loginForm___title {\n    font-size: 2.2rem;\n  }\n\n  .src-scripts-components-LoginForm-__loginForm___logo {\n    width: 35rem;\n  }\n}\n\n", ""]);
// Exports
exports.locals = {
	"loginContainer": "src-scripts-components-LoginForm-__loginForm___loginContainer",
	"container": "src-scripts-components-LoginForm-__loginForm___container",
	"approve": "src-scripts-components-LoginForm-__loginForm___approve",
	"title": "src-scripts-components-LoginForm-__loginForm___title",
	"logo": "src-scripts-components-LoginForm-__loginForm___logo",
	"hr": "src-scripts-components-LoginForm-__loginForm___hr"
};