// @flow
import { combineReducers } from 'redux';

import { types as authTypes, types } from 'nozzmo-redux-jwt';

import * as common from './common';
import * as userTypes from '../types/login';

export type LoginState = {
  isLoading: boolean,
  currentUser: Object,
};

type LOGIN_TYPE = {
  type: string
}

const isLoading = (
  state: boolean = false,
  action: LOGIN_TYPE,
): boolean => {
  switch (action.type) {
    case authTypes.LOGIN_STARTED:
      return true;
    case authTypes.LOGIN_SUCCEED:
    case authTypes.LOGIN_FAILED:
      return false;
    default:
      return state;
  }
};

const currentUser = common.singleton({
  populate: [userTypes.POPULATE_CURRENT_USER],
  clear: [userTypes.CLEAR_CURRENT_USER],
})

const login = combineReducers({
  isLoading,
  currentUser,
});

export default login;

export const isLoginLoading = (state: LoginState) => state.isLoading;
export const getCurrentUser = (state: LoginState) => state.currentUser;
export const isCurrentUserAdmin = (state: LoginState): boolean => {
  if (!!state.currentUser) {
    return state.currentUser.type === 'admin' /* || state.currentUser.is_superuser */; 
  }
  return false;
}
