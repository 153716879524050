// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import type { ID_TYPE } from '../../types/common';
import KidGraphics from '../KidGraphics';

import styles from './kidGraphicsContent.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/anthropometryResults';


type KidGraphicsContentPropTypes = {
  onLoad: Function,
  kid: Object,
  study: ID_TYPE,
  isFetching: boolean,
  results: Array<Object>
};


const KidGraphicsContent = ({
  onLoad,
  kid,
  study,
  isFetching,
  results
}: KidGraphicsContentPropTypes) => {
  useEffect(() => {
    onLoad(study, kid.id,);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {"Gráficas de antropometría"}
      </div>
      <KidGraphics
        loading={isFetching}
        data={results}
        gender={kid.gender}
        name={kid.full_name}
      />
    </div>
  )
};


export default connect(
  state => {
    const kid = selectors.getKidSelected(state) || {};
    const { id } = selectors.getSelectedStudy(state);

    return ({
      kid,
      study: id,
      results: selectors.getKidAnthropometryResults(state, kid.id),
      isFetching: selectors.isFetchingAnthropometryResults(state),
    });
  },
  dispatch => ({
    onLoad(study, kid) {
      dispatch(actions.startFetchAnthropometryResults(study, { kid }));
    }
  })
)(KidGraphicsContent);
