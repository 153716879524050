// @flow
import React from 'react';
import { MdGraphicEq } from 'react-icons/lib/md';

import DataTable from '../DataTable';
import TextColumn from '../DataTable/Columns/Text';
import ButtonColumn from '../DataTable/Columns/Button';


type StudyResultsParticipantsTablePropTypes = {
  data: Array<Object>,
  openPointsGraphics: Function,
  hasScorableInstrument: boolean,
};


const StudyResultsParticipantsTable = ({
  data,
  openPointsGraphics,
  hasScorableInstrument,
}: StudyResultsParticipantsTablePropTypes) => {

  const scorableColumns = (hasScorableInstrument && [
    TextColumn({
      Header: 'Punteo',
      accessor: 'points',
      editable: false,
    }),
    TextColumn({
      Header: 'Categoría',
      accessor: 'category',
      editable: false,
    }),
  ]) || []
  
  return (
    <DataTable
      data={data}
      columns={[
        TextColumn({
          Header: 'ID',
          accessor: 'id',
          editable: false,
          maxWidth: 50,
        }),
        TextColumn({
          Header: 'Nombres',
          accessor: 'first_name',
          editable: false,
        }),
        TextColumn({
          Header: 'Apellidos',
          accessor: 'last_name',
          editable: false,
        }),
        TextColumn({
          Header: 'Código de identificación',
          accessor: 'cui',
          editable: false,
        }),
        TextColumn({
          Header: 'Rango de edad',
          accessor: 'age',
          editable: false,
        }),
        ...scorableColumns,
        ButtonColumn({
          Header: '',
          accessor: 'files',
          buttonProps: {
            Icon: MdGraphicEq,
            size: 'small',
            tint: 'primary',
            isRound: true,
            title: 'Gráfica',
          },
          getOnClick: (params: { id: number }) => () => {
            openPointsGraphics(params.id);
          },
        }),
      ]}
      minRows={0}
      defaultPageSize={99999}
      showPagination={false}
    />
  )
};

export default StudyResultsParticipantsTable;

