// @flow
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import reduxReset from 'redux-reset';
import { types as jwtTypes } from 'nozzmo-redux-jwt';

import {
  persistStore, 
  persistReducer,
} from 'redux-persist';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { createOffline } from '@redux-offline/redux-offline';

// import createPhoenixSocketMiddleware from '../lib/socketMiddleware';
import mainSaga from '../sagas';
import reducer from '../reducers';
import persistorConfiguration from './persistorConfiguration';


const sagaMiddleware = createSagaMiddleware();
// const socketMiddleware = createPhoenixSocketMiddleware();

const configureStore = (history: Object): { store: Object, persistor: Function } => {
  
  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  
  const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore,
  } = createOffline({
    ...offlineConfig,
    persistOptions: {
      ...persistorConfiguration
    }
    // persistCallback: rehydrateStore,
  });
  
  
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware, 
    offlineMiddleware
    // socketMiddleware,
  ];

  const persistedReducer = persistReducer(
    persistorConfiguration,
    offlineEnhanceReducer(reducer),
  );

  const store = composeEnhancers(
    offlineEnhanceStore,
    applyMiddleware(...middlewares),
    reduxReset(jwtTypes.LOGOUT),
  )(createStore)(persistedReducer);

  const persistor = persistStore(store);
  sagaMiddleware.run(mainSaga);

  return { store, persistor };
};

export default configureStore;
