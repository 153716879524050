// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type QUESTIONS_SET_TYPE = {
  id: ID_TYPE,
  name: string,
  questions: Array<ID_TYPE>,
  name: string,
  key: string,
  description: string,
  instrument: ID_TYPE,
};

export type QUESTIONS_SETS_FETCH_STARTED_TYPE = {
  type: 'QUESTIONS_SETS_FETCH_STARTED',
};
export const QUESTIONS_SETS_FETCH_STARTED = 'QUESTIONS_SETS_FETCH_STARTED';

export type QUESTIONS_SETS_FETCH_COMPLETED_TYPE = {
  type: 'QUESTIONS_SETS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: QUESTIONS_SET_TYPE},
    order: Array<number>
  },
};
export const QUESTIONS_SETS_FETCH_COMPLETED = 'QUESTIONS_SETS_FETCH_COMPLETED';

export type QUESTIONS_SETS_ACTION_TYPE =
  | QUESTIONS_SETS_FETCH_STARTED_TYPE
  | QUESTIONS_SETS_FETCH_COMPLETED_TYPE;
