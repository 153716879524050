// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './dataTable.css';

type TablePropTypes = {
  children?: Node,
  className: string,
};

const Table = ({ children, className = '', ...rest }: TablePropTypes) => (
  <table className={`${styles.table} ${className}`} {...rest} role="grid">
    { children }
  </table>
);

export default Table;
