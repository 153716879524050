// @flow
import type { ComponentType } from 'react';
import React from 'react';

import styles from './popupCloseButton.css';
import Button from '../Button';
import { withClosePopup } from '../Popup/providers';


type PopupCloseButtonPropTypes = {
  Icon: ComponentType<*>,
  className: string,
  size: string,
  isOutside: boolean,
  closePopup?: Function
};

const PopupCloseButton = ({
  Icon,
  className = '',
  closePopup,
  size = 'medium',
  isOutside = false,
  ...props
}: PopupCloseButtonPropTypes) => (
  <Button
    {...props}
    onClick={closePopup}
    Icon={Icon}
    size={size}
    className={
      `
        ${styles.popupCloseButton}
        ${isOutside ? styles.isOutside : ''}
        ${typeof className !== 'undefined' ? className : ''}
      `
    }
  />
);

export default withClosePopup(PopupCloseButton);
