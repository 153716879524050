// @flow
import { schema } from 'normalizr';

import { choice } from './choices';
// import { answer } from './answers';

export const question = new schema.Entity('questions', {
  // choices: [choice],
  // answers: [answer],
});
export const arrayOfQuestions = new schema.Array(question);
