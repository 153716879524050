import React, { useState } from 'react';

import styles from './listItem.css';

const CheckboxListItem = ({
  id,
  label,
  subLabel,
  checked,
  onSelectItem,
  className
}) => {
  return (
    <div key={id} className={`
      ${styles.checkboxItem}
      ${className}
    `}>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => onSelectItem && onSelectItem(id)}
      />
      <div>
        <span className={styles.label}>{label}</span>
        {subLabel && <p className={styles.subLabel}>{subLabel}</p>}
      </div>
      

    </div>
  )
}

export default CheckboxListItem;