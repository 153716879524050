// @flow
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Waypoint from 'react-waypoint';

import type { ERROR_TYPE } from '../../types/common';
import ParticipantsTable from '../ParticipantsTable';
import FullPlaceholder from '../ListPlaceholder';
import { FullLoader } from '../Loader';

import styles from './studyParticipantsList.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/participants';
import * as fileActions from '../../actions/files';
import * as closeableActions from '../../actions/closeable';
import FileUploader, { FILE_UPLOADER } from '../FileUploader';
import UpdateParticipantPopup, { PARTICIPANT_POPUP } from '../UpdateParticipantPopup';
import DeleteParticipantPopup, { DELETE_PARTICIPANT_POPUP } from '../DeleteParticipantPopup';

type StudyParticipantsListPropTypes = {
  participants: Array<Object>,
  hasScorableInstrument: boolean,
  isFetching: boolean,
  error: ERROR_TYPE,
  hasMore: boolean,
  fetchInstrumentParticipants: Function,
  openFilesPopup: Function,
  loadFiles: Function,
  onEditClicked: Function,
  onDeleteClicked: Function,
};

const StudyParticipantsList = ({
  participants,
  hasScorableInstrument,
  isFetching,
  hasMore,
  fetchInstrumentParticipants,
  openFilesPopup,
  loadFiles,
  onEditClicked,
  onDeleteClicked,
}: StudyParticipantsListPropTypes) => {
  const [selectedParticipantId, setSelectedParticipantId] = useState(1);

  return (
    <div className={styles.approve}>
      {
        participants.length > 0 && (
          <Fragment>
            <ParticipantsTable
              data={participants}
              filesOnClick={(id) => {
                setSelectedParticipantId(id);
                loadFiles(id);
                openFilesPopup();
              }}
              onEditClicked={onEditClicked}
              onDeleteClicked={onDeleteClicked}
              hasScorableInstrument={hasScorableInstrument}
            />
            <Waypoint
              onEnter={() => hasMore && fetchInstrumentParticipants()}
              viewportBottom={10}
            />
          </Fragment>
        )
      }
      {
        isFetching && (
          <FullLoader
            tint="mediumGray"
            title="Cargando ingresos"
          />
        )
      }
      {
        participants.length === 0 && !isFetching && (
          <FullPlaceholder />
        )
      }
      <FileUploader participantId={selectedParticipantId} />

      <UpdateParticipantPopup />
      <DeleteParticipantPopup />
    </div>
  );
}


export default connect(
  (state, ownProps) => {
    const { studyId, hasScorableInstrument } = ownProps;
    const data = selectors.getStudyParticipants(state, studyId);

    const participants = data.map((el = {}) => ({
      ...el,
      // allow to modify data.
    }));

    return ({
      participants,
      hasScorableInstrument,
      isFetching: selectors.isFetchingStudyParticipants(state),
      hasMore: selectors.hasMoreStudyParticipants(state),
    });
  },
  dispatch => ({
    fetchInstrumentParticipants() {
      dispatch(actions.startFetchStudyParticipants());
    },
    openFilesPopup() {
      dispatch(closeableActions.open(FILE_UPLOADER));
    },
    loadFiles(id) {
      dispatch(fileActions.startFileFetch(id));
    },
    onEditClicked(id) {
      dispatch(actions.selectParticipant(id));
      dispatch(closeableActions.open(PARTICIPANT_POPUP));
    },
    onDeleteClicked(id) {
      dispatch(actions.selectParticipant(id));
      dispatch(closeableActions.open(DELETE_PARTICIPANT_POPUP));
    },
  })
)(StudyParticipantsList);

