// @flow
import type { ID_TYPE } from '../types/common';

import type { 
  ANTHROPOMETRY_RESULT_TYPE,
  ANTHROPOMETRY_RESULTS_FETCH_STARTED_TYPE,
  ANTHROPOMETRY_RESULTS_FETCH_COMPLETED_TYPE,
  ANTHROPOMETRY_KID_RESULTS_FETCH_STARTED_TYPE,
} from '../types/anthopometryResults';

import * as types from '../types/anthropometryResults';
import { error } from './common';


export const startFetchAnthropometryResults = (
  study,
  filters
): ANTHROPOMETRY_RESULTS_FETCH_STARTED_TYPE => ({
  type: types.ANTHROPOMETRY_RESULTS_FETCH_STARTED,
  payload: {
    study,
    filters
  }
});

export const completeFetchAnthropometryResults = (
  entities: {[ID_TYPE]: ANTHROPOMETRY_RESULT_TYPE},
  order: Array<ID_TYPE>,
): ANTHROPOMETRY_RESULTS_FETCH_COMPLETED_TYPE => ({
  type: types.ANTHROPOMETRY_RESULTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchAnthropometryResults = error(types.ANTHROPOMETRY_RESULTS_FETCH_FAILED);
