// @flow
import React, { useEffect, useState } from 'react';

import styles from './indicatorsTable.css';

type IndicatorsTablePropTypes = {
  results: Array<Object> | any,
  className?: string,
};

// const colors = ['blue', 'red', 'green', 'purple'];
const colors = [];

const getRowColorStyle = (color) => {
  switch (color) {
    case 'red':
      return styles.categorySectionRed;
    case 'green':
      return styles.categorySectionGreen;
    case 'purple':
      return styles.categorySectionPurple;
    default:
      return styles.categorySectionGray;
  }
}

const getMainRowColorStyle = (color) => {
  switch (color) {
    case 'red':
      return styles.red;
    case 'green':
      return styles.green;
    case 'purple':
      return styles.purple;
    default:
      return styles.secondary;
  }
}

const IndicatorsTable = ({ results, className }: IndicatorsTablePropTypes) => {
  let indexColor = 3;

  return (
    <div className={className ? styles.className : styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.indicatorsHeader}>
            <th
              className={`
                ${styles.first}
                ${styles.cell}
              `}
            >Categoria</th>
            <th
              className={`
                ${styles.second}
                ${styles.cell}
              `}
            >Nombre</th>
            <th
              className={`
                ${styles.third}
                ${styles.cell}
              `}
            >Porcentaje</th>
          </tr>
        </thead>
        {
          Array.isArray(results)
            ? results.map((el) => (
                <tr>
                  <th className={styles.name}>{el.name}</th>
                  <th>{`${el.percentage} %`}</th>
                </tr>
              ))
            : Object.keys(results).map((x) => {
              if (indexColor === 3) indexColor = 0
              else indexColor += 1;
              return <AxisGroup data={results[x]} color={colors[indexColor]} />
            })
        }
      </table>
    </div>
  );
};

const AxisGroup = ({ data, color }) => {
  const [axis, setAxis] = useState({ id: 0, name: null });

  useEffect(() => {
    setAxis({
      id: data[Object.keys(data)[0]][0].axis_id,
      name: data[Object.keys(data)[0]][0].axis_name,
    })
  }, [data]);

  return (
    <tbody className={getRowColorStyle(color)}>
      <tr className={getMainRowColorStyle(color)}>
        <th className={styles.axisName} colSpan={3}>{axis.name}</th>
      </tr>
      {
        Object.values(data).map(x => <CategoryGroup data={x} />)
      }
    </tbody>
  )
};

const CategoryGroup = ({ data }) => {
  return data.map((x, index) => (
    <tr>
      <td
        className={`
          ${styles.first}
          ${styles.cell}
        `}
      >
        {index === 0 ? x.category_name : ''}
      </td>
      <td
        className={`
          ${styles.second}
          ${styles.cell}
        `}
      >{x.name}</td>
      <td
        className={`
          ${styles.third}
          ${styles.cell}
        `}
      >{`${x.percentage} %`}</td>
    </tr>
  ));
};

export default IndicatorsTable;
