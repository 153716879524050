// @flow
import React from 'react';
import { Route, Switch } from 'react-router';
import {
  ConnectedRouter,
  routerActions,
} from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import * as selectors from './reducers';
import LoginView from './components/views/Login';
import StudiesView from './components/views/Studies';
import ResultsView from './components/views/Results';
import StudyView from './components/views/Study';


const UserIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: selectors.isAuthenticated,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const configRouter = (history: Object) => () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path="/" component={LoginView} />
      <Route exact path="/studies" component={UserIsAuthenticated(StudiesView)} />
      <Route path="/results" component={UserIsAuthenticated(ResultsView)} />
      <Route path="/studies/:studyId" component={UserIsAuthenticated(StudyView)} />
    </Switch>
  </ConnectedRouter>
);

export default configRouter;
