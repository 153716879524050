// @flow
import { pluginComponent as plugin } from 'redux-plugin';

import { register } from '../../actions/closeable';
import Popover, { ClickPopover, HoverPopover } from './Popover';

const PLUGIN_NAME = 'closeable';


export const ReduxPopover = plugin({
  defaultStateKey: PLUGIN_NAME,
  registerPluginElement: register,
})(Popover);

export const ReduxClickPopover = plugin({
  defaultStateKey: PLUGIN_NAME,
  registerPluginElement: register,
})(ClickPopover);

export const ReduxHoverPopover = plugin({
  defaultStateKey: PLUGIN_NAME,
  registerPluginElement: register,
})(HoverPopover);
