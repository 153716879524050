// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import { STOPLIGHT_RESULTS_FETCH_STARTED } from '../types/stoplightResults';
import { Studies } from '../api'
import { arrayOfStoplightResults } from '../api/schemas/stoplightResults';

import * as selectors from '../reducers';
import * as actions from '../actions/stoplightResults';
import * as studiesActions from '../actions/studies';

function* fetchStoplightResults({ payload }) {
  const { studyId } = payload;
  const token = yield select(selectors.getToken);
  const filters = yield select(selectors.getStudiesStoplightFilters, studyId);
  const study = yield select(selectors.getSelectedStudy);

  // Sort study phases
  const lPhases = study ? sortBy(study.phases, ['ordinal']) : [];
  const lPhasesIds = [];

  // Get all before phases
  for (let i = 0; i < lPhases.length; i++) {
    const phase = lPhases[i];
    lPhasesIds.push(phase.id);

    if (phase.id == filters['phase']) break;
  };

  const query = { ...filters, phase: lPhasesIds };
  try {
    const { response, timeout } = yield race({
      response: call(
        [Studies.custom , 'stoplightResults'],
        {
          token, 
          id: studyId,
          filters: { filters: JSON.stringify(query) },
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('Fetch stoplight results timout');
    }

    const transformedResult = response.map(el => {
      el.id = el.pk;
      el.results = [];
      el.labels.map((label, i) => {
        el.results.push({
          name: label,
          data: el.values[i] ? el.values[i].toFixed(2) : '0.00',
        });
      });
      delete el.labels;
      delete el.values;
      return el;
    });

    const {
      entities: { stoplightResult },
      result,
    } = normalize(transformedResult, arrayOfStoplightResults);

    yield put(studiesActions.updateStudySpotlightResults(studyId, result));
    yield put(actions.completeFetchStoplightResults(stoplightResult, result));
  } catch (e) { /* noop */ }
}

export function* watchFetchStoplightResults(): Iterator<any> {
  yield takeEvery(
    STOPLIGHT_RESULTS_FETCH_STARTED,
    fetchStoplightResults
  );
}
