// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import IndicatorsList from './indicatorList';
import ToggleView from '../ToggleView';
import SliderFilter from '../SlideFilter';
import Button from '../Button';
import PopupFilters, { POPUP_FILTERS_INDICATORS_ID } from './popupFilters';
import { FullLoader } from '../Loader';
import { TABLE_VIEW_TYPE } from '../../settings';
import MultipleSelect from '../MultipleSelect';

import * as selectors from '../../reducers';
import * as actions from '../../actions/indicatorResults';
import * as studyActions from '../../actions/studies';
import * as closableActions from '../../actions/closeable';

import styles from './studyIndicatorResults.css';

type StudyIndicatorResultsPropTypes = {
  studyId: ID_TYPE,
  onChangeFilters: Function,
  loading: Boolean,
  phases: Array<number>,
  filters: Object,
  openModal: Function,
};

const StudyIndicatorResults = ({
  studyId,
  loading,
  phases,
  filters,
  onLoad,
  onChangeFilters,
  clearFilters,
  openModal,
}: StudyIndicatorResultsPropTypes) => {
  const [ viewType, setViewType ] = useState(TABLE_VIEW_TYPE);
  const [phaseFilter, setPhaseFilter] = useState();

  useEffect(() => {
    onLoad(studyId);
    // onChangeFilters('phase', 1, studyId);
    return () => clearFilters();
  }, []);

  if (loading) {
    return <FullLoader tint="mediumGray" title="Cargando datos"/>
  } else {
    return(
      <div className={styles.viewContainer}>
        <div className={styles.row}>
          <div className={styles.sliderContainer}>
            {/* <SliderFilter
              phases={phases}
              onChange={(el) => onChangeFilters('phase', el, studyId)}
            /> */}
            <span>Selecciona las fases que deseas filtrar:</span>
            <MultipleSelect
              options={phases.map(x => ({ value: x, label: x.name }))}
              value={phaseFilter}
              onChange={(vals) => {
                setPhaseFilter(vals);
                onChangeFilters('phase', vals.map(x => x.id), studyId);
              }}
            />
          </div>
          <div className={styles.alignRight}>
            <Button
              title="Más filtros"
              size='small'
              tint='mediumGray'
              onClick={() => openModal()}
              className={styles.approveButton}
            />
            <ToggleView
              value={viewType}
              onChange={(val) => setViewType(val)}
            />
          </div>
        </div>
        <IndicatorsList
          viewType={viewType}
          study={studyId}
        />
        <PopupFilters />
      </div> 
    );
  }
};

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);

    const isFetchingStudy = selectors.isFetchingStudy(state, studyId);
    const study = selectors.getSelectedStudy(state);

    let loading = true;

    if (study != null && !isFetchingStudy) {
      loading = false;
    };

    return {
      studyId,
      loading,
      phases: study ? study.phases : [],
      filters: study ? study.filters : {},
    };
  },
  dispatch => ({
    onLoad(study) {
      dispatch(actions.startFetchStudyIndicatorResults(study));
    },
    onChangeFilters(key, value, study) {
      dispatch(actions.updateIndicatorsFilters(key, value));
      dispatch(actions.startFetchStudyIndicatorResults(study));
    },
    clearFilters() {
      dispatch(actions.clearIndicatorsFilters());
    },
    openModal() {
      dispatch(closableActions.open(POPUP_FILTERS_INDICATORS_ID));
    }
  })
)(StudyIndicatorResults);
