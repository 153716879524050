//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type {
  STUDY_TYPE,
} from '../types/studyTypes';
import * as common from './common';
import * as types from '../types/studyTypes';


export type StudyTypesState = {
  byId: { [ID_TYPE]: STUDY_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
};

const byId = common.byId({
  fetched: [types.FETCH_STUDY_TYPES_COMPLETED],
  removed: [],
});

const order = common.order({
  fetched: [types.FETCH_STUDY_TYPES_COMPLETED],
});

const isFetching = common.isFetching({
  started: [types.FETCH_STUDY_TYPES_STARTED],
  succeed: [types.FETCH_STUDY_TYPES_COMPLETED],
  failed: [types.FETCH_STUDY_TYPES_FAILED],
});

const error = common.error({
  clear: [
    types.FETCH_STUDY_TYPES_STARTED,
    types.FETCH_STUDY_TYPES_COMPLETED
  ],
  populate: [types.FETCH_STUDY_TYPES_FAILED],
});

const studyTypes = combineReducers({
  byId,
  order,
  isFetching,
  error,
});

export default studyTypes;

export const getStudyType = (state: StudyTypesState, id: ID_TYPE): STUDY_TYPE => state.byId[id];
export const getStudyTypes = (state: StudyTypesState): Array<STUDY_TYPE> => state.order.map(id => getStudyType(state, id));
export const getStudyTypesError = (state: StudyTypesState): ERROR_TYPE => state.error;
export const isFetchingStudyTypes = (state: StudyTypesState): boolean => state.isFetching;
