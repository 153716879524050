exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-GenericResultsTable-__genericResultsTable___container {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n  overflow: auto;\n}\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___label {\n  color: var(--almostGrayColor);\n  font-size: 1.9rem;\n  font-weight: 600;\n  padding: 2rem 0;\n}\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___table {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid var(--almostGrayColor);\n  font-size: 1.5rem\n}\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___table th {\n    border: 1px solid var(--almostGrayColor);\n    color: var(--almostGrayColor);\n  }\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___table th:first-child {\n    width: 50rem;\n  }\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___table td {\n    border: 1px solid var(--almostGrayColor);\n    text-align: center;\n    padding: 0.5rem\n  }\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___table td p {\n      margin: 0;\n    }\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___table td:first-child {\n    text-align: start !important;\n  }\n\n.src-scripts-components-GenericResultsTable-__genericResultsTable___footer {\n  display: flex;\n  flex: 1 1;\n  height: 15rem;\n}\n\n@media screen and (max-width: 768px) {\n  .src-scripts-components-GenericResultsTable-__genericResultsTable___table {\n    overflow-x:auto;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-GenericResultsTable-__genericResultsTable___container",
	"label": "src-scripts-components-GenericResultsTable-__genericResultsTable___label",
	"table": "src-scripts-components-GenericResultsTable-__genericResultsTable___table",
	"footer": "src-scripts-components-GenericResultsTable-__genericResultsTable___footer"
};