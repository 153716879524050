// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';
import { submit, change, initialize, reset } from 'redux-form';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import chunk from 'lodash/chunk';
import max from 'lodash/max';
import isEmptyObject from 'is-empty-object';
import { sumArrays } from '../utils';

import { REQUEST_TIMEOUT, ENTREPRENEUR_POINTS_CATEGORIES } from '../settings';
import { throwTimeout } from '../lib/http';

import { USERS_ADD_STARTED, USERS_UPDATE_STARTED } from '../types/users';
import { Participants, Studies } from '../api';
import {
  OFFLINE_PARTICIPANTS_FETCH_STARTED,
  STUDY_PARTICIPANTS_FETCH_STARTED,
  STUDY_PARTICIPANT_FETCH_STARTED,
  STUDY_PARTICIPANTS_REMOVED,
} from '../types/participants';
import { arrayOfParticipants } from '../api/schemas/participants';

import * as selectors from '../reducers';
import * as participantsActions from '../actions/participants';
import * as closeableActions from '../actions/closeable';
import * as instrumentActions from '../actions/instruments';
import * as locationsActions from '../actions/locations';
import * as sublocationsActions from '../actions/sublocations';
import * as organizationsActions from '../actions/organizations';
import * as actions from '../actions/users';

// const LARGE_PAGE_SIZE = 100;
const ORGANIZATIONS_CHUNKS_SIZE = 3;

function* addUser(action) {
  const user = action.payload;
  try {
    const token = yield select(selectors.getToken);
    const { userResponse, timeout } = yield race({
      userResponse: call(
        [Participants, 'create'],
        {
          token,
          data: { ...user, organization: user.community }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT * 2),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    yield put(actions.completeAddUsers(userResponse));

    yield put(change('addInstrumentSubmission', 'participant', userResponse.id));
    yield put(submit('addInstrumentSubmission'));


  } catch (error) {
    const {
      message,
      data,
      isPlain,
    } = error;
    console.log(error)
    
    yield put(actions.failAddUsers({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* updateUser(action) {
  const values = action.payload;
  const { id } = yield select(selectors.getSelectedStudyParticipant);

  try {
    const token = yield select(selectors.getToken);
    const { userResponse, timeout } = yield race({
      userResponse: call(
        [Participants, 'update'],
        {
          id,
          token,
          data: { ...values, organization: values.community }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('updateUser saga');
    }

    yield put(participantsActions.unselectParticipant());
    yield put(actions.completeUpdateUsers(userResponse));
    yield put(closeableActions.close('UpdateParticipantPopup'));


  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    console.log(error)
    
    yield put(actions.failUpdateUsers({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

function* fetchParticipants(action)  {
  try {
    const token = yield select(selectors.getToken);
    const page = yield select(selectors.getStudyParticipantsNextPage);
    const filters = yield select(selectors.getStudyParticipantsFilters);

    const nextPage = page != null ? page : 1;

    const { participantsResponse, timeout } = yield race({
      participantsResponse: call(
        [Participants, 'list'],
        {
          token,
          filters: {
            page: nextPage,
            page_size: 15,
            ...filters
          }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchUsers saga');
    }

    
    // STEP 1: Fetch participant study points
    const finalParticipants = []
    
    if (filters.scorable && filters.study) {
      for (const participant of participantsResponse.results) {

        const { phases = [] } = yield select(selectors.getStudy, filters.study);
        const summaryResults = {};

        for (const phase of phases) {
          let query = { participant: participant.id , phase: phase.id };

          const { responseResults = [], timeout } = yield race({
            responseResults: call(
              [Studies.custom, 'results'],
              {
                token,
                id: filters.study,
                filters: {filters: JSON.stringify(query)},
              },
            ),
            timeout: call(delay, REQUEST_TIMEOUT),
          });

          if (timeout) {
            throwTimeout('fetchStudyResults');
          }

          // STEP 2: map every question & its points
          responseResults.map(result => {
            let pointsResults = {};
            pointsResults = (summaryResults[result.id] || {}).pointsResults || {};
            Object.keys(result.points || {}).forEach(field => {
              const old = pointsResults[field] || [];
              pointsResults[field] = [ ...old, result.points && result.points[field] ? result.points[field] : 0 ];
            });

            summaryResults[result.id] = {
              id: result.id,
              pointsResults,
            };
          });
        }

        // STEP 3: Sum all points and get participant score
        const resultsWithPoints = Object.keys(summaryResults)
          .filter(key => !isEmptyObject(summaryResults[key].pointsResults))
          .map(key => summaryResults[key]);

        const entrepreneurTypes = uniqWith(resultsWithPoints.map(result => Object.keys(result.pointsResults)), isEqual)[0];
        
        const entrepreneurPoints = []
        entrepreneurTypes.forEach(entrepreneurType => {

          const initPoints = [...phases.map(() => 0)]

          const entTypeResult = resultsWithPoints.reduce((acc, curr) => {
            const total = [...sumArrays(acc, curr.pointsResults[entrepreneurType] || [])];
            return total;
          }, initPoints)
          // NOTE: we will display the points / score of the higher phase
          //        Also, we remove zero values because the participant has no new submission yet
          const lastPhasePoints = entTypeResult.filter(val => val > 0).length > 0 
            ? entTypeResult.filter(val => val > 0).slice(-1).pop()
            : 0
          entrepreneurPoints.push(lastPhasePoints);
        })

        // STEP 4: Finally, append the higher score / points to the current participant
        const points = max(entrepreneurPoints);
        // FIX: careful, reverse is destructive...it changes the original array.
        // const participantCategory = ENTREPRENEUR_POINTS_CATEGORIES.reverse().find(category => points >= category.value) || ENTREPRENEUR_POINTS_CATEGORIES[0];
        const participantCategory = ENTREPRENEUR_POINTS_CATEGORIES.slice().reverse().find(category => points >= category.value) || ENTREPRENEUR_POINTS_CATEGORIES[0];
        const category = participantCategory.name;

        finalParticipants.push({
          ...participant,
          points,
          category,  
        })
      }


    } else {
      // Case for the non-scorable studies
      finalParticipants.push(...participantsResponse.results)
    }
    
    const {
      entities: {
        participants,
      },
      result,
    } = normalize(finalParticipants, arrayOfParticipants);

    yield put(participantsActions.completeFetchStudyParticipants(participants, result, participantsResponse.next));

  } catch (error) {

    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(participantsActions.failFetchStudyParticipants({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
};

// Remove participant
function* removeParticipant(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;

    console.log('DELETTTTTING: ', id)

    const { response, timeout } = yield race({
      participantsResponse: call(
        [Participants, 'remove'],
        {
          token,
          id
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('removeParticipant saga');
    }

    yield put(participantsActions.unselectParticipant());

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(participantsActions.failRemoveStudyParticipant({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

// Offline Mode Support
function* fetchOfflineParticipants(action)  {
  try {
    const { filters } = action.payload;
    const token = yield select(selectors.getToken);
    const study = yield select(selectors.getSelectedStudy);
    const { instrument, id } = study;
    let finalFilters = {};

    if (id) finalFilters = {...filters, study: id ? id : undefined}

    // If we want the offline mode to work, we also need other data related to the 
    // questions, instruments and organizations, so our submission form will also work
    yield put(instrumentActions.startFetchInstrumentQuestions(instrument.id));
    yield put(locationsActions.startFetchLocations());
    yield put(sublocationsActions.startFetchSublocations());
    // Don't remove this, because the org selector must have all organizations info
    yield put(organizationsActions.startFetchOrganizations());

    const organizationsChunks = chunk(filters.organizations, ORGANIZATIONS_CHUNKS_SIZE);
    let finalEntities = {};
    let finalOrder = [];

    for (const chunk of organizationsChunks) {
      try {
        const { participantsResponse, timeout } = yield race({
          participantsResponse: call(
            [Studies.custom, 'participantsKidSearch'],
            {
              token,
              filters: {
                ...finalFilters,
                organizations: chunk,
              }
            },
          ),
          timeout: call(delay, REQUEST_TIMEOUT),
        });
      
        if (timeout) {
          throwTimeout('fetchParticipants saga');
        }
        
        const {
          entities: {
            participants,
          },
          result,
        } = normalize(participantsResponse, arrayOfParticipants);

        finalEntities = {...finalEntities, ...participants};
        finalOrder = [...finalOrder, ...result];
      } catch (error) {
        yield put({ type: 'FETCH_ERROR', error });
      }
    };

    yield put(closeableActions.close('initOfflineMode'));
    yield put(participantsActions.completeFetchOfflineParticipants(finalEntities, finalOrder));

  } catch (error) {

    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(participantsActions.failFetchOfflineParticipants({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
};



function* fetchStudyParticipant(action) {
  try {
    const token = yield select(selectors.getToken);
    const id = action.payload;

    const { response, timeout } = yield race({
      response: call(
        [Participants, 'detail'],
        {
          token,
          id,
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    yield put(participantsActions.completeFetchStudyParticipant(response));

    yield put(initialize('addParticipantForm', {
      ...response,
      community: response.organization
    }));

  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(participantsActions.failFetchParticipant({
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }));
  }
}

export function* watchAddUser(): Iterator<any> {
  yield takeEvery(
    USERS_ADD_STARTED,
    addUser,
  );
};

export function* watchUpdateUser(): Iterator<any> {
  yield takeEvery(
    USERS_UPDATE_STARTED,
    updateUser,
  );
};

export function* watchFetchParticipants():Iterator<any> {
  yield takeEvery(
    STUDY_PARTICIPANTS_FETCH_STARTED,
    fetchParticipants,
  );
};

export function* watchRemoveParticipant(): Iterator<any> {
  yield takeEvery(
    STUDY_PARTICIPANTS_REMOVED,
    removeParticipant,
  )
}

export function* watchFetchOfflineParticipants():Iterator<any> {
  yield takeEvery(
    OFFLINE_PARTICIPANTS_FETCH_STARTED,
    fetchOfflineParticipants,
  );
};

export function* watchFetchParticipant(): Iterator<any> {
  yield takeEvery(
    STUDY_PARTICIPANT_FETCH_STARTED,
    fetchStudyParticipant,
  )
}
