// @flow
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/lib/md';
import { change, initialize } from 'redux-form';

import TypeaheadLoadingResults from '../TypeheadLoadingResults';
import AsyncTypeahead from '../Typeahead/async';
import { RenderTypeahead } from '../FieldRenderer';
import {
  CustomRenderItem,
  CustomRenderSelected,
} from './customSelectComponents';

import { Studies } from '../../api/index';
import { transformOmnisearchResults } from './utils';
import { PARTICIPANT_TYPES, ANTHROPOMETRY_TYPE } from '../../settings';

import styles from './participantTypeahead.css';
import * as selectors from '../../reducers';


type ParticipantTypeaheadPropTypes = {
  fetchParticipants: Function,
  onSelectParticipant: Function,
}; 


const ParticipantTypeahead = ({
  fetchParticipants,
  onSelectParticipant,
}: ParticipantTypeaheadPropTypes) => (
  <div className={styles.searchContainer}>
    <h2>{"Datos Generales:"}</h2>

    <div className={styles.search}>
      <AsyncTypeahead
        name="participant"
        component={RenderTypeahead}
        inputDebounce={700}
        renderSelectedValue={(element, onClear) => <CustomRenderSelected element={element} onClear={onClear} />}
        renderLoading={() => <TypeaheadLoadingResults size="small" count={3} />}
        fetchItems={fetchParticipants}
        renderItem={(item, isHighlighted) => (
          <CustomRenderItem
            {...item}
            item={item}
            isHighlighted={isHighlighted}
          />
        )}
        onChange={el => el != null && onSelectParticipant(el)}
        inputProps={{
          Icon: MdSearch,
          iconPosition: 'right',
          placeholder: 'Busca al participante...',
        }}
      />
    </div>
  </div>
);


export default connect(
  (state, { instrumentType }) => {
    const token = selectors.getToken(state);
    const study = selectors.getSelectedStudy(state);
    const isOnline = selectors.hasInternetConnection(state);
    
    // Offline Mode Support
    if (!isOnline) {
      const offlineParticipants = selectors.getOfflineParticipants(state);
      let fetchParticipants = (text: string) => Promise.all(offlineParticipants.filter(participant => {
        return ((participant.first_name && participant.first_name.toLowerCase().indexOf(text.toLowerCase()) !== -1) ||
                (participant.last_name && participant.last_name.toLowerCase().indexOf(text.toLowerCase()) !== -1)) && 
                (instrumentType !== ANTHROPOMETRY_TYPE 
                  ? participant.type !== PARTICIPANT_TYPES.KID_TYPE 
                  : true);
      })).then(response => {
        let participants = response;
        if (instrumentType !== ANTHROPOMETRY_TYPE) {
          participants = participants.filter(el => el.type !== PARTICIPANT_TYPES.KID_TYPE);
        }  
        return transformOmnisearchResults(participants);
      });

      return {
        fetchParticipants
      };
    }

    const fetchParticipants = (text: string) => Studies.custom.participantsKidSearch({
      token,
      filters: {
        text,
        study: study ? study.id : undefined,
      },
    }).then(response => {

      let participants = response;
      if (instrumentType !== ANTHROPOMETRY_TYPE) {
        participants = participants.filter(el => el.type !== PARTICIPANT_TYPES.KID_TYPE);
      }

      return transformOmnisearchResults(participants);
    });
    
    return {
      fetchParticipants
    };
  },
  (dispatch, { instrumentType }) => ({
    onSelectParticipant(participant) {

      if (participant.type === PARTICIPANT_TYPES.KID_TYPE) {
        dispatch(change('addInstrumentSubmission', 'kid', participant.id));
        dispatch(change('addInstrumentSubmission', 'participant', participant.mother));
        dispatch(change('addInstrumentSubmission', 'instrument_type', instrumentType));

        dispatch(initialize('addKidForm', {
          ...participant,
          kid: participant.id,
          birthdate: moment(participant.birthdate).toDate(),
        }));
      } else {
        if (instrumentType === ANTHROPOMETRY_TYPE) {
          dispatch(change('addKidForm', 'mother', participant.id));
          dispatch(change('addKidForm', 'possible_kids', participant.kids));
          dispatch(change('addInstrumentSubmission', 'instrument_type', instrumentType));

        } else {
          dispatch(change('addInstrumentSubmission', 'participant', participant.id));
          dispatch(initialize('addParticipantForm', {
            community: participant.organization,
            ...participant
          }));
        }
      }
    }
  })
)(ParticipantTypeahead);
