import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import CheckboxListItem from '../CheckboxListItem';

import styles from './list.css';

type CheckboxListPropTypes = {
  className: string,
  items: Array<any>,
  selectedItems: Array<any>,
  onSelectItems?: Function,
};

const CheckboxList = ({
  className,
  items,
  selectedItems,
  onSelectItems,
}: CheckboxListPropTypes) => {
  const [hasSelectAll, setHasSelectAll] = useState(false);

  const onSelectAll = () => {
    setHasSelectAll(!hasSelectAll);
    if (!hasSelectAll) {
      onSelectItems(items.map((item) => item.id));
    } else {
      onSelectItems([]);
    }
  };

  const onSelectItem = (itemId) => {
    onSelectItems(selectedItems.includes(itemId)
      ? selectedItems.filter((id) => id !== itemId)
      : [...selectedItems, itemId]
    );
  };

  return (
    <div className={`
      ${styles.list}
      ${className}
    `}>
      <CheckboxListItem
        className={styles.selectAll}
        id={uuidv4()}
        label={'Seleccionar todas'}
        checked={hasSelectAll}
        onSelectItem={onSelectAll}
      />
      <div className={styles.scrollableContentWrapper}>
        <div className={styles.scrollableContent}>
        {
          items.map((item, idx) => (
            <CheckboxListItem
              key={idx}
              id={item.id}
              checked={selectedItems.includes(item.id)}
              label={item.label}
              subLabel={item.subLabel}
              onSelectItem={onSelectItem}
            />
          ))
        }
        </div>
      </div>
    </div>
  )
}

export default CheckboxList;