exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../../node_modules/postcss-loader/src/index.js??postcss!../Tip/tip.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-Closeable-TipRail-__tipRail___tipRail {\n  position: absolute;\n  z-index: 2;\n}\n\n.src-scripts-components-Closeable-TipRail-__tipRail___horizontal {\n  height: var(--tipHeight);\n  left: 0;\n  width: 100%\n}\n\n.src-scripts-components-Closeable-TipRail-__tipRail___horizontal.src-scripts-components-Closeable-TipRail-__tipRail___above {\n    bottom: calc(100% + var(--shadowSpread));\n  }\n\n.src-scripts-components-Closeable-TipRail-__tipRail___horizontal.src-scripts-components-Closeable-TipRail-__tipRail___below {\n    bottom: calc(-1 * var(--tipHeight));\n  }\n\n.src-scripts-components-Closeable-TipRail-__tipRail___vertical {\n  height: 100%;\n  top: 0;\n  width: var(--tipHeight)\n}\n\n.src-scripts-components-Closeable-TipRail-__tipRail___vertical.src-scripts-components-Closeable-TipRail-__tipRail___after {\n    left: 100%;\n  }\n\n.src-scripts-components-Closeable-TipRail-__tipRail___vertical.src-scripts-components-Closeable-TipRail-__tipRail___before {\n    right: 100%;\n  }\n", ""]);
// Exports
exports.locals = {
	"tipRail": "src-scripts-components-Closeable-TipRail-__tipRail___tipRail",
	"horizontal": "src-scripts-components-Closeable-TipRail-__tipRail___horizontal",
	"above": "src-scripts-components-Closeable-TipRail-__tipRail___above",
	"below": "src-scripts-components-Closeable-TipRail-__tipRail___below",
	"vertical": "src-scripts-components-Closeable-TipRail-__tipRail___vertical",
	"after": "src-scripts-components-Closeable-TipRail-__tipRail___after",
	"before": "src-scripts-components-Closeable-TipRail-__tipRail___before"
};