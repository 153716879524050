// @flow
import React from 'react';

import FilterComponent from './field';
import styles from './multipleFilters.css';
import { generateChoices, listToMatrix } from '../../utils';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


type MultipleFiltersListPropTypes = {
  filters: Array<Object>,
  onChange: Function,
  values: Object,
};

export const MultipleFiltersList = ({
  filters,
  onChange,
  values,
}: MultipleFiltersListPropTypes) => {
  return (
    <div className={styles.container}>
      {
        filters.map((el, i) => (
          <div key={i} className={styles.filterTwo}>
            <div className={styles.name}>
              {el.name}
            </div>
            <FilterComponent
              type={el.input_type}
              element={el}
              onChange={onChange}
              value={values[el.type]}
            />
          </div>
        ))
      }
    </div>
  )
};

// TODO: REPLACE BY THE BOTTON
const MultipleFilters = ({
  filters,
  onChange,
}: MultipleFiltersPropTypes) => {

  return(
    <Accordion allowZeroExpanded>
      <AccordionItem>
          <AccordionItemHeading className={styles.clearOutline}>
              <AccordionItemButton className={styles.clearOutline}>
                <div className={styles.header}>
                  {"Más filtros..."}
                </div>
              </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.container}>
              {
                filters.map(el => (
                  <div className={styles.filter}>
                    <div className={styles.name}>
                      {el.name}
                    </div>
                    <FilterComponent 
                      type={el.input_type}
                      element={el}
                      onChange={onChange}
                    />
                  </div>
                ))
              }
            </div>
          </AccordionItemPanel>
      </AccordionItem>
  </Accordion>
  )
};

export default MultipleFilters;
