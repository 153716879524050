// @flow
import { schema } from 'normalizr';

import { user } from './users';
import { location, sublocation } from './locations';

export const organization = new schema.Entity('organizations', {
  users: [user],
  location,
  sublocation,
});
export const arrayOfOrganizations = new schema.Array(organization);
