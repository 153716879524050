// @flow
import React from 'react';
import { connect } from 'react-redux';
import { MdSearch } from 'react-icons/lib/md';
import { change, formValueSelector, initialize } from 'redux-form';

import OrganizationResult from '../OrganizationResult';
import { RenderTypeahead } from '../FieldRenderer';
import AsyncTypeahead from '../Typeahead/async';
import TypeaheadLoadingResults from '../TypeheadLoadingResults';
/* import {
  CustomRenderItem,
  CustomRenderSelected,
} from './customSelectComponents'; */
import { Organizations } from '../../api/index';
import * as selectors from '../../reducers';
import * as userActions from '../../actions/users';

import styles from './organizationsTypeahead.css';


type OrganizationsTypeaheadPropTypes = {
  fetchParticipants: Function,
  onSelect: Function,
  onClick: Function,
};

const CustomRenderItem = (item: Object, isHighlighted: boolean) => (
  <OrganizationResult
    {...item}  
    isSelected={isHighlighted}
    size="small"
  />
);


const OrganizationsTypeahead = ({
  title='Configuración de muestra',
  fetchOrganizations,
  onSelect,
  onClick,
}: OrganizationsTypeaheadPropTypes) => (
  <div className={styles.searchContainer}>
    <h2>{title}</h2>

    <div className={styles.search}>
      <AsyncTypeahead 
        name="participant"
        component={RenderTypeahead}
        inputDebounce={200}
        renderLoading={() => <TypeaheadLoadingResults size="small" count={3} />}
        fetchItems={fetchOrganizations}
        renderItem={CustomRenderItem}
        onChange={el => el != null && onSelect(el)}
        inputProps={{
          Icon: MdSearch,
          iconPosition: 'right',
        }}
      />
    </div>
  </div>
);

const transformOmnisearchResults = (response) => {

  return (
    response.map((result) => {
      /* TODO: fix this convert function to pass only needed values */
      const newResult = {
        ...result,
        value: result.name,
        textIndex: result.text,
        object_id: result.id,
        key: result.id,
        result_type: result.type
      };
  
      return {
        ...newResult,
        label: result.first_name,
        // group_type: 'user',
        // target_role: 'user',
        base_model_id: newResult.id,
      };
  
    })
  )
};

export default connect(
  (state, ownProps) => {
    const token = selectors.getToken(state);

    const fetchOrganizations = (text: string) => Organizations.list({
      token,
      filters: { text },
    }).then(transformOmnisearchResults);
    
    return {
      fetchOrganizations,
      onSelect(o) {
        ownProps.onSelect(o);
      }
    }
  },
)(OrganizationsTypeahead);
