exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/boxsizes.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/shadows.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, ".src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeaheadContainer{\n  margin: 1rem 0;\n  /* border: 1px solid; */\n}\n\n.src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeahead {\n  align-items: center;\n  display: flex;\n  padding: 0.75rem 1rem;\n  border-radius: var(--elementBorderRadius);\n  border: 1px solid var(--mediumGrayColor);\n  box-sizing: border-box;\n  background: var(--paleGrayColor)\n}\n\n.src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeahead:focus-within {\n    background: #fff;\n    border: 2px solid var(--mainColor);\n    margin: -1px\n  }\n\n.src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeahead:focus-within + svg {\n      color: var(--mainColor);\n      -webkit-transform: scale(1.15);\n              transform: scale(1.15);\n    }\n\n.src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeahead:hover {\n    background: #fff;\n    border: 2px solid var(--mainColor);\n    margin: -1px;\n  }\n\n.src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___title {\n  color: var(--almostGrayColor);\n  display: flex;\n  font-size: 1.8rem;\n  padding: 0 2rem 1rem 0;\n  font-weight: 500;\n}\n\n.src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___tags {\n  display: flex;\n  flex: 1 1;\n  /* min-width: 40rem; */\n}\n", ""]);
// Exports
exports.locals = {
	"usersTaggedTypeaheadContainer": "src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeaheadContainer",
	"usersTaggedTypeahead": "src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___usersTaggedTypeahead",
	"title": "src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___title",
	"tags": "src-scripts-components-UsersTaggedTypeahead-__usersTaggedTypeahead___tags"
};