// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
// import KidGraphics from '../KidGraphics';
import KidGraphicsContent from '../KidGraphicsPopupContent';

import * as selectors from '../../reducers';
import * as actions from '../../actions/anthropometryResults';
import styles from './kidGraphicsPopup.css';


type KidGraphicsPopupTypes = {
};


const KidGraphicsPopup = () => (
  <Popup
    id="kidGraphics"
    theme="main"
    transitionMs={600}
  >
    <PopupContainer isFullscreen>
      <div className={styles.closeButton}>
        <PopupCloseButton
          Icon={MdClose}
          // size='large'
          // tint='gray'
          // isTransparent={true}
          tint="mediumGray"
          isRound={true}
        />
      </div>
      <KidGraphicsContent />
    </PopupContainer>
  </Popup>
);

export default KidGraphicsPopup;
