// @flow
/* eslint react/destructuring-assignment: 0 */
import React from 'react';
import range from 'lodash/range';
import moment from 'moment';

import Select from '../Select';
import Switch from '../Switch';

import styles from './timePicker.css';


type TimePickerPropTypes = {
  hourStep?: number,
  minuteStep?: number,
  value?: string,
  onChange?: Function,
  onFocus?: Function,
  onBlur?: Function,
  clearMargins?: boolean,
};

type TimePickerStateTypes = {
  hourValue: string,
  minuteValue: string,
  isAmValue: boolean,
};

const TIME_FORMAT = 'HH:mm A';
const DEFAULT_HOUR_STEP = 1;
const DEFAULT_MINUTE_STEP = 5;

const getTimeString = (
  hours: string | number,
  minutes: string | number,
  isAm: boolean,
) => `${hours}:${minutes} ${isAm ? 'AM' : 'PM'}`;

const parseTimeString = (
  timeString: string,
): [number, number, boolean] => {
  const parsed = moment(timeString, TIME_FORMAT);
  const [hours, minutes] = parsed.toArray().slice(3, 5);
  const isAmValue = hours < 12;
  let hourValue;

  if (isAmValue) {
    hourValue = hours;
  } else if (hours === 12) {
    hourValue = 12;
  } else {
    hourValue = hours % 12;
  }

  return [
    hourValue,
    minutes,
    isAmValue,
  ];
};


const nowTimeString = () => moment().format(TIME_FORMAT);

class TimePicker extends React.Component<TimePickerPropTypes, TimePickerStateTypes> {
  constructor(props: TimePickerPropTypes) {
    const { value } = props;
    super(props);

    this.state = this._adjustValues(
      ...parseTimeString(value !== '' && value != null ? value : nowTimeString()),
    );
  }

  componentWillReceiveProps(nextProps: TimePickerPropTypes) {
    const { value } = this.props;
    if (value !== nextProps.value) {
      this.setState(
        this._adjustValues(
          ...parseTimeString(nextProps.value || nowTimeString()),
        ),
      );
    }
  }

  _adjustValues(
    hourValue: number,
    minuteValue: number,
    isAmValue: boolean,
  ): TimePickerStateTypes {
    const {
      hourStep = DEFAULT_HOUR_STEP,
      minuteStep = DEFAULT_MINUTE_STEP,
    } = this.props;

    return {
      hourValue: `${hourValue - (hourValue % hourStep)}`,
      minuteValue: `${minuteValue - (minuteValue % minuteStep)}`,
      isAmValue,
    };
  }

  render() {
    const {
      onChange = (_h, _m, _i) => undefined,
      onFocus,
      onBlur,
      hourStep = DEFAULT_HOUR_STEP,
      minuteStep = DEFAULT_MINUTE_STEP,
      clearMargins = false,
    } = this.props;

    const {
      hourValue,
      minuteValue,
      isAmValue,
    } = this.state;

    const hourValueOptions = isAmValue ? range(0, 12, hourStep) : [12, ...range(1, 12, hourStep)];
    const minuteValueOptions = range(0, 60, minuteStep);

    return (
      <div className={styles.timePickerContainer}>
        <Select
          className={styles.hours}
          value={hourValue}
          onChange={
            option => onChange(
              getTimeString(
                option.toString(),
                this.state.minuteValue,
                this.state.isAmValue,
              ),
            )
          }
          onFocus={onFocus}
          onBlur={() => onBlur && onBlur(this.props.value)}
          options={hourValueOptions.map(value => ({ value: `${value}`, label: value }))}
          clearMargins={clearMargins}
        />
        <Select
          className={styles.minutes}
          value={minuteValue}
          onChange={
            option => onChange(
              getTimeString(
                this.state.hourValue,
                option.toString(),
                this.state.isAmValue,
              ),
            )
          }
          onFocus={onFocus}
          onBlur={() => onBlur && onBlur(this.props.value)}
          options={minuteValueOptions.map(value => ({ value: `${value}`, label: value }))}
          clearMargins={clearMargins}
        />
        <Switch
          value={isAmValue}
          trueTitle="AM"
          falseTitle="PM"
          onChange={
            (value) => {
              let hourString = this.state.hourValue;
              if (value && hourValue === '12') {
                hourString = '0';
              } else if (!value && hourValue === '0') {
                hourString = '12';
              }

              return onChange(
                getTimeString(
                  hourString,
                  this.state.minuteValue,
                  value,
                ),
              );
            }
          }
          clearMargins={clearMargins}
        />
      </div>
    );
  }
}


export default TimePicker;
