// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupHeaderRightGroup.css';


type PopupHeaderRightGroupPropTypes = {
  children?: Node,
  padding?: string,
};

const PopupHeaderRightGroup = ({
  children,
  padding='medium',
}: PopupHeaderRightGroupPropTypes) => (
  <div className={
    `
      ${styles.popupHeaderRightGroup}
      ${styles[padding]}
    `
  }
  >
    { children }
  </div>
);

export default PopupHeaderRightGroup;
