exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___container {\n  display: flex;\n  /* border: 1px solid red; */\n  flex-direction: column;\n  padding: 8rem 4rem;\n  overflow: scroll;\n  max-height: 90vh;\n  box-sizing: content-box;\n}\n\n.src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___row {\n  display: flex;\n  flex: 1 1;\n}\n\n.src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___col {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1;\n}\n\n.src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___title {\n  /* border: 1px solid green; */\n  padding: 2rem 4rem;\n  color: var(--grayColor);\n  font-size: 4rem;\n  font-weight: 500;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___container",
	"row": "src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___row",
	"col": "src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___col",
	"title": "src-scripts-components-KidGraphicsPopupContent-__kidGraphicsContent___title"
};