// @flow
import type { Element } from 'react';
import React from 'react';

import type { ID_TYPE } from '../../types/common';
import Typeahead from './index';


type OPTION_TYPE = {
  value: ID_TYPE,
  label: string,
  textIndex: string,
};

type TypeaheadPropTypes = {
  renderItem?: (Object, boolean) => Element<any>,
  value: ?OPTION_TYPE,
  renderSelectedValue?: (Object, Function) => Element<any>,
  inputProps?: Object,
  onChange?: Function,
  onBlur?: Function,
  renderLoading?: () => Element<any>,
  renderEmpty?: () => Element<any>,
  inputDebounce?: number,
  fetchItems: string => Promise<Array<OPTION_TYPE>>,
};

type TypeaheadStateTypes = {
  items: Array<OPTION_TYPE>,
  isLoading?: boolean,
  onInputChange?: Function,
};

class AsyncTypeahead extends React.Component<TypeaheadPropTypes, TypeaheadStateTypes> {
  constructor(props: TypeaheadPropTypes) {
    super(props);
    this.state = {
      items: [],
      isLoading: false,
      onInputChange: this._getOnInputChange(props.fetchItems),
    };
  }

  componentWillReceiveProps(nextProps: TypeaheadPropTypes) {
    const { fetchItems } = this.props;
    if (fetchItems !== nextProps.fetchItems) {
      this.setState({
        onInputChange: this._getOnInputChange(nextProps.fetchItems),
      });
    }
  }

  getInput() {
    if (this._typeahead) {
      return this._typeahead.getInput();
    }

    return null;
  }

  getAutocomplete() {
    if (this._typeahead) {
      return this._typeahead.getAutocomplete();
    }

    return null;
  }

  _getOnInputChange(fetchItems: Function) {
    return (stringValue: string) => {
      this.setState(
        { isLoading: true },
        () => fetchItems(stringValue).then(items => this.setState({
          items,
          isLoading: false,
        })).catch(
          () => this.setState({
            items: [],
            isLoading: false,
          }),
        ),
      );
    };
  }

  _typeahead: ?Typeahead;

  render() {
    return (
      <Typeahead
        {...this.props}
        {...this.state}
        ref={(node) => { this._typeahead = node; }}
        shouldItemRender={() => true}
      />
    );
  }
}


export default AsyncTypeahead;
