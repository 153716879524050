// @flow
import React, { Fragment } from 'react';
import reduce from 'lodash/reduce';

import AddOrganizationRow from '../AddOrganizationRow';
import OrganizationsTypeahead from '../OrganizationsTypeahead';

import styles from './addOrganizationTable.css';

const AddOrganizationsTable = ({ value = [], onChange }) => {

  return (
    <Fragment>
      {/* <TypeAhead onClick={(selected) => onChange([...value, [selected.id, selected.name]]) }/> */}
      <OrganizationsTypeahead onSelect={(selected) => {
        const nValue = [ ...value ];
        let flag = false;
        for (let i = 0; i < nValue.length; i++) {
          if (nValue[i][0] == selected.id) {
            flag = true;
            break;
          };
        }

        if (!flag) {
          onChange([ ...nValue, [selected.id]])
        }
      }} />
      <table className={styles.table}>
        <tbody className={styles.tbody}>
          {
            value.length > 0 && value.map(
              (eValue, index) => (
                <AddOrganizationRow
                  value={eValue}  
                  onChange={
                    (ocValue) => {
                      const lList = [...value];
                      lList[index] = ocValue;
                      onChange(lList);
                    }
                  }
                  onRemove={
                    (id) => {
                      const nList = value.filter((item) => item[0] != id);
                      onChange(nList);
                    }
                  }
                />
              )
            )
          }
        </tbody>
      </table>
    </Fragment>
  )
};


export default AddOrganizationsTable;
