// @flow
import { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  RESULT_EXIT_PROFILE_TYPE,
  RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE,
  RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE,
  STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE,
  STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE,
  INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE,
  INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE,
  RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED_TYPE,
  RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED_TYPE
} from '../types/resultsExitProfile';
import * as types from '../types/resultsExitProfile';
import { error } from './common';


export const startFetchResultsExitProfile = (
  filters: Object,
): RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE => ({
  type: types.RESULTS_EXIT_PROFILE_FETCH_STARTED,
  payload: filters
});

export const completeFetchResultsExitProfile = (
  entities: {[ID_TYPE]: RESULT_EXIT_PROFILE_TYPE},
  order: Array<ID_TYPE>,
): RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE => ({
  type: types.RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchResultsExitProfile = error(types.RESULTS_EXIT_PROFILE_FETCH_FAILED);

export const startStudyResultsExitProfile = (
  id: ID_TYPE,
): STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE => ({
  type: types.STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED,
  payload: id,
});

export const completeStudyResultsExitProfile = (
  entities: {[ID_TYPE]: RESULT_EXIT_PROFILE_TYPE},
  order: Array<ID_TYPE>,
): STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE => ({
  type: types.STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failStudyResultsExitProfile = error(types.STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED);

export const startFetchInstrumentResultsExitProfile = (
  id: ID_TYPE,
): INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE => ({
  type: types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED,
  payload: id,
});

export const completeFetchInstrumentResultsExitProfile = (
  entities: {[ID_TYPE]: RESULT_EXIT_PROFILE_TYPE},
  order: Array<ID_TYPE>,
): INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchInstrumentResultsExitProfile = error(types.INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED);

export const updateExitProfileExtraInfo = (key: string, value: any): RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED_TYPE => ({
  type: types.RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED,
  payload: {
    key,
    value,
  }
});

export const clearExitProfileExtraInfo = (): RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED_TYPE => ({
  type: types.RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED,
});