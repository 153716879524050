// @flow
import React, { Fragment } from 'react';

import { ID_TYPE } from '../../types/common';
import styles from './genericResultsTable.css'


type GenericResultsTablePropTypes = {
  results: {[string]: Array<number>},
  phases: Array<number>,
  name: string
};


const GenericResultsTable = ({
  results,
  phases = [],
  name,
}: GenericResultsTablePropTypes) => (
  <div className={styles.container}>
    <div className={styles.label}>
      {name}
    </div>
    <div>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th></th>
            {
              phases.map(el => (
                <th key={el}>{el}</th>
              ))
            }
          </tr>
          {
            Object.keys(results).map(el => (
              <tr key={el}>
                <td>
                  <p dangerouslySetInnerHTML={{ __html: el }}/>
                </td>
                {
                  results[el].map((el, i) => <td key={i}>{el}</td>)
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  </div>
);

export default GenericResultsTable;
