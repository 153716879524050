// @flow
import React from 'react';

import OmnisearchResultDummy from '../OmnisearchResultDummy';

type UserResultPropTypes = {
  firstName: string,
  lastName: string,
  userRole: string,
  pictureURL?: string,
  isSelected?: boolean,
  size?: string,
  onClick?: Function
};

const UserResult = ({
  firstName = '',
  lastName = '',
  userRole = '',
  pictureURL,
  isSelected = false,
  size = 'medium',
  onClick,
}: UserResultPropTypes) => (
  <OmnisearchResultDummy
    title={`${firstName} ${lastName}`}
    subtitle={userRole}
    pictureURL={pictureURL}
    isSelected={isSelected}
    size={size}
    onClick={onClick}
  />
);

export default UserResult;
