// @flow
import React from 'react';
import { connect } from 'react-redux';
import { reset, initialize, change } from 'redux-form';

import { PARTICIPANT_TYPES } from '../../settings';
import UserResult from '../UserResult';
import CloseableInputSearchResult from '../CloseableInputSearchResult';


type RenderSelectedPropTypes = {
  element: Object,
  onClear: Function, 
  resetForm: Function,
};

type UserRenderResultPropTypes = {
  firstName: string,
  lastName: string,
  username: string,
  profilePicture: string,
  isSelected: boolean,
  onClick: Function,
}

const renderSelected = ({
  element = {},
  onClear,
  resetForm,
}: RenderSelectedPropTypes) => {
  switch (element.type) {
    case PARTICIPANT_TYPES.KID_TYPE: {
      return (
        <CloseableInputSearchResult
          title={element.full_name}
          subtitle="Hijo"
          closeAction={() => {
            onClear();
            resetForm();
          }}
          pictureURL={element.image}
        />
      )
    };
    case PARTICIPANT_TYPES.PARTICIPANT: {
      return (
        <CloseableInputSearchResult
          title={`${element.first_name} ${element.last_name}`}
          subtitle={element.type}
          closeAction={() => {
            onClear();
            resetForm();
          }}
          pictureURL={element.image}
        />
      )
    }
    default:
      break;
  }
}


export const CustomRenderSelected = connect(
  (state, ownProps) => {
    return ({
      element: ownProps.element,
      onClear: ownProps.onClear,
    })
  },
  dispatch => ({
    resetForm() {
      dispatch(initialize('addParticipantForm', {}));
      dispatch(initialize('addKidForm', {}));
      dispatch(change('addKidForm', 'kid_select', -1));

      dispatch(change('addInstrumentSubmission', 'participant', null));
      dispatch(change('addInstrumentSubmission', 'kid', null));
    }
  })
)(renderSelected);


export class CustomRenderItem extends React.Component<> {
  render() {
    const { item, isHighlighted, onClick } = this.props;

    switch (item.result_type) {
      case PARTICIPANT_TYPES.KID_TYPE: {
        return (
          <UserResult
            {...item}
            firstName={item.full_name}
            userRole={"Hijo"}
            isSelected={isHighlighted}
            size="small"
            onClick={onClick}
          />
        )
      }
      case PARTICIPANT_TYPES.PARTICIPANT: {
        return (
          <UserResult
            {...item}
            firstName={item.first_name}
            lastName={item.last_name}
            userRole={"Participante"}
            isSelected={isHighlighted}
            size="small"
            onClick={onClick}
          />
        )
      }
      default: {
        break;
      }
    }
  }
}


export class UserRenderResult extends React.Component<UserRenderResultPropTypes> {
  render() {
    const {
      firstName,
      lastName,
      username,
      profilePicture,
      isSelected,
      onClick,
    } = this.props;

    return (
      <UserResult
        firstName={firstName}
        lastName={lastName}
        userRole={username}
        pictureURL={profilePicture}
        isSelected={isSelected}
        size="small"
        onClick={onClick}
      />
    )
  }
};
