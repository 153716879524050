// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import type {
  QUESTIONS_SET_TYPE,
  QUESTIONS_SETS_FETCH_STARTED_TYPE,
  QUESTIONS_SETS_FETCH_COMPLETED_TYPE
} from '../types/questionsSets';
import * as types from '../types/questionsSets';


export const startFetchQuestionsSets = (): QUESTIONS_SETS_FETCH_STARTED_TYPE => ({
  type: types.QUESTIONS_SETS_FETCH_STARTED,
});

export const completeFetchQuestionsSets = (
  entities: {[ID_TYPE]: QUESTIONS_SET_TYPE},
  order: Array<ID_TYPE>,
): QUESTIONS_SETS_FETCH_COMPLETED_TYPE => ({
  type: types.QUESTIONS_SETS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});
