// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type CHOICE_TYPE = {
  id: ID_TYPE,
  label: string,
};

export type CHOICES_FETCH_STARTED_TYPE = {
  type: 'CHOICES_FETCH_STARTED',
  payload: {},
};
export const CHOICES_FETCH_STARTED = 'CHOICES_FETCH_STARTED';

export type CHOICES_FETCH_COMPLETED_TYPE = {
  type: 'CHOICES_FETCH_COMPLETED',
  payload: {},
};
export const CHOICES_FETCH_COMPLETED = 'CHOICES_FETCH_COMPLETED';

export type CHOICES_FETCH_FAILED_TYPE = {
  type: 'CHOICES_FETCH_FAILED',
  payload: {},
};
export const CHOICES_FETCH_FAILED = 'CHOICES_FETCH_FAILED';

export type CHOICES_ACTION_TYPE =
  | CHOICES_FETCH_STARTED_TYPE
  | CHOICES_FETCH_COMPLETED_TYPE
  | CHOICES_FETCH_FAILED_TYPE;
