// @flow
import {
  call,
  put,
  select,
  takeEvery,
  race,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { normalize } from 'normalizr';

import { REQUEST_TIMEOUT } from '../settings';
import { throwTimeout } from '../lib/http';

import {
  ANTHROPOMETRY_RESULTS_FETCH_STARTED,
  ANTHROPOMETRY_KID_RESULTS_FETCH_STARTED
} from '../types/anthropometryResults';
import { Studies } from '../api';
import { arrayOfAnthopometryResult } from '../api/schemas/anthropometryResults';

import * as selectors from '../reducers';
import * as actions from '../actions/anthropometryResults';
import * as kidActions from '../actions/kids';


function* fetchAnthropometryResults(action) {
  try {
    const token = yield select(selectors.getToken);
    const { study, filters = {} } = action.payload;
    const { response, timeout } = yield race({
      response: call(
        [Studies.custom , 'anthropometryResults'],
        {
          token,
          study,
          id: study,
          filters: {
            ...filters
          }
        },
      ),
      timeout: call(delay, REQUEST_TIMEOUT),
    });

    if (timeout) {
      throwTimeout('fetchInstruments saga');
    }

    // Convert weigth to kg
    const FACTOR_KG_TO_LIB = 2.20456;

    const responseUpdated = response.map(el => ({
      ...el,
      weight: el.weight_lbs,
      height: el.height_cm,
      age: el.age_months ? el.age_months : el.age_years,
    }));

    const {
      entities: { anthropometryResult },
      result,
    } = normalize(responseUpdated, arrayOfAnthopometryResult);
    yield put(actions.completeFetchAnthropometryResults(anthropometryResult, result));

    if (filters.kid != null) {
      yield put(kidActions.updateKid({ id: filters.kid, anthropometryResults: result }));
    }
    
  } catch (error) {
    const {
      message,
      data,
      isPlain,
      statusCode,
    } = error;
    
    yield put(actions.failFetchAnthropometryResults({
      statusCode,
      message,
      data: isPlain ? 'Error en el servidor' : data,
      retryAction: action,
    }))
  }
}


export function* watchFetchAnthropometryResults(): Iterator<any> {
  yield takeEvery(
    ANTHROPOMETRY_RESULTS_FETCH_STARTED,
    fetchAnthropometryResults,
  )
}
