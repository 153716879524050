// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type RESULT_EXIT_PROFILE_TYPE = {
  id: ID_TYPE,
  value: number,
  phase: string,
  label: string,
};

export type RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE = {
  type: 'RESULTS_EXIT_PROFILE_FETCH_STARTED',
  payload: Object,
};
export const RESULTS_EXIT_PROFILE_FETCH_STARTED = 'RESULTS_EXIT_PROFILE_FETCH_STARTED';

export type RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE = {
  type: 'RESULTS_EXIT_PROFILE_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: RESULT_EXIT_PROFILE_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const RESULTS_EXIT_PROFILE_FETCH_COMPLETED = 'RESULTS_EXIT_PROFILE_FETCH_COMPLETED';

export type RESULTS_EXIT_PROFILE_FETCH_FAILED_TYPE = {
  type: 'RESULTS_EXIT_PROFILE_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const RESULTS_EXIT_PROFILE_FETCH_FAILED = 'RESULTS_EXIT_PROFILE_FETCH_FAILED';

export type STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE = {
  type: 'STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED',
  payload: ID_TYPE,
};
export const STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED = 'STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED';

export type STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE = {
  type: 'STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: RESULT_EXIT_PROFILE_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED = 'STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED';

export type STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED_TYPE = {
  type: 'STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED = 'STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED';

export type INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE = {
  type: 'INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED',
  payload: ID_TYPE,
};
export const INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED = 'INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED';

export type INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE = {
  type: 'INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: RESULT_EXIT_PROFILE_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED = 'INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED';

export type INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED_TYPE = {
  type: 'INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED = 'INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED';

// Timestamp & extra info
export type RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED_TYPE = {
  type: 'RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED',
  payload: {
    key: string,
    value: any,
  }
};
export const RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED = 'RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED';

export type RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED_TYPE = {
  type: 'RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED',
};
export const RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED = 'RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED';

export type RESULTS_EXIT_PROFILE_ACTION_TYPE =
  | RESULT_EXIT_PROFILE_TYPE
  | RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE
  | RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE
  | RESULTS_EXIT_PROFILE_FETCH_FAILED_TYPE
  | RESULT_EXIT_PROFILE_EXTRA_INFO_UPDATED_TYPE
  | RESULT_EXIT_PROFILE_EXTRA_INFO_CLEARED_TYPE
  | STUDY_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE
  | STUDY_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE
  | STUDY_RESULTS_EXIT_PROFILE_FETCH_FAILED_TYPE
  | INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_STARTED_TYPE
  | INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_COMPLETED_TYPE
  | INSTRUMENT_RESULTS_EXIT_PROFILE_FETCH_FAILED_TYPE;
