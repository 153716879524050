// @flow
import React from 'react';

import styles from './highlightCell.css';


type HighlightPropTypes = {
  id: number,
  value: string | number,
  onClick: ?Function,
};

const Highlight = ({ value, onClick }: HighlightPropTypes) => {
  return (
    <span
      onClick={onClick}
      className={`
        ${styles.highlight}
        ${onClick ? styles.clickleable : ''}`}>
      { value }
    </span>
  )
};


export default Highlight;
