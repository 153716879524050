// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import StudySubmissionsList from '../StudySubmissionsList';
import SearchBar from './searchBar';

import * as actions from '../../actions/instrumentSubmissions';
import styles from './studySubmissions.css';


type StudySubmissionsPropTypes = {
  studyId: number,
  onLoad: Function,
  clearFilters: Function
};


const StudySubmissions = ({
  onLoad,
  studyId,
  clearFilters
}: StudySubmissionsPropTypes) => {
  useEffect(() => {
    onLoad(studyId);

    return () => clearFilters()
  });

  return (
    <Fragment>
      <SearchBar />
      <div className={styles.container}>
        <StudySubmissionsList studyId={studyId}/>
      </div>
    </Fragment>
  );
}

export default connect(
  (state, ownProps) => {
    const studyId = parseInt(ownProps.match.params.id, 10);

    return ({
      studyId,
    });
  },
  dispatch => ({
    onLoad(studyId) {
      dispatch(actions.updateInstrumentSubmissionsFilters('study', studyId));
      dispatch(actions.startFetchInstrumentSubmissions());
    },
    clearFilters() {
      dispatch(actions.updateInstrumentSubmissionsFilters('text', ''));
    }
  }),
)(StudySubmissions);
