// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type USER_TYPE = {
  id: ID_TYPE,
  name: string,
  dpi: number,
  age: number,
  type: 'participant' | 'children',
  livingTime: string,
  civilStatus: string,
  ethnicity: string,
  isLawyer: boolean,
  liveChildren: number,
  ageYoungestSoon: number,
  physiologicalState: string,
  isAccompanied: boolean,
  isCompanionAttentive: boolean,
  organization: ID_TYPE,
  group: number,
  gender: string,
  birthdate: Date,
  age: number,
  weight: number,
  files: Array<ID_TYPE>,
};

export type USERS_FETCH_STARTED_TYPE = {
  type: 'USERS_FETCH_STARTED',
  payload: {},
};
export const USERS_FETCH_STARTED = 'USERS_FETCH_STARTED';

export type USERS_FETCH_COMPLETED_TYPE = {
  type: 'USERS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: USER_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const USERS_FETCH_COMPLETED = 'USERS_FETCH_COMPLETED';

export type USERS_FETCH_FAILED_TYPE = {
  type: 'USERS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';

export type USERS_ADD_STARTED_TYPE = {
  type: 'USERS_ADD_STARTED',
  payload: USER_TYPE,
};
export const USERS_ADD_STARTED = 'USERS_ADD_STARTED';

export type USERS_ADD_COMPLETED_TYPE = {
  type: 'USERS_ADD_COMPLETED',
  payload: USER_TYPE,
};
export const USERS_ADD_COMPLETED = 'USERS_ADD_COMPLETED';

export type USERS_ADD_FAILED_TYPE = {
  type: 'USERS_ADD_FAILED',
  payload: {},
};
export const USERS_ADD_FAILED = 'USERS_ADD_FAILED';

export type USERS_UPDATE_STARTED_TYPE = {
  type: 'USERS_UPDATE_STARTED',
  payload: {},
};
export const USERS_UPDATE_STARTED = 'USERS_UPDATE_STARTED';

export type USERS_UPDATE_COMPLETED_TYPE = {
  type: 'USERS_UPDATE_COMPLETED',
  payload: {},
};
export const USERS_UPDATE_COMPLETED = 'USERS_UPDATE_COMPLETED';

export type USERS_UPDATE_FAILED_TYPE = {
  type: 'USERS_UPDATE_FAILED',
  payload: {},
};
export const USERS_UPDATE_FAILED = 'USERS_UPDATE_FAILED';

export type USERS_REMOVED_TYPE = {
  type: 'USERS_REMOVED',
  payload: {},
};
export const USERS_REMOVED = 'USERS_REMOVED';

export type USERS_REMOVE_STARTED_TYPE = {
  type: 'USERS_REMOVE_STARTED',
  payload: {},
};
export const USERS_REMOVE_STARTED = 'USERS_REMOVE_STARTED';

export type USERS_REMOVE_COMPLETED_TYPE = {
  type: 'USERS_REMOVE_COMPLETED',
  payload: {},
};
export const USERS_REMOVE_COMPLETED = 'USERS_REMOVE_COMPLETED';

export type USERS_REMOVE_FAILED_TYPE = {
  type: 'USERS_REMOVE_FAILED',
  payload: {},
};
export const USERS_REMOVE_FAILED = 'USERS_REMOVE_FAILED';

export type USER_SELECTED_TYPE = {
  type: 'USER_SELECTED',
  payload: ID_TYPE,
};
export const USER_SELECTED = 'USER_SELECTED';

export type USER_ERROR_CLEANED_TYPE = {
  type: 'USER_ERROR_CLEANED'
};
export const USER_ERROR_CLEANED = 'USER_ERROR_CLEANED';


export type USERS_ACTION_TYPE =
  | USERS_FETCH_STARTED_TYPE
  | USERS_FETCH_COMPLETED_TYPE
  | USERS_FETCH_FAILED_TYPE
  | USERS_ADD_STARTED_TYPE
  | USERS_ADD_COMPLETED_TYPE
  | USERS_ADD_FAILED_TYPE
  | USERS_UPDATE_STARTED_TYPE
  | USERS_UPDATE_COMPLETED_TYPE
  | USERS_UPDATE_FAILED_TYPE
  | USERS_REMOVED_TYPE
  | USERS_REMOVE_STARTED_TYPE
  | USERS_REMOVE_COMPLETED_TYPE
  | USERS_REMOVE_FAILED_TYPE
  | USER_SELECTED_TYPE
  | USER_ERROR_CLEANED_TYPE;
