// @flow
import React, { Fragment } from 'react';

import { FullLoader } from '../Loader';
import EntrepreneurChartView from '../StudyResults/entrepreneurChart';
import styles from './orgGraphics.css';


type StudyParticipantGraphicsType = {
  loading: boolean,
  data: Array<Object>,
  name: string,
  participant: string,
  olderChildrenFilters: boolean,
};


const StudyParticipantGraphics = ({
  loading = true,
  results = [],
  phases = [],
  name,
  participant,
}: StudyParticipantGraphicsType) => {

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <FullLoader
          tint="mediumGray"
          title="Cargando graficas"
        />
      </div>
    )
  } else {
    return (
      <Fragment>  
        <div className={styles.row}>
          <div className={`${styles.chart}`}>
            {
              results && results.length > 0 && 
                <EntrepreneurChartView 
                  results={results} 
                  phases={phases}
                  name={name}
                  participant={participant} />
            }
          </div>
        </div>
      </Fragment>
    )
  }

};


export default StudyParticipantGraphics;
