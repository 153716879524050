const validate = (values) => {
  const errorMessage = 'Requerido';
  const errors = {};

  if (!values.first_name) errors.first_name = errorMessage;
  if (!values.last_name) errors.last_name = errorMessage;
  if (!values.location) errors.location = errorMessage;
  if (!values.sublocation) errors.sublocation = errorMessage;
  if (!values.community) errors.community = errorMessage;
  if (!values.group) errors.group = errorMessage;
  if (!values.cui) errors.cui = errorMessage;
  if (!values.age) errors.age = errorMessage;
  if (!values.civil_state) errors.age = errorMessage;
  if (!values.ethnicity) errors.ethnicity = errorMessage;
  if (!values.years_living_in_community)
    errors.years_living_in_community = errorMessage;

  return errors;
};

export default validate;
