// @flow
import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import StopLightVirtualList from './StopLightVirtualList';

import Button from '../Button';
import FullPlaceholder from '../ListPlaceholder';
import { INSTRUMENT_FILTERS_ID } from '../PopupInstrumentFilters';
import { FullLoader } from '../Loader';
import { listToMatrix } from '../../utils';
import styles from './stoplightResults.css';

import * as actions from '../../actions/results';
import * as closeableActions from '../../actions/closeable';
import * as selectors from '../../reducers';


type StopLightResultsPropTypes = {
  spotlightResults: any[],
  ignoreStoplightColors: boolean,
  isFetching: boolean,
  onLoad: Function,
  openMoreFilters: Function,
};


const StopLightResults = ({
  spotlightResults = [],
  ignoreStoplightColors,
  isFetching,
  onLoad,
  openMoreFilters,
}: StopLightResultsPropTypes) => {
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Fragment>
      <div className={styles.row}>
        <Button
          title="Más filtros"
          size='small'
          tint='mediumGray'
          onClick={() => openMoreFilters()}
        />
      </div>
      {
        !isFetching && spotlightResults.length > 0 && (
          <StopLightVirtualList
            spotlightResults={spotlightResults}
            ignoreStoplightColors={ignoreStoplightColors}  
          />
        )
      }
      {
        isFetching && (
          <FullLoader
            tint="mediumGray"
            title="Cargando resultados"
          />
        )
      }
      {
        !spotlightResults.length && !isFetching && (
          <FullPlaceholder />
        )
      }
    </Fragment>
  )
};

export default connect(
  (state, ownProps) => {
    const instrument = selectors.getSelectedInstrument(state) || selectors.getDefaultInstrument(state);

    const results = !!instrument
    ? selectors.getSemaphoreResultsByInstrumentId(state, !!instrument ? instrument.id : -1)
    : {};
    const { ignore_stoplight_colors } = instrument || {};

    let gridResults = listToMatrix(Object.values(results || []), 2);

    return {
      spotlightResults: gridResults,
      ignoreStoplightColors: ignore_stoplight_colors,
      isFetching: selectors.isFetchingInstrumentResults(state),
    };
  },
  dispatch => ({
    onLoad() {
      dispatch(actions.startFetchStudyResults('semaphore'));
    },
    openMoreFilters() {
      dispatch(closeableActions.open(INSTRUMENT_FILTERS_ID));
    }
  })
)(StopLightResults);