exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___center {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n.src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___mainMessage,\n.src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___secondaryMessage {\n  font-weight: bold;\n  text-align: center;\n  width: 65%;\n}\n\n.src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___mainMessage {\n  font-size: 3em;\n}\n\n.src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___secondaryMessage {\n  font-size: 2em;\n}\n\n.src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___dangerMessage {\n  color: #f74742;\n  font-style: inherit;\n}\n\n.src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___footer {\n  margin-top: 1.5em;\n  margin-bottom: 4em;\n}\n\n@media only screen and (max-width: 480px) {\n  .src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___footer {\n    display: grid;\n    grid-gap: 1.5em;\n    overflow: hidden;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"center": "src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___center",
	"mainMessage": "src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___mainMessage",
	"secondaryMessage": "src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___secondaryMessage",
	"dangerMessage": "src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___dangerMessage",
	"footer": "src-scripts-components-DeleteParticipantPopup-__deleteParticipantPopup___footer"
};