// @flow
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupCloseButton from '../PopupCloseButton';
import Button from '../Button';
import UpdateStudyForm from '../UpdateStudyForm';

import { ID_TYPE } from '../../types/common';
import styles from './studiesFormPopup.css';
import * as selectors from '../../reducers';
import * as actions from '../../actions/studies';

export const UPDATE_STUDY_POPUP = 'UpdateStudyPopup';

type StudiesFormPopupType = {
  studyId: ID_TYPE,
  onLoad: Function,
};


const StudiesFormPopup = ({
  studyId,
  onLoad,
}: StudiesFormPopupType) => {
  useEffect(() => {
    onLoad(studyId);
  }, []);

  return (
    <Popup
      theme='main'
      transitionMs={600}
      id={UPDATE_STUDY_POPUP}
    >
      <PopupContainer isFullscreen>
        <PopupCloseButton
          isRound
          Icon={MdClose}
          tint="mediumGray"
          className={styles.close}
        />
        <div className={styles.content}>
          <UpdateStudyForm />
        </div>
      </PopupContainer>
    </Popup>
  )
};

export default connect(
  state => ({
    studyId: (selectors.getSelectedStudy(state) || {}).id,
  }),
  dispatch => ({
    onLoad() {
      // dispatch(actions.startFetchStudy(id));
    }
  }),
)(StudiesFormPopup);