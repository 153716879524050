// @flow
import React from 'react';

import Popup from '../Popup';
import OfflineModeContent from '../OfflineModePopupContent';

const OfflineModePopup = () => (
  <Popup
    id="initOfflineMode"
    theme="main"
    transitionMs={600}
    dontCloseOnEscape
  >
    <OfflineModeContent/>
  </Popup>
);


export default OfflineModePopup;
