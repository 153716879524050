// @flow
import React from 'react';

import styles from './inputError.css';


type InputErrorPropTypes = {
  title: string
};

const InputError = ({ title }: InputErrorPropTypes) => (
  <div className={styles.error}>
    { title }
  </div>
);


export default InputError;
