// @flow
import React from 'react';

import type { ID_TYPE } from '../../types/common';
import Switch from '../Switch';

import styles from './multiSelect.css';

/*
michelle castellanos de citymax
$650.00, sin linea blanca, sin muebles, excepto closets
*/

type OPTION_TYPE = {
  value: ID_TYPE,
  label: string,
};


type MultiSelectPropTypes = {
  value?: Array<ID_TYPE>,
  options: Array<OPTION_TYPE>,
  onChange?: Function,
  // onFocus?: Function,
  onBlur?: Function,
  selectedTint?: string,
  deselectedTint?: string,
  // elementComponent: Function,
  disabled?: Array<ID_TYPE>,
};

class MultiSelect extends React.Component<MultiSelectPropTypes> {
  render() {
    const {
      value = [],
      options,
      onChange,
      // onFocus,
      onBlur,
      selectedTint,
      deselectedTint,
      // elementComponent: Function,
      disabled = [],
    } = this.props;

    return (
      <div className={styles.multiSelectContainer}>
        {
          options.map(option => (
            <Switch
              activeTint={selectedTint}
              inactiveTint={deselectedTint}
              key={option.value}
              onChange={
                (isSelected) => {
                  // eslint-disable-next-line react/destructuring-assignment
                  const lValue = this.props.value || [];
                  if (isSelected) {
                    if (!lValue.includes(option.value)) {
                      return onChange && onChange([...lValue, option.value]);
                    }
                  } else if (lValue.includes(option.value)) {
                    return onChange && onChange(lValue.filter(v => v !== option.value));
                  }

                  return undefined;
                }
              }
              value={value.includes(option.value)}
              trueTitle={option.label}
              falseTitle={option.label}
              isSingleButton
              // eslint-disable-next-line react/destructuring-assignment
              onBlur={() => onBlur && onBlur(this.props.value)}
              isDisabled={disabled.includes(option.value)}
            />
          ))
        }
      </div>
    );
  }
}


export default MultiSelect;
