// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createHistory from 'history/createBrowserHistory';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es'; 

import { i18nSetup } from './i18n';
import './assets/styles/base.css';
import configRouter from './scripts/router';

import configureStore from './scripts/store/configureStore';
import registerServiceWorker from './scripts/registerServiceWorker';


const history = createHistory();
const { store, persistor } = configureStore(history);
const Router = configRouter(history);

const locale = (navigator.languages && navigator.languages[0])
  || navigator.language
  || navigator.userLanguage
  || 'es-MX';

moment.tz.setDefault("America/Guatemala");


addLocaleData([...en, ...es]);
i18nSetup(locale);

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={locale}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </IntlProvider>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);

registerServiceWorker();
