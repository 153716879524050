// @flow
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import range from 'lodash/range';

import StudyForm from '../StudyForm';
import validate from '../AddStudyForm/validation';

import * as selectors from '../../reducers';
import * as actions from '../../actions/studies';

export const UPDATE_FORM_ID = 'updateStudyForm';


const onSubmit = (values, dispatch) => {
  const numberOfPhases = parseInt(values.phases, 10) + 1;

  const phases = values.phases ? range(1, numberOfPhases) : null;
  const users = values.users.map(el => el.id);

  dispatch(actions.startUpdateStudy({
    ...values,
    phases,
    users,
  }));
};

export default reduxForm({
  form: UPDATE_FORM_ID,
  onSubmit,
  loadValues: true,
  validate,
})(StudyForm);
 