// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type ORGANIZATION_TYPE = {
  id: ID_TYPE,
  name: string,
  location: ID_TYPE,
  sublocation: ID_TYPE,
  users: Array<ID_TYPE>
};

// General Organizations
export type ORGANIZATIONS_FETCH_STARTED_TYPE = {
  type: 'ORGANIZATIONS_FETCH_STARTED',
};
export const ORGANIZATIONS_FETCH_STARTED = 'ORGANIZATIONS_FETCH_STARTED';

export type ORGANIZATIONS_FETCH_COMPLETED_TYPE = {
  type: 'ORGANIZATIONS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: ORGANIZATION_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const ORGANIZATIONS_FETCH_COMPLETED = 'ORGANIZATIONS_FETCH_COMPLETED';

export type ORGANIZATIONS_FETCH_FAILED_TYPE = {
  type: 'ORGANIZATIONS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const ORGANIZATIONS_FETCH_FAILED = 'ORGANIZATIONS_FETCH_FAILED';

// Paginated Organizations (listed on study/:id/organizations)
export type PAGINATED_ORGANIZATIONS_FETCH_STARTED_TYPE = {
  type: 'PAGINATED_ORGANIZATIONS_FETCH_STARTED',
};
export const PAGINATED_ORGANIZATIONS_FETCH_STARTED = 'PAGINATED_ORGANIZATIONS_FETCH_STARTED';

export type PAGINATED_ORGANIZATIONS_FETCH_COMPLETED_TYPE = {
  type: 'PAGINATED_ORGANIZATIONS_FETCH_COMPLETED',
  payload: {
    entities: {[ID_TYPE]: ORGANIZATION_TYPE},
    order: Array<ID_TYPE>,
  },
};
export const PAGINATED_ORGANIZATIONS_FETCH_COMPLETED = 'PAGINATED_ORGANIZATIONS_FETCH_COMPLETED';

export type PAGINATED_ORGANIZATIONS_FETCH_FAILED_TYPE = {
  type: 'PAGINATED_ORGANIZATIONS_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const PAGINATED_ORGANIZATIONS_FETCH_FAILED = 'PAGINATED_ORGANIZATIONS_FETCH_FAILED';


export type ORGANIZATION_SELECTED_TYPE = {
  type: 'ORGANIZATION_SELECTED',
  payload: ID_TYPE,
};
export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';


export type ORGANIZATIONS_ADD_STARTED_TYPE = {
  type: 'ORGANIZATIONS_ADD_STARTED',
  payload: {},
};
export const ORGANIZATIONS_ADD_STARTED = 'ORGANIZATIONS_ADD_STARTED';

export type ORGANIZATIONS_ADD_COMPLETED_TYPE = {
  type: 'ORGANIZATIONS_ADD_COMPLETED',
  payload: {},
};
export const ORGANIZATIONS_ADD_COMPLETED = 'ORGANIZATIONS_ADD_COMPLETED';

export type ORGANIZATIONS_ADD_FAILED_TYPE = {
  type: 'ORGANIZATIONS_ADD_FAILED',
  payload: {},
};
export const ORGANIZATIONS_ADD_FAILED = 'ORGANIZATIONS_ADD_FAILED';

export type ORGANIZATIONS_UPDATE_STARTED_TYPE = {
  type: 'ORGANIZATIONS_UPDATE_STARTED',
  payload: {},
};
export const ORGANIZATIONS_UPDATE_STARTED = 'ORGANIZATIONS_UPDATE_STARTED';

export type ORGANIZATIONS_UPDATE_COMPLETED_TYPE = {
  type: 'ORGANIZATIONS_UPDATE_COMPLETED',
  payload: {},
};
export const ORGANIZATIONS_UPDATE_COMPLETED = 'ORGANIZATIONS_UPDATE_COMPLETED';

export type ORGANIZATIONS_UPDATE_FAILED_TYPE = {
  type: 'ORGANIZATIONS_UPDATE_FAILED',
  payload: {},
};
export const ORGANIZATIONS_UPDATE_FAILED = 'ORGANIZATIONS_UPDATE_FAILED';

export type ORGANIZATIONS_REMOVED_TYPE = {
  type: 'ORGANIZATIONS_REMOVED',
  payload: {},
};
export const ORGANIZATIONS_REMOVED = 'ORGANIZATIONS_REMOVED';

export type ORGANIZATIONS_REMOVE_STARTED_TYPE = {
  type: 'ORGANIZATIONS_REMOVE_STARTED',
  payload: {},
};
export const ORGANIZATIONS_REMOVE_STARTED = 'ORGANIZATIONS_REMOVE_STARTED';

export type ORGANIZATIONS_REMOVE_COMPLETED_TYPE = {
  type: 'ORGANIZATIONS_REMOVE_COMPLETED',
  payload: {},
};
export const ORGANIZATIONS_REMOVE_COMPLETED = 'ORGANIZATIONS_REMOVE_COMPLETED';

export type ORGANIZATIONS_REMOVE_FAILED_TYPE = {
  type: 'ORGANIZATIONS_REMOVE_FAILED',
  payload: {},
};
export const ORGANIZATIONS_REMOVE_FAILED = 'ORGANIZATIONS_REMOVE_FAILED';

export type ORGANIZATIONS_FILTERS_UPDATED_TYPE = {
  type: 'ORGANIZATIONS_FILTERS_UPDATED',
  payload: Object,
};
export const ORGANIZATIONS_FILTERS_UPDATED = 'ORGANIZATIONS_FILTERS_UPDATED';


export type ALL_ORGANIZATIONS_UNSELECTED_TYPE = {
  type: 'ALL_ORGANIZATIONS_UNSELECTED',
};
export const ALL_ORGANIZATIONS_UNSELECTED = 'ALL_ORGANIZATIONS_UNSELECTED';

export type ORGANIZATIONS_ACTION_TYPE =
  | ORGANIZATIONS_FETCH_STARTED_TYPE
  | ORGANIZATIONS_FETCH_COMPLETED_TYPE
  | ORGANIZATIONS_FETCH_FAILED_TYPE
  | PAGINATED_ORGANIZATIONS_FETCH_STARTED_TYPE
  | PAGINATED_ORGANIZATIONS_FETCH_COMPLETED_TYPE
  | PAGINATED_ORGANIZATIONS_FETCH_FAILED_TYPE
  | ORGANIZATIONS_ADD_STARTED_TYPE
  | ORGANIZATIONS_ADD_COMPLETED_TYPE
  | ORGANIZATIONS_ADD_FAILED_TYPE
  | ORGANIZATIONS_UPDATE_STARTED_TYPE
  | ORGANIZATIONS_UPDATE_COMPLETED_TYPE
  | ORGANIZATIONS_UPDATE_FAILED_TYPE
  | ORGANIZATIONS_REMOVED_TYPE
  | ORGANIZATIONS_REMOVE_STARTED_TYPE
  | ORGANIZATIONS_REMOVE_COMPLETED_TYPE
  | ORGANIZATIONS_REMOVE_FAILED_TYPE
  | ORGANIZATION_SELECTED_TYPE
  | ORGANIZATIONS_FILTERS_UPDATED_TYPE
  | ALL_ORGANIZATIONS_UNSELECTED_TYPE;
