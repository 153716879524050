// @flow
import React from 'react';
import { MdClose } from 'react-icons/lib/md';

import Popup from '../Popup';
import PopupContainer from '../PopupContainer';
import PopupHeader from '../PopupHeader';
import PopupHeaderRightGroup from '../PopupHeaderRightGroup';
import PopupHeaderLeftGroup from '../PopupHeaderLeftGroup';
import PopupCloseButton from '../PopupCloseButton';
import PopupContent from '../PopupContent';
import FiltersContent from './filters';
import styles from './popupStudyFilters.css';


export const INSTRUMENT_FILTERS_ID = 'InstrumentFilters';

const PopupInstrumentFilters = () => (
  <Popup id={INSTRUMENT_FILTERS_ID}>
    <PopupContainer
      elevation={8}
      isRound
      size="small"
    >
      <PopupHeader
        isAlert
        className={styles.header}
      >
        <PopupHeaderLeftGroup>
          <div className={styles.title}>
            {"Más filtros"}
          </div>
        </PopupHeaderLeftGroup>
        <PopupHeaderRightGroup>
          <PopupCloseButton
            tint="mediumGray"
            Icon={MdClose}
            clearMargins
            isTransparent
            isOutlined
          />
        </PopupHeaderRightGroup>
      </PopupHeader>
      <FiltersContent />
    </PopupContainer>
  </Popup>
);

export default PopupInstrumentFilters;
