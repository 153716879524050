export default {
  titles: {
    contacts: 'Contacts',
    personalInfo: 'Personal Info',
  },
  labels: {
    requiredField: 'This field is required',
    name: {
      one: 'Name',
      other: 'Names',
    },
    userType: 'User Type',
    lastName: {
      one: 'Last Name',
      other: 'Last Names',
    },
    phone: 'Phone',
    code: 'Code',
    email: 'Email',
    address: 'Address',
    assignedRoutes: 'Assigned Routes',
    monitor: {
      one: 'Monitor',
      other: 'Monitors',
    },
    driver: 'Driver',
    type: 'Type',
    stops: 'Stops',
    route: {
      one: 'Route',
      other: 'Routes',
    },
    allowsChangeRequests: 'Allows Change Requests',
    allowsEntry: 'Allows Entry',
    allowsExit: 'Allows Exit',
    students: {
      one: 'Student',
      other: 'Students',
    },
    parent: {
      one: 'Parent',
      other: 'Parents',
    },
    plates: 'Plates',
    capacity: 'Capacity',
    year: 'Year',
    identifier: 'Identifier',
  },
  buttons: {
    cancel: 'Cancel',
    delete: 'Delete',
    // add: 'Add',
    create: 'Create {{Item}}',
    requestChange: 'Request Stop Change',
    pending: 'Pending',
    solved: 'Solved',
    aprove: 'Aprove',
    reject: 'Reject',
    send: 'Send',
    dismissAll: 'Dismiss All',
    edit: 'Edit',
    exploreStops: 'Explore Stops',
    add: 'Agregar',
  },
  common: {
    addTo: 'Add {{element}} to {{to}}',
    goingTo: 'Traveling to:',
    referenceOrAddress: 'point of reference or address',
    calendarization: 'Calendarization',
    settings: 'Settings',
    liveMap: 'Live map',
    changeStopRequest: {
      one: 'Change Stop Request',
      other: 'Change Stop Requests',
    },
    user: {
      one: 'User',
      other: 'Users',
    },
    routesAndStops: 'Routes and Stops',
    bus: {
      one: 'Bus',
      other: 'Buses',
    },
    gateway: {
      one: 'Gateway',
      other: 'Gateways',
    },
    gate: {
      one: 'Gate',
      other: 'Gates',
    },
    reports: 'Reports',
    results: 'results',
    notifications: 'Notifications',
    passengers: {
      one: '1 passanger',
      other: '{{count}} passangers',
    },
    search: 'Search...',
    searchItem: {
      one: 'Search for a {{Item}} here...',
      other: 'Search for {{Item}} here...',
    },
    booleanTrue: 'Yes',
    booleanFalse: 'No',
    loading: 'Loading {{Item}}...',
    noResults: "There doesn't appear to be any {{Item}}",
    confirmDeletion: 'Are you sure you want to delete {{Item}}?',
    all: 'All',
  },
  changeStopRequests: {
    changeFor: 'Change Stop Request for ',
    fromTo: 'From {{stop}} to {{stop2}}',
    requestDate: 'Requested for dates ranging from {{date}} to {{date2}} by {{user}}',
    requestType: 'Request Type',
    dateRange: 'Range of Dates to apply change',
    toStop: 'Change to',
    commentary: 'Commentary',
    commented: "{{user}}'s commentary:",
    reason: 'Reason',
    entry: 'Entry',
    exit: 'Exit',
    from: 'from',
    to: {
      one: 'to',
      other: 'until'
    },
    requestForDates: 'Requested for dates from',
    by: 'by',
  },
  gateways: {
    creatingGateway: 'Creating Gateway',
    creatingGate: 'Creating Gate',
    addStudent: 'Add a student to this gateway',
    addStudentToParent: 'Add a student to the parent',
  },
  routes: {
    expectedDepartureTime: 'Expected Departure Time',
    expectedArrivalTime: 'Expected Arrival Time',
    routeTimeFrame: 'Days the route is active',
    recolection: 'Recolection',
    delivery: 'Delivery',
  },
  reports: {
    element: 'element',
    entryExitHours: 'Arrival and Departure Hours',
    travelAttendance: 'Travel Attendance',
    stopsByTravel: 'Stops per Trip',
    routesStudentList: "'Route's Student List",
    changeStopRequests: 'Change Stop Requests',
    travelCount: 'Travel Count by Student',
    searchFor: 'Search for the element you want to generate a report with',
    helpText: 'Usually a route, student or school',
  },
  weekdays: {
    mon: 'Monday',
    tues: 'Tuesday',
    wed: 'Wednesday',
    thurs: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  },
  notifications: {
    for: 'For:',
    title: 'Title:',
    writeMessage: 'Write your message here...',
    onlyRoute: 'Notify students in route only',
  },
  temporalBanners: {
    defaultError: 'An error has ocurred',
    fetchError: 'An error has ocurred fetching {{Item}}',
    updateError: 'An error has ocurred updating {{Item}}',
    createError: 'An error has ocurred creating {{Item}}',
    removeError: 'An error has ocurred deleting {{Item}}',
    addError: 'An error has ocurred adding {{Item}}',
    unasignError: 'An error has ocurred unasigning {{Item}}',
    accessCodeError: 'An error has ocurred generating access code',
    update: '{{Item}} successfully updated',
    create: '{{Item}} successfully created',
    remove: '{{Item}} successfully eliminated',
    add: '{{Item}} successfully added',
    unasign: '{{Item}} successfully unasigned',
  },
  flashMessages: {
    tripStarted: "{{Route}}'s trip has started",
    busArrived: "{{Route}}'s bus has arrived at stop: {{Stop}}",
    tripFinished: "{{Route}}'s trip has finished",
  },
};
