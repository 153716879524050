exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/font.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "\n.src-scripts-components-ListPlaceholder-__listPlaceholder___container {\n  display: flex;\n  flex: 1 1;\n  align-items: center;\n  justify-content: center;\n  color: var(--mediumGrayColor);\n  font-weight: bold;\n  font-family: var(--primaryFont);\n  font-size: 2.2rem;\n  text-transform: uppercase;\n  min-height: 30rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "src-scripts-components-ListPlaceholder-__listPlaceholder___container"
};