// @flow
import type { ID_TYPE, ERROR_TYPE } from './common';

export type INSTRUMENT_SUBMISSION_TYPE = {
  id: ID_TYPE,
  study: ID_TYPE,
  phase: ID_TYPE,
  instrument: ID_TYPE,
  participant: ID_TYPE,
  answers: Array<number>,
  creation_date: Date,
};

export type INSTRUMENT_SUBMISSIONS_FETCH_STARTED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_FETCH_STARTED',
  payload: {
    page: number,
  },
};
export const INSTRUMENT_SUBMISSIONS_FETCH_STARTED = 'INSTRUMENT_SUBMISSIONS_FETCH_STARTED';

export type INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED',
  payload: {
    entities: {[number]: INSTRUMENT_SUBMISSION_TYPE},
    order: Array<number>,
    nextPage: ?number,
  },
};
export const INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED = 'INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED';

export type INSTRUMENT_SUBMISSIONS_FETCH_FAILED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_FETCH_FAILED',
  payload: {},
};
export const INSTRUMENT_SUBMISSIONS_FETCH_FAILED = 'INSTRUMENT_SUBMISSIONS_FETCH_FAILED';



export type INSTRUMENT_SUBMISSIONS_ADD_STARTED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_ADD_STARTED',
  payload: {
    values: Object
  },
};
export const INSTRUMENT_SUBMISSIONS_ADD_STARTED = 'INSTRUMENT_SUBMISSIONS_ADD_STARTED';

export type INSTRUMENT_SUBMISSIONS_ADD_COMPLETED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_ADD_COMPLETED',
  payload: {},
};
export const INSTRUMENT_SUBMISSIONS_ADD_COMPLETED = 'INSTRUMENT_SUBMISSIONS_ADD_COMPLETED';

export type INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED',
  payload: {},
};
export const INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED = 'INSTRUMENT_SUBMISSIONS_OFFLINE_ADD_COMPLETED';


export type INSTRUMENT_SUBMISSIONS_ADD_FAILED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_ADD_FAILED',
  payload: {},
};
export const INSTRUMENT_SUBMISSIONS_ADD_FAILED = 'INSTRUMENT_SUBMISSIONS_ADD_FAILED';

export type INSTRUMENT_SUBMISSIONS_UPDATE_STARTED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_UPDATE_STARTED',
  payload: INSTRUMENT_SUBMISSION_TYPE,
};
export const INSTRUMENT_SUBMISSIONS_UPDATE_STARTED = 'INSTRUMENT_SUBMISSIONS_UPDATE_STARTED';

export type INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED',
};
export const INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED = 'INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED';

export type INSTRUMENT_SUBMISSIONS_UPDATE_FAILED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_UPDATE_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_SUBMISSIONS_UPDATE_FAILED = 'INSTRUMENT_SUBMISSIONS_UPDATE_FAILED';

// Delete submission types
export type INSTRUMENT_SUBMISSIONS_REMOVED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_REMOVED',
  payload: ID_TYPE,
};
export const INSTRUMENT_SUBMISSIONS_REMOVED = 'INSTRUMENT_SUBMISSIONS_REMOVED';

export type INSTRUMENT_SUBMISSIONS_REMOVE_FAILED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_REMOVE_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_SUBMISSIONS_REMOVE_FAILED = 'INSTRUMENT_SUBMISSIONS_REMOVE_FAILED';

export type INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED_TYPE = {
  type: 'INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED',
  payload: {
    key: string,
    value: any,
  }
};
export const INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED = 'INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED';

export type INSTRUMENT_SUBMISSION_SELECTED_TYPE = {
  type: 'INSTRUMENT_SUBMISSION_SELECTED',
  payload: ID_TYPE,
};
export const INSTRUMENT_SUBMISSION_SELECTED = 'INSTRUMENT_SUBMISSION_SELECTED';

export type UNSELECT_ALL_INSTRUMENT_SUBMISSIONS_TYPE = {
  type: 'UNSELECT_ALL_INSTRUMENT_SUBMISSIONS',
};
export const UNSELECT_ALL_INSTRUMENT_SUBMISSIONS = 'UNSELECT_ALL_INSTRUMENT_SUBMISSIONS';

export type INSTRUMENT_SUBMISSION_FETCH_STARTED_TYPE = {
  type: 'INSTRUMENT_SUBMISSION_FETCH_STARTED',
  payload: ID_TYPE,
};
export const INSTRUMENT_SUBMISSION_FETCH_STARTED = 'INSTRUMENT_SUBMISSION_FETCH_STARTED';

export type INSTRUMENT_SUBMISSION_FETCH_COMPLETED_TYPE = {
  type: 'INSTRUMENT_SUBMISSION_FETCH_COMPLETED',
  payload: INSTRUMENT_SUBMISSION_TYPE,
};
export const INSTRUMENT_SUBMISSION_FETCH_COMPLETED = 'INSTRUMENT_SUBMISSION_FETCH_COMPLETED';

export type INSTRUMENT_SUBMISSION_FETCH_FAILED_TYPE = {
  type: 'INSTRUMENT_SUBMISSION_FETCH_FAILED',
  payload: ERROR_TYPE,
};
export const INSTRUMENT_SUBMISSION_FETCH_FAILED = 'INSTRUMENT_SUBMISSION_FETCH_FAILED';

export type REQUIRED_VALUES_ERROR_TYPE = {
  type: 'REQUIRED_VALUES_ERROR',
  payload: Array<ID_TYPE>,
};
export const REQUIRED_VALUES_ERROR = 'REQUIRED_VALUES_ERROR';

export type CLEAR_REQUIRED_VALUES_ERROR_TYPE = {
  type: 'CLEAR_REQUIRED_VALUES_ERROR',
};
export const CLEAR_REQUIRED_VALUES_ERROR = 'CLEAR_REQUIRED_VALUES_ERROR';


export type INSTRUMENT_SUBMISSIONS_ACTION_TYPE =
  | INSTRUMENT_SUBMISSIONS_FETCH_STARTED_TYPE
  | INSTRUMENT_SUBMISSIONS_FETCH_COMPLETED_TYPE
  | INSTRUMENT_SUBMISSIONS_FETCH_FAILED_TYPE
  | INSTRUMENT_SUBMISSIONS_ADD_STARTED_TYPE
  | INSTRUMENT_SUBMISSIONS_ADD_COMPLETED_TYPE
  | INSTRUMENT_SUBMISSIONS_ADD_OFFLINE_COMPLETED_TYPE
  | INSTRUMENT_SUBMISSIONS_ADD_FAILED_TYPE
  | INSTRUMENT_SUBMISSIONS_UPDATE_STARTED_TYPE
  | INSTRUMENT_SUBMISSIONS_UPDATE_COMPLETED_TYPE
  | INSTRUMENT_SUBMISSIONS_UPDATE_FAILED_TYPE
  | INSTRUMENT_SUBMISSIONS_REMOVED_TYPE
  | INSTRUMENT_SUBMISSIONS_REMOVE_FAILED_TYPE
  | INSTRUMENT_SUBMISSIONS_FILTERS_UPDATED_TYPE
  | INSTRUMENT_SUBMISSION_SELECTED_TYPE
  | UNSELECT_ALL_INSTRUMENT_SUBMISSIONS_TYPE
  | INSTRUMENT_SUBMISSION_FETCH_STARTED_TYPE
  | INSTRUMENT_SUBMISSION_FETCH_COMPLETED_TYPE
  | INSTRUMENT_SUBMISSION_FETCH_FAILED_TYPE
  | REQUIRED_VALUES_ERROR_TYPE
  | CLEAR_REQUIRED_VALUES_ERROR_TYPE;
