// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Waypoint from 'react-waypoint';

import Table from './table';
import { FullLoader } from '../Loader';
import FullPlaceholder from '../ListPlaceholder';
import OrganizationGraphicsPopup from '../OrganizationGraphicsPopup';

import * as selectors from '../../reducers';
import * as actions from '../../actions/organizations';
import * as closeableActions from '../../actions/closeable';


type OrganizationsListPropTypes = {
  organizations: Array<Object>,
  isFetching: boolean,
  error: ERROR_TYPE,
  hasMore: boolean,
  fetchOrganizations: Function,
  openOrganizationGraphics: Function,
};


const OrganizationsList = ({
  organizations = [],
  submissions,
  isFetching,
  hasMore,
  fetchOrganizations,
  openOrganizationGraphics,
}: OrganizationsListPropTypes) => (
  <div style={{ maxHeight: '62rem', overflow: 'scroll', marginBottom: "8rem" }}>
    {
      organizations.length > 0 && (
        <Fragment>
          <Table
            data={organizations}
            openOrganizationGraphics={openOrganizationGraphics}
          />
          <Waypoint
            onEnter={() => hasMore && fetchOrganizations()}
            viewportBottom={10}
          />
        </Fragment>
      )
    }
    {
      isFetching && (
        <FullLoader
          tint="mediumGray"
          title="Cargando ingresos"
        />
      )
    }
    {
      organizations.length === 0 && !isFetching && (
        <FullPlaceholder />
      )
    }
    <OrganizationGraphicsPopup />
  </div>
);

export default connect(
  state => ({
    hasMore: selectors.hasMoreOrganizations(state),
    isFetching: selectors.isFetchingOrganizations(state),
    organizations: selectors.getPaginatedOrganizations(state),
  }),
  dispatch => ({
    fetchOrganizations() {
      dispatch(actions.startFetchPaginatedOrganizations());
    },
    openOrganizationGraphics(id) {
      dispatch(actions.selectOrganization(id));
      dispatch(closeableActions.open('organizationGraphics'));
    }
  }),
)(OrganizationsList);
