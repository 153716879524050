// @flow
import type { ComponentType } from 'react';
import React from 'react';

import type { ButtonCellPropTypes } from '../Cells/Button';
import Cell from '../Cells/Button';


type ButtonPropsTypes = {
  Header?: string | ComponentType<*> | React$Node,
  accessor: string | Function,
  buttonProps?: Object,
  getOnClick?: Object => Function,
  getHref?: Object => string,
};

const Button = ({
  Header,
  accessor,
  buttonProps = {},
  getOnClick,
  getHref,
  ...props
}: ButtonPropsTypes) => ({
  Header,
  accessor,
  Cell: ({ value, original }: { value: ButtonCellPropTypes, original: Object }) => {
    let extraProps = {};
    if (getOnClick) {
      extraProps = {
        ...extraProps,
        onClick: getOnClick(original),
      };
    }

    if (getHref) {
      extraProps = {
        ...extraProps,
        href: getHref(original),
        useAnchor: true,
      };
    }

    return <Cell {...buttonProps} {...value} {...extraProps} />;
  },
  ...props,
});


export default Button;
