// @flow
import React from "react";
import ReactApexChart from "react-apexcharts";

import { COLORS_BASE, COLORS_BASE_VALUES } from "../../utils/colors";

import styles from "./genericAnswersChart.css";

type ANSWER = {
  name: string,
  data: number,
};

type GenericAnswersChartPropTypes = {
  question: string,
  answers: Array<ANSWER>,
  colors: string[],
};

const GenericAnswersChart = ({
  question,
  answers,
  colors,
  ignoreStoplightColors = false,
}: GenericAnswersChartPropTypes) => {
  const finalColors = !ignoreStoplightColors 
    ? colors.map((col) => COLORS_BASE[col] || '#f89151')
    : colors.map((_, idx) => COLORS_BASE_VALUES[idx % COLORS_BASE_VALUES.length] || '#f89151')
  const totalItems = answers.map((x) => parseInt(x.data)).reduce((a, b) => a + b, 0)

  return (
    <>
      <p className={styles.chartTitle}>{question}</p>
      <ReactApexChart
        options={{
          chart: {
            type: "bar",
            animations: {
              enabled: false,
              animateGradually: {
                enabled: false,
              },
              dynamicAnimation: {
                enabled: false,
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              // Permite que cada barra sea de un color distinto
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: (value) => value +'%',
            style: {
              colors: ["#000"]
            }
          },
          legend: {
            // este muestra la etiqueta junto a su color
            // show: false
          },
          xaxis: {
            categories: answers.map((x) => x.name),
            labels: {
              // Este muestra los nombres abajo de las columnas
              show: false,
            },
          },
          yaxis: {
            forceNiceScale: false,
            max: 100,
            labels: {
              formatter: (value) => value.toFixed(0) +'%',
            },
          },
          colors: finalColors,
          tooltip: {
            y: {
              formatter: function(value, { series, dataPointIndex }) {
                const sum = series[0].reduce((a, b) => a + b, 0);
                const percent = (value / sum) * 100;
                return `${percent.toFixed(0)}% (${parseInt(answers[dataPointIndex].data)} respuestas)`
              },
            },
          },
        }}
        type="bar"
        // height={330}
        series={[{ data: answers.map((x) => ((totalItems && totalItems > 0 ? parseInt(x.data) / totalItems : 0) * 100.0).toFixed(0) ) }]}
      />
    </>
  );
};

export default GenericAnswersChart;
