// @flow
import type { ID_TYPE, ERROR_TYPE } from '../types/common';

import {
  STUDY_RESULTS_TYPE,
  FETCH_STUDY_RESULTS_STARTED_TYPE,
  FETCH_STUDY_RESULTS_COMPLETED_TYPE,
  UPDATE_STUDY_RESULTS_FILTERS_TYPE,
  CLEAR_STUDY_RESULTS_FILTERS_TYPE,
  INSTRUMENT_RESULTS_STARTED_TYPE,
  INSTRUMENT_RESULTS_COMPLETED_TYPE,
} from '../types/studyResults';
import * as types from '../types/studyResults';
import { error } from './common';


export const startFetchStudyResults = (id: ID_TYPE): FETCH_STUDY_RESULTS_STARTED_TYPE => ({
  type: types.FETCH_STUDY_RESULTS_STARTED,
  payload: {
    id
  },
});

export const completeFetchStudyResults = (
  entities: {[ID_TYPE]: STUDY_RESULTS_TYPE},
  order: Array<ID_TYPE>,
): FETCH_STUDY_RESULTS_COMPLETED_TYPE => ({
  type: types.FETCH_STUDY_RESULTS_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchStudyResults = error(types.FETCH_STUDY_RESULTS_FAILED);

export const updateStudyResultsFilters = (
  key: String,
  value: any,
): UPDATE_STUDY_RESULTS_FILTERS_TYPE => ({
  type: types.UPDATE_STUDY_RESULTS_FILTERS,
  payload: {
    key,
    value,
  }
});

export const clearStudyResultsFilters = (): CLEAR_STUDY_RESULTS_FILTERS_TYPE => ({
  type: types.CLEAR_STUDY_RESULTS_FILTERS,
})

export const startFetchInstrumentResults = (id: ID_TYPE): INSTRUMENT_RESULTS_STARTED_TYPE => ({
  type: types.INSTRUMENT_RESULTS_STARTED,
  payload: id,
});

export const completeFetchInstrumentsResults = (
  entities: {[ID_TYPE]: STUDY_RESULTS_TYPE},
  order: Array<ID_TYPE>,
): INSTRUMENT_RESULTS_COMPLETED_TYPE => ({
  type: types.INSTRUMENT_RESULTS_COMPLETED,
  payload: {
    entities,
    order,
  }
});

export const failFetchInstrumentResults = error(types.INSTRUMENT_RESULTS_FAILED);

