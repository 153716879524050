//@flow
import { combineReducers } from 'redux';

import type { ID_TYPE, ERROR_TYPE } from '../types/common';
import type { USER_TYPE } from '../types/users';
import * as common from './common';
import * as types from '../types/users';

export type UsersState = {
  byId: { [ID_TYPE]: USER_TYPE },
  order: Array<ID_TYPE>,
  isFetching: boolean,
  error: ERROR_TYPE,
  selected: number,
  counter: number,
  adding: boolean,
  updating: boolean,
};

const byId = common.byId({
  added: [types.USERS_ADD_COMPLETED],
  fetched: [types.USERS_FETCH_COMPLETED],
});

const order = common.order({
  added: [types.USERS_ADD_COMPLETED],
  fetched: [types.USERS_FETCH_COMPLETED],
  confirmed: [],
  preferPrepend: true,
});
 
const isFetching = common.isFetching({
  started: [types.USERS_FETCH_STARTED],
  succeed: [types.USERS_FETCH_COMPLETED],
  failed: [types.USERS_FETCH_FAILED],
});

const error = common.error({
  clear: [
    types.USERS_FETCH_STARTED,
    types.USERS_FETCH_COMPLETED,
    types.USERS_ADD_STARTED,
    types.USERS_ADD_COMPLETED,
    types.USER_ERROR_CLEANED,
  ],
  populate: [
    types.USERS_FETCH_FAILED,
    types.USERS_ADD_FAILED,
  ],
});

const selected = common.mux({
  selected: [types.USER_SELECTED],
  allDeselected: [],
  default: -1,
});

const counter = common.counter({
  incremented: [],
  decremented: [],
  reset: [],
});

const adding = common.isFetching({
  started: [types.USERS_ADD_STARTED],
  succeed: [types.USERS_ADD_COMPLETED],
  failed: [types.USERS_ADD_FAILED]
});

const users = combineReducers({
  byId,
  order,
  isFetching,
  error,
  selected,
  counter,
  adding
});


export default users;


// Selectors
export const getUser = (state: UsersState, id: ID_TYPE): ?USER_TYPE => state.byId[id];
export const getUsers = (state: UsersState): Array<?USER_TYPE> => state.order.map(i => getUser(state, i));
export const isFetchingUsers = (state: UsersState): boolean => state.isFetching;
export const getUsersError = (state: UsersState): ERROR_TYPE => state.error;
export const getSelectedUser = (state: UsersState): ?USER_TYPE => getUser(state, state.selected);
export const getUserCounter = (state: UsersState): number => state.counter;
export const isAddingUser = (state: UsersState): boolean => state.adding;
export const isUpdatingUser = (state: UsersState): boolean => state.updating;
