// @flow
import React from 'react';

import styles from './dataTable.css';

type PivotValuePropTypes = {
  subRows?: Array<Object>,
  value: string,
  className: string,
};

const PivotValue = ({ subRows, value, className = '', ...rest }: PivotValuePropTypes) => (
  <span className={`${styles.pivotValue} ${className}`} {...rest}>
    {value}
    {subRows ? `(${subRows.length})` : ''}
  </span>
);

export default PivotValue;
