// @flow
import { types } from 'nozzmo-redux-jwt';

import type {
  populateCurrentUserType,
  clearCurrentUserType,
} from '../types/login';

import * as loginTypes from '../types/login';

export const startLogin = (
  phone: string,
  code: string,
  deviceInfo: { deviceID: string },
) => ({
  type: types.LOGIN_STARTED,
  payload: {
    username: phone,
    password: code,
    deviceInfo,
  },
});

export const populateCurrentUser = (
  userData: Object,
): populateCurrentUserType => ({
  type: loginTypes.POPULATE_CURRENT_USER,
  payload: userData,
});

export const clearCurrentUser = (): populateCurrentUserType => ({
  type: loginTypes.CLEAR_CURRENT_USER,
});
