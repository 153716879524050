// @flow
import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

import type { ID_TYPE } from '../../types/common';
import styles from './multipleSelect.css';


type OPTION_TYPE = {
  value: ID_TYPE
};

type MultipleSelectPropTypes = {
  size?: string,
  value?: Array<ID_TYPE>,
  options: Array<OPTION_TYPE>,
  onChange?: OPTION_TYPE => void,
  size?: string,
  className?: string,
};


const MultipleSelect = ({
  value = [],
  options,
  onChange,
  size = '',
  className,
  ...props
}: MultipleSelectPropTypes) => {

  let newValues = [];
  (value || []).map(id => {
    newValues = [...newValues, ...options.filter(el => el.value === id)];
  });

  return (
    <ReactSelect
      {...props}
      className={
        `
          ${styles.select}
          ${{...styles}}
          ${styles[size] ? styles[size] : ''}
          ${className ? styles[className] : ''}
        `
      }
      isMulti
      classNamePrefix="react-multi-select"
      value={newValues}
      options={options}
      onChange={
        values => onChange && onChange([...values.map(el => el.value)])
      }
    />
  )
}


export default MultipleSelect;
