exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/font.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".src-scripts-components-Label-__label___label {\n  color: var(--almostGrayColor);\n  font-family: var(--primaryFont);\n  font-size: 1.8rem;\n  font-weight: 500;\n  margin-bottom: 1rem;\n  white-space: pre-wrap;\n  /* text-transform: uppercase; */\n}\n", ""]);
// Exports
exports.locals = {
	"label": "src-scripts-components-Label-__label___label"
};