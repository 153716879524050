exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../../../../node_modules/postcss-loader/src/index.js??postcss!../../../assets/styles/variables/colors.css");
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-scripts-components-AddKidForm-__addKidForm___section {\n  display: flex;\n  /* border: 1px solid red; */\n  flex-direction: column;\n  padding: 4rem;\n  border-bottom: 1px solid var(--mediumGrayColor);\n}\n\n.src-scripts-components-AddKidForm-__addKidForm___select {\n  align-self: center;\n  width: 50%;\n  margin-bottom: 4rem\n}\n\n.src-scripts-components-AddKidForm-__addKidForm___select h2 {\n    color: var(--almostGrayColor);\n    font-size: 1.8rem;\n    font-weight: 500;\n  }\n\n.src-scripts-components-AddKidForm-__addKidForm___row {\n  /* border: 1px solid green; */\n  display: flex;\n  flex-direction: row;\n  min-width: 100rem;\n}\n\n.src-scripts-components-AddKidForm-__addKidForm___col {\n  flex: 1 1;\n  padding: 2rem 1rem;\n}\n\n.src-scripts-components-AddKidForm-__addKidForm___form {\n  margin-bottom: 8rem;\n}\n\n.src-scripts-components-AddKidForm-__addKidForm___separator {\n  border-bottom: 1px solid var(--mediumGrayColor);\n  margin-top: 8rem;\n  margin-bottom: 1rem;\n  position: relative;\n  text-align: center\n}\n\n.src-scripts-components-AddKidForm-__addKidForm___separator span {\n    position: absolute;\n    color: var(--almostGrayColor);\n    font-size: 2rem;\n    font-weight: 800;\n    bottom: -3.2rem;\n    background: #fff;\n    padding: 2rem 3rem;\n    -webkit-transform: translateX(-9rem);\n            transform: translateX(-9rem);\n  }\n\n@media(max-width: 768px) {\n  .src-scripts-components-AddKidForm-__addKidForm___row {\n    flex-wrap: wrap;\n    justify-content: center;\n    min-width: 0;\n  }\n\n  .src-scripts-components-AddKidForm-__addKidForm___col {\n    flex: none;\n    min-width: 36rem;\n    width: auto;\n  }\n\n  .src-scripts-components-AddKidForm-__addKidForm___select {\n    width: 90%;\n    text-align: center;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"section": "src-scripts-components-AddKidForm-__addKidForm___section",
	"select": "src-scripts-components-AddKidForm-__addKidForm___select",
	"row": "src-scripts-components-AddKidForm-__addKidForm___row",
	"col": "src-scripts-components-AddKidForm-__addKidForm___col",
	"form": "src-scripts-components-AddKidForm-__addKidForm___form",
	"separator": "src-scripts-components-AddKidForm-__addKidForm___separator"
};