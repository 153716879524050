// @flow
import type { Node } from 'react';
import React from 'react';

import styles from './popupContent.css';


type PopupContentPropTypes = {
  children: Node,
  size?: string,
  clearPaddings?: boolean,
  isContrasted?: boolean,
  isRound?: boolean,
  className?: string,
  isBorderless?: boolean,
};

const PopupContent = ({
  children,
  size = 'medium',
  clearPaddings = false,
  isContrasted = false,
  isRound = false,
  className = '',
  isBorderless = false,
}: PopupContentPropTypes) => (
  <div className={
    `
      ${styles.popupContent}
      ${styles[size]}
      ${clearPaddings ? styles.clearPaddings : ''}
      ${isContrasted ? styles.contrasted : ''}
      ${isRound ? styles.round : ''}
      ${isBorderless ? styles.borderless : ''}
      ${className}
    `
  }
  >
    { children }
  </div>
);

export default PopupContent;
